import { useTranslation } from "common/locales";
import {
  FormControl,
  SelectChangeEvent,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { RESEED_TYPE } from "model";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

interface YieldTypeRadioButtonsProps {
  currentValue?: RESEED_TYPE;
  onChange: (e: RESEED_TYPE) => void;
}

export default function ReseedTypeRadioButtons<T>({
  onChange,
  currentValue,
}: YieldTypeRadioButtonsProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <FormControl fullWidth sx={{ margin: "30px 0px 30px 0px " }}>
      <RadioGroup
        row
        id="reseed-type-select"
        value={currentValue ?? ("" as unknown as RESEED_TYPE)}
        onChange={(e: SelectChangeEvent<RESEED_TYPE>) => {
          onChange(e.target.value as unknown as RESEED_TYPE);
        }}
      >
        {Object.entries(RESEED_TYPE).map(([key, value]) => {
          const text = t(`reseedEvent.reseedType.${value}`);
          const tooltipText = t(`reseedEvents.${value}.explanation`);
          return (
            <FormControlLabel
              sx={{ margin: "0px 40px 0px 0px " }}
              value={value as string}
              key={key}
              control={<Radio />}
              label={
                <>
                  {text}
                  <Tooltip title={tooltipText}>
                    <IconButton
                      style={{
                        transform: "scale(0.8) translate(-10px ,-10px)",
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                      edge="end"
                      size="large"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
