import { useState } from "react";
import styled from "styled-components";
import { Collapse, ListItem, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { selectAllArableFields, useAppSelector } from "model";
import { useTranslation } from "common/locales";
import { useSelector } from "react-redux";
import ArableFieldsList from "features/arableFieldList/ArableFieldsList";

export default function ArableFields() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const arableFields = useSelector(selectAllArableFields);
  const arableFieldIds = arableFields.map(({ guid }) => guid);

  return (
    <>
      <ListItem key="arable" button onClick={toggleOpen}>
        <ListItemText primary={t("fieldList.arable.fields")} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Nested in={open} timeout="auto" unmountOnExit>
        <ArableFieldsList
          emptyText={t("fieldlist.emptyArable")}
          fieldIds={arableFieldIds}
        />
      </Nested>
    </>
  );

  function toggleOpen() {
    setOpen(!open);
  }
}

const Nested = styled(Collapse)(
  ({ theme }) => `
  padding-left: ${theme.spacing(4)};
`
);
