import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useTranslation } from "common/locales";
import { Link } from "react-router-dom";
import { useAppSelector, selectAllArableFields, ArableField } from "model";
import FieldIcon from "features/fieldList/FieldIcon";
import {
  EmptyList,
  ListItemStyled,
  LoadingList,
} from "features/fieldList/FieldsList";
interface FieldsListProps {
  fieldIds?: string[];
  emptyText?: string;
}

export default function ArableFieldsList({
  fieldIds,
  emptyText,
}: FieldsListProps) {
  let arableFields = useAppSelector(selectAllArableFields);
  if (fieldIds)
    arableFields = arableFields.filter(({ guid }) => fieldIds.includes(guid));

  const loading = useAppSelector((state) => state.fields.loading);

  if (arableFields.length === 0 && loading !== "pending") {
    return <EmptyList text={emptyText} />;
  }

  if (arableFields.length === 0 && loading === "pending") {
    // TODO: this leads to constant blinking due to RefreshProvider
    return <LoadingList />;
  }

  return (
    <List>
      {arableFields.map((arableField: ArableField) => (
        <div key={`field-key-${arableField.guid}`}>
          <ArableFieldListItem key={arableField.guid} field={arableField} />
        </div>
      ))}
    </List>
  );
}

function ArableFieldListItem({ field: arableField }: ArableFieldListItemProps) {
  const { t } = useTranslation();

  return (
    <ListItemStyled>
      <ListItem button component={Link} to={`/field/${arableField.guid}`}>
        <ListItemAvatar>
          <FieldIcon geom={arableField.geom} dryMatterNow={100} />
        </ListItemAvatar>
        <ListItemText
          primary={arableField.name}
          secondary={t(
            `fieldinput.arableType.option.${arableField.arableType}`
          )}
        />
      </ListItem>
    </ListItemStyled>
  );
}

type ArableFieldListItemProps = {
  field: ArableField;
};
