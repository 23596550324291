import { CircularProgress, useTheme } from "@mui/material";
import { Source, Layer, MapRef, Marker } from "react-map-gl";
import { useSelector } from "react-redux";
import {
  getAnimalGroupColorMapping,
  RootState,
  getAllFieldGeoms,
  getFieldsWithNoTilesForNotDeletedFieldBoundaries,
  fieldWithAnimalsCenters,
  selectAllFieldsWithNotDeletedBoundaries,
  selectTmpDeletedFeatures,
  getFieldsCenterWithNoTilesForNotDeletedFieldBoundaries,
  selectAllFields,
  Field,
} from "model";
import { Feature, FeatureCollection } from "geojson";
import { useRouteMatch } from "react-router-dom";
import { groupBy } from "lodash";
import { MutableRefObject } from "react";
import AnimalsLocationMarker from "./AnimalsLocationMarker";
import DiaryEntryLocationMarkerWithScroll from "./DiaryEntryLocationMarkerWithScroll";

interface Props {
  editedFieldId?: string;
  fieldId?: string;
  mapRef: MutableRefObject<MapRef | null>;
  setTooltipOverMarker: (value: boolean) => void;
}

interface MatchProps {
  id: string;
}

export default function FieldGeoJSONLayers({
  editedFieldId,
  fieldId,
  mapRef,
  setTooltipOverMarker,
}: Props) {
  const theme = useTheme();
  const editMatch = useRouteMatch<MatchProps>("/edit-field/:id");
  const fieldGeoms = useSelector(getAllFieldGeoms);
  const currentFieldGeom = useSelector(
    (state: RootState) => fieldId && state.fields.entities[fieldId]?.geom
  );
  const tmpDeletedFeatures = useSelector(selectTmpDeletedFeatures);
  const fields = useSelector(selectAllFieldsWithNotDeletedBoundaries).filter(
    (field) => field.guid !== editedFieldId
  ); // TODO: move to selector
  const fieldsByAnimalGroups = groupBy(fields, "animalGroup");
  const fieldsWithNoTiles = useSelector(
    getFieldsWithNoTilesForNotDeletedFieldBoundaries
  ).filter((fieldGuid) => fieldGuid !== editedFieldId); // TODO: move to selector
  const colorByAnimalGroupId: Record<string, string> = useSelector(
    getAnimalGroupColorMapping
  );

  const fieldsWithNoTilesCenters = useSelector(
    getFieldsCenterWithNoTilesForNotDeletedFieldBoundaries
  ).filter((field) => field.guid !== editedFieldId); // TODO: move to selector;

  const fieldsWithAnimals = useSelector(fieldWithAnimalsCenters);
  const zoom = mapRef.current?.getMap()?.getZoom();
  const allFields = useSelector(selectAllFields);

  return (
    <>
      <Source
        id="field-outlines"
        data={fieldGeoms as FeatureCollection}
        type="geojson"
      >
        <Layer
          id="field-fill"
          type="fill"
          //filter out fill of field thats being edited
          filter={["!=", "id", editMatch?.params.id || ""]}
          paint={{ "fill-opacity": 0 }}
        />
        <Layer
          id="field-fill-no-data"
          type="fill"
          //only show this if a field has no tiles
          filter={["in", ["get", "id"], ["literal", fieldsWithNoTiles]]}
          paint={{ "fill-color": "#666666" }}
        />
        {Object.keys(fieldsByAnimalGroups).map((animalGroupId) => {
          const fieldIdsInAnimalGroup = fieldsByAnimalGroups[animalGroupId].map(
            ({ guid }) => guid
          );
          return (
            <Layer
              key={animalGroupId}
              id={`field-outlines-group-${animalGroupId}`}
              type="line"
              filter={["in", ["get", "id"], ["literal", fieldIdsInAnimalGroup]]}
              paint={{
                "line-color": colorByAnimalGroupId[animalGroupId] || "#666666",
                "line-width": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5, ["^", 2, -6]],
                  24,
                  ["*", 5, ["^", 2, 7]],
                ],
                "line-offset": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5 / 2 - 1, ["^", 2, -6]],
                  24,
                  ["*", 5 / 2 - 1, ["^", 2, 5]],
                ],
              }}
              layout={{
                "line-join": "miter",
              }}
            />
          );
        })}
      </Source>
      {currentFieldGeom &&
        fieldId &&
        fieldId !== editedFieldId &&
        tmpDeletedFeatures.indexOf(fieldId) === -1 && (
          <Source
            id="field-highlight"
            data={currentFieldGeom as Feature}
            type="geojson"
          >
            <Layer
              id="field-highlight"
              type="line"
              paint={{
                "line-color": "#FFFFFF",
                "line-width": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5, ["^", 2, -6]],
                  24,
                  ["*", 5, ["^", 2, 7]],
                ],
                "line-offset": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5 / 2 - 1, ["^", 2, -6]],
                  24,
                  ["*", 5 / 2 - 1, ["^", 2, 5]],
                ],
              }}
              layout={{
                "line-join": "miter",
              }}
            />
          </Source>
        )}
      {zoom &&
        zoom >= 13 &&
        !editedFieldId && // hiding animal markers when editing the field
        fieldsWithAnimals.map((field) => {
          const { coordinates } = field.center.geometry;
          return (
            <AnimalsLocationMarker
              zoom={zoom}
              coordinates={coordinates}
              htmlColor={field.color}
              key={field.guid}
              currentField={field}
              fields={fields}
            />
          );
        })}
      {zoom &&
        zoom >= 13 &&
        !editedFieldId && // hiding animal markers when editing the field
        allFields.map((field: Field) => {
          return (
            <DiaryEntryLocationMarkerWithScroll
              setTooltipOverMarker={setTooltipOverMarker}
              key={`diary-locarion-markers-${field.guid}`}
              zoom={zoom}
              currentField={field}
            />
          );
        })}

      {zoom &&
        zoom >= 15 &&
        fieldsWithNoTilesCenters.map((field) => {
          const { coordinates } = field.center.geometry;
          return (
            <Marker
              key={`no-tiles-field-${field.guid}`}
              longitude={coordinates[0]}
              latitude={coordinates[1]}
            >
              <CircularProgress size={20} />
            </Marker>
          );
        })}
    </>
  );
}
