import styled from "styled-components";
import { Popper, Typography, Paper } from "@mui/material";

interface HintPopperProps {
  open: boolean;
  title: string;
  text: string;
  parentEl?: HTMLElement | HTMLDivElement | null;
}

export default function HintPopper({
  open,
  parentEl,
  title,
  text,
}: HintPopperProps) {
  // TODO: if we want to have more than 1 hint-popover we should have unique id's
  const id = open ? "hint-popover" : undefined;
  return (
    <StyledPopper
      id={id}
      open={open}
      anchorEl={parentEl}
      placement="bottom"
      modifiers={[
        {
          name: "flip",
          enabled: false,
        },
        {
          name: "offset",
          enabled: true,
          options: { offset: "-150, 20" },
        },
      ]}
    >
      <StyledPaper>
        <Text>
          <Typography variant="h6">
            {title}
            <span role="img" aria-label="Moving Cow">
              🐄{" "}
            </span>
          </Typography>
          <Typography variant="body1">
            {text}
            <span role="img" aria-label="Pointing Up">
              ☝️
            </span>
          </Typography>
        </Text>
      </StyledPaper>
    </StyledPopper>
  );
}

const Text = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
`
);

const StyledPopper = styled(Popper)(
  ({ theme }) => `
  z-index: ${theme.zIndex.drawer + 1};
`
);

const StyledPaper = styled(Paper)`
  background-color: #454545;
  color: #ffffff;
`;
