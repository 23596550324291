import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { useTranslation } from "common/locales";
import { useHistory } from "react-router-dom";
import { mixpanel } from "common/analytics";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const InputsAndOutputs = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Container>
      <List>
        <ListItem>
          <ListItemText>
            <Typography>{t("toolbar.inputs.and.outputs.view")}</Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          onClick={() => {
            mixpanel.track("Add new re-seeding event clicked");
            history.push(`/grass-types-reseeding-detail`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <ChevronRightIcon
                sx={{
                  color: "black",
                  background: "#dbf4c2",
                  borderRadius: "25px",
                }}
              />
            </ListItemIcon>
            <ListItemText>{t("inputs.outputs.reseeding.title")}</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            mixpanel.track("Add new feed purchase event clicked");
            history.push(`/feed-overview`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <ChevronRightIcon
                sx={{
                  color: "black",
                  background: "#dbf4c2",
                  borderRadius: "25px",
                }}
              />
            </ListItemIcon>
            <ListItemText>
              {t("inputs.outputs.feed.purchase.title")}
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            mixpanel.track("Go to yields clicked");
            history.push(`/yield-overview`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <ChevronRightIcon
                sx={{
                  color: "black",
                  background: "#dbf4c2",
                  borderRadius: "25px",
                }}
              />
            </ListItemIcon>
            <ListItemText>{t("inputs.outputs.yield.title")}</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            mixpanel.track("Go to fertilizer clicked");
            history.push(`/fertilizer-overview`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <ChevronRightIcon
                sx={{
                  color: "black",
                  background: "#dbf4c2",
                  borderRadius: "25px",
                }}
              />
            </ListItemIcon>
            <ListItemText>{t("inputs.outputs.fertlizer.title")}</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            mixpanel.track("Go to fertilizer clicked");
            history.push(`/field-preparation-overview`);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <ChevronRightIcon
                sx={{
                  color: "black",
                  background: "#dbf4c2",
                  borderRadius: "25px",
                }}
              />
            </ListItemIcon>
            <ListItemText>
              {t("inputs.outputs.fieldPreparation.title")}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Container>
  );
};

export default InputsAndOutputs;
