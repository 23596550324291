import {
  InputAdornment,
  TextField,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { useAppSelector } from "model";
import { useTranslation } from "common/locales";

import styled, { css } from "styled-components";
import StockClassDemandTemplateAutocomplete from "./StockClassDemandTemplateAutocomplete";
import { useCallback, useMemo } from "react";
import { DefaultStockClasses } from "model/defaultStockClassesSlice";
import { StockClassWithBirthingPeriods } from "model/stockClassBirthingPeriodSlices";
import { FormikErrors } from "formik";
import StockClassBirthingPeriodInput from "./StockClassBirthingPeriodInput";
interface StockClassInputProps {
  handleInputChange: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  deletedStockClasses: Array<string>;
  onDelete: (guid: string) => void;
  index: number;
  stockClass: StockClassWithBirthingPeriods;
  remove: <T>(index: number) => T | undefined;
  feedBudgetPlannerEnabled: boolean;
  errors?: FormikErrors<StockClassWithBirthingPeriods>;
  validateField: any;
}

export default function StockClassInput({
  stockClass,
  index,
  remove,
  handleInputChange,
  deletedStockClasses,
  onDelete,
  setFieldValue,
  feedBudgetPlannerEnabled,
  errors,
  validateField,
}: StockClassInputProps) {
  const { t } = useTranslation();
  const defaultStockClasses: DefaultStockClasses = useAppSelector(
    (state) => state.defaultStockClasses.stockClasses
  );

  const selectedDefaultStockClass = useMemo(
    () =>
      defaultStockClasses.find(
        (d) => d.name === stockClass.defaultStockClassName
      ),
    [defaultStockClasses, stockClass]
  );

  const handleChangeNonNegative = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldName: string
    ) => {
      const value = parseFloat(e.target.value);
      if (!isNaN(value) && value < 0) {
        // do not allow negative values
        return;
      }
      handleInputChange(e, fieldName);
    },
    [handleInputChange]
  );

  // we need to keep demand % proportional to the average weight when it changes
  const onAverageWeightChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = parseFloat(e.target.value);
      if (!isNaN(value) && value < 0) {
        // do not allow negative values
        return;
      }
      const currentConsumptionFraction =
        !isNaN(stockClass.averageAnimalWeightKg) &&
        !isNaN(stockClass.consumptionKgPerDay) &&
        stockClass.averageAnimalWeightKg > 0
          ? stockClass.consumptionKgPerDay / stockClass.averageAnimalWeightKg
          : 0;

      setFieldValue(
        `stockClasses[${index}].consumptionKgPerDay`,
        ((value || 0) * currentConsumptionFraction).toFixed(2)
      );

      handleInputChange(e, `stockClasses[${index}].averageAnimalWeightKg`);
    },
    [index, stockClass]
  );

  const consumptionPercent =
    !isNaN(stockClass.consumptionKgPerDay) &&
    !isNaN(stockClass.averageAnimalWeightKg) &&
    stockClass.averageAnimalWeightKg > 0
      ? (
          (stockClass.consumptionKgPerDay / stockClass.averageAnimalWeightKg) *
          100
        ).toFixed(1)
      : 0;
  return (
    <StockClassCard variant="outlined">
      <CardHeader
        style={{ padding: "16px 16px 0 0" }}
        action={
          <IconButton
            onClick={() => {
              onDelete(stockClass.guid);
              remove(index);
            }}
            size="large"
          >
            <Delete />
          </IconButton>
        }
      />
      <CardContent style={{ padding: "0 42px 0 42px" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledGrid item>
            {feedBudgetPlannerEnabled ? (
              <Field
                label={t("edit.stockClassName.feedBudgetPlanner.label")}
                id={`stock-class-input-name-${index}`}
                name={`stockClasses[${index}].name`}
                value={stockClass.name}
                required
                fullWidth
                margin="normal"
                variant="standard"
                onChange={(e) =>
                  handleInputChange(e, `stockClasses[${index}].name`)
                }
                error={!!errors?.name}
                helperText={errors?.name}
              />
            ) : (
              <StyledFormControlAutocomplete fullWidth variant="standard">
                <StockClassDemandTemplateAutocomplete
                  setFieldValue={setFieldValue}
                  index={index}
                  stockClass={stockClass}
                  error={errors?.name}
                  handleInputChange={handleInputChange}
                  validateField={validateField}
                />
              </StyledFormControlAutocomplete>
            )}
          </StyledGrid>
          {feedBudgetPlannerEnabled && ( // only showing when feed budget planner is enabled
            <StyledGrid item>
              <StyledFormControl fullWidth variant="standard">
                <InputLabel
                  id={`stock-class-input-default-stock-class-name-${index}-label`}
                  variant="standard"
                >
                  {t("edit.stockClassDefaultStockClass.label")}
                </InputLabel>
                <Select
                  labelId={`stock-class-input-default-stock-class-name-${index}-label`}
                  id={`stock-class-input-default-stock-class-name-${index}`}
                  value={stockClass.defaultStockClassName}
                  name={`stockClasses[${index}].defaultStockClassName`}
                  label={t("edit.stockClassDefaultStockClass.label")}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      `stockClasses[${index}].defaultStockClassName`
                    )
                  }
                  variant="standard"
                >
                  {defaultStockClasses.map((defaultStockClass) => (
                    <MenuItem
                      value={defaultStockClass.name}
                      key={`stock-class-input-default-stock-class-menu-${index}-${defaultStockClass.name}`}
                    >
                      {t(`defaultStockClass.${defaultStockClass.name}`)}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </StyledGrid>
          )}
          <StyledGrid item>
            <Field
              fullWidth
              variant="standard"
              required
              id={`stock-class-input-weight-${index}`}
              type="number"
              name={`stockClasses[${index}].averageAnimalWeightKg`}
              value={stockClass.averageAnimalWeightKg}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
                inputProps: { min: 1 },
              }}
              onChange={onAverageWeightChange}
              label={t("edit.stockClassAvgWeight.label")}
              helperText={errors?.averageAnimalWeightKg}
              error={!!errors?.averageAnimalWeightKg}
            />
          </StyledGrid>
          <StyledGrid item>
            <Field
              fullWidth
              variant="standard"
              required
              id={`stock-class-input-animals-${index}`}
              type="number"
              name={`stockClasses[${index}].animalCount`}
              value={stockClass.animalCount}
              onChange={(e) =>
                handleChangeNonNegative(e, `stockClasses[${index}].animalCount`)
              }
              label={t("edit.stockClassNrOfAnimals.label")}
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              helperText={errors?.animalCount}
              error={!!errors?.animalCount}
            />
          </StyledGrid>
          {!feedBudgetPlannerEnabled && (
            <StyledGrid item>
              <Field
                fullWidth
                variant="standard"
                required
                id={`stock-class-input-demand-${index}`}
                type="number"
                name={`stockClasses[${index}].consumptionKgPerDay`}
                value={stockClass.consumptionKgPerDay}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{`kgDM (${consumptionPercent}%)`}</InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  handleChangeNonNegative(
                    e,
                    `stockClasses[${index}].consumptionKgPerDay`
                  )
                }
                label={t("edit.stockClassDemand.label")}
                helperText={errors?.consumptionKgPerDay}
                error={!!errors?.consumptionKgPerDay}
              />
            </StyledGrid>
          )}
          {feedBudgetPlannerEnabled &&
            selectedDefaultStockClass?.birthingAbility && (
              <StockClassBirthingPeriodInput
                stockClass={stockClass}
                index={index}
                handleInputChange={handleInputChange}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            )}
        </Grid>
      </CardContent>
    </StockClassCard>
  );
}

const Field = styled(TextField)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(2)};
  `
);

const StyledFormControlAutocomplete = styled(FormControl)(
  // fixing 8px margin-bottom on autocomplete
  ({ theme }) => css`
    & > .MuiAutocomplete-root > .MuiFormControl-fullWidth {
      margin-bottom: 0;
    }
  `
);

const StyledFormControl = styled(FormControl)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(2)};
    text-align: left;
  `
);

const StockClassCard = styled(Card)(
  ({ theme }) => css`
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  `
);

const StyledGrid = styled(Grid)`
  width: 100%;
`;
