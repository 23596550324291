import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityId,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { RootState } from "./rootReducer";
import {
  BEGINNING_OF_TIME,
  GENERAL_FEED_TYPE,
  GENERAL_FEED_UNITS,
  getFeedStock,
} from "./feedStockSlice";

export const FEED_EVENT_REJECTION_PERIOD_DAYS = 30;

export type GeneralFeedEvent = {
  animalGroupGuids: string[];
  guid: string;
  note: string;
  feedEventDate: string;
  feed: GeneralFeed[];
  creationDate?: string;
};

export type GeneralFeed = {
  unitType: GENERAL_FEED_UNITS;
  feedType: GENERAL_FEED_TYPE;
  amount: number;
};

class FeedAPI extends API<GeneralFeedEvent> {
  async getAllFeedEventsForFarm(
    farmGuid: string,
    feedEventTimeAfter?: string
  ): Promise<GeneralFeedEvent[]> {
    const feedEventTimeAfterParam = feedEventTimeAfter
      ? `&feedEventTimeAfter=${feedEventTimeAfter}`
      : "";
    const res = await fetch(
      `${this.baseUrl}${this.entity}?farmGuid=${farmGuid}${feedEventTimeAfterParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as GeneralFeedEvent[];
  }
}

const feedEventApi = new FeedAPI("feedEvent");

export const createFeedEvent = createAsyncThunk(
  "feedEvent/create",
  async (
    {
      generalFeedEvent,
      farmGuid,
    }: { generalFeedEvent: GeneralFeedEvent; farmGuid: string },
    { dispatch }
  ) => {
    const result = await feedEventApi.createOne(generalFeedEvent);
    await dispatch(
      getFeedStock({
        farmGuid: farmGuid,
        timeAfter: BEGINNING_OF_TIME,
      })
    );
    return { ...generalFeedEvent, ...result };
  }
);

export const updateFeedEvent = createAsyncThunk(
  "feedEvent/update",
  async (
    {
      generalFeedEvent,
      farmGuid,
    }: { generalFeedEvent: GeneralFeedEvent; farmGuid: string },
    { dispatch }
  ) => {
    const result = await feedEventApi.updateOne(generalFeedEvent);
    await dispatch(
      getFeedStock({
        farmGuid: farmGuid,
        timeAfter: BEGINNING_OF_TIME,
      })
    );
    return { ...generalFeedEvent, ...result };
  }
);

export const feedEventAdapter = createEntityAdapter<GeneralFeedEvent>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (feedEvent) => feedEvent.guid,
  // Keeps the array sorted by guid
  sortComparer: (a, b) => a.guid.localeCompare(b.guid),
});

export const { selectAll: selectAllFeedEvents } = feedEventAdapter.getSelectors(
  (state: RootState) => state.feedEvents
);

export const getAllFeedEventsForFarm = createAsyncThunk<
  Promise<Array<GeneralFeedEvent>>,
  { farmGuid: string; feedEventTimeAfter?: string }
>("readings/getFeedEventsBulk", async ({ farmGuid, feedEventTimeAfter }) => {
  return feedEventApi.getAllFeedEventsForFarm(farmGuid, feedEventTimeAfter);
});

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";
type FeedEventState = EntityState<GeneralFeedEvent> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialfeedEventState: FeedEventState = feedEventAdapter.getInitialState({
  loading: "idle",
  error: null,
  status: null,
});

const feedEventSlice = createSlice({
  name: "feedEvent",
  initialState: initialfeedEventState,
  reducers: {
    resetFeedEventEntries: () => initialfeedEventState,
  },
  extraReducers: {
    [createFeedEvent.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createFeedEvent.fulfilled.type]: (
      state,
      action: PayloadAction<GeneralFeedEvent>
    ) => {
      if (state.loading === "pending") {
        feedEventAdapter.upsertOne(state, action.payload);
        state.loading = "idle";
        state.status = "success";
      }
    },
    [createFeedEvent.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },

    [getAllFeedEventsForFarm.fulfilled.type]: (state, action) => {
      feedEventAdapter.setAll(
        state,
        action.payload as
          | readonly GeneralFeedEvent[]
          | Record<EntityId, GeneralFeedEvent>
      );
    },

    [getAllFeedEventsForFarm.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [getAllFeedEventsForFarm.fulfilled.type]: (
      state,
      action: PayloadAction<Array<GeneralFeedEvent>>
    ) => {
      feedEventAdapter.upsertMany(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [getAllFeedEventsForFarm.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
    [updateFeedEvent.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [updateFeedEvent.fulfilled.type]: (
      state,
      action: PayloadAction<GeneralFeedEvent>
    ) => {
      feedEventAdapter.upsertOne(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [updateFeedEvent.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
  },
});
export const { resetFeedEventEntries } = feedEventSlice.actions;
export const feedEventReducer = feedEventSlice.reducer;
