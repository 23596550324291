import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { ARABLE_TYPE } from "model";
import { RootState } from "./rootReducer";

export enum YIELD_TYPES {
  GRASS = "GRASS",
  MILK = "MILK",
  MEAT = "MEAT",
  CROP = "CROP",
}
export enum GRASS_YIELD_TYPES {
  HAY = "HAY",
  SILAGE = "SILAGE",
  HAYLAGE = "HAYLAGE",
}

enum CROP_BY_PRODUCT {
  STRAW = "STRAW",
}

export const CROP_YIELD_TYPE = {
  ...CROP_BY_PRODUCT,
  ...ARABLE_TYPE,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CROP_YIELD_TYPE = CROP_BY_PRODUCT | ARABLE_TYPE;

export type SingleYieldSummary = {
  yieldType:
    | CROP_YIELD_TYPE
    | GRASS_YIELD_TYPES
    | YIELD_TYPES.MEAT
    | YIELD_TYPES.MILK;
  amount: number;
  revenue: number;
};

export class YieldSummary {
  yieldName!: YIELD_TYPES;
  yieldSummaries!: SingleYieldSummary[];
}

export type YieldSummariesWithId = {
  id: number;
  yieldSummary: YieldSummary[];
};

export default class YieldSummaryAPI {
  baseUrl: string;
  entity: string;
  constructor(entity: string) {
    this.baseUrl = `${
      process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"
    }/v1/`;
    this.entity = entity;
  }

  async fetchYieldSummary(
    params: URLSearchParams
  ): Promise<YieldSummariesWithId> {
    const res = await fetch(`${this.baseUrl}${this.entity}?${params}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
    });
    return { id: 1, yieldSummary: await res.json() } as YieldSummariesWithId;
  }
}

const yieldSummaryApi = new YieldSummaryAPI("yieldSummary");

export const getYieldSummary = createAsyncThunk<
  Promise<YieldSummariesWithId>,
  { farmGuid: string; yieldEventTimeAfter: string }
>(
  "yieldSummary/get",
  async ({ farmGuid, yieldEventTimeAfter }, { dispatch }) => {
    const yeildSummaryWithId: YieldSummariesWithId =
      await yieldSummaryApi.fetchYieldSummary(
        new URLSearchParams({ farmGuid, yieldEventTimeAfter })
      );
    return yeildSummaryWithId;
  }
);

export const yieldSummaryAdapter = createEntityAdapter<YieldSummariesWithId>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (yieldSummary) => 1,
});

export const { selectById: selectYieldSummaryById } =
  yieldSummaryAdapter.getSelectors((state: RootState) => state.yieldSummary);

const initialYieldSummaryState: EntityState<YieldSummariesWithId> =
  yieldSummaryAdapter.getInitialState({});

const yieldSummarySlice = createSlice({
  name: "yieldSummary",
  initialState: initialYieldSummaryState,
  reducers: { resetYieldSummary: () => initialYieldSummaryState },
  extraReducers: {
    [getYieldSummary.fulfilled.type]: (state, action) => {
      yieldSummaryAdapter.upsertOne(
        state,
        action.payload as YieldSummariesWithId
      );
    },
  },
});
export const { resetYieldSummary } = yieldSummarySlice.actions;
export const yieldSummaryReducer = yieldSummarySlice.reducer;
