import DropdownInput from "common/components/DropdownInput";
import { useTranslation } from "common/locales";
import {
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { GENERAL_FEED_TYPE } from "model";

interface FeedTypeDropdownProps {
  currentFeedType?: GENERAL_FEED_TYPE | undefined;
  disabled: boolean;
  onChange: (e: SelectChangeEvent<GENERAL_FEED_TYPE>) => void;
  feedTypeInputError: boolean;
  label: string;
  name: string;
}

export const useVegetationStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
      maxWidth: 400,
      paddingBottom: theme.spacing(2),
    },
  })
);

export default function FeedTypeDropdown({
  disabled,
  onChange,
  currentFeedType,
  feedTypeInputError,
  label,
  name,
}: FeedTypeDropdownProps) {
  const classes = useVegetationStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      className={classes.formControl}
      disabled={disabled}
      error={feedTypeInputError}
      fullWidth
    >
      <InputLabel
        id="assignedGroup-select-label"
        variant="standard"
        shrink={true}
        error={feedTypeInputError}
        required
      >
        {t(label)}
      </InputLabel>
      <Select
        labelId="assignedGroup-select-label"
        id="assignedGroup-select"
        value={currentFeedType ?? ("" as GENERAL_FEED_TYPE)}
        onChange={onChangeSelect}
        input={<DropdownInput />}
        error={feedTypeInputError}
        name={name}
      >
        {Object.entries(GENERAL_FEED_TYPE).map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {t(`feedPurchase.feedType.option.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  function onChangeSelect(e: SelectChangeEvent<GENERAL_FEED_TYPE>) {
    onChange(e);
  }
}
