import { useState } from "react";
import styled from "styled-components";
import { Collapse, ListItem, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import FieldsList from "features/fieldList/FieldsList";
import { getAverageCoverForSilage, selectAllFields } from "model";
import { useTranslation } from "common/locales";
import { useSelector } from "react-redux";

export default function Silage() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const fields = useSelector(selectAllFields);

  const unassignedFields = fields.filter(({ animalGroup }) => !animalGroup);
  const unassignedFieldIds = unassignedFields.map(({ guid }) => guid);
  const averageCover = Math.round(useSelector(getAverageCoverForSilage));

  return (
    <>
      <ListItem key="silage" button onClick={toggleOpen}>
        <ListItemText
          primary={t("silage.title")}
          secondary={t("silage.description", { averageCover })}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Nested in={open} timeout="auto" unmountOnExit>
        <FieldsList
          emptyText={t("fieldlist.emptySilage")}
          fieldIds={unassignedFieldIds}
        />
      </Nested>
    </>
  );

  function toggleOpen() {
    setOpen(!open);
  }
}

const Nested = styled(Collapse)(
  ({ theme }) => `
  padding-left: ${theme.spacing(4)};
`
);
