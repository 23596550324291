import {
  Button,
  Card,
  CardContent,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Link as MuiLink,
  IconButton,
} from "@mui/material";
import { useTranslation } from "common/locales";
import { Link } from "react-router-dom";
import styled from "styled-components";
import YouTube from "react-youtube";
import { useMediaQuery, useTheme } from "@mui/material";
import drawField from "./draw_field.png";
import enterAnimalGroup from "./enter_animalgroup.png";
import planRotation from "./plan_rotation.png";
import { useCallback, useState } from "react";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

export default function Welcome() {
  const { t } = useTranslation();
  return (
    <>
      <DialogTitle id="dialog-title">
        <span role="img" aria-label="Tada">
          🎉
        </span>{" "}
        {t("signup.welcome.title")}
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          {t("signup.welcome.text")}
          <span role="img" aria-label="point-right">
            👉
          </span>{" "}
          <MuiLink href="mailto:support@ruumi.io" underline="hover">
            {t("signup.welcome.link")}
          </MuiLink>{" "}
        </Typography>
        <WelcomeCard>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <YoutubeWrapper
                desktopVideo="TX0PhAi5Ev4"
                mobileVideo="aFYJnHtXBAk"
                desktopPreview={drawField}
                title="signup.welcome.enterFields.title"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <WelcomeCardText>
                <Typography variant="h6">
                  {t("signup.welcome.enterFields.title")}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {t("signup.welcome.enterFields.text")}
                </Typography>
              </WelcomeCardText>
            </Grid>
          </Grid>
        </WelcomeCard>
        <WelcomeCard>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <YoutubeWrapper
                desktopVideo="OOsbz7l0zsY"
                mobileVideo="xZbMXsONPwY"
                desktopPreview={enterAnimalGroup}
                title="signup.welcome.enterAnimalGroups.title"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <WelcomeCardText>
                <Typography variant="h6">
                  {t("signup.welcome.enterAnimalGroups.title")}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {t("signup.welcome.enterAnimalGroups.text")}
                </Typography>
              </WelcomeCardText>
            </Grid>
          </Grid>
        </WelcomeCard>
        <WelcomeCard>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <YoutubeWrapper
                desktopVideo="LE3D03GgM8Q"
                mobileVideo="EV1WuhI89NM"
                desktopPreview={planRotation}
                title="signup.welcome.planRotations.title"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <WelcomeCardText>
                <Typography variant="h6">
                  {t("signup.welcome.planRotations.title")}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {t("signup.welcome.planRotations.text")}
                </Typography>
              </WelcomeCardText>
            </Grid>
          </Grid>
        </WelcomeCard>
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          to="/add-farm/enter-name"
          color="primary"
          variant="contained"
        >
          {t("signup.button.getStarted")}
        </Button>
      </DialogActions>
    </>
  );
}

const WelcomeCard = styled(Card)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)};
`
);

const WelcomeCardText = styled(CardContent)`
  padding: 16px;
`;

interface YoutubeWrapperProps {
  mobileVideo: string;
  desktopVideo: string;
  desktopPreview: string;
  title: string;
}

function YoutubeWrapper({
  mobileVideo,
  desktopVideo,
  desktopPreview,
  title,
}: YoutubeWrapperProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const playerVars = {
    autoplay: 0,
    modestbranding: 1,
    showinfo: 0,
    rel: 0,
    origin:
      process.env.NODE_ENV === "production"
        ? "https://graze.ruumi.io"
        : "http://localhost:3000",
  };

  const height = "100%",
    width = "100%";

  return (
    <>
      <Dialog onClose={handleClose} fullScreen open={isOpen}>
        <StyledDialogTitle>
          <Typography variant="h6">{t(title)}</Typography>
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </StyledDialogTitle>
        <DialogContent>
          <YouTube
            videoId={isMobile ? mobileVideo : desktopVideo}
            opts={{
              height,
              width,
              playerVars,
            }}
            style={{ display: "block", height: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <WelcomeCardPreview image={desktopPreview} onClick={handleOpen}>
        <IconButton aria-haspopup="true" onClick={handleOpen} size="large">
          <PlayIcon htmlColor="#FF7043" />
        </IconButton>
      </WelcomeCardPreview>
    </>
  );
}

const WelcomeCardPreview = styled(CardMedia)`
  height: 100%;
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PlayIcon = styled(PlayCircleFilledWhiteIcon)(
  ({ theme }) => `
  width: ${theme.spacing(15)};
  height: ${theme.spacing(15)};
`
);

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
  margin: 0;
  padding: ${theme.spacing(2)} ${theme.spacing(3)};
`
);

const CloseButton = styled(IconButton)(
  ({ theme }) => `
  position: fixed;
  right: ${theme.spacing(2)};
  top: ${theme.spacing(1)};
`
);
