import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { RootState } from "./rootReducer";

export type FarmerImageUpload = {
  guid: string;
  image: string;
  imageName: string;
};

const farmerImageUploadApi = new API("FarmerImageUpload");

export const getSingleFarmerImageUpload = createAsyncThunk<
  Promise<any>,
  { guid: string }
>("farmerImageUpload/getSingle", async ({ guid }: { guid: string }) => {
  return await farmerImageUploadApi.getOne(guid);
});

const farmerImageUploadAdapter = createEntityAdapter<FarmerImageUpload>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (farmerImageUpload: FarmerImageUpload) => farmerImageUpload.guid,
  // Keeps the array sorted by guid
  sortComparer: (a: FarmerImageUpload, b: FarmerImageUpload) =>
    a.guid.localeCompare(b.guid),
});

export const {
  selectById: selectFarmerImageUploadById,
  selectAll: selectAllFArmerImageUploads,
} = farmerImageUploadAdapter.getSelectors(
  (state: RootState) => state.farmerImageUploads
);

const initialFarmerImageUploadState: EntityState<FarmerImageUpload> =
  farmerImageUploadAdapter.getInitialState({});

const farmerImageUploadSlice = createSlice({
  name: "farmerImageUpload",
  initialState: initialFarmerImageUploadState,
  reducers: {},
  extraReducers: {
    [getSingleFarmerImageUpload.fulfilled.type]: (state, { payload }) => {
      farmerImageUploadAdapter.upsertOne(state, payload);
    },
  },
});

export const farmerImageUploadReducer = farmerImageUploadSlice.reducer;
