import { Container } from "@mui/material";
import { DrawerForm } from "common/components";
import { ARABLE_TYPE, TmpFieldDetails } from "model";
import ArableTypeDropdown from "./ArableTypeDropdown";

interface ArableFieldDetailsEditorProps {
  field: TmpFieldDetails;
  currentArableType?: ARABLE_TYPE;
  handleArableTypeChange: (arableType?: ARABLE_TYPE) => void;
  fieldNameError?: boolean;
  arableTypeInputError: boolean;
}

export default function ArableFieldDetailsEditor(
  props: ArableFieldDetailsEditorProps
) {
  const {
    field,
    handleArableTypeChange,
    currentArableType,
    arableTypeInputError,
  } = props;
  return (
    <Container>
      <DrawerForm>
        <ArableTypeDropdown
          disabled={!!currentArableType}
          onChange={handleArableTypeChange}
          currentArableType={field.arableType}
          arableTypeInputError={arableTypeInputError}
          label={"fieldinput.arableType.text"}
        ></ArableTypeDropdown>
      </DrawerForm>
    </Container>
  );
}
