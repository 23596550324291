import SvgIcon from "@mui/material/SvgIcon";

export default function InitialScreenSelectLogo() {
  return (
    <SvgIcon viewBox="0 0 58 56" sx={{ width: "80px", height: "100px" }}>
      <path
        d="M49.132 26.7493C48.162 26.9884 47.2228 27.3225 46.3306 27.7459C49.7403 22.5161 53.5884 17.5379 57.84 12.8564C52.1006 15.732 46.7598 19.2321 41.9332 23.2811C42.0435 22.8038 42.211 22.3392 42.4327 21.8958C48.4371 11.222 55.4296 10.1456 55.4296 10.1456C55.4296 10.1456 47.829 9.61739 39.3816 15.4078C40.3647 10.4352 41.7178 5.53029 43.4316 0.727534C39.7641 4.79967 36.5929 9.22773 33.9744 13.9328C30.9229 8.90209 27.2267 4.22459 22.9645 0C25.1254 4.53591 26.9607 9.19677 28.4585 13.9527C27.5573 12.5086 26.3545 11.2419 24.9189 10.2253C23.6808 9.30719 22.2472 8.63693 20.7101 8.25761C19.173 7.87829 17.5664 7.79831 15.9937 8.0228C15.0708 4.3951 14.7124 2.20253 14.7124 2.20253C14.22 4.3015 13.8575 6.42438 13.6267 8.56098C10.8262 9.50867 8.28445 11.0051 6.17814 12.9461C6.17814 12.9461 9.43551 9.95625 13.4312 10.3748C13.0616 14.3523 12.9999 18.3488 13.2466 22.3343C10.5392 19.6216 7.58616 17.1244 4.41916 14.8696C7.36921 19.1095 10.0098 23.5243 12.3237 28.0848C11.4362 27.6672 10.5049 27.3333 9.54409 27.0882C3.3008 25.6032 0 26.8689 0 26.8689C0 26.8689 7.03592 29.201 10.0436 33.3968C12.0631 36.2172 12.5192 38.1208 12.5952 44.8081V54.5642C12.5952 55.2307 13.1354 55.7709 13.8018 55.7709H44.8525C45.5189 55.7709 46.0592 55.2307 46.0592 54.5642V44.8081C46.1243 37.7719 46.5587 35.8385 48.6217 32.9981C51.6293 28.8024 58.5241 31.0049 58.5241 31.0049C58.5241 31.0049 55.3753 25.2643 49.132 26.7493ZM20.6517 15.8662C21.6506 17.4807 22.0307 21.1583 22.0632 26.0517C20.0794 21.3866 18.3865 16.6217 16.9926 11.7801C18.499 12.9026 19.7435 14.2924 20.6517 15.8662Z"
        fill="#AFE57F"
      />
    </SvgIcon>
  );
}
