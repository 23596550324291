import styled from "styled-components";

const AnimalGroupColorIcon = styled.div<{ color: string }>`
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  margin-left: 2px;
`;

export default AnimalGroupColorIcon;
