import { List, ListItem, ListItemText, Tooltip } from "@mui/material";
import {
  useAppSelector,
  selectAllEventsForField,
  Field,
  ManualDMInputResponse,
  ManualDMInputResponseItem,
} from "model";
import { SatelliteIcon } from "common/icons";
import { useTranslation } from "common/locales";
import FieldEventListItem from "./FieldEventListItem";
import {
  DiaryEntry,
  selectDiaryEntryEventsOnField,
} from "model/diaryEntrySlice";
import DiaryEntryListItem from "./DiaryEntryListItem";

export default function FieldEventList({ field }: { field: Field }) {
  const { t } = useTranslation();

  const manualDMForField: Array<ManualDMInputResponse> = useAppSelector(
    (state) => selectAllEventsForField(state, field.guid)
  );

  const diaryEntriesForField: Array<DiaryEntry> = useAppSelector((state) =>
    selectDiaryEntryEventsOnField(state, field.guid)
  );

  // see https://stackoverflow.com/questions/51528780/typescript-check-typeof-against-custom-type
  const isDiaryEntry = (x: any): boolean =>
    Object.keys(x).includes("entryDate");
  const isManualDMInputResponse = (x: any): boolean =>
    Object.keys(x).includes("manualDryMatterInput");

  const getDate = (event: ManualDMInputResponse | DiaryEntry): Date => {
    if (isDiaryEntry(event)) {
      return new Date((event as DiaryEntry).entryDate);
    }
    if (isManualDMInputResponse(event)) {
      return new Date(
        getLatestTime((event as ManualDMInputResponse).manualDryMatterInput)
      );
    }
    return new Date(1);
  };

  let allEvents: Array<ManualDMInputResponse | DiaryEntry> = [];

  allEvents = allEvents.concat(manualDMForField).concat(diaryEntriesForField);

  // This will now sort on every render and we did not find out how to fix it, see https://github.com/robofarmio/web-app-frontend/pull/775#discussion_r1502445622
  // we might go back to 2021 methods like "onMount" etc to be able to compare if we find this is too slow
  const sortedEventList = allEvents.sort(
    (
      a: ManualDMInputResponse | DiaryEntry,
      b: ManualDMInputResponse | DiaryEntry
    ) => {
      const dateA: Date = getDate(a);
      const dateB: Date = getDate(b);
      return dateB.valueOf() - dateA.valueOf();
    }
  );

  return (
    <List>
      <ListItem>
        <ListItemText primary={t("fieldevents.list.title")} />
      </ListItem>
      {sortedEventList.map((dm: ManualDMInputResponse | DiaryEntry) => {
        if (isManualDMInputResponse(dm)) {
          return (
            <FieldEventListItem
              key={dm.guid}
              dm={dm as ManualDMInputResponse}
              field={field}
            ></FieldEventListItem>
          );
        } else {
          return (
            <DiaryEntryListItem key={dm.guid} diaryEntry={dm as DiaryEntry} />
          );
        }
      })}
    </List>
  );
}

export function AutoDetectedIcon() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("fieldevent.autodetected.tooltip")}>
      <div>
        <SatelliteIcon />
      </div>
    </Tooltip>
  );
}

function getLatestTime(response: ManualDMInputResponseItem) {
  const { event: e } = response;
  if (e.dateTimeOut) {
    // covers move out and grazing/partial grazing events
    return Date.parse(e.dateTimeOut);
  }

  if (e.dateTimeIn) {
    // covers move in event
    return Date.parse(e.dateTimeIn);
  }

  if (e.dateTimeCut) {
    // silage event
    return Date.parse(e.dateTimeCut);
  }

  return Date.parse(response.dateTimeMeasurementStart); // reading
}
