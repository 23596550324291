import { addMonths, differenceInDays, subMonths } from "date-fns";
import { ManualDMInputResponse } from "model";

// Historic kg dry matter / ha / day of growth
export const historicKgHaDay = [10, 10, 10, 20, 50, 80, 70, 60, 50, 30, 20, 10];
export const historicKgM2Day = historicKgHaDay.map((i) => i / 10000);
export function growthRateFallback(now: Date) {
  // translated from https://github.com/robofarmio/grazers-eye/blob/8d84675d8de0866bc1ad952525ee68879bbc526a/grazers_eye/growth.py
  const month = now.getMonth();

  // We linearly interpolate between this month's
  // growth and next month's growth based on the
  // how many days have past vs. are still out.

  const this_month_idx = month;
  const next_month_idx = month < 11 ? month + 1 : 0;

  const this_month_growth = historicKgHaDay[this_month_idx];
  const next_month_growth = historicKgHaDay[next_month_idx];

  const days_past = now.getDate();
  const days_next =
    new Date(now.getFullYear(), now.getMonth(), 0).getDate() - days_past;

  const c = days_past / (days_past + days_next);
  const growth = (1 - c) * this_month_growth + c * next_month_growth;

  return growth;
}

export function growthRateFallbackKgM2Day(now: Date) {
  return growthRateFallback(now) / 10000;
}

export function getGrowthRatesKgM2DayFromReadings(
  readingsByFieldGuid: Map<string, ManualDMInputResponse[]>
) {
  const now = new Date();
  const growthRatesByFieldGuid = new Map<string, number[]>();

  for (const [fieldGuid, manualDMInputResponses] of readingsByFieldGuid) {
    // normalizing ManualDMInputResponse to { date, value }, split grazing events into 2 readings
    const readings = manualDMInputResponses.flatMap((dm) => {
      const res = [];
      if (
        dm.manualDryMatterInput.event.preEventCover &&
        dm.manualDryMatterInput.event.preEventCover > 0 &&
        dm.manualDryMatterInput.event.dateTimeIn
      ) {
        res.push({
          date: new Date(dm.manualDryMatterInput.event.dateTimeIn),
          value: dm.manualDryMatterInput.event.preEventCover / 10000,
        });
      }
      if (
        dm.manualDryMatterInput.event.postEventCover &&
        dm.manualDryMatterInput.event.postEventCover > 0 &&
        dm.manualDryMatterInput.event.dateTimeOut
      ) {
        res.push({
          date: new Date(dm.manualDryMatterInput.event.dateTimeOut),
          value: dm.manualDryMatterInput.event.postEventCover / 10000,
        });
      }
      if (
        res.length === 0 &&
        dm.manualDryMatterInput.dryMatterValue &&
        dm.manualDryMatterInput.dryMatterValue > 0
      ) {
        res.push({
          date: new Date(dm.manualDryMatterInput.dateTimeMeasurementStart),
          value: dm.manualDryMatterInput.dryMatterValue / 10000,
        });
      }
      return res;
    });
    readings.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    const growthRates = [...historicKgM2Day]; // fill with hardcoded growth rates first
    for (let month = 12; month > 0; month--) {
      const dateStart = subMonths(now, month);
      const dateEnd = addMonths(dateStart, 1);

      const readingsForMonth = readings.filter(
        (r) => r.date >= dateStart && r.date <= dateEnd
      );

      // looking for readings with max value difference (https://www.geeksforgeeks.org/maximum-difference-between-two-elements/)
      if (readingsForMonth.length < 2) {
        continue;
      }

      let min = readingsForMonth[0];
      let max = readingsForMonth[1];
      let maxDiffKgM2 = max.value - min.value;

      for (let i = 1; i < readingsForMonth.length; i++) {
        if (readingsForMonth[i].value - min.value > maxDiffKgM2) {
          max = readingsForMonth[i];
          maxDiffKgM2 = max.value - min.value;
        }

        if (readingsForMonth[i].value < min.value) {
          min = readingsForMonth[i];
        }
      }

      if (maxDiffKgM2 > 0 && dateEnd !== dateStart) {
        growthRates[dateStart.getMonth()] =
          maxDiffKgM2 / differenceInDays(dateEnd, dateStart);
      }
    }
    growthRatesByFieldGuid.set(fieldGuid, growthRates);
  }

  return growthRatesByFieldGuid;
}
