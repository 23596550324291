import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
} from "@mui/material";

import Skeleton from "@mui/material/Skeleton";
import times from "lodash/times";

export default function DetailViewLoading({ size = 4 }: { size?: number }) {
  return (
    <Container>
      <List>
        <ListItem>
          <ListItemText
            primary={<Skeleton variant="text" width={80} />}
            secondary={<Skeleton variant="text" width={110} />}
          />
          <ListItemSecondaryAction>
            <Skeleton variant="rectangular" width={48} height={24} />
          </ListItemSecondaryAction>
        </ListItem>
        {times(size).map((n) => (
          <ListItem key={`detailview-skeleton-${n}`}>
            <ListItemIcon>
              <Skeleton variant="rectangular" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={<Skeleton variant="text" width={110} />}
              secondary={<Skeleton variant="text" width={130} />}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
