import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";
import {
  RootState,
  selectAllAnimalGroups,
  selectAllStockClasses,
  StockClass,
} from "model";
import { API } from "common/utils";

export interface StockClassBirthingPeriod {
  guid: string;
  dateStart: string;
  dateEnd: string;
  expectedNumAnimalsWithoutPregnancy: number;
  expectedNumOffspringPerAnimal: number;
  stockClassGuid: string;
}

const stockClassBirthingPeriodApi = new API<StockClassBirthingPeriod>(
  "stockClassBirthingPeriods"
);

const stockClassBirthingPeriodAdapter =
  createEntityAdapter<StockClassBirthingPeriod>({
    selectId: (stockClassBirthingPeriod) => stockClassBirthingPeriod.guid,
  });

const { selectAll: selectAllStockClassBirthingPeriods } =
  stockClassBirthingPeriodAdapter.getSelectors(
    (state: RootState) => state.stockClassBirthingPeriods
  );

type StockClassBirthingPeriodsByAnimalGroupId = {
  [id: string]: Array<StockClassBirthingPeriod>;
};

const selectStockClassBirthingPeriodByStockClassId = createSelector(
  selectAllStockClassBirthingPeriods,
  (stockClassBirthingPeriods) =>
    stockClassBirthingPeriods.reduce(
      (
        acc: StockClassBirthingPeriodsByAnimalGroupId,
        stockClassBirthingPeriod
      ) => {
        if (!acc[stockClassBirthingPeriod.stockClassGuid]) {
          acc[stockClassBirthingPeriod.stockClassGuid] = [];
        }
        acc[stockClassBirthingPeriod.stockClassGuid].push(
          stockClassBirthingPeriod
        );

        return acc;
      },
      {}
    )
);

const selectItemId = (state: RootState, itemId: string) => itemId;

export const selectStockClassBirthingPeriodsForStockClass = createSelector(
  [selectStockClassBirthingPeriodByStockClassId, selectItemId],
  (stockClassBirthingPeriods, stockClassId) =>
    stockClassBirthingPeriods[stockClassId]
);

export const selectStockClassBirthingPeriodsForAnimalGroup = createSelector(
  [
    selectAllAnimalGroups,
    selectAllStockClasses,
    selectAllStockClassBirthingPeriods,
    selectItemId,
  ],
  (animalGroups, stockClasses, stockClassBirthingPeriods, animalGroupGuid) => {
    const animalGroup = animalGroups.find((ag) => ag.guid === animalGroupGuid);
    const filteredStockClassGuids = stockClasses
      .filter((sc) => sc.animalGroup === animalGroup?.guid)
      .map((sc) => sc.guid);
    const filteredStockClassBirthingPeriods = stockClassBirthingPeriods.filter(
      (scbp) => filteredStockClassGuids.includes(scbp.stockClassGuid)
    );
    return filteredStockClassBirthingPeriods;
  }
);

export const selectStockClassWithBirthingPeriodsForAnimalGroup = createSelector(
  [
    selectAllAnimalGroups,
    selectAllStockClasses,
    selectAllStockClassBirthingPeriods,
    selectItemId,
  ],
  (animalGroups, stockClasses, stockClassBirthingPeriods, animalGroupGuid) => {
    const animalGroup = animalGroups.find((ag) => ag.guid === animalGroupGuid);
    const filteredStockClasses = stockClasses.filter(
      (sc) => sc.animalGroup === animalGroup?.guid
    );
    return filteredStockClasses.map((sc) => ({
      ...sc,
      birthingPeriodValues: stockClassBirthingPeriods.filter(
        (scbp) => sc.guid === scbp.stockClassGuid
      ),
    }));
  }
);

export const fetchStockClassBirthingPeriodsById = createAsyncThunk(
  "stockClassBirthingPeriods/fetchById",
  (stockClassBirthingPeriodIds: string[]) => {
    return stockClassBirthingPeriodApi.getMany(stockClassBirthingPeriodIds);
  }
);

export const createStockClassBirthingPeriod = createAsyncThunk(
  "stockClassBirthingPeriod/create",
  async (stockClassBirthingPeriod: StockClassBirthingPeriod, { dispatch }) => {
    const newStockClassBirthingPeriod =
      await stockClassBirthingPeriodApi.createOne(stockClassBirthingPeriod);
    return await stockClassBirthingPeriodApi.getOne(
      newStockClassBirthingPeriod.guid
    );
  }
);

export const updateStockClassBirthingPeriod = createAsyncThunk(
  "stockClassBirthingPeriod/update",
  async (stockClassBirthingPeriod: StockClassBirthingPeriod, { dispatch }) => {
    const updatedStockClassBirthingPeriod =
      await stockClassBirthingPeriodApi.updateOne(stockClassBirthingPeriod);
    return await stockClassBirthingPeriodApi.getOne(
      updatedStockClassBirthingPeriod.guid
    );
  }
);

export const deleteStockClassBirthingPeriod = createAsyncThunk(
  "stockClassBirthingPeriod/delete",
  async (stockClassBirthingPeriodId: string) => {
    await stockClassBirthingPeriodApi.deleteOne(stockClassBirthingPeriodId);
    return stockClassBirthingPeriodId;
  }
);

type StockClassBirthingPeriodState = EntityState<StockClassBirthingPeriod> & {
  loading: boolean;
  error: string | null;
  status: "success" | "error" | null;
};

const initialStockClassBirthingPeriodState: StockClassBirthingPeriodState =
  stockClassBirthingPeriodAdapter.getInitialState({
    loading: false,
    error: null,
    status: null,
  });

const stockClassBirthingPeriodSlice = createSlice({
  name: "stockClassBirthingPeriod",
  initialState: initialStockClassBirthingPeriodState,
  reducers: {
    resetStockClassBirthingPeriods: () => initialStockClassBirthingPeriodState,
  },
  extraReducers: {
    [fetchStockClassBirthingPeriodsById.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchStockClassBirthingPeriodsById.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.status = "success";
      stockClassBirthingPeriodAdapter.upsertMany(
        state,
        payload as
          | readonly StockClassBirthingPeriod[]
          | Record<EntityId, StockClassBirthingPeriod>
      );
    },
    [fetchStockClassBirthingPeriodsById.rejected.type]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.status = "error";
    },
    [updateStockClassBirthingPeriod.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [updateStockClassBirthingPeriod.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassBirthingPeriodAdapter.upsertOne(
        state,
        payload as StockClassBirthingPeriod
      );
    },
    [updateStockClassBirthingPeriod.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [createStockClassBirthingPeriod.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [createStockClassBirthingPeriod.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassBirthingPeriodAdapter.upsertOne(
        state,
        payload as StockClassBirthingPeriod
      );
    },
    [createStockClassBirthingPeriod.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [deleteStockClassBirthingPeriod.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteStockClassBirthingPeriod.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassBirthingPeriodAdapter.removeOne(state, payload as EntityId);
    },
    [deleteStockClassBirthingPeriod.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
  },
});

export type StockClassWithBirthingPeriods = StockClass & {
  birthingPeriodValues: Array<StockClassBirthingPeriod>;
};

export const stockClassBirthingPeriodReducer =
  stockClassBirthingPeriodSlice.reducer;
export const { resetStockClassBirthingPeriods } =
  stockClassBirthingPeriodSlice.actions;
