import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { useTranslation } from "common/locales";

interface Props {
  disabled: boolean;
  date: Date | null;
  setDate: (date: Date | null) => void;
  dateError: boolean;
  name: string;
}

export default function EnterDate({
  disabled,
  date,
  setDate,
  dateError,
  name,
}: Props) {
  const { t } = useTranslation();

  const handleDateChange = (d: Date | null) => {
    setDate(d);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={disabled}
        disableFuture
        inputFormat="MMMM do yyyy"
        label={t("addDate.enterTime.dateLabel")}
        value={date}
        renderInput={(params) => (
          <TextField
            onKeyDown={onKeyDown}
            variant="standard"
            error={dateError}
            {...params}
            name={name}
          />
        )}
        onChange={handleDateChange}
        disableMaskedInput
      />
    </LocalizationProvider>
  );
}
