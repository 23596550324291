import { MACHINERY, FieldPreparationEvent } from "model";

export type FieldPreparationEventFormData = {
  note?: string;
  machinery: MACHINERY[];
  fuel: number;
  fieldPreparationEventDate: Date;
  fieldGuids: string[];
};
export const convertFromFormInput = (
  fieldPreparationFormData: FieldPreparationEventFormData
): FieldPreparationEvent => {
  return {
    note: fieldPreparationFormData.note,
    machinery: fieldPreparationFormData.machinery,
    fuel: fieldPreparationFormData.fuel,
    fieldPreparationEventDate: new Date(
      fieldPreparationFormData.fieldPreparationEventDate
    ).toISOString(),
    fieldGuids: fieldPreparationFormData.fieldGuids,
    guid: "",
  };
};
