import mixpanel, { OverridedMixpanel } from "mixpanel-browser";

const useMixPanel =
  process.env.NODE_ENV === "production" && process.env.REACT_APP_MIXPANEL_TOKEN;

type UsedMixpanelMethods =
  | "init"
  | "identify"
  | "alias"
  | "track"
  | "time_event"
  | "register"
  | "people"
  | "reset"; // Add more methods to the type if used

const mockMixpanel: Pick<OverridedMixpanel, UsedMixpanelMethods> = {
  // To use additional mixpanel API features, add them to this proxy object

  // eslint-disable-next-line @typescript-eslint/unbound-method
  init: mixpanel.init, // the init method can be proxied directly since it doesn't matter if mixpanel is initialised in development
  identify: (id) => null,
  alias: (id) => null,
  track: (name, props) => null,
  time_event: (event_name) => null,
  register: (props) => null,
  people: {
    set: () => null,
    set_once: () => null,
    unset: () => null,
    increment: () => null,
    remove: () => null,
    append: () => null,
    union: () => null,
    track_charge: () => null,
    clear_charges: () => null,
    delete_user: () => null,
  },
  reset: () => null,
};

const activeMixPanel = useMixPanel ? mixpanel : mockMixpanel;

export { activeMixPanel as mixpanel };
