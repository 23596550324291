import { formatRelative } from "date-fns";
import { getDateFnsLocale } from "common/utils/helpers";

export const SHOW_RELATIVE_TIME_DAYS_AGO = 604800000;

export function getFormattedTime(dateStr: string, locale: string): string {
  const date = Date.parse(dateStr);
  return Date.now() - date <= SHOW_RELATIVE_TIME_DAYS_AGO
    ? formatRelative(date, new Date(), {
        locale: getDateFnsLocale(locale),
        weekStartsOn: 1,
      })
    : new Date(date).toLocaleDateString(locale);
}

export function getFormattedDate(dateStr: string, locale: string): string {
  const formatRelativeLocale: { [key: string]: string } = {
    lastWeek: "'Last' eeee",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "'Next' eeee",
    other: "dd.MM.yyyy",
  };

  const customLocale = {
    ...getDateFnsLocale(locale),
    formatRelative: (token: string) => formatRelativeLocale[token],
  };
  const date = Date.parse(dateStr);
  return Date.now() - date <= SHOW_RELATIVE_TIME_DAYS_AGO
    ? formatRelative(date, new Date(), {
        locale: customLocale,
        weekStartsOn: 1,
      })
    : new Date(date).toLocaleDateString(locale);
}
