import { ListItemSecondaryAction, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useNotification } from "app/NotificationProvider";
import { mixpanel } from "common/analytics";
import { useTranslation } from "common/locales";
import {
  deleteAllTilesForField,
  invalidateFieldData,
  ManualDMInputResponse,
  updateOneReading,
  useAppDispatch,
} from "model";

export default function FieldEventConfirmationMenu({
  machineInput,
  children,
}: {
  machineInput: ManualDMInputResponse;
  children?: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { notify } = useNotification();
  return (
    <ListItemSecondaryAction>
      <IconButton
        edge="end"
        onClick={() => handleAcceptMachineReading(machineInput)}
        aria-label={t("fieldevents.menu.accept")}
        size="large"
      >
        <CheckIcon />
      </IconButton>
      <IconButton
        edge="end"
        onClick={() => handleRejectMachineReading(machineInput)}
        aria-label={t("fieldevents.menu.reject")}
        size="large"
      >
        <ClearIcon />
      </IconButton>
      {children}
    </ListItemSecondaryAction>
  );
  async function handleAcceptMachineReading(reading: ManualDMInputResponse) {
    await dispatch(
      updateOneReading({
        ...reading,
        manualDryMatterInput: {
          ...reading.manualDryMatterInput,
          verificationStatus: "Accepted",
        },
      })
    );
    notify(t("fieldevents.notify.accepted"));
    mixpanel.track("Autodetected event accepted");
    await dispatch(deleteAllTilesForField(reading.manualDryMatterInput.field));
    dispatch(invalidateFieldData(reading.manualDryMatterInput.field));
  }
  async function handleRejectMachineReading(reading: ManualDMInputResponse) {
    await dispatch(
      updateOneReading({
        ...reading,
        manualDryMatterInput: {
          ...reading.manualDryMatterInput,
          verificationStatus: "Rejected",
        },
      })
    );
    notify(t("fieldevents.notify.rejected"));
    mixpanel.track("Autodetected event rejected");
    await dispatch(deleteAllTilesForField(reading.manualDryMatterInput.field));
    dispatch(invalidateFieldData(reading.manualDryMatterInput.field));
  }
}
