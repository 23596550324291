import { Dispatch, SetStateAction, useState } from "react";
import { Container, Dialog } from "@mui/material";
import {
  YieldEvent,
  selectCurrentFarm,
  updateYieldEvent,
  useAppDispatch,
  useAppSelector,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import EnterYieldEvent from "./EnterYieldEvent";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function UpdateYieldEvent({
  yieldEvent,
  dialogOpen,
  setDialogOpen,
}: {
  yieldEvent: YieldEvent;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const handleClose = () => {
    setDialogOpen(false);
    mixpanel.track("Update yield event cancelled");
  };

  const currentFarm = useAppSelector(selectCurrentFarm);

  async function handleSave(yieldEvent: YieldEvent) {
    await dispatch(
      updateYieldEvent({
        yieldEvent,
        farmGuid: currentFarm?.guid ?? "",
      })
    );
    notify(t("addYieldEvent.add.success"));
    mixpanel.track("YieldEvent created");
    handleClose();
  }
  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={dialogOpen}
      onClose={handleClose}
    >
      <StyledContainer>
        <EnterYieldEvent
          yieldEvent={yieldEvent}
          handleSave={handleSave}
          handleClose={() => {
            handleClose();
          }}
        />
      </StyledContainer>
    </Dialog>
  );
}
