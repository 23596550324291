import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Fragment } from "react";

import { useTranslation } from "common/locales";

import {
  FeedStocks,
  GENERAL_FEED_TYPE,
  GENERAL_FEED_UNITS,
  GeneralFeed,
  useAppSelector,
} from "model";
import { convertToFeedStockAmountString } from "./generalMethods";

interface NotEnoughFeedDialogProps {
  open: boolean;
  setClose: () => void;
  feeds: GeneralFeed[] | undefined;
  oldFeeds: GeneralFeed[] | undefined;
  feedStocks: FeedStocks | undefined;
}

export function sumFeedForType(
  feeds: GeneralFeed[] | undefined,
  feedTypeEnum: GENERAL_FEED_TYPE
) {
  let sumAmount = 0;
  if (feeds) {
    for (const feed of feeds.filter(
      (feed: GeneralFeed) => feed.feedType === feedTypeEnum
    )) {
      sumAmount =
        feed.amount * (feed.unitType === GENERAL_FEED_UNITS.TONNE ? 1000 : 1) +
        sumAmount;
    }
  }
  return sumAmount;
}

export default function NotEnoughFeedDialog(props: NotEnoughFeedDialogProps) {
  const { open, setClose, feeds, feedStocks, oldFeeds } = props;
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);

  const notEnoughFeedAvailableMessage = (
    feeds: GeneralFeed[] | undefined,
    feedStocks: FeedStocks | undefined,
    oldFeeds: GeneralFeed[] | undefined
  ) => {
    const errorMessage = (
      <>
        {Object.values(GENERAL_FEED_TYPE).map((feedType) => {
          const feedTypeEnum = GENERAL_FEED_TYPE[feedType];
          // sum the new values for this feed Type
          const sumAmountNew = sumFeedForType(feeds, feedTypeEnum);
          // in case this is an update we need to make sure we subtract the original value from the available feed
          const sumAmountOld = sumFeedForType(oldFeeds, feedTypeEnum);
          const availableStock = feedStocks?.feedStock.filter(
            (feedStock) => feedStock.type === feedTypeEnum
          );

          const availableStockAmount = availableStock
            ? availableStock?.length === 0
              ? 0
              : availableStock[0].amount
            : 0;
          if (sumAmountNew - sumAmountOld > availableStockAmount) {
            const feedTypeS = t(`feedPurchase.feedType.option.${feedType}`);
            return (
              <Typography key={feedType}>
                Available {feedTypeS} is{" "}
                {convertToFeedStockAmountString(
                  availableStockAmount + sumAmountOld,
                  locale,
                  GENERAL_FEED_UNITS.KG,
                  t
                )}{" "}
                but feed sums up to{" "}
                {convertToFeedStockAmountString(
                  sumAmountNew,
                  locale,
                  GENERAL_FEED_UNITS.KG,
                  t
                )}
                .{" "}
              </Typography>
            );
          }
          return <Fragment key={feedType}></Fragment>;
        })}
        <Typography>
          Please reduce the amount for feed to the respective available feed.{" "}
        </Typography>
      </>
    );
    return errorMessage;
  };

  return (
    <Dialog
      open={open}
      onClose={setClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        {t("notEnoughFeed.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {notEnoughFeedAvailableMessage(feeds, feedStocks, oldFeeds)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose} color="primary" autoFocus>
          {t("notEnoughFeed.cancel.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
