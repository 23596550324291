import { EditingMode, ModeProps } from "react-map-gl-draw";
import {
  ClickEvent,
  FeatureCollection,
  PointerMoveEvent,
  Feature,
} from "@nebula.gl/edit-modes";
import { throttle } from "lodash";
import { THROTTLE_MS } from "./const";

type PointerMoveHandler = (
  event: PointerMoveEvent,
  props: ModeProps<FeatureCollection>
) => void;

export class EditingModeOverride extends EditingMode {
  protected handlePointerMoveThrottled: PointerMoveHandler | null | undefined =
    null;

  constructor(throttleMs = THROTTLE_MS) {
    super();
    this.handlePointerMoveThrottled = throttle(
      this.handlePointerMoveAux.bind(this),
      throttleMs
    );
  }

  protected handlePointerMoveAux(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ): void {
    super.handlePointerMove(event, props);
  }

  // fixing issue #557
  public handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    try {
      super.handleClick(event, props);
    } catch (e) {
      console.log("Exception while EditingModeOverride handling click");
      console.error(e);
    }
  }

  // fixing issue #557
  public _getCursorEditHandle(event: PointerMoveEvent, feature: Feature) {
    try {
      return super._getCursorEditHandle(event, feature);
    } catch (e) {
      console.log("Exception while EditingModeOverride _getCursorEditHandle");
      console.error(e);
      return null as any;
    }
  }

  public handlePointerMove(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ) {
    try {
      if (this.handlePointerMoveThrottled) {
        this.handlePointerMoveThrottled(event, props);
      }
    } catch (e) {
      console.log("Exception while EditingModeOverride handlePointerMove");
      console.error(e);
    }
  }
}
