import { useState, MouseEvent } from "react";
import { IconButton, MenuItem, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useHistory } from "react-router-dom";
import { RootState, useAppDispatch } from "model/rootReducer";
import { AnimalGroup, deleteAnimalGroup } from "../../model/animalGroupSlice";
import { useTranslation } from "common/locales";
import { useSelector } from "react-redux";
import useConfirmation from "common/utils/useConfirmation";
import { mixpanel } from "../../common/analytics";

export default function AnimalGroupMenu({ guid, name }: AnimalGroup) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const confirmDeleteGroup = useConfirmation({
    title: t("confirmationdeletedialog.animalGroup.title"),
    description: t("confirmationdeletedialog.animalGroup.description", {
      name,
    }),
  });
  const history = useHistory();
  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  return (
    <div>
      <IconButton edge="end" aria-haspopup="true" onClick={open} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={close}
      >
        <MenuItem
          to={`/edit-animal-group/${guid}`}
          component={Link}
          onClick={close}
        >
          {t("animalGroup.menu.edit")}
        </MenuItem>
        <MenuItem onClick={deleteGroup}>
          <Typography color="secondary">
            {t("animalGroup.menu.delete")}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );

  async function deleteGroup() {
    close();
    const confirmed = await confirmDeleteGroup();
    if (confirmed) {
      await dispatch(deleteAnimalGroup(guid));
      mixpanel.track("AnimalGroup deleted");
      history.push(`/farm/${currentFarmId || ""}`);
    }
  }

  function open(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function close() {
    setAnchorEl(null);
  }
}
