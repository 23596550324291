import { unwrapResult } from "@reduxjs/toolkit";
import { mixpanel } from "common/analytics";
import {
  fetchFieldsById,
  getAllInvalidatedFields,
  validateFieldData,
  useAppDispatch,
  fetchTilesByIds,
  getAllInvalidatedArableFields,
  fetchArableFieldsById,
  validateArableFieldData,
} from "model";
import { useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { useNotification } from "./NotificationProvider";
import { useTranslation } from "common/locales";

const REFRESH_INTERVAL = 1 * 1000;
const MAX_RETRY_PERIOD = 900; // retrying for 15 minutes just in case there are too many fields in the queue

interface RefreshProviderProps {
  children?: React.ReactNode;
}

const RefreshProvider: FC<RefreshProviderProps> = ({ children }) => {
  const invalidatedFields = useSelector(getAllInvalidatedFields);
  const invalidatedArableFields = useSelector(getAllInvalidatedArableFields);
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  useEffect(() => {
    const refreshFields = async () => {
      const now = Date.now() / 1000;
      const fieldsToPull = invalidatedFields.filter(
        (f) =>
          f.invalidatedTimestamp &&
          now - f.invalidatedTimestamp < MAX_RETRY_PERIOD
      );
      const newFields = await dispatch(
        fetchFieldsById(fieldsToPull.map((f) => f.guid))
      ).then(unwrapResult);
      void dispatch(fetchTilesByIds(newFields.flatMap((f) => f.tiles ?? [])));

      const fieldsToValidate = invalidatedFields.filter(
        (f) =>
          f.invalidatedTimestamp &&
          now - f.invalidatedTimestamp >= MAX_RETRY_PERIOD
      );
      fieldsToValidate.forEach((f) => dispatch(validateFieldData(f.guid)));

      const arableFieldsToPull = invalidatedArableFields.filter(
        (f) =>
          f.invalidatedTimestamp &&
          now - f.invalidatedTimestamp < MAX_RETRY_PERIOD
      );
      const newArableFields = await dispatch(
        fetchArableFieldsById(arableFieldsToPull.map((f) => f.guid))
      ).then(unwrapResult);
      void dispatch(
        fetchTilesByIds(newArableFields.flatMap((f) => f.tiles ?? []))
      );

      const arableFieldsToValidate = invalidatedArableFields.filter(
        (f) =>
          f.invalidatedTimestamp &&
          now - f.invalidatedTimestamp >= MAX_RETRY_PERIOD
      );
      arableFieldsToValidate.forEach((f) =>
        dispatch(validateArableFieldData(f.guid))
      );

      return;
    };

    const refreshInterval = setInterval(refreshFields, REFRESH_INTERVAL);

    // clear interval when there no fields with invalid data
    if (
      invalidatedFields.length === 0 &&
      invalidatedArableFields.length === 0
    ) {
      clearInterval(refreshInterval);
    }

    //clear interval when component unmounts
    return () => clearInterval(refreshInterval);
  }, [invalidatedFields, invalidatedArableFields, dispatch]);

  useEffect(() => {
    const reloadTimeout = 5 * 86400000; // 5 days
    const checkForReloadTimeout = 60000; // 60 sec
    let initialTime = Date.now();
    let timeout: NodeJS.Timeout;
    const reloadPage = () => {
      const locationMatch = /\/(farm|animal-group)\/[A-Za-z0-9]+$/.test(
        window.location.hash
      );
      clearTimeout(timeout);
      if (locationMatch && Date.now() - initialTime >= reloadTimeout) {
        initialTime = Date.now();
        notify(t("common.reloadPage"));
        mixpanel.track("Reload page"); // before the timeout so that event has time to be submitted
        console.log("Reloading the page in 6 sec");

        timeout = setTimeout(() => {
          window.location.reload();
        }, 6000); // reload in 6 seconds, so users can read the message
      } else {
        timeout = setTimeout(reloadPage, checkForReloadTimeout); // trying again every 60 sec
      }
    };
    timeout = setTimeout(reloadPage, checkForReloadTimeout);
    return () => {
      clearTimeout(timeout);
    };
  }, [t, notify]);

  return <>{children}</>;
};

export default RefreshProvider;
