import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  DialogActions,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  AnimalGroup,
  getDemand,
  getAllFieldsForAnimalGroupId,
  getAvailableDryMatterForAnimalGroupId,
  useAppDispatch,
  useAppSelector,
  selectAllFields,
  fetchstockClassesById,
} from "model";
import { useNotification } from "app/NotificationProvider";
import HeaderBar from "common/components/HeaderBar";
import { useTranslation } from "common/locales";
import { sumBy } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { mixpanel } from "../../common/analytics";

import {
  StockClassWithBirthingPeriods,
  selectStockClassWithBirthingPeriodsForAnimalGroup,
} from "model/stockClassBirthingPeriodSlices";
import AnimalGroupDemandSupplyChart from "./AnimalGroupDemandSupplyChart";
import StockClassFeedAllocationChart from "./StockClassFeedAllocationChart";
import {
  createStockClassFeedAllocation,
  deleteStockClassFeedAllocation,
  updateStockClassFeedAllocation,
} from "model/stockClassFeedAllocationSlice";
import { StockClassFeedAllocationExtended } from "./utils";
import FieldTransferDialog from "./FieldTransferDialog";
import { ConfirmCancellationDialog } from "common/components";
import TargetEntryCoverDialog from "./TargetEntryCoverDialog";

export default function FeedBudgetPlannerDialog({
  open,
  close,
  animalGroup,
}: GrassWedgeProps) {
  useEffect(() => {
    if (open) {
      mixpanel.track("Feed allocation open");
    }
  }, [open]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  function handleConfirmationClose() {
    setConfirmDialogOpen(false);
  }

  const { guid, name } = animalGroup;

  const fields = useAppSelector(getAllFieldsForAnimalGroupId(guid));
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { notify } = useNotification();

  const stockClasses = useAppSelector((state) =>
    selectStockClassWithBirthingPeriodsForAnimalGroup(state, animalGroup.guid)
  );

  const [fieldTransferDialogOpen, setFieldTransferDialogOpen] = useState(false);

  const [selectedStockClass, setSelectedStockClass] = useState<
    StockClassWithBirthingPeriods | undefined
  >(stockClasses?.at(0));

  const handleStockClassChange = (
    event: React.MouseEvent<HTMLElement>,
    sc: StockClassWithBirthingPeriods
  ) => {
    mixpanel.track("feed budgeting stock class expand clicked");
    if (sc) {
      setSelectedStockClass(sc);
    }
  };
  const [temporaryFeedAllocations, setTemporaryFeedAllocations] = useState<
    Map<string, StockClassFeedAllocationExtended[]>
  >(
    new Map(
      stockClasses.map((sc) => {
        return [sc.guid, []];
      })
    )
  );

  const [removedFeedAllocationsGuids, setRemovedFeedAllocationsGuids] =
    useState<string[]>([]);

  const [newFeedAllocationsGuids, setNewFeedAllocationsGuids] = useState<
    string[]
  >([]);

  function updateTemporaryFeedAllocations(
    stockClassGuid: string,
    feedAllocations: StockClassFeedAllocationExtended[]
  ) {
    const newMap = new Map(
      temporaryFeedAllocations.set(stockClassGuid, feedAllocations)
    );

    setTemporaryFeedAllocations(newMap);
  }

  useEffect(() => {
    if (stockClasses) {
      setSelectedStockClass(stockClasses.at(0));
    }
  }, [stockClasses]);

  if (!stockClasses) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onBackdropClick={() => setConfirmDialogOpen(true)}
    >
      <HeaderBar
        nested
        title={t("feedBudgetPlanner.title", { name })}
        onBack={() => setConfirmDialogOpen(true)}
      >
        <ToggleButtonGroup
          value={selectedStockClass}
          exclusive
          onChange={handleStockClassChange}
          style={{ marginLeft: "50px" }}
        >
          {stockClasses.map((sc, index) => {
            return (
              <ToggleButton key={sc.guid} value={sc} aria-label="kg">
                {sc.name}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <AssignButton
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            mixpanel.track(
              "feed budgeting change field allocation button clicked"
            );
            setFieldTransferDialogOpen(true);
          }}
        >
          {t("grassWedge.assignField")}
        </AssignButton>
      </HeaderBar>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            justifyContent={isMobile ? "flex-start" : "center"}
            alignItems="center"
            style={{ paddingBottom: theme.spacing(3), display: "flex" }}
          >
            <Typography variant="h5">
              {t("feedBudgetPlanner.animalGroupChart.title", {
                name: animalGroup.name,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <AnimalGroupDemandSupplyChart
              stockClasses={stockClasses}
              animalGroup={animalGroup}
              temporaryFeedAllocations={temporaryFeedAllocations}
            />
            {fields.length < 1 && (
              <Grid
                container
                justifyContent={isMobile ? "flex-start" : "center"}
                alignItems="center"
                style={{ paddingBottom: theme.spacing(3) }}
              >
                <Typography variant="h5">
                  {t("feedBudgetPlanner.noFields.prompt")}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            justifyContent={isMobile ? "flex-start" : "center"}
            alignItems="center"
            style={{ paddingBottom: theme.spacing(3), display: "flex" }}
          >
            <Typography variant="h5">
              {t("feedBudgetPlanner.stockClassDemandChart.title", {
                name: selectedStockClass?.name,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            {stockClasses.map((sc) => (
              <StockClassFeedAllocationChart
                stockClass={sc}
                visible={selectedStockClass?.guid === sc.guid}
                updateTemporaryFeedAllocations={updateTemporaryFeedAllocations}
                updateRemovedFeedAllocationsGuids={
                  setRemovedFeedAllocationsGuids
                }
                updateNewFeedAllocationsGuids={setNewFeedAllocationsGuids}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfirmDialogOpen(true)} color="secondary">
          {t("settings.button.close")}
        </Button>
        <Button onClick={(e) => save(e)} color="primary">
          {t("settings.button.save")}
        </Button>
      </DialogActions>
      <FieldTransferDialog
        open={fieldTransferDialogOpen}
        animalGroupId={animalGroup.guid}
        close={() => {
          setFieldTransferDialogOpen(false);
        }}
      />
      <ConfirmCancellationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmationClose}
      />
    </Dialog>
  );

  function save(e: any) {
    mixpanel.track("feed budgeted save changes button clicked");
    e?.preventDefault();
    try {
      removedFeedAllocationsGuids.forEach(
        (guid) => void dispatch(deleteStockClassFeedAllocation(guid))
      );

      Array.from(temporaryFeedAllocations.values()).forEach((fas) => {
        fas.forEach((fa) => {
          if (newFeedAllocationsGuids.includes(fa.guid)) {
            void dispatch(
              createStockClassFeedAllocation({
                dateStart: fa.dateStart,
                allocatedBodyWeightFraction: fa.allocatedBodyWeightFraction,
                allocationType: fa.allocationType,
                stockClassGuid: fa.stockClassGuid,
              } as any)
            ).then(() => dispatch(fetchstockClassesById([fa.stockClassGuid])));
          } else {
            void dispatch(
              updateStockClassFeedAllocation({
                guid: fa.guid,
                dateStart: fa.dateStart,
                allocatedBodyWeightFraction: fa.allocatedBodyWeightFraction,
                allocationType: fa.allocationType,
              } as any)
            ).then(() => dispatch(fetchstockClassesById([fa.stockClassGuid])));
          }
        });
      });
      setTemporaryFeedAllocations(
        new Map(
          stockClasses.map((sc) => {
            return [sc.guid, []];
          })
        )
      );

      mixpanel.track("Feed budget edited");
      notify(t("grassWedge.notification.saved"), "success");
    } catch (e) {
      notify(t("grassWedge.notification.error"), "error");
    }
    close();
  }
}

type GrassWedgeProps = {
  open: boolean;
  close: () => void;
  animalGroup: AnimalGroup;
};

const AssignButton = styled(Button)(
  ({ theme }) => `
  margin: ${theme.spacing(2)};
`
);
