import { TextField, Grid, FormControl, Typography } from "@mui/material";
import { useTranslation } from "common/locales";
import styled, { css } from "styled-components";
import { useCallback } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StockClassWithBirthingPeriods } from "model/stockClassBirthingPeriodSlices";
import { FormikErrors } from "formik";

interface StockClassBirthingPeriodInput {
  handleInputChange: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  index: number;
  stockClass: StockClassWithBirthingPeriods;
  errors?: FormikErrors<StockClassWithBirthingPeriods>;
}

export default function StockClassBirthingPeriodInput({
  stockClass,
  index,
  handleInputChange,
  setFieldValue,
  errors,
}: StockClassBirthingPeriodInput) {
  const { t } = useTranslation();
  const handleDateStartChange = useCallback(
    // https://stackoverflow.com/questions/56312372/react-datepicker-with-a-formik-form/56320889#56320889
    (newDate: Date | string | null, birthingPeriodIndex: number) => {
      setFieldValue(
        `stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].dateStart`,
        newDate,
        false
      );
      handleInputChange(
        `stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].dateStart`
      );
    },
    [handleInputChange]
  );

  const handleDateEndChange = useCallback(
    // https://stackoverflow.com/questions/56312372/react-datepicker-with-a-formik-form/56320889#56320889
    (newDate: Date | string | null, birthingPeriodIndex: number) => {
      setFieldValue(
        `stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].dateEnd`,
        newDate,
        false
      );
      handleInputChange(
        `stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].dateEnd`
      );
    },
    [handleInputChange]
  );

  const handleChangeNonNegative = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldName: string,
      min = 1
    ) => {
      const value = parseFloat(e.target.value);
      if (!isNaN(value) && value < min) {
        // do not allow negative values
        return;
      }
      handleInputChange(e, fieldName);
    },
    [handleInputChange]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledGrid item>
        <StyledTypography variant="h6" textAlign="left">
          {t("animalGroupInput.stockClasses.birthingPeriods")}
        </StyledTypography>
      </StyledGrid>
      {stockClass.birthingPeriodValues.map(
        (birthingPeriod, birthingPeriodIndex) => {
          const error = (errors?.birthingPeriodValues as any)?.[
            birthingPeriodIndex
          ];
          return (
            <FormControl
              key={`sc-${stockClass.guid}-${birthingPeriod}`}
              fullWidth
            >
              <StyledGrid item>
                <MobileDatePicker
                  inputFormat="MMMM do"
                  label={t(
                    "animalGroupInput.stockClasses.birthingPeriods.dateStart"
                  )}
                  value={new Date(birthingPeriod.dateStart)}
                  renderInput={(params) => (
                    <Field
                      variant="standard"
                      {...params}
                      fullWidth
                      required
                      id={`stock-class-input-birthing-period-start-${index}-${birthingPeriod.guid}`}
                      name={`stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].dateStart`}
                    />
                  )}
                  onChange={(e) =>
                    handleDateStartChange(e, birthingPeriodIndex)
                  }
                  DialogProps={{
                    PaperProps: {
                      sx: { "& *:focus": { outline: "none" } },
                    },
                  }}
                />
              </StyledGrid>
              <StyledGrid item>
                <MobileDatePicker
                  inputFormat="MMMM do"
                  label={t(
                    "animalGroupInput.stockClasses.birthingPeriods.dateEnd"
                  )}
                  value={new Date(birthingPeriod.dateEnd)}
                  minDate={new Date(birthingPeriod.dateStart)}
                  renderInput={(params) => (
                    <Field
                      required
                      variant="standard"
                      {...params}
                      fullWidth
                      id={`stock-class-input-birthing-period-end-${index}-${birthingPeriod.guid}`}
                      name={`stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].dateEnd`}
                    />
                  )}
                  onChange={(e) => handleDateEndChange(e, birthingPeriodIndex)}
                  DialogProps={{
                    PaperProps: {
                      sx: { "& *:focus": { outline: "none" } },
                    },
                  }}
                />
              </StyledGrid>

              <StyledGrid item>
                <Field
                  fullWidth
                  variant="standard"
                  required
                  type="number"
                  id={`stock-class-input-birthing-period-expectedNumAnimalsWithoutPregnancy-${index}-${birthingPeriod.guid}`}
                  name={`stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].expectedNumAnimalsWithoutPregnancy`}
                  value={birthingPeriod.expectedNumAnimalsWithoutPregnancy}
                  onChange={(e) =>
                    handleChangeNonNegative(
                      e,
                      `stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].expectedNumAnimalsWithoutPregnancy`,
                      0
                    )
                  }
                  label={t(
                    "animalGroupInput.stockClasses.birthingPeriods.expectedNumAnimalsWithoutPregnancy"
                  )}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  helperText={error && error.expectedNumAnimalsWithoutPregnancy}
                  error={!!(error && error.expectedNumAnimalsWithoutPregnancy)}
                />
              </StyledGrid>
              <StyledGrid item>
                <Field
                  fullWidth
                  variant="standard"
                  required
                  type="number"
                  id={`stock-class-input-birthing-period-expectedNumOffspringPerAnimal-${index}-${birthingPeriod.guid}`}
                  name={`stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].expectedNumOffspringPerAnimal`}
                  value={birthingPeriod.expectedNumOffspringPerAnimal}
                  onChange={(e) =>
                    handleChangeNonNegative(
                      e,
                      `stockClasses[${index}].birthingPeriodValues[${birthingPeriodIndex}].expectedNumOffspringPerAnimal`,
                      1
                    )
                  }
                  label={t(
                    "animalGroupInput.stockClasses.birthingPeriods.expectedNumOffspringPerAnimal"
                  )}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  helperText={error && error.expectedNumOffspringPerAnimal}
                  error={!!(error && error.expectedNumOffspringPerAnimal)}
                />
              </StyledGrid>
            </FormControl>
          );
        }
      )}
    </LocalizationProvider>
  );
}

const StyledGrid = styled(Grid)`
  width: 100%;
`;

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(3)};
  `
);

const Field = styled(TextField)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(2)};
  `
);
