import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import {
  useAppSelector,
  FEED_EVENT_REJECTION_PERIOD_DAYS,
  YieldEvent,
  selectAllAnimalGroups,
  AnimalGroup,
  MilkYieldAmount,
  GrassYieldAmount,
  MeatYieldAmount,
  CropYieldAmount,
  YIELD_TYPES,
  selectAllFields,
  selectAllArableFields,
  Field,
  ArableField,
  SOLD_OR_KEPT,
} from "model";
import { useTranslation } from "common/locales";
import { getFormattedDate } from "../../common/utils/getFormattedTime";
import { MoreVert } from "@mui/icons-material";

import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import UpdateYieldEventDialog from "./UpdateYieldEventDialog";
import YieldEventIcon from "common/icons/YieldEventIcon";
import { convertToYieldAmountString } from "./generalMethods";

export default function YieldEventListItem({
  yieldEvent,
}: {
  yieldEvent: YieldEvent;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);

  const icon = <YieldEventIcon />;

  const formattedTime = getFormattedDate(yieldEvent.yieldEventDate, locale);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  let animalGroups: string[] = [];
  if (
    yieldEvent.yieldDetails.yieldType === YIELD_TYPES.MEAT ||
    yieldEvent.yieldDetails.yieldType === YIELD_TYPES.MILK
  ) {
    animalGroups = yieldEvent.yieldDetails.animalGroupGuids;
  }
  const allAnimalGroups: AnimalGroup[] = useSelector(selectAllAnimalGroups);
  const selectedAnimalGroups = allAnimalGroups.filter((animalGroup) =>
    animalGroups.includes(animalGroup.guid)
  );

  let grassFields: string[] = [];
  let arableFields: string[] = [];
  if (YIELD_TYPES.GRASS === yieldEvent.yieldDetails.yieldType) {
    grassFields = yieldEvent.yieldDetails.fieldGuids;
  }
  if (YIELD_TYPES.CROP === yieldEvent.yieldDetails.yieldType) {
    arableFields = yieldEvent.yieldDetails.fieldGuids;
  }
  const allGrassFields: Field[] = useSelector(selectAllFields);
  const allArableFields: ArableField[] = useSelector(selectAllArableFields);

  const selectedGrassFields = allGrassFields.filter((grassField) =>
    grassFields.includes(grassField.guid)
  );

  const selectedArableFields = allArableFields.filter((arableField) =>
    arableFields.includes(arableField.guid)
  );
  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  const canEdit =
    differenceInDays(
      Date.now(),
      yieldEvent.creationDate ? new Date(yieldEvent.creationDate) : Date.now()
    ) <= FEED_EVENT_REJECTION_PERIOD_DAYS;

  return (
    <ListItem key={`re-listitem-${yieldEvent.guid}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" display="block">
            {t("yieldEvent.yieldEventDate", {
              yieldEventDate: formattedTime,
            })}
          </Typography>
        }
        secondary={
          <>
            <Typography component="span" display="block" noWrap>
              {t(
                `yieldEvent.yieldType.option.${yieldEvent.yieldDetails.yieldType}`
              )}{" "}
              yield
            </Typography>
            {selectedAnimalGroups.length > 0 && (
              <Typography component="span" display="block" noWrap>
                {t("yieldEvent.animalGroups.harvestedFrom")}
                {selectedAnimalGroups
                  .map((animalGroup) => animalGroup.name)
                  .join(", ")}
              </Typography>
            )}
            {selectedGrassFields.length > 0 && (
              <Typography component="span" display="block" noWrap>
                {t("yieldEvent.grassFields.harvestedFrom")}
                {selectedGrassFields
                  .map((grassField) => grassField.name)
                  .join(", ")}
              </Typography>
            )}
            {selectedArableFields.length > 0 && (
              <Typography component="span" display="block" noWrap>
                {t("yieldEvent.arableFields.harvestedFrom")}
                {selectedArableFields
                  .map((arableField) => arableField.name)
                  .join(", ")}
              </Typography>
            )}
            {yieldEvent.yieldDetails.yieldAmounts.map(
              (
                singleYield:
                  | MilkYieldAmount
                  | GrassYieldAmount
                  | MeatYieldAmount
                  | CropYieldAmount,
                index: number
              ) => {
                const yieldString = convertToYieldAmountString(
                  singleYield.amount,
                  locale,
                  singleYield.unitType,
                  t
                );

                const priceString = singleYield.revenue
                  ? singleYield.revenue.toLocaleString(locale, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                    }) + " £"
                  : "unknown revenue";

                return (
                  <Fragment key={`re-listitem-${index}`}>
                    {"grassYieldType" in singleYield && (
                      <Typography component="span" display="block">
                        {t(
                          `yieldEvent.yieldType.option.${singleYield.grassYieldType}`
                        )}
                      </Typography>
                    )}
                    {"cropYieldType" in singleYield && (
                      <Typography component="span" display="block">
                        {t(
                          `yieldEvent.yieldType.option.${singleYield.cropYieldType}`
                        )}
                      </Typography>
                    )}
                    {singleYield.soldOrKept === SOLD_OR_KEPT.KEPT && (
                      <Typography component="span" display="block">
                        {t("yieldEvent.amount.kept", {
                          amount: yieldString,
                        })}
                      </Typography>
                    )}

                    {singleYield.soldOrKept === SOLD_OR_KEPT.SOLD && (
                      <Typography component="span" display="block">
                        {t("yieldEvent.amount.sold", {
                          amount: yieldString,
                          soldPrice: priceString,
                        })}
                      </Typography>
                    )}
                  </Fragment>
                );
              }
            )}

            {yieldEvent.note && (
              <Typography component="span" display="block">
                Note: {yieldEvent.note}
              </Typography>
            )}
          </>
        }
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={openMenu}
            id={yieldEvent.guid}
            size="large"
          >
            <MoreVert />
          </IconButton>
          <UpdateYieldEventDialog
            closeMenu={closeMenu}
            menuAnchor={menuAnchor}
            yieldEvent={yieldEvent}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
