import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  MenuItem,
  Select,
  Box,
  Collapse,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { Dispatch, SetStateAction, useState, Fragment } from "react";
import { useTranslation } from "common/locales";
import { Link, Redirect } from "react-router-dom";

import { ManualDMInputItem, EventType } from "model";

import EventInput from "./EventInput";
import StyledCoverAppBar from "./StyledCoverAppBar";

interface Props {
  manualDMInputs: Array<ManualDMInputItem>;
  setManualDMInputs: Dispatch<SetStateAction<Array<ManualDMInputItem>>>;
}

export default function EnterReasons({
  manualDMInputs,
  setManualDMInputs,
}: Props) {
  const { t } = useTranslation();

  const [dateError, setDateError] = useState(false);
  const [coverError, setCoverError] = useState(false);
  const handleReasonChange = (
    e: SelectChangeEvent<EventType>,
    fieldId: string
  ) => {
    setManualDMInputs(
      manualDMInputs.map((i) => {
        if (i.field === fieldId) {
          switch (e.target.value) {
            case EventType.None:
              i.event = {
                eventType: EventType.None,
              };
              break;
            case EventType.GrazingEvent:
              i.event = {
                eventType: EventType.GrazingEvent,
                dateTimeIn: new Date(),
                dateTimeOut: new Date(),
              };
              break;
            case EventType.PartialGrazingEvent:
              // NOTE: We used to have part-grazed events; not used anymore
              break;
            case EventType.SilageEvent:
              // NOTE: We used to have silage events; not used anymore
              break;
          }
        }
        return i;
      })
    );
  };

  const DIFFERENCE_THRESHOLD = 500;
  const fieldsWithLargeDifference = manualDMInputs.reduce(
    (acc: Array<ManualDMInputItem>, reading: ManualDMInputItem) => {
      if (
        reading.newDryMatterValue &&
        Math.abs(reading.newDryMatterValue - reading.dryMatterValue) >
          DIFFERENCE_THRESHOLD
      ) {
        acc.push(reading);
      }
      return acc;
    },
    []
  );

  if (fieldsWithLargeDifference.length > 0) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h5">
              {t("addCovers.enterReasons.heading")}
            </Typography>
            <Typography variant="h6">
              {t("addCovers.enterReasons.subheading")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t("addCovers.enterReasons.fieldName")}
                    </TableCell>
                    <TableCell align="center">
                      {t("addCovers.enterCovers.current")}
                    </TableCell>
                    <TableCell align="center">
                      {t("addCovers.enterCovers.new")}
                    </TableCell>
                    <TableCell align="center">
                      {t("addCovers.enterReasons.reason")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fieldsWithLargeDifference.map(
                    (reading: ManualDMInputItem) => (
                      <Fragment key={reading.field}>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {reading.fieldName}
                          </TableCell>
                          <TableCell align="center">
                            {reading.dryMatterValue}
                          </TableCell>
                          <TableCell align="center">
                            {reading.newDryMatterValue}
                          </TableCell>
                          <TableCell align="right">
                            <Select
                              labelId="reason-select-label"
                              id="reason-select"
                              value={reading.event?.eventType}
                              onChange={(e) =>
                                handleReasonChange(e, reading.field)
                              }
                            >
                              <MenuItem value={EventType.GrazingEvent}>
                                {t("addCovers.enterReasons.menu.grazed")}
                              </MenuItem>
                              <MenuItem value={EventType.None}>
                                {t("addCovers.enterReasons.menu.noEvent")}
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={
                                Boolean(reading.event?.eventType) &&
                                reading.event?.eventType !== EventType.None
                              }
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1} padding={1}>
                                <EventInput
                                  reading={reading}
                                  manualDMInputs={manualDMInputs}
                                  setManualDMInputs={setManualDMInputs}
                                  dateError={dateError}
                                  setDateError={setDateError}
                                  coverError={coverError}
                                  setCoverError={setCoverError}
                                />
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
        <StyledCoverAppBar position="fixed" color="primary">
          <Button component={Link} to="enter-covers" color="secondary">
            {t("addCovers.back.label")}
          </Button>
          <Button
            component={Link}
            to="confirm-covers"
            color="primary"
            disabled={coverError || dateError}
          >
            {t("addCovers.next.label")}
          </Button>
        </StyledCoverAppBar>
      </>
    );
  } else {
    return <Redirect to="confirm-covers" />;
  }
}
