import { useHistory, useRouteMatch } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import {
  useAppDispatch,
  useAppSelector,
  changeFieldVisibility,
  setIsEditing,
  deleteTmpFeatures,
  setEditorSelectionType,
  setEditorIsInTentativeMode,
} from "model";
import { useTranslation } from "common/locales";
import { EditorSelectionType } from "features/mapDrawTools/enums";

interface ConfirmCancellationDialogProps {
  open: boolean;
  onClose: (value?: string) => void;
}

export default function ConfirmCancellationDialog(
  props: ConfirmCancellationDialogProps
) {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const fieldEditMatch = useRouteMatch<{
    fieldId: string;
  }>("/edit-field/:fieldId");
  const fieldAddMatch = useRouteMatch("/add-field");
  const animalGroupEditMatch = useRouteMatch<{ animalGroupId: string }>(
    "/edit-animal-group/:animalGroupId"
  );
  const animalGroupFeedAllocationMatch = useRouteMatch<{
    animalGroupId: string;
  }>("/animal-group/:animalGroupId/feed-allocation");
  const animalGroupAddMatch = useRouteMatch("/add-animal-group");

  const currentFarmId = useAppSelector((state) => state.farms.currentFarmId);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isEditing = useAppSelector((state) => state.app.isEditing);

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    if (fieldEditMatch || fieldAddMatch) {
      dispatch(setEditorSelectionType(EditorSelectionType.NONE)); // reset editor
      dispatch(setEditorIsInTentativeMode(false));
      if (isEditing) {
        dispatch(setIsEditing());
      }
      fieldEditMatch &&
        dispatch(
          changeFieldVisibility({
            guid: fieldEditMatch.params.fieldId,
            visibility: "visible",
          })
        );
      dispatch(deleteTmpFeatures());
      fieldEditMatch && history.push(`/field/${fieldEditMatch.params.fieldId}`);
      fieldAddMatch && history.push(`/farm/${currentFarmId || ""}`);
    }

    if (animalGroupAddMatch) {
      history.push(`/farm/${currentFarmId || ""}`);
    }
    if (animalGroupEditMatch) {
      history.push(
        `/animal-group/${animalGroupEditMatch.params.animalGroupId}`
      );
    }

    if (animalGroupFeedAllocationMatch) {
      history.push(
        `/animal-group/${animalGroupFeedAllocationMatch.params.animalGroupId}`
      );
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        {t("confirmcanceldialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("confirmcanceldialog.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="secondary">
          {t("confirmcanceldialog.confirm.label")}
        </Button>
        <Button onClick={handleCancel} color="primary" autoFocus>
          {t("confirmcanceldialog.cancel.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
