import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useTranslation } from "common/locales";

interface ConfirmCancellationDialogProps {
  dialogOpen: boolean;
  closeDialog: () => void;
  onConfirmCancel: () => void;
  onCancelCancel: () => void;
}

export default function ConfirmCancellationDialog(
  props: ConfirmCancellationDialogProps
) {
  const { onConfirmCancel, onCancelCancel, dialogOpen, closeDialog } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        {t("confirmcanceldialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("confirmcanceldialog.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onConfirmCancel();
            closeDialog();
          }}
          color="secondary"
        >
          {t("confirmcanceldialog.confirm.label")}
        </Button>
        <Button
          onClick={() => {
            onCancelCancel();
            closeDialog();
          }}
          color="primary"
          autoFocus
        >
          {t("confirmcanceldialog.cancel.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
