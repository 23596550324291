import { useState, useEffect } from "react";
import { Switch, useHistory, useRouteMatch, Route } from "react-router-dom";
import {
  EnterCovers,
  EnterTime,
  EnterReasons,
  ConfirmCovers,
} from "features/bulkCoverInput";
import { Container, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectAllFields,
  Field,
  invalidateFieldData,
  deleteAllTilesForField,
  ManualDMInputItem,
  RootState,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { EventType, createManyReadings } from "../../model/manualInputSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

function roundToHour(date: Date) {
  const p = 60 * 60 * 1000; // milliseconds in an hour
  return new Date(Math.round(date.getTime() / p) * p);
}

export default function Covers() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  const handleClose = () => {
    history.push(`/farm/${currentFarmId || ""}`);
  };

  const fields = useSelector(selectAllFields);

  const [manualDMInputs, setManualDMInputs] = useState<
    Array<ManualDMInputItem>
  >([]);
  const [dateTimeMeasurementStart, setDateTimeMeasurementStart] = useState(
    roundToHour(new Date())
  );
  const [dateTimeMeasurementEnd, setDateTimeMeasurementEnd] = useState(
    roundToHour(new Date())
  );

  useEffect(() => {
    if (fields.length > 0) {
      setManualDMInputs(
        fields.map((field: Field) => ({
          dryMatterValue: field.dryMatterNow,
          field: field.guid,
          fieldName: field.name,
          event: { eventType: EventType.None },
          kind: "user",
          verificationStatus: "Accepted",
        }))
      );
    }
  }, [fields]);

  async function handleSave() {
    const preparedInputs = manualDMInputs.reduce(
      (acc: Array<ManualDMInputItem>, manualInput) => {
        if (manualInput.newDryMatterValue) {
          acc.push({
            ...manualInput,
            dryMatterValue: manualInput.newDryMatterValue,
          });
        }
        return acc;
      },
      []
    );
    await dispatch(
      createManyReadings(
        preparedInputs.map((i) => ({
          guid: "new",
          manualDryMatterInput: {
            ...i,
            dateTimeMeasurementStart: dateTimeMeasurementStart.toISOString(),
            dateTimeMeasurementEnd: dateTimeMeasurementEnd.toISOString(),
            event: {
              ...i.event,
              dateTimeCut:
                i.event.dateTimeCut && i.event.dateTimeCut.toISOString(),
              dateTimeIn:
                i.event.dateTimeIn && i.event.dateTimeIn.toISOString(),
              dateTimeOut:
                i.event.dateTimeOut && i.event.dateTimeOut.toISOString(),
            },
          },
        }))
      )
    ).then(unwrapResult);
    manualDMInputs
      .filter((i) => i.newDryMatterValue)
      .forEach(async (i) => {
        // it's important to wait for the tiles to be deleted,
        // as we are setting field.tiles=[] when we are invalidating field data,
        // and we need the field to still have tile ids when deleting the tiles for a field
        await dispatch(deleteAllTilesForField(i.field));
        dispatch(invalidateFieldData(i.field));
      });
    notify(t("addCovers.success"));
    mixpanel.track("CoverAdd successful");
    history.push(`/farm/${currentFarmId || ""}`);
  }

  return (
    <Dialog fullScreen disableEscapeKeyDown open onClose={handleClose}>
      <StyledContainer>
        <Switch>
          <Route path={`${path}/enter-time`}>
            <EnterTime
              dateTimeMeasurementStart={dateTimeMeasurementStart}
              setDateTimeMeasurementStart={setDateTimeMeasurementStart}
              dateTimeMeasurementEnd={dateTimeMeasurementEnd}
              setDateTimeMeasurementEnd={setDateTimeMeasurementEnd}
            />
          </Route>
          <Route path={`${path}/enter-covers`}>
            <EnterCovers
              manualDMInputs={manualDMInputs}
              setManualDMInputs={setManualDMInputs}
            />
          </Route>
          <Route path={`${path}/enter-reasons`}>
            <EnterReasons
              manualDMInputs={manualDMInputs}
              setManualDMInputs={setManualDMInputs}
            />
          </Route>
          <Route path={`${path}/confirm-covers`}>
            <ConfirmCovers
              dateTimeMeasurementStart={dateTimeMeasurementStart}
              dateTimeMeasurementEnd={dateTimeMeasurementEnd}
              manualDMInputs={manualDMInputs}
              handleSave={handleSave}
            />
          </Route>
        </Switch>
      </StyledContainer>
    </Dialog>
  );
}
