import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityId,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { RootState } from "./rootReducer";
import {
  CROP_YIELD_TYPE,
  GRASS_YIELD_TYPES,
  YIELD_TYPES,
  getYieldSummary,
} from "./yieldSummarySlice";
import { getFeedStock } from "./feedStockSlice";
import { MACHINERY } from "./commonTypes";

export const YIELD_EVENT_REJECTION_PERIOD_DAYS = 30;

const BEGINNING_OF_TIME = "1970-01-01T00:00:01.000Z";

export enum GRASS_UNITS {
  KG = "KG",
  TONNE = "TONNE",
}

export enum CROP_UNITS {
  KG = "KG",
  TONNE = "TONNE",
}

export enum MILK_UNITS {
  LITRE = "LITRE",
  M3 = "M3",
}

export enum MEAT_UNITS {
  KG = "KG",
  TONNE = "TONNE",
}

export enum SOLD_OR_KEPT {
  SOLD = "SOLD",
  KEPT = "KEPT",
}

export enum REVENUE_CURRENCY {
  GBP = "GBP",
}

export type GrassYieldAmount = {
  unitType: GRASS_UNITS;
  amount: number;
  soldOrKept: SOLD_OR_KEPT;
  grassYieldType: GRASS_YIELD_TYPES;
  revenue?: number;
  revenueCurrency?: REVENUE_CURRENCY;
};

export type GrassYieldDetails = {
  yieldType: YIELD_TYPES.GRASS;
  yieldAmounts: GrassYieldAmount[];
  fieldGuids: string[];
};

export type CropYieldAmount = {
  unitType: CROP_UNITS;
  amount: number;
  soldOrKept: SOLD_OR_KEPT;
  cropYieldType: CROP_YIELD_TYPE;
  revenue?: number;
  revenueCurrency?: REVENUE_CURRENCY;
};

export type CropYieldDetails = {
  yieldType: YIELD_TYPES.CROP;
  yieldAmounts: CropYieldAmount[];
  fieldGuids: string[];
};

export type MilkYieldAmount = {
  unitType: MILK_UNITS;
  amount: number;
  soldOrKept: SOLD_OR_KEPT;
  revenue?: number;
  revenueCurrency?: REVENUE_CURRENCY;
};

export type MilkYieldDetails = {
  yieldType: YIELD_TYPES.MILK;
  yieldAmounts: MilkYieldAmount[];
  animalGroupGuids: string[];
};

export type MeatYieldAmount = {
  unitType: MEAT_UNITS;
  amount: number;
  soldOrKept: SOLD_OR_KEPT;
  revenue?: number;
  revenueCurrency?: REVENUE_CURRENCY;
};

export type MeatYieldDetails = {
  yieldType: YIELD_TYPES.MEAT;
  yieldAmounts: MeatYieldAmount[];
  animalGroupGuids: string[];
};

export type YieldEvent = {
  creationDate?: string;
  guid: string;
  yieldEventDate: string;
  note?: string;
  fuel?: number;
  machinery?: MACHINERY[];
  yieldDetails:
    | GrassYieldDetails
    | MilkYieldDetails
    | MeatYieldDetails
    | CropYieldDetails;
};

class YieldAPI extends API<YieldEvent> {
  async getAllYieldEventsForFarm(
    farmGuid: string,
    yieldEventTimeAfter?: string | undefined
  ): Promise<YieldEvent[]> {
    const yieldEventTimeAfterParam = yieldEventTimeAfter
      ? `&yieldEventTimeAfter=${yieldEventTimeAfter}`
      : "";
    const res = await fetch(
      `${this.baseUrl}${this.entity}?farmGuid=${farmGuid}${yieldEventTimeAfterParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as YieldEvent[];
  }
}

const yieldEventApi = new YieldAPI("yield");

export const createYieldEvent = createAsyncThunk(
  "yieldEvent/create",
  async (
    { yieldEvent, farmGuid }: { yieldEvent: YieldEvent; farmGuid: string },
    { dispatch }
  ) => {
    const result = await yieldEventApi.createOne(yieldEvent);
    await dispatch(
      getYieldSummary({
        farmGuid: farmGuid,
        yieldEventTimeAfter: BEGINNING_OF_TIME,
      })
    );
    await dispatch(
      getFeedStock({
        farmGuid: farmGuid,
        timeAfter: BEGINNING_OF_TIME,
      })
    );
    return { ...yieldEvent, ...result };
  }
);

export const updateYieldEvent = createAsyncThunk(
  "yieldEvent/update",
  async (
    { yieldEvent, farmGuid }: { yieldEvent: YieldEvent; farmGuid: string },
    { dispatch }
  ) => {
    const result = await yieldEventApi.updateOne(yieldEvent);
    await dispatch(
      getYieldSummary({
        farmGuid: farmGuid,
        yieldEventTimeAfter: BEGINNING_OF_TIME,
      })
    );
    await dispatch(
      getFeedStock({
        farmGuid: farmGuid,
        timeAfter: BEGINNING_OF_TIME,
      })
    );

    return { ...yieldEvent, ...result };
  }
);

export const yieldEventAdapter = createEntityAdapter<YieldEvent>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (yieldEvent: YieldEvent) => yieldEvent.guid,
  // Keeps the array sorted by guid
  sortComparer: (a: YieldEvent, b: YieldEvent) => a.guid.localeCompare(b.guid),
});

export const { selectAll: selectAllYieldEvents } =
  yieldEventAdapter.getSelectors((state: RootState) => state.yieldEvents);

export const getAllYieldEventsForFarm = createAsyncThunk<
  Promise<Array<YieldEvent>>,
  { farmGuid: string; yieldEventTimeAfter?: string }
>(
  "yieldEvent/getYieldEventsBulk",
  async ({
    farmGuid,
    yieldEventTimeAfter,
  }: {
    farmGuid: string;
    yieldEventTimeAfter?: string | undefined;
  }) => {
    return yieldEventApi.getAllYieldEventsForFarm(
      farmGuid,
      yieldEventTimeAfter
    );
  }
);

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";
type YieldEventState = EntityState<YieldEvent> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialYieldEventState: YieldEventState =
  yieldEventAdapter.getInitialState({
    loading: "idle",
    error: null,
    status: null,
  });

const yieldEventSlice = createSlice({
  name: "yieldEvent",
  initialState: initialYieldEventState,
  reducers: {
    resetYieldEventEntries: () => initialYieldEventState,
  },
  extraReducers: {
    [createYieldEvent.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createYieldEvent.fulfilled.type]: (
      state,
      action: PayloadAction<YieldEvent>
    ) => {
      if (state.loading === "pending") {
        yieldEventAdapter.upsertOne(state, action.payload);
        state.loading = "idle";
        state.status = "success";
      }
    },
    [createYieldEvent.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },

    [getAllYieldEventsForFarm.fulfilled.type]: (state, action) => {
      yieldEventAdapter.setAll(
        state,
        action.payload as readonly YieldEvent[] | Record<EntityId, YieldEvent>
      );
    },

    [getAllYieldEventsForFarm.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [getAllYieldEventsForFarm.fulfilled.type]: (
      state,
      action: PayloadAction<Array<YieldEvent>>
    ) => {
      yieldEventAdapter.upsertMany(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [getAllYieldEventsForFarm.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
    [updateYieldEvent.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [updateYieldEvent.fulfilled.type]: (
      state,
      action: PayloadAction<YieldEvent>
    ) => {
      yieldEventAdapter.upsertOne(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [updateYieldEvent.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
  },
});
export const { resetYieldEventEntries } = yieldEventSlice.actions;
export const yieldEventReducer = yieldEventSlice.reducer;
