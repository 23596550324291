import SvgIcon from "@mui/material/SvgIcon";

export default function GrassWedgeIcon({ disabled }: { disabled?: boolean }) {
  return (
    <SvgIcon
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      style={{ opacity: disabled ? "0.3" : "1" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="3.99999" height="18" fill="#308068" />
      <rect x="6" y="3" width="4" height="15" fill="#6ABC6D" />
      <rect x="12" y="6" width="4" height="12" fill="#8BDC7A" />
      <rect x="18" y="9" width="4" height="9" fill="#B1E579" />
    </SvgIcon>
  );
}
