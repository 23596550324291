import SvgIcon from "@mui/material/SvgIcon";

export default function FertilizerEventIcon() {
  return (
    <SvgIcon
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="96.000000pt"
      height="97.000000pt"
      viewBox="0 0 96.000000 97.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,97.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M497 680 c-14 -16 -28 -44 -32 -62 -4 -18 -16 -45 -26 -60 -18 -25
-18 -33 -3 -75 10 -28 11 -53 2 -53 -4 0 -8 -5 -8 -10 0 -6 65 -43 145 -82
115 -58 149 -71 163 -62 27 16 135 232 128 258 -4 16 -45 42 -154 99 -82 42
-159 77 -170 77 -11 0 -31 -13 -45 -30z m194 -70 c77 -39 142 -77 145 -84 5
-13 -69 -169 -100 -211 -14 -20 -17 -19 -133 39 l-119 58 -15 57 c-14 53 -13
60 3 87 10 16 18 36 18 44 0 21 37 80 50 80 6 0 73 -32 151 -70z"
        />
        <path
          d="M555 537 c0 -50 4 -98 8 -105 11 -17 105 -64 110 -54 2 4 10 30 16
59 11 46 10 56 -7 96 -21 47 -76 97 -108 97 -17 0 -19 -8 -19 -93z m67 36 c19
-17 24 -43 8 -43 -5 0 -10 7 -10 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 19
9 19 32 -2z m-26 -70 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z
m74 -25 c0 -9 -4 -19 -8 -22 -4 -2 -8 11 -8 30 0 20 3 29 8 21 4 -6 8 -20 8
-29z m-60 -8 c8 -5 12 -11 10 -14 -3 -2 -14 2 -24 10 -22 15 -10 19 14 4z"
        />
        <path
          d="M280 490 c6 -11 17 -20 24 -20 17 0 26 -22 26 -61 0 -31 -9 -36 -27
-18 -23 23 -35 4 -14 -23 12 -14 21 -40 21 -57 l0 -30 -107 -3 c-74 -2 -108
-7 -111 -15 -3 -10 49 -13 238 -13 189 0 241 3 238 13 -3 8 -38 13 -116 15
-62 1 -112 7 -112 12 0 5 7 7 15 4 10 -4 15 0 15 11 0 11 -5 15 -16 11 -25
-10 -17 104 10 126 25 20 13 37 -14 20 -17 -10 -20 -10 -20 6 0 23 -19 42 -42
42 -16 0 -17 -3 -8 -20z"
        />
        <path
          d="M396 391 c-3 -5 5 -16 19 -25 30 -20 35 -20 35 0 0 10 -6 14 -15 10
-9 -3 -15 0 -15 9 0 16 -16 20 -24 6z"
        />
        <path
          d="M372 349 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"
        />
        <path d="M386 312 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z" />
      </g>
    </SvgIcon>
  );
}
