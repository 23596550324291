import { List, ListItem, ListItemText } from "@mui/material";
import {
  useAppSelector,
  selectAllFeedPurchaseEvents,
  GeneralFeedPurchaseEvent,
  GeneralFeedEvent,
  selectAllFeedEvents,
} from "model";
import { useTranslation } from "common/locales";
import FeedPurchaseEventListItem from "./FeedPurchaseEventListItem";
import FeedEventListItem from "./FeedEventListItem";
export default function FeedPurchaseEventList() {
  const { t } = useTranslation();

  const feedPurchaseEventsForFarm: Array<
    GeneralFeedPurchaseEvent | GeneralFeedEvent
  > = useAppSelector((state) => selectAllFeedPurchaseEvents(state));

  const feedEventsForFarm: Array<GeneralFeedPurchaseEvent | GeneralFeedEvent> =
    useAppSelector((state) => selectAllFeedEvents(state));

  const allEvents: Array<GeneralFeedEvent | GeneralFeedPurchaseEvent> =
    feedPurchaseEventsForFarm.concat(feedEventsForFarm);

  // see https://stackoverflow.com/questions/51528780/typescript-check-typeof-against-custom-type
  const isFeedEvent = (
    x: GeneralFeedEvent | GeneralFeedPurchaseEvent
  ): boolean => Object.keys(x).includes("feedEventDate");
  const isFeedPurchaseEventv = (
    x: GeneralFeedEvent | GeneralFeedPurchaseEvent
  ): boolean => Object.keys(x).includes("feedPurchaseEventDate");

  const getDate = (
    event: GeneralFeedEvent | GeneralFeedPurchaseEvent
  ): Date => {
    if (isFeedEvent(event)) {
      return new Date((event as GeneralFeedEvent).feedEventDate);
    }
    if (isFeedPurchaseEventv(event)) {
      return new Date(
        (event as GeneralFeedPurchaseEvent).feedPurchaseEventDate
      );
    }
    return new Date(1);
  };

  const sortedFeedPurchaseEventsForFarm = allEvents.sort(
    (
      a: GeneralFeedPurchaseEvent | GeneralFeedEvent,
      b: GeneralFeedPurchaseEvent | GeneralFeedEvent
    ) => getDate(b).valueOf() - getDate(a).valueOf()
  );

  return (
    <List>
      <ListItem key="title">
        <ListItemText primary={t("feedPurchase.list.title")} />
      </ListItem>
      {sortedFeedPurchaseEventsForFarm.map(
        (event: GeneralFeedPurchaseEvent | GeneralFeedEvent) => {
          if (isFeedEvent(event)) {
            return (
              <FeedEventListItem
                feedEvent={event as GeneralFeedEvent}
                key={`re-listitem-${event.guid}`}
              />
            );
          } else {
            return (
              <FeedPurchaseEventListItem
                feedPurchaseEvent={event as GeneralFeedPurchaseEvent}
                key={`re-listitem-${event.guid}`}
              />
            );
          }
        }
      )}
    </List>
  );
}
