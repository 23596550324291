import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Field, selectAllFields, updateField, useAppDispatch } from "model";
import { useTranslation } from "common/locales";
import { useState } from "react";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";

export default function AssignFieldDialog({
  animalGroupId,
  open,
  close,
}: AssignFieldDialogProps) {
  const [field, setField] = useState<Field | null>(null);
  const fields = useSelector(selectAllFields).filter(
    (field) => field.animalGroup !== animalGroupId
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} onBackdropClick={onClose}>
      <DialogTitle>{t("assignFieldDialog.title")}</DialogTitle>
      <DialogContent>
        <Autocomplete
          disabled={fields.length === 0}
          options={fields}
          getOptionLabel={(field) => field.name}
          style={{ width: "100%" }}
          onChange={(_, newValue) => setField(newValue)}
          value={field}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                fields.length === 0
                  ? "assignFieldDialog.nofieldsLabel"
                  : "assignFieldDialog.inputLabel"
              )}
              variant="outlined"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("settings.button.close")}
        </Button>
        <Button disabled={fields.length === 0} onClick={assign} color="primary">
          {t("assignFieldDialog.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function onClose() {
    setField(null);
    close();
  }

  function assign() {
    mixpanel.track("feed budgeting assign button clicked");
    if (field) {
      setField(null);
      close();
      void dispatch(updateField({ ...field, animalGroup: animalGroupId }));
    }
  }
}

type AssignFieldDialogProps = {
  open: boolean;
  close: () => void;
  animalGroupId: string;
};
