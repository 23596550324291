import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import {
  useAppSelector,
  FEED_EVENT_REJECTION_PERIOD_DAYS,
  FieldPreparationEvent,
  selectAllFields,
  selectAllArableFields,
  Field,
  ArableField,
} from "model";
import { useTranslation } from "common/locales";
import { getFormattedDate } from "../../common/utils/getFormattedTime";
import { MoreVert } from "@mui/icons-material";

import { useState, Fragment } from "react";
import { useSelector } from "react-redux";

import FieldPreparationEventIcon from "common/icons/FieldPreparationEventIcon";
import UpdateFieldPreparationEventDialog from "./UpdateFieldPreparationEventDialog";

export default function FieldPreparationEventListItem({
  fieldPreparationEvent,
}: {
  fieldPreparationEvent: FieldPreparationEvent;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);

  const icon = <FieldPreparationEventIcon />;

  const formattedTime = getFormattedDate(
    fieldPreparationEvent.fieldPreparationEventDate,
    locale
  );
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const allGrassFields: Field[] = useSelector(selectAllFields);
  const allArableFields: ArableField[] = useSelector(selectAllArableFields);

  const selectedGrassFields = allGrassFields.filter((grassField) =>
    fieldPreparationEvent.fieldGuids.includes(grassField.guid)
  );

  const selectedArableField = allArableFields.filter((arableField) =>
    fieldPreparationEvent.fieldGuids.includes(arableField.guid)
  );
  const fieldNames = selectedGrassFields
    .map((field: Field) => field.name)
    .concat(selectedArableField.map((field: ArableField) => field.name));

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  const canEdit =
    differenceInDays(
      Date.now(),
      fieldPreparationEvent.creationDate
        ? new Date(fieldPreparationEvent.creationDate)
        : Date.now()
    ) <= FEED_EVENT_REJECTION_PERIOD_DAYS;

  return (
    <ListItem key={`re-listitem-${fieldPreparationEvent.guid}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" display="block">
            {t("fieldPreparationEvent.fieldPreparationEventDate", {
              fieldPreparationEventDate: formattedTime,
            })}
          </Typography>
        }
        secondary={
          <>
            <Typography component="span" display="block" noWrap>
              {fieldNames.join(", ")}
            </Typography>
            <Typography component="span" display="block" noWrap>
              {t("fieldPreparationEvent.fuel.name")}:{" "}
              {fieldPreparationEvent.fuel} litre
            </Typography>
            <Typography component="span" display="block" noWrap>
              {t("fieldPreparationEvent.machinery.name")}:{" "}
              {fieldPreparationEvent.machinery
                .map((machine) =>
                  t(`yieldEventInput.machinery.options.${machine}`)
                )
                .join(", ")}
            </Typography>

            {fieldPreparationEvent.note && (
              <Typography
                component="span"
                display="block"
                textOverflow="ellipsis"
                overflow="auto"
              >
                {t("fieldPreparationEvent.note.name")}:{" "}
                {fieldPreparationEvent.note}
              </Typography>
            )}
          </>
        }
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={openMenu}
            id={fieldPreparationEvent.guid}
            size="large"
          >
            <MoreVert />
          </IconButton>
          {
            <UpdateFieldPreparationEventDialog
              closeMenu={closeMenu}
              menuAnchor={menuAnchor}
              fieldPreparationEvent={fieldPreparationEvent}
            />
          }
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
