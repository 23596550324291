import { Grid, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useTranslation } from "common/locales";
import { FormikErrors } from "formik";
import React from "react";
import {
  FERTILIZER_TYPES,
  NITROGEN_FERTILIZER_TYPE,
  ORGANIC_FERTILIZER_ANIMAL_TYPES,
  ORGANIC_FERTILIZER_APPLICATION_METHOD,
  ORGANIC_FERTILIZER_TYPES,
} from "model";

import GenericDropdown from "features/general/GenericDropdown";
import {
  AnyOtherFertilizerEvent,
  FertilizerEventFormData,
  LimeApplicationEvent,
  OrganicFertilizerApplicationEvent,
  SyntheticFertilizerApplicationEvent,
} from "./FertilizerEventFormData";
import NameAndGuidMultiSelectDropdown from "features/general/NameAndGuidMultiSelectDropdown";

interface Props {
  fertilizerProperties:
    | OrganicFertilizerApplicationEvent
    | SyntheticFertilizerApplicationEvent
    | LimeApplicationEvent
    | AnyOtherFertilizerEvent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleInputChange: (event: any, name: string) => Promise<void>;
  errors?: FormikErrors<FertilizerEventFormData>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fertilizerType: FERTILIZER_TYPES;
}

export default function EnterFertilizerEventFertilizerProperties<YT>({
  fertilizerType,
  fertilizerProperties,
  handleInputChange,
  errors,
  setFieldValue,
}: Props) {
  const { t } = useTranslation();

  if (fertilizerType === FERTILIZER_TYPES.ORGANIC) {
    return (
      <Grid
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        container
        flexDirection={"row"}
        sx={{
          justifyItems: "baseline",
          justifySelf: "baseline",
          alignSelf: "baseline",
          paddingBottom: 5,
        }}
        display="flex"
        alignItems="baseline"
      >
        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
          <GenericDropdown
            disabled={false}
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.organicFertilizerType`
              );
            }}
            currentValue={
              (fertilizerProperties as OrganicFertilizerApplicationEvent)
                .organicFertilizerType
            }
            inputError={
              (
                errors?.fertilizerProperties as OrganicFertilizerApplicationEvent
              )?.organicFertilizerType
                ? true
                : false
            }
            label={"fertilizerEvent.fertilizerProperties.organicType.text"}
            name={`fertilizerProperties.organicFertilizerType`}
            possibleValues={ORGANIC_FERTILIZER_TYPES}
            tPrefix={"fertilizerEvent.organicFertilizerType.label"}
          />
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  (fertilizerProperties as OrganicFertilizerApplicationEvent)
                    ?.isImported ?? false
                }
              />
            }
            label={t("organicFertilizer.imported.label")}
            name={`fertilizerProperties.isImported`}
            onChange={(event) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setFieldValue(
                "fertilizerProperties.isImported",
                (event.target as any).checked,
                false
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
          <NameAndGuidMultiSelectDropdown
            required={true}
            selectedItems={Object.keys(ORGANIC_FERTILIZER_ANIMAL_TYPES)
              .filter((key: string) => {
                return (
                  fertilizerProperties as OrganicFertilizerApplicationEvent
                ).animalType?.includes(key as ORGANIC_FERTILIZER_ANIMAL_TYPES);
              })
              .map((key: string) => {
                return {
                  guid: key,
                  name: t(`fertilizerEventInput.animalType.options.${key}`),
                };
              })}
            items={Object.keys(ORGANIC_FERTILIZER_ANIMAL_TYPES).map(
              (key: string) => {
                return {
                  name: t(`fertilizerEventInput.animalType.options.${key}`),
                  guid: key,
                };
              }
            )}
            disabled={false}
            onChange={async (e) =>
              await handleInputChange(e, "fertilizerProperties.animalType")
            }
            selectInputError={
              (
                errors?.fertilizerProperties as OrganicFertilizerApplicationEvent
              )?.animalType
                ? true
                : false
            }
            label={t("fertilizerEventInput.animalType.label")}
            name={"fertilizerProperties.animalType"}
          />
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
          <GenericDropdown
            disabled={false}
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.applicationMethod`
              );
            }}
            currentValue={
              (fertilizerProperties as OrganicFertilizerApplicationEvent)
                .applicationMethod
            }
            inputError={
              (
                errors?.fertilizerProperties as OrganicFertilizerApplicationEvent
              )?.applicationMethod
                ? true
                : false
            }
            label={"fertilizerProperties.applicationMethod"}
            name={`fertilizerProperties.applicationMethod`}
            possibleValues={ORGANIC_FERTILIZER_APPLICATION_METHOD}
            tPrefix={
              "fertilizerEvent.fertilizerProperties.applicationMethod.options"
            }
          />
        </Grid>
      </Grid>
    );
  }

  if (fertilizerType === FERTILIZER_TYPES.SYNTHETIC) {
    return (
      <Grid
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        container
        flexDirection={"row"}
        sx={{
          justifyItems: "baseline",
          justifySelf: "baseline",
          alignSelf: "baseline",
          paddingBottom: 5,
        }}
        display="flex"
        alignItems="baseline"
      >
        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
          <GenericDropdown
            disabled={false}
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.typeOfNitrogen`
              );
            }}
            currentValue={
              (fertilizerProperties as SyntheticFertilizerApplicationEvent)
                .typeOfNitrogen
            }
            inputError={
              (
                errors?.fertilizerProperties as SyntheticFertilizerApplicationEvent
              )?.typeOfNitrogen
                ? true
                : false
            }
            label={"fertilizerEvent.fertilizerProperties.typeOfNitrogen.text"}
            name={`fertilizerProperties.typeOfNitrogen`}
            possibleValues={NITROGEN_FERTILIZER_TYPE}
            tPrefix={"fertilizerEvent.typeOfNitrogen.label"}
          />
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
          <TextField
            fullWidth
            required
            value={
              (fertilizerProperties as SyntheticFertilizerApplicationEvent)
                .productName
            }
            type="text"
            variant="standard"
            disabled={false}
            id="productName"
            error={
              (
                errors?.fertilizerProperties as SyntheticFertilizerApplicationEvent
              )?.productName
                ? true
                : false
            }
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.productName`
              );
            }}
            label={t("fertilizerEvent.fertilizerProperties.productName.label")}
            maxRows={1}
            name={`fertilizerProperties.productName`}
          />
        </Grid>
        <Grid item xs={12} sm={4} display="flex" alignItems="baseline">
          <TextField
            required
            value={
              (fertilizerProperties as SyntheticFertilizerApplicationEvent)
                .nPercentage
            }
            type="number"
            variant="standard"
            disabled={false}
            id="nPercentage"
            error={
              (
                errors?.fertilizerProperties as SyntheticFertilizerApplicationEvent
              )?.nPercentage
                ? true
                : false
            }
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.nPercentage`
              );
            }}
            label={t("fertilizerEvent.fertilizerProperties.nPercentage.label")}
            maxRows={1}
            name={`fertilizerProperties.nPercentage`}
          />
        </Grid>
        <Grid item xs={12} sm={4} display="flex" alignItems="baseline">
          <TextField
            value={
              (fertilizerProperties as SyntheticFertilizerApplicationEvent)
                .pPercentage
            }
            type="number"
            variant="standard"
            disabled={false}
            id="pPercentage"
            error={
              (
                errors?.fertilizerProperties as SyntheticFertilizerApplicationEvent
              )?.pPercentage
                ? true
                : false
            }
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.pPercentage`
              );
            }}
            label={t("fertilizerEvent.fertilizerProperties.pPercentage.label")}
            maxRows={1}
            name={`fertilizerProperties.pPercentage`}
          />
        </Grid>
        <Grid item xs={12} sm={4} display="flex" alignItems="baseline">
          <TextField
            value={
              (fertilizerProperties as SyntheticFertilizerApplicationEvent)
                .kPercentage
            }
            type="number"
            variant="standard"
            disabled={false}
            id="kPercentage"
            error={
              (
                errors?.fertilizerProperties as SyntheticFertilizerApplicationEvent
              )?.kPercentage
                ? true
                : false
            }
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.kPercentage`
              );
            }}
            label={t("fertilizerEvent.fertilizerProperties.kPercentage.label")}
            maxRows={1}
            name={`fertilizerProperties.kPercentage`}
          />
        </Grid>
      </Grid>
    );
  }

  if (fertilizerType === FERTILIZER_TYPES.LIME) {
    // export type LimeApplicationEvent = {
    //   phPriorToApplication: number;
    // };

    return (
      <Grid
        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
        container
        flexDirection={"row"}
        sx={{
          justifyItems: "baseline",
          justifySelf: "baseline",
          alignSelf: "baseline",
          paddingBottom: 5,
        }}
        display="flex"
        alignItems="baseline"
      >
        <Grid item xs={12} sm={2} display="flex" alignItems="baseline">
          <TextField
            required
            value={
              (fertilizerProperties as LimeApplicationEvent)
                .phPriorToApplication
            }
            type="number"
            variant="standard"
            disabled={false}
            id="phPriorToApplication"
            error={
              (errors?.fertilizerProperties as LimeApplicationEvent)
                ?.phPriorToApplication
                ? true
                : false
            }
            onChange={async (event) => {
              await handleInputChange(
                event,
                `fertilizerProperties.phPriorToApplication`
              );
            }}
            label={t(
              "fertilizerEvent.fertilizerProperties.phPriorToApplication.label"
            )}
            maxRows={1}
            name={`fertilizerProperties.phPriorToApplication`}
          />
        </Grid>
      </Grid>
    );
  }
  return <Grid></Grid>;
}
