import styled from "styled-components";
import { AppBar, Toolbar, AppBarProps } from "@mui/material";

const FlexGrow = styled.div(
  () => `
    flex-grow:1;
`
);

const CoverAppBar: React.FC<AppBarProps> = ({ children, ...props }) => {
  return (
    <AppBar {...props}>
      <Toolbar>
        <FlexGrow />
        {children}
      </Toolbar>
    </AppBar>
  );
};

const StyledCoverAppBar = styled(CoverAppBar)`
  top: auto;
  bottom: 0;
  background: white;
`;

export default StyledCoverAppBar;
