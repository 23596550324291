import { useState, MouseEvent } from "react";
import styled, { css } from "styled-components";
import { ButtonBase, Menu, MenuItem, Typography } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { mixpanel } from "../../common/analytics";
import { useHistory } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  switchFarm,
  selectAllFarms,
  selectCurrentFarm,
  Farm,
} from "model";

const fontVariants = [
  "button",
  "caption",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "inherit",
  "subtitle1",
  "subtitle2",
  "body1",
  "body2",
  "overline",
  undefined,
] as const;
export type FontVariant = typeof fontVariants[number];
export default function FarmSwitch(params: {
  switchToFarmView: boolean;
  fontVariant: FontVariant;
  fontColor: string;
}) {
  const { switchToFarmView, fontVariant, fontColor } = params;
  const [changeFarmAnchor, setChangeFarmAnchor] =
    useState<HTMLElement | null>();

  const history = useHistory();

  const user = useAppSelector((state) => state.user.details);
  const userFarms = useAppSelector(selectAllFarms);
  const currentFarm = useAppSelector(selectCurrentFarm);

  const dispatch = useAppDispatch();

  if (userFarms.length === 1) {
    return (
      <Headline $singlefarm variant={fontVariant} sx={{ color: fontColor }}>
        {currentFarm?.name}
      </Headline>
    );
  }

  const byName = (l: Farm, b: Farm) => l.name.localeCompare(b.name);

  // Show own farms first (if there are any)
  const ownFarms = userFarms.filter((f) => f.owner === user?.guid).sort(byName);
  const consultingFarms = userFarms
    .filter((f) => f.owner !== user?.guid)
    .sort(byName);

  const userFarmsGrouped = [...ownFarms, ...consultingFarms];

  return (
    <>
      <HeadlineButton onClick={openFarmSelector}>
        <Headline variant={fontVariant} sx={{ color: fontColor }}>
          {currentFarm?.name}
        </Headline>
        <DropdownButton sx={{ color: fontColor }} />
      </HeadlineButton>
      <Menu
        id="farm-selector-menu"
        anchorEl={changeFarmAnchor}
        keepMounted
        open={Boolean(changeFarmAnchor)}
        onClose={() => setChangeFarmAnchor(null)}
      >
        {userFarmsGrouped.map((farm) => (
          <MenuItem
            onClick={() => onSelectFarm(farm.guid)}
            key={farm.guid}
            disabled={farm.guid === currentFarm?.guid}
          >
            {farm.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  function onSelectFarm(farmId: string) {
    void dispatch(switchFarm(farmId));
    mixpanel.track("Farm changed");
    setChangeFarmAnchor(null);
    if (switchToFarmView) {
      history.replace(`/farm/${farmId}`);
    }
  }

  function openFarmSelector(event: MouseEvent<HTMLButtonElement>) {
    setChangeFarmAnchor(event.currentTarget);
  }
}

const Headline = styled(Typography)<{ $singlefarm?: boolean }>`
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-align: left;
  ${({ $singlefarm, theme }) =>
    $singlefarm &&
    css`
      margin: ${theme.spacing(2)};
      margin-left: 0;
    `}
`;

const HeadlineButton = styled(ButtonBase)`
  justify-content: flex-start;
`;

const DropdownButton = styled(ArrowDropDown)(
  ({ theme }) => `
  margin: ${theme.spacing(2)};
  margin-left: ${theme.spacing(1)};
`
);
