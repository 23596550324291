import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useTranslation } from "common/locales";

import { ArableField } from "model";
import { YieldDetailsFormData } from "./EnterYieldEvent";

interface CropTypeMissingDialogProps {
  open: boolean;
  setClose: () => void;
  arableFields: ArableField[];
  crops: YieldDetailsFormData[] | undefined;
}

export default function CropTypeMissingDialog(
  props: CropTypeMissingDialogProps
) {
  const { open, setClose, arableFields, crops } = props;
  const { t } = useTranslation();

  let message = "";
  if (!crops) {
    message = "You did not define any crops.";
  } else {
    const missingCropTypes: string[] = [];
    const fieldNamesWithMIssingCropTypes: string[] = [];
    const cropTypes = crops?.map((crop) => crop.detailYieldType);
    for (const arableField of arableFields) {
      if (!cropTypes.includes(arableField.arableType)) {
        missingCropTypes.push(
          t(`fieldinput.arableType.option.${arableField.arableType}`)
        );
        fieldNamesWithMIssingCropTypes.push(arableField.name);
      }
    }
    message = `Selected fields ${fieldNamesWithMIssingCropTypes.join(
      ", "
    )} have crops ${missingCropTypes.join(
      ", "
    )} but they were not added to crop list.`;
  }
  const errorMessage = (
    <>
      <Typography key={1}>{message}</Typography>
      <Typography>Please add them or remove the respective fields.</Typography>
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={setClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        {t("notEnoughFeed.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClose} color="primary" autoFocus>
          {t("notEnoughFeed.cancel.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
