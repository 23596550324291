import { useParams } from "react-router-dom";

import { useAppSelector, selectFieldById, selectArableFieldById } from "model";
import FieldDetails from "./FieldDetails";
import DetailViewLoading from "common/components/DetailViewLoading";
import { ArableFieldDetails } from "features/arableFieldDetail";

export default function FieldView() {
  const { fieldId } = useParams<{ fieldId: string }>();
  const currentField = useAppSelector((state) =>
    selectFieldById(state, fieldId)
  );

  const currentArableField = useAppSelector((state) =>
    selectArableFieldById(state, fieldId)
  );

  return currentField ? (
    <FieldDetails field={currentField} />
  ) : currentArableField ? (
    <ArableFieldDetails field={currentArableField} />
  ) : (
    <DetailViewLoading />
  );
}
