import { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useAppSelector, DiaryEntry } from "model";
import { useTranslation } from "common/locales";
import { getFormattedTime } from "../../common/utils/getFormattedTime";
import DiaryEntryDialogAndMoreMenu from "./DiaryEntryDialogAndMoreMenu";
import { useAppDispatch } from "model";

export default function DiaryEntryListItem({
  diaryEntry,
}: {
  diaryEntry: DiaryEntry;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);
  const icon = <AutoStoriesIcon />;
  const dispatch = useAppDispatch();
  const { entryDate } = diaryEntry;
  const formattedTime = getFormattedTime(entryDate, locale);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }
  function closeMenu() {
    setMenuAnchor(null);
  }

  return (
    <ListItem key={`re-listitem-${diaryEntry.guid}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={t("diaryEntry.show.titleName")}
        secondary={
          <>
            <Typography component="span" display="block">
              {t("diaryEntry.entryDate.label", {
                diaryEntryDate: formattedTime,
              })}
            </Typography>

            {diaryEntry.diaryEntryText && (
              <Typography component="span" display="block" noWrap>
                {t("diaryEntry.diaryEntryText.label", {
                  diaryEntryText: diaryEntry.diaryEntryText,
                })}
              </Typography>
            )}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          onClick={openMenu}
          id={diaryEntry.guid}
          size="large"
        >
          <MoreVert />
        </IconButton>
        <DiaryEntryDialogAndMoreMenu
          diaryEntry={diaryEntry}
          closeMenu={closeMenu}
          anchor={menuAnchor}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
