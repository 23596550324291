import { Menu, MenuItem, PopoverPosition } from "@mui/material";
import {
  updateField,
  useAppDispatch,
  useAppSelector,
  selectAllAnimalGroups,
  selectFieldById,
} from "model";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";

interface MoveFieldMenuProps {
  fieldId: string;
  anchor?: null | HTMLElement;
  anchorPosition?: PopoverPosition;
  close: () => void;
}

export default function MoveFieldMenu({
  fieldId,
  anchor,
  anchorPosition,
  close,
}: MoveFieldMenuProps) {
  const currentField = useAppSelector((state) =>
    selectFieldById(state, fieldId)
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const animalGroups = useAppSelector(selectAllAnimalGroups);
  const { notify } = useNotification();

  const otherAnimalGroups = animalGroups.filter(
    ({ guid }) => guid !== currentField?.animalGroup
  );

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        anchorPosition={anchorPosition}
        anchorReference={
          anchor ? "anchorEl" : anchorPosition ? "anchorPosition" : "none"
        }
        keepMounted
        open={Boolean(anchor || anchorPosition)}
        onClose={close}
      >
        {currentField?.animalGroup && (
          <MenuItem key="silage" onClick={moveToAnimalGroup(undefined)}>
            {t("fieldlistmenu.silage.label")}
          </MenuItem>
        )}
        {otherAnimalGroups.map(({ guid, name }) => (
          <MenuItem key={guid} onClick={moveToAnimalGroup(guid, name)}>
            {t("fieldlistmenu.animalGroup.label", { group: name })}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  function moveToAnimalGroup(guid?: string, animalGroupName?: string) {
    return async () => {
      if (currentField) {
        close();
        try {
          await dispatch(updateField({ ...currentField, animalGroup: guid }));
          mixpanel.track("FieldAssign successful");
          notify(
            guid
              ? t("fieldlistmenu.notification.movedAnimalGroup", {
                  fieldName: currentField?.name,
                  animalGroupName: animalGroupName,
                })
              : t("fieldlistmenu.notification.movedToSilage", {
                  fieldName: currentField?.name,
                })
          );
        } catch (e) {
          mixpanel.track("FieldAssign failed");
          notify(t("fieldlistmenu.notification.moveFailed"), "error");
        }
      }
    };
  }
}
