import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "common/locales";
import {
  createOneReading,
  EventType,
  EventResponse,
  ManualDMInputResponse,
  updateOneReading,
} from "model";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Field,
  invalidateFieldData,
  useAppDispatch,
  deleteAllTilesForField,
} from "model";
import { mixpanel } from "common/analytics";
import { useNotification } from "app/NotificationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface Props {
  field: Field;
  manualInput?: ManualDMInputResponse;
  icon?: React.ReactElement;
}
export default function EventEntryDialog({ field, manualInput, icon }: Props) {
  const [open, setOpen] = useState(false);

  let dateTimeIn = new Date();
  if (manualInput && manualInput.manualDryMatterInput.event.dateTimeIn) {
    dateTimeIn = new Date(
      Date.parse(manualInput.manualDryMatterInput.event.dateTimeIn)
    );
  }
  if (manualInput && manualInput.manualDryMatterInput.event.dateTimeCut) {
    dateTimeIn = new Date(
      Date.parse(manualInput.manualDryMatterInput.event.dateTimeCut)
    );
  }

  let dateTimeOut = new Date();
  if (manualInput && manualInput.manualDryMatterInput.event.dateTimeOut) {
    dateTimeOut = new Date(
      Date.parse(manualInput.manualDryMatterInput.event.dateTimeOut)
    );
  }

  let initialPreCover = field.dryMatterNow;
  if (manualInput && manualInput.manualDryMatterInput.event.preEventCover) {
    initialPreCover = manualInput.manualDryMatterInput.event.preEventCover;
  }
  let initialPostCover = 1500;
  if (manualInput && manualInput.manualDryMatterInput.event.postEventCover) {
    initialPostCover = manualInput.manualDryMatterInput.event.postEventCover;
  }
  const [preEventDate, setPreEventDate] = useState<Date | null>(dateTimeIn);
  const [postEventDate, setPostEventDate] = useState<Date | null>(dateTimeOut);
  const [postCover, setPostCover] = useState<number>(initialPostCover);
  const [preCover, setPreCover] = useState<number>(initialPreCover);
  const [eventType, setEventType] = useState<EventType>(
    manualInput
      ? manualInput.manualDryMatterInput.event.eventType
      : EventType.GrazingEvent
  );

  const [timeError, setTimeError] = useState(false);
  const [coverError, setCoverError] = useState(false);

  const handlePreCoverChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const cover = Number(e.target.value);
    setPreCover(cover);
    if (cover < postCover) {
      setCoverError(true);
    } else {
      setCoverError(false);
    }
  };

  const handlePostCoverChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const cover = Number(e.target.value);
    setPostCover(cover);
    if (preCover < cover) {
      setCoverError(true);
    } else {
      setCoverError(false);
    }
  };

  const handlePreDateChange = (date: Date | null) => {
    setPreEventDate(date);
    if (date && postEventDate && date > postEventDate) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
  };
  const handlePostDateChange = (date: Date | null) => {
    setPostEventDate(date);
    if (preEventDate && date && preEventDate > date) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
  };
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  return (
    <>
      {manualInput ? (
        icon ? (
          <IconButton
            edge="end"
            onClick={() => setOpen(true)}
            aria-label={t("fieldevents.menu.edit")}
            size="large"
          >
            {icon}
          </IconButton>
        ) : (
          <div onClick={() => setOpen(true)}>{t("fieldevents.menu.edit")}</div>
        )
      ) : (
        <Tooltip title={t("addCovers.singleEvent.tooltip")}>
          <IconButton onClick={() => setOpen(true)} edge="end" size="large">
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t("addCovers.singleEvent.title")}</DialogTitle>
        <DialogContent style={{ maxWidth: "300px", paddingBottom: "16px" }}>
          <Typography variant="h6">Type</Typography>
          <Select
            fullWidth
            labelId="reason-select-label"
            id="reason-select"
            value={eventType}
            onChange={(e) => setEventType(e.target.value as EventType)}
          >
            <MenuItem value={EventType.GrazingEvent}>
              {t("addCovers.enterReasons.menu.grazed")}
            </MenuItem>
            <MenuItem value={EventType.PartialGrazingEvent}>
              {t("addCovers.enterReasons.menu.partGrazed")}
            </MenuItem>
            <MenuItem value={EventType.SilageEvent}>
              {t("addCovers.enterReasons.menu.silage")}
            </MenuItem>
          </Select>
          <Typography variant="h6" style={{ margin: "24px 0 0 0" }}>
            Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                label={t("addCovers.eventInput.preCover.Label")}
                fullWidth
                value={preCover}
                onChange={handlePreCoverChange}
                type="number"
              />
              <TextField
                variant="standard"
                label={t("addCovers.eventInput.postCover.Label")}
                fullWidth
                error={coverError}
                helperText={
                  coverError ? t("addCovers.eventInput.postCover.Error") : ""
                }
                value={postCover}
                onChange={handlePostCoverChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <>
                  {eventType === EventType.SilageEvent && (
                    <DateTimePicker
                      inputFormat="MMMM do HH:mm"
                      ampm={false}
                      label={t("addCovers.eventInput.dateCut.Label")}
                      disableFuture
                      value={preEventDate}
                      onChange={setPreEventDate}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} fullWidth />
                      )}
                      DialogProps={{
                        /*https://github.com/mui/material-ui/issues/26664*/
                        PaperProps: {
                          sx: { "& *:focus": { outline: "none" } },
                        },
                      }}
                    />
                  )}
                  {eventType !== EventType.SilageEvent && (
                    <>
                      <DateTimePicker
                        inputFormat="MMMM do HH:mm"
                        disableFuture
                        ampm={false}
                        label={t("addCovers.eventInput.dateIn.Label")}
                        value={preEventDate}
                        onChange={handlePreDateChange}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} fullWidth />
                        )}
                        DialogProps={{
                          /*https://github.com/mui/material-ui/issues/26664*/
                          PaperProps: {
                            sx: { "& *:focus": { outline: "none" } },
                          },
                        }}
                      />
                      <DateTimePicker
                        inputFormat="MMMM do HH:mm"
                        ampm={false}
                        label={t("addCovers.eventInput.dateOut.Label")}
                        value={postEventDate}
                        onChange={handlePostDateChange}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            error={timeError}
                            helperText={
                              timeError
                                ? t("addCovers.eventInput.dateOut.Error")
                                : ""
                            }
                            fullWidth
                          />
                        )}
                        DialogProps={{
                          /*https://github.com/mui/material-ui/issues/26664*/
                          PaperProps: {
                            sx: { "& *:focus": { outline: "none" } },
                          },
                        }}
                      />
                    </>
                  )}
                </>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            {t("addCovers.cancel.label")}
          </Button>
          <Button
            color="primary"
            disabled={timeError || coverError}
            onClick={() => {
              if (preCover < postCover) {
                setCoverError(true);
              } else {
                if (manualInput) {
                  void handleUpdate();
                } else {
                  void handleSave();
                }
              }
            }}
          >
            {t("addCovers.save.label")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
  async function handleSave() {
    // we add 5 minutes to the provided post  event time,
    // since we are only asking for the input of a single event
    // and we don't want users to have to enter a date of measurement
    const timeDiff = 5 * 60000;

    if (preEventDate && postEventDate) {
      let eventData: EventResponse = {
        eventType: eventType,
        preEventCover: preCover,
        postEventCover: postCover,
      };

      if (eventType === EventType.SilageEvent) {
        eventData = {
          ...eventData,
          dateTimeCut: preEventDate.toISOString(),
        };
      } else {
        eventData = {
          ...eventData,
          dateTimeIn: preEventDate.toISOString(),
          dateTimeOut: postEventDate.toISOString(),
        };
      }
      await dispatch(
        createOneReading({
          guid: "new",
          manualDryMatterInput: {
            dryMatterValue: postCover,
            field: field.guid,
            dateTimeMeasurementStart: postEventDate.toISOString(),
            dateTimeMeasurementEnd: new Date(
              postEventDate.getTime() + timeDiff
            ).toISOString(),
            event: eventData,
            kind: manualInput ? manualInput.manualDryMatterInput.kind : "user",
            verificationStatus: "Accepted",
          },
        })
      );
      notify(t("addCovers.success"));
      mixpanel.track("Single EventAdd successful");
      await dispatch(deleteAllTilesForField(field.guid));
      dispatch(invalidateFieldData(field.guid));
      setOpen(false);
    }
  }
  async function handleUpdate() {
    // we add 5 minutes to the provided post  event time,
    // since we are only asking for the input of a single event
    // and we don't want users to have to enter a date of measurement
    const timeDiff = 5 * 60000;

    if (preEventDate && postEventDate) {
      let eventData: EventResponse = {
        eventType: eventType,
        preEventCover: preCover,
        postEventCover: postCover,
      };

      if (eventType === EventType.SilageEvent) {
        eventData = {
          ...eventData,
          dateTimeCut: preEventDate.toISOString(),
        };
      } else {
        eventData = {
          ...eventData,
          dateTimeIn: preEventDate.toISOString(),
          dateTimeOut: postEventDate.toISOString(),
        };
      }
      await dispatch(
        updateOneReading({
          guid: manualInput!.guid, //we only call handleUpdate if manualInput exists
          manualDryMatterInput: {
            dryMatterValue: postCover,
            field: field.guid,
            dateTimeMeasurementStart: postEventDate.toISOString(),
            dateTimeMeasurementEnd: new Date(
              postEventDate.getTime() + timeDiff
            ).toISOString(),
            event: eventData,
            kind: manualInput ? manualInput.manualDryMatterInput.kind : "user",
            verificationStatus: "Accepted",
          },
        })
      );
      notify(t("addCovers.success"));
      mixpanel.track("EventUpdate successful");
      await dispatch(deleteAllTilesForField(field.guid));
      dispatch(invalidateFieldData(field.guid));
      setOpen(false);
    }
  }
}
