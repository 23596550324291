import { FertilizerEvent, YieldEvent } from "model";
import { useState } from "react";
import FertilizerEventMoreMenu from "./FertilizerEventMoreMenu";
import UpdateFertilizerEvent from "./UpdateFertilizerEvent";
export default function UpdateFertilizerEventDialog({
  fertilizerEvent,
  menuAnchor,
  closeMenu,
}: {
  fertilizerEvent: FertilizerEvent;
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!fertilizerEvent) {
    return null;
  }
  return (
    <>
      <FertilizerEventMoreMenu
        fertilizerEvent={fertilizerEvent}
        close={closeMenu}
        anchor={menuAnchor}
        setDialogOpen={setDialogOpen}
      />
      <UpdateFertilizerEvent
        fertilizerEvent={fertilizerEvent}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
}
