import { getGeom } from "@turf/invariant";
import { lineString, Feature, Geometry, Position } from "@turf/helpers";
import lineIntersect from "@turf/line-intersect";
import isPointOnLine from "@turf/boolean-point-on-line";

type Coords = Array<Position>;

/**
 *
 * NOTE: adapted from https://github.com/Turfjs/turf/tree/master/packages/turf-boolean-valid
 *
 * booleanValid checks if the geometry is a valid according to the OGC Simple Feature Specification.
 *
 * @name booleanValid
 * @param {Geometry|Feature<any>} feature GeoJSON Feature or Geometry
 * @returns {boolean} true/false
 * @example
 * var line = turf.lineString([[1, 1], [1, 2], [1, 3], [1, 4]]);
 *
 * turf.booleanValid(line); // => true
 * turf.booleanValid({foo: "bar"}); // => false
 */
export default function booleanValid(feature: Feature<any> | Geometry) {
  // Automatic False
  if (!feature || !feature.type) return false;

  // Parse GeoJSON
  const geom = getGeom(feature);
  const type = geom.type;
  let coords = geom.coordinates;
  if (coords.length === 0) return false;
  switch (type) {
    case "LineString":
      coords = geom.coordinates;
      if (coords.length < 2) return false;
      for (let i = 0; i < coords.length; i++) {
        if (i > 1) {
          if (
            lineIntersect(lineString(coords), lineString(coords.slice(0, i)))
              .features.length >
            i - 1
          )
            return false;
        }
      }
      return true;
    case "Polygon":
      coords = geom.coordinates[0];
      if (coords.length < 4) return false;
      for (let i = 0; i < coords.length; i++) {
        if (coords.length < 4) return false;
        if (!checkRingsClose(coords)) return false;
        if (checkRingsForSpikesPunctures(coords)) return false;
        if (i > 1) {
          if (
            lineIntersect(lineString(coords), lineString(coords.slice(0, i)))
              .features.length > i
          )
            return false;
        }
      }
      return true;
    default:
      return false;
  }
}

function checkRingsClose(geom: Coords) {
  return (
    geom[0][0] === geom[geom.length - 1][0] ||
    geom[0][1] === geom[geom.length - 1][1]
  );
}

function checkRingsForSpikesPunctures(geom: Coords) {
  for (let i = 0; i < geom.length - 1; i++) {
    const point = geom[i];
    for (let ii = i + 1; ii < geom.length - 2; ii++) {
      const seg = [geom[ii], geom[ii + 1]];
      if (isPointOnLine(point, lineString(seg))) return true;
    }
  }
  return false;
}
