import { RENDER_STATE } from "react-map-gl-draw";

enum SHAPE {
  POINT = "Point",
  LINE_STRING = "LineString",
  POLYGON = "Polygon",
  RECTANGLE = "Rectangle",
  CIRCLE = "Circle",
}

const STROKE_COLOR = "rgb(38, 181, 242)";
const STROKE_COLOR_SELECTED = "rgb(255, 0, 0)";
const FILL_COLOR = "none";

const RECT_STYLE = {
  stroke: STROKE_COLOR,
  strokeWidth: 2,
  x: -6,
  y: -6,
  height: 12,
  width: 12,
};

const CIRCLE_RADIUS = 16;

const SELECTED_STYLE = {
  stroke: STROKE_COLOR_SELECTED,
  strokeWidth: 2,
  fill: FILL_COLOR,
};

const HOVERED_STYLE = {
  stroke: STROKE_COLOR,
  strokeWidth: 2,
  fill: FILL_COLOR,
};

const UNCOMMITTED_STYLE = {
  stroke: STROKE_COLOR,
  strokeDasharray: "4,2",
  strokeWidth: 2,
  fill: FILL_COLOR,
};

const INACTIVE_STYLE = UNCOMMITTED_STYLE;

const DEFAULT_STYLE = {
  stroke: "#000",
  strokeWidth: 2,
  fill: FILL_COLOR,
};

export function getFeatureStyle({ feature, state }: any) {
  const type = feature.properties.shape || feature.geometry.type;
  let style = null;

  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE };
      break;

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE };
      break;

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE };
      break;

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE };
      break;

    default:
      style = { ...DEFAULT_STYLE };
  }

  switch (type) {
    case SHAPE.POINT:
      //@ts-ignore
      style.r = CIRCLE_RADIUS;
      break;
    case SHAPE.LINE_STRING:
      style.fill = "none";
      break;
    case SHAPE.POLYGON:
      //@ts-ignore
      style.strokeWidth = 4;
      if (state === RENDER_STATE.CLOSING) {
        //@ts-ignore
        style.strokeDasharray = "4,2";
      }

      break;
    case SHAPE.RECTANGLE:
      if (state === RENDER_STATE.UNCOMMITTED) {
        //@ts-ignore
        style.strokeDasharray = "4,2";
      }

      break;
    default:
  }

  return style;
}

export function getEditHandleStyle({ feature, shape, index, state }: any) {
  let style = {};
  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE };
      break;

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE };
      break;

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE };
      break;

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE };
      break;

    default:
      style = { ...DEFAULT_STYLE };
  }

  switch (shape) {
    case "circle":
      //@ts-ignore
      style.r = CIRCLE_RADIUS;
      break;
    case "rect":
      style = { ...style, ...RECT_STYLE };
      break;
    default:
  }

  return style;
}
