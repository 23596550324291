import { FarmerImageUpload } from "model";

export const DiaryEntryImage = ({
  farmerImageUpload,
  style,
  onImageClick,
  onMouseOver,
  onMouseOut,
  border,
}: {
  farmerImageUpload: FarmerImageUpload | undefined;
  style: any;
  onImageClick: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  border?: string;
}) => {
  const imageType: string | undefined = farmerImageUpload?.imageName
    .split(".")
    .pop();
  return (
    <img
      alt=""
      src={`data:image/${imageType};base64,${farmerImageUpload?.image}`}
      style={{ ...style, outline: border }}
      onClick={() => {
        onImageClick();
      }}
      onMouseOver={() => {
        onMouseOver && onMouseOver();
      }}
      onMouseOut={() => {
        onMouseOut && onMouseOut();
      }}
    />
  );
};
