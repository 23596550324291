import { Polygon, MultiPolygon, Feature, FeatureCollection } from "@turf/turf";
import { AnimalGroup, Field, FieldResponse } from "model";
import {
  indigo,
  pink,
  purple,
  teal,
  orange,
  yellow,
} from "@mui/material/colors";

import * as Locales from "date-fns/locale";
import { Locale } from "date-fns";

// TODO: decide if we want to move this into the corresponding models or folders

export const multiPolygonToPolygon = (f: Feature) => {
  const geom = f.geometry as MultiPolygon;
  return {
    type: f.type,
    properties: {
      id: f.properties?.guid,
      area: f.properties?.area,
      dryMatterNow: f.properties?.dryMatterNow
        ? f.properties?.dryMatterNow * 10000
        : -1,
      renderType: "Polygon",
    },
    geometry: {
      type: "Polygon",
      coordinates: geom.coordinates[0],
    },
  };
};

export const polygonToMultiPolygon = (f: Feature) => {
  const geom = f.geometry as Polygon;
  return {
    type: f.type,
    properties: {
      guid: f.properties?.id,
    },
    geometry: {
      type: "MultiPolygon",
      coordinates: [geom.coordinates],
    },
  };
};

export const prepareFieldRequest = (field: Field) => {
  return {
    ...field,
    dryMatterNow: field.dryMatterNow && field.dryMatterNow / 10000,
    geom: {
      type: "FeatureCollection",
      features: field.geom && [polygonToMultiPolygon(field.geom)],
    } as FeatureCollection,
  };
};

export const processFieldResponse = (fields: Array<FieldResponse>) => {
  return fields.map((field: FieldResponse) => {
    const dryMatterNow = Math.floor(field.dryMatterNow * 10000);
    const area = field.geom.features[0].properties?.area;
    const geom = multiPolygonToPolygon(field.geom.features[0]) as Feature;
    const f: Field = {
      ...field,
      visibility: "visible",
      dryMatterNow: dryMatterNow,
      area: area,
      geom,
    };
    return f;
  });
};

export function processAnimalGroupResponse(
  serverAnimalGroups: AnimalGroup[]
): AnimalGroup[] {
  return serverAnimalGroups.map((group) => ({
    ...group,
    postGrazingTarget:
      group.postGrazingTarget && Math.floor(group.postGrazingTarget * 10000),
  }));
}

export function prepareAnimalGroupRequest(
  clientAnimalGroup: AnimalGroup
): AnimalGroup {
  if (!clientAnimalGroup.postGrazingTarget) return clientAnimalGroup;
  return {
    ...clientAnimalGroup,
    postGrazingTarget: clientAnimalGroup.postGrazingTarget / 10000,
  };
}

export function coverInKgPerHaToDryMatter(cover: number, areaInM2: number) {
  const areaInHa = areaInM2 / 10000;
  return Math.max(0, cover) * areaInHa;
}

const colors = [
  yellow[500],
  teal[400],
  orange[400],
  pink[400],
  indigo[400],
  teal[800],
  orange[800],
  pink[800],
  purple[800],
];

export function getColorForIndex(index: number) {
  if (index < 0) return "#666666";
  return colors[index % colors.length];
}

export function getDateFnsLocale(locale: string): Locale {
  // @ts-ignore
  return Locales[locale] ?? Locales[locale] ?? Locales.enUS;
}

export function round(i: number): number {
  // fixing possible rounding errors
  return Math.round(i * 100) / 100;
}
