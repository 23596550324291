import {
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  Theme,
  Typography,
  DialogTitle,
  DialogContent,
  Link as MuiLink,
  DialogActions,
  Card,
  CardContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DeleteForever, Undo, Delete } from "@mui/icons-material";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { Create } from "@mui/icons-material";
import { DrawControlButton } from "./MapEditDrawTools";
import handleImg from "./handle.png";
import handleSelectedImg from "./handle-selected.png";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
  },
}));

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export function DrawingHelpModal({ isOpen, handleClose }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const getButton = (icon: JSX.Element) => (
    <DrawControlButtonExtended
      variant="contained"
      size="small"
      disableElevation
      disableRipple
      color="drawTool"
    >
      {icon}
    </DrawControlButtonExtended>
  );

  const pencilButton = () => getButton(<Create />);
  const undoButton = () => getButton(<Undo />);
  const deleteButton = () => getButton(<Delete />);
  const deleteForeverButton = () => getButton(<DeleteForever />);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullScreen={isMobile}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="dialog-title">
        <span role="img" aria-label="Pencil">
          ✏️
        </span>{" "}
        {t("map.drawfield.help.modal.title")}
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          {t("map.drawfield.help.modal.welcome")}{" "}
          <span role="img" aria-label="point-right">
            👉
          </span>{" "}
          <MuiLink href="mailto:support@ruumi.io" underline="hover">
            {t("map.drawfield.help.modal.link")}
          </MuiLink>
        </Typography>

        <Typography variant="h5" paragraph>
          {t("map.drawfield.help.modal.createMode.title")}
        </Typography>

        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.createMode.description"
                values={{
                  pencilButton,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography component="div">
              <FlexTypography>
                <FormattedMessage
                  id="map.drawfield.help.modal.createMode.zoomControls.description"
                  values={{
                    navIcon: () => ZoomControls,
                  }}
                />
              </FlexTypography>
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.createMode.undo.description"
                values={{
                  undoButton,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage id="map.drawfield.help.modal.createMode.boundaryCorrectness.description" />
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.createMode.finish.description"
                values={{
                  pencilButton,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>

        <Typography variant="h5" paragraph>
          {t("map.drawfield.help.modal.editMode.title")}
        </Typography>

        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.editMode.description"
                values={{
                  pencilButton,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.editMode.move.description"
                values={{
                  movePointImg: () => <Img src={handleImg} />,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.editMode.select.description"
                values={{
                  selectPointImg: () => <Img src={handleSelectedImg} />,
                  deleteButton,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography component="div">
              <FlexTypography>
                <FormattedMessage
                  id="map.drawfield.help.modal.createMode.zoomControls.description"
                  values={{
                    navIcon: () => ZoomControls,
                  }}
                />
              </FlexTypography>
            </Typography>
          </ModalCardContent>
        </ModalCard>
        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.editMode.finish.description"
                values={{
                  pencilButton,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>

        <Typography variant="h5">
          {t("map.drawfield.help.modal.deleteBoundary.title")}
        </Typography>
        <ModalCard square>
          <ModalCardContent>
            <Typography paragraph>
              <FormattedMessage
                id="map.drawfield.help.modal.deleteBoundary.description"
                values={{
                  deleteButton: deleteForeverButton,
                }}
              />
            </Typography>
          </ModalCardContent>
        </ModalCard>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          {t("map.drawfield.help.modal.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DrawingHelpModal;

const ZoomControlContainer = styled.span(
  ({ theme }) => `
  padding: 0 ${theme.spacing(2)};
`
);

const FlexTypography = styled.div`
  display: flex;
  align-items: center;
`;

const ZoomControls = (
  <ZoomControlContainer>
    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
      <button
        className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in"
        type="button"
        title="Zoom In"
      >
        <span className="mapboxgl-ctrl-icon" aria-hidden="true"></span>
      </button>
      <button
        className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out"
        type="button"
        title="Zoom Out"
      >
        <span className="mapboxgl-ctrl-icon" aria-hidden="true"></span>
      </button>
    </div>
  </ZoomControlContainer>
);

const Img = styled.img(
  ({ theme }) => `
  max-height: 3rem;
  width: auto;
  vertical-align: middle;
  border-radius: ${theme.spacing(0.5)};
  margin: ${theme.spacing(0.5)} 0;
`
);

const DrawControlButtonExtended = styled(DrawControlButton)`
  cursor: auto;
`;

const ModalCard = styled(Card)(
  ({ theme }) => `
margin-bottom: ${theme.spacing(2)};
`
);

const ModalCardContent = styled(CardContent)(
  ({ theme }) => `
padding: ${theme.spacing(2)};
`
);
