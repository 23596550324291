// Split to prevent circular dependency
export function getLogRocketReduxMiddlewareConfig(
  fetchTokenFulfilledType: string,
  fetchUserByIdFulfilledType: string
) {
  return {
    stateSanitizer: function (state: any) {
      // removing PII from state
      return {
        ...(state || {}),
        user: {
          ...(state?.user || {}),
          details: {
            ...(state?.user?.details || {}),
            name: undefined,
            mail: undefined,
            password: undefined,
            phone: undefined,
            address: undefined,
          },
        },
      };
    },
    actionSanitizer: function (action: any) {
      if (action.type === fetchUserByIdFulfilledType) {
        return {
          ...action,
          payload: {
            ...action.payload,
            name: undefined,
            mail: undefined,
            password: undefined,
            phone: undefined,
            address: undefined,
          },
        };
      }

      if (action.type === fetchTokenFulfilledType) {
        return {
          ...action,
          payload: {
            ...action.payload,
            token: undefined,
          },
        };
      }

      return action;
    },
  };
}
