import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { useTranslation } from "common/locales";
import { mixpanel } from "../common/analytics";
import {
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Typography,
  LinearProgress,
  Box,
  DialogContent,
} from "@mui/material";

import { useAppDispatch, useAppSelector, sendPasswordResetEmail } from "model";
import { FullScreenDialog } from "common/components";
import { unwrapResult } from "@reduxjs/toolkit";
import { restoreToken } from "common/utils/token";

interface LocationType {
  mail: string;
}

enum EMAIL_STATUS {
  DEFAULT,
  SENT_SUCCESS,
  SENT_FAILURE,
}

export default function PasswordForgotDialog() {
  const [mail, setMail] = useState("");
  const [emailSent, setEmailSent] = useState<EMAIL_STATUS>(
    EMAIL_STATUS.DEFAULT
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const loginInProgress = useAppSelector((state) => state.app.loginInProgress);

  useEffect(() => {
    if (location.state) {
      setMail((location.state as LocationType).mail);
    } else {
      const params = new URLSearchParams(location.search);
      if (params.has("mail")) {
        setMail(params.get("mail") as string);
      }
    }
  }, [location]);

  const { t } = useTranslation();

  restoreToken(location);
  const handleResetEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    mixpanel.track("Password reset email sending attempted");

    try {
      const result = await dispatch(sendPasswordResetEmail({ mail })).then(
        unwrapResult
      ); // we don't check if the email was actually sent or user is still in the cooldown period (30s), always show success to the user
      setEmailSent(EMAIL_STATUS.SENT_SUCCESS);
      if (result?.emailSent) {
        mixpanel.track("Password reset email sending success");
      } else {
        mixpanel.track("Password reset email sending failed, cooldown");
      }
    } catch (err) {
      console.log(err);
      setEmailSent(EMAIL_STATUS.SENT_FAILURE);
      mixpanel.track("Password reset email sending failed, unknown");
    }
  };

  return (
    <FullScreenDialog>
      {loginInProgress && <LinearProgress />}
      <DialogTitle id="dialog-title">
        {t("passwordForgot.dialog.title")}
      </DialogTitle>
      <DialogContent>
        {emailSent === EMAIL_STATUS.DEFAULT && (
          <Form onSubmit={handleResetEmail}>
            <TextField
              variant="standard"
              required
              id="input-mail"
              disabled={loginInProgress}
              type="email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              label={t("passwordForgot.mail.label")}
              autoFocus={true}
              data-private
            />

            <StyledDialogActions>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={loginInProgress || mail.trim().length === 0}
              >
                {t("passwordForgot.button.label")}
              </Button>
            </StyledDialogActions>
          </Form>
        )}

        {emailSent === EMAIL_STATUS.SENT_SUCCESS && (
          <Box display="block" p={1}>
            <Typography>{t("passwordForgot.success")}</Typography>
            <StyledTypography>{t("passwordForgot.success.2")}</StyledTypography>
          </Box>
        )}
        {emailSent === EMAIL_STATUS.SENT_FAILURE && (
          <Box display="block" p={1}>
            <StyledTypography variant="subtitle1">
              {t("passwordForgot.failure")}
            </StyledTypography>
          </Box>
        )}
      </DialogContent>
    </FullScreenDialog>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: fit-content;
`;

const StyledDialogActions = styled(DialogActions)(
  ({ theme }) => `
  padding-top: ${theme.spacing(2)};
`
);

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`
);
