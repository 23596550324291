import { Grid, TextField } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ManualDMInputItem, EventType } from "model";
import { useTranslation } from "common/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface EventInputProps {
  reading: ManualDMInputItem;
  manualDMInputs: Array<ManualDMInputItem>;
  setManualDMInputs: Dispatch<SetStateAction<Array<ManualDMInputItem>>>;
  dateError: boolean;
  setDateError: React.Dispatch<React.SetStateAction<boolean>>;
  coverError: boolean;
  setCoverError: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EventInput({
  reading,
  manualDMInputs,
  setManualDMInputs,
  dateError,
  setDateError,
  coverError,
  setCoverError,
}: EventInputProps) {
  const { t } = useTranslation();

  const handleDateInChange = (date: Date | null) => {
    setManualDMInputs(
      manualDMInputs.map((i) => {
        if (i.field === reading.field) {
          i.event.dateTimeIn = date ? date : undefined;
        }
        return i;
      })
    );
  };
  const handleDateOutChange = (date: Date | null) => {
    setManualDMInputs(
      manualDMInputs.map((i) => {
        if (i.field === reading.field) {
          if (date && i.event.dateTimeIn && date > i.event.dateTimeIn) {
            i.event.dateTimeOut = date;
            setDateError(false);
          } else {
            setDateError(true);
          }
        }
        return i;
      })
    );
  };
  const handleDateCutChange = (date: Date | null) => {
    setManualDMInputs(
      manualDMInputs.map((i) => {
        if (i.field === reading.field) {
          i.event.dateTimeCut = date ? date : undefined;
        }
        return i;
      })
    );
  };
  const handlePostCoverChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setManualDMInputs(
      manualDMInputs.map((i) => {
        if (i.field === reading.field) {
          if (
            i.event.preEventCover &&
            i.event.preEventCover > Number(e.target.value)
          ) {
            setCoverError(false);
          } else {
            setCoverError(true);
          }
          i.event.postEventCover = Number(e.target.value);
        }
        return i;
      })
    );
  };
  const handlePreCoverChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setManualDMInputs(
      manualDMInputs.map((i) => {
        if (i.field === reading.field) {
          if (i.event.postEventCover && Number(e.target.value)) {
            if (i.event.postEventCover > Number(e.target.value)) {
              setCoverError(true);
            } else {
              setCoverError(false);
            }
          }
          i.event.preEventCover = Number(e.target.value);
        }
        return i;
      })
    );
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label={t("addCovers.eventInput.preCover.Label")}
            fullWidth
            value={reading.event.preEventCover ?? ""}
            onChange={handlePreCoverChange}
            type="number"
          />
          <TextField
            variant="standard"
            label={t("addCovers.eventInput.postCover.Label")}
            fullWidth
            error={coverError}
            helperText={
              coverError ? t("addCovers.eventInput.postCover.Error") : ""
            }
            value={reading.event.postEventCover ?? ""}
            onChange={handlePostCoverChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <>
              {reading.event.eventType !== EventType.SilageEvent && (
                <>
                  <DateTimePicker
                    inputFormat="MMMM do HH:mm"
                    disableFuture
                    ampm={false}
                    label={t("addCovers.eventInput.dateIn.Label")}
                    value={reading.event.dateTimeIn}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="standard" />
                    )}
                    onChange={handleDateInChange}
                    DialogProps={{
                      /*https://github.com/mui/material-ui/issues/26664*/
                      PaperProps: {
                        sx: { "& *:focus": { outline: "none" } },
                      },
                    }}
                  />
                  <DateTimePicker
                    inputFormat="MMMM do HH:mm"
                    ampm={false}
                    label={t("addCovers.eventInput.dateOut.Label")}
                    value={reading.event.dateTimeOut}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        error={dateError}
                        helperText={
                          dateError
                            ? t("addCovers.eventInput.dateOut.Error")
                            : ""
                        }
                      />
                    )}
                    onChange={handleDateOutChange}
                    DialogProps={{
                      /*https://github.com/mui/material-ui/issues/26664*/
                      PaperProps: {
                        sx: { "& *:focus": { outline: "none" } },
                      },
                    }}
                  />
                </>
              )}
            </>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
}
