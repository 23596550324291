import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "common/locales";
import { deleteDiaryEntry, DiaryEntry } from "model";
import { useAppDispatch } from "model";
import { unwrapResult } from "@reduxjs/toolkit";
import { mixpanel } from "common/analytics";
import { useNotification } from "app/NotificationProvider";

interface Props {
  diaryEntry: DiaryEntry;
  setOpenDiaryEntryDialogGuid: (value: string | null) => void;
  canEdit: boolean;
}

export default function DiaryEntryConfirmDialog({
  diaryEntry,
  setOpenDiaryEntryDialogGuid,
  canEdit,
}: Props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  return (
    <>
      <Tooltip title={t("diaryEntry.delete.tooltip")}>
        {canEdit ? (
          <Button color="secondary" onClick={() => setOpen(true)}>
            {t("diaryEntry.delete.label")}
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={() => setOpenDiaryEntryDialogGuid(null)}
          >
            {t("diaryEntry.view.close.label")}
          </Button>
        )}
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle maxWidth="300px">
          {t("diaryEntry.areYouSure.label")}
        </DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={() => handleDelete()}>
            {t("diaryEntry.delete.confirm.label")}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              mixpanel.track("diaryEntry clicked Cancel");
              setOpen(false);
            }}
          >
            {t("diaryEntry.cancel.label")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  async function handleDelete() {
    mixpanel.track("diaryEntry clicked Delete");
    await dispatch(deleteDiaryEntry(diaryEntry)).then(unwrapResult);
    notify(t("diaryEntry.deleted.notification"));
    mixpanel.track("diaryEntry deleted");
    setOpen(false);
    setOpenDiaryEntryDialogGuid(null);
  }
}
