import { useTranslation } from "common/locales";
import {
  DialogActions,
  Button,
  TextField,
  Typography,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FormErrors, FormValues, StyledForm } from "./NewSignupDialog";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
interface Props {
  values: FormValues;
  errors: FormErrors;
  handleChange: any;
  handleSubmit: any;
  signupError: boolean;
}

const SIGNIN_URL = "/login";

export default function UserDetails({
  values,
  handleChange,
  handleSubmit,
  errors,
  signupError,
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const confirmPasswordError =
    !!values.password && !!values.confirmPassword && !!errors.confirmPassword; // only if password and confirm password fields have some text

  const REDIRECT_URL = params.get("from")
    ? `${SIGNIN_URL}?from=${params.get("from")}`
    : SIGNIN_URL;
  const history = useHistory();
  const onSignInClick = (event: any) => {
    event.preventDefault();
    history.push(REDIRECT_URL);
  };

  return (
    <>
      <StyledDialogTitle id="dialog-title">
        {t("signup.userDetails.dialogTitle")}
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledTypography paragraph>
          {t("newSignup.userDetails.text1")}
        </StyledTypography>
        <StyledTypography2 paragraph>
          {t("newSignup.userDetails.text2")}
          <span role="img" aria-label="innocent">
            😇
          </span>
        </StyledTypography2>
        <StyledTypography paragraph>
          <FormattedMessage
            id="newSignup.userDetails.alreadyRegistered"
            values={{
              a: (chunks: any) => (
                <a href="/" onClick={onSignInClick}>
                  {chunks}
                </a>
              ),
            }}
          />
        </StyledTypography>
        <StyledForm id="form-part-1" onSubmit={handleSubmit}>
          <TextField
            variant="standard"
            required
            id="mail"
            type="email"
            value={values.mail}
            onChange={handleChange}
            label={t("signup.mail.label")}
            error={signupError}
            helperText={signupError && t("signup.signupError")}
          />
          <TextField
            variant="standard"
            required
            id="password"
            value={values.password}
            onChange={handleChange}
            label={t("signup.password.label")}
            type="password"
            autoFocus={true}
          />
          <TextField
            variant="standard"
            required
            id="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            label={t("signup.confirmPassword.label")}
            type="password"
            error={confirmPasswordError}
            helperText={confirmPasswordError && errors.confirmPassword}
          />
          <StyledDialogActions>
            <Button
              color="primary"
              type="submit"
              form="form-part-1"
              variant="contained"
            >
              {t("signup.button.newSignup")}
            </Button>
          </StyledDialogActions>
        </StyledForm>
      </StyledDialogContent>
    </>
  );
}

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
  padding-left: ${theme.spacing(4)};
  padding-top: ${theme.spacing(2.5)};
`
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => `
  padding: 12px 32px!important;
`
);

const StyledDialogActions = styled(DialogActions)(
  ({ theme }) => `
  padding-top: ${theme.spacing(2)};
`
);

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`
);

const StyledTypography2 = styled(StyledTypography)(
  ({ theme }) => `
  margin-bottom: 24px;
`
);
