import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { RootState } from "model";
import { findIndex } from "lodash";
import { API } from "common/utils";
import {
  getColorForIndex,
  prepareAnimalGroupRequest,
  processAnimalGroupResponse,
} from "common/utils/helpers";
import { fetchApi } from "common/utils/Api";
import {
  selectAllStockClassFeedAllocations,
  StockClassFeedAllocation,
} from "./stockClassFeedAllocationSlice";

export type AnimalGroup = {
  guid: string;
  name: string;
  farm: string;
  postGrazingTarget?: number;
  rotationLength?: number;
  stockClasses: Array<string>;
  color?: string;
  fieldNow?: string;
  feedBudgetPlannerEnabled?: boolean;
};

const animalGroupApi = new API<AnimalGroup>("animalGroups");

export const animalGroupAdapter = createEntityAdapter<AnimalGroup>({
  selectId: (animalGroup) => animalGroup.guid,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const {
  selectAll: selectAllAnimalGroups,
  selectById: selectAnimalGroupById,
  selectEntities: selectAnimalGroupEntities,
  selectIds: selectAnimalGroupIds,
} = animalGroupAdapter.getSelectors((state: RootState) => state.animalGroups);

export const getAnimalGroupIndex = (animalGroupId?: string) =>
  createSelector(selectAllAnimalGroups, (animalGroups) => {
    return findIndex(animalGroups, { guid: animalGroupId });
  });

export const getAnimalGroupColor = (animalGroupId?: string) =>
  createSelector(getAnimalGroupIndex(animalGroupId), (index) => {
    return getColorForIndex(index);
  });

export const getAnimalGroupColorMapping = createSelector(
  selectAllAnimalGroups,
  (animalGroups) => {
    const colorByGuid: Record<string, string> = {};

    animalGroups.forEach((ag, index) => {
      colorByGuid[ag.guid] = ag.color ?? getColorForIndex(index);
    });

    return colorByGuid;
  }
);

export const fetchAnimalGroupsById = createAsyncThunk(
  "animalGroups/fetchById",
  (animalGroupIds: string[]) => animalGroupApi.getMany(animalGroupIds)
);

export const createAnimalGroup = createAsyncThunk(
  "animalGroups/create",
  async (animalGroup: AnimalGroup, { dispatch }) => {
    const postResult = await animalGroupApi.createOne(
      prepareAnimalGroupRequest(animalGroup)
    );

    const newAnimalGroup = await animalGroupApi.getOne(postResult.guid);
    await dispatch(fetchstockClassesById(newAnimalGroup.stockClasses));

    return newAnimalGroup;
  }
);

export const updateAnimalGroup = createAsyncThunk(
  "animalGroups/update",
  async (animalGroup: AnimalGroup, { dispatch }) => {
    await animalGroupApi.updateOne(prepareAnimalGroupRequest(animalGroup));
    const updatedAnimalGroup = await animalGroupApi.getOne(animalGroup.guid);
    await dispatch(fetchstockClassesById(updatedAnimalGroup.stockClasses));
    return updatedAnimalGroup;
  }
);

const removeAnimalGroupFromFields = createAction<string>(
  "fields/animalGroupDeleted"
);

export const deleteAnimalGroup = createAsyncThunk(
  "animalGroups/delete",
  async (guid: string, { dispatch }) => {
    await animalGroupApi.deleteOne(guid);
    dispatch(removeAnimalGroupFromFields(guid));
    return guid;
  }
);

const initialAnimalGroupState: AnimalGroupState =
  animalGroupAdapter.getInitialState({
    loading: false,
    error: null,
    status: null,
  });

const animalGroupSlice = createSlice({
  name: "animalGroup",
  initialState: initialAnimalGroupState,
  reducers: {
    resetAnimalGroups: () => initialAnimalGroupState,
  },
  extraReducers: {
    [fetchAnimalGroupsById.fulfilled.type]: success((state, { payload }) => {
      animalGroupAdapter.upsertMany(
        state,
        processAnimalGroupResponse(payload as AnimalGroup[])
      );
    }),
    [createAnimalGroup.fulfilled.type]: success((state, { payload }) =>
      animalGroupAdapter.upsertOne(
        state,
        processAnimalGroupResponse([payload])[0]
      )
    ),
    [updateAnimalGroup.fulfilled.type]: success((state, { payload }) =>
      animalGroupAdapter.upsertOne(
        state,
        processAnimalGroupResponse([payload])[0]
      )
    ),
    [deleteAnimalGroup.fulfilled.type]: success((state, { payload }) =>
      animalGroupAdapter.removeOne(state, payload)
    ),
    [fetchAnimalGroupsById.pending.type]: loading,
    [createAnimalGroup.pending.type]: loading,
    [updateAnimalGroup.pending.type]: loading,
    [deleteAnimalGroup.pending.type]: loading,
    [fetchAnimalGroupsById.rejected.type]: error,
    [createAnimalGroup.rejected.type]: error,
    [updateAnimalGroup.rejected.type]: error,
    [deleteAnimalGroup.rejected.type]: error,
  },
});

function loading(state: AnimalGroupState) {
  state.loading = true;
}

function error(state: AnimalGroupState, { error }: { error: string }) {
  state.loading = false;
  state.error = error;
  state.status = "error";
}

function success(
  fn: (state: AnimalGroupState, action: { payload: any }) => void
) {
  return (state: AnimalGroupState, action: { payload: any }) => {
    state.loading = false;
    state.status = "success";
    fn(state, action);
  };
}

type AnimalGroupState = EntityState<AnimalGroup> & {
  loading: boolean;
  error: string | null;
  status: "success" | "error" | null;
};

export interface StockClass {
  guid: string;
  name: string;
  animalCount: number;
  consumptionKgPerDay: number;
  averageAnimalWeightKg: number;
  animalGroup: string;
  defaultStockClassName: string | undefined;
  birthingPeriods: Array<string>;
  feedAllocations?: Array<string>;
  targetEntryCoverKgM2?: number;
}

const stockClassApi = new API<StockClass>("stockClasses");

export const stockClassAdapter = createEntityAdapter<StockClass>({
  selectId: (stockClass) => stockClass.guid,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const { selectAll: selectAllStockClasses } =
  stockClassAdapter.getSelectors((state: RootState) => state.stockClasses);

type StockClassesByAnimalGroupId = {
  [id: string]: Array<StockClass>;
};

export const selectStockClassByAnimalGroupId = createSelector(
  selectAllStockClasses,
  (stockClasses) =>
    stockClasses.reduce((acc: StockClassesByAnimalGroupId, stockClass) => {
      if (!acc[stockClass.animalGroup]) {
        acc[stockClass.animalGroup] = [];
      }
      acc[stockClass.animalGroup].push(stockClass);

      return acc;
    }, {})
);

const selectItemId = (state: RootState, itemId: string) => itemId;

export const selectStockClassesForAnimalGroup = createSelector(
  [selectStockClassByAnimalGroupId, selectItemId],
  (stockClasses, animalGroupId) => stockClasses[animalGroupId]
);

export const getDemand = createSelector(
  [selectStockClassByAnimalGroupId, selectItemId],
  (stockClasses, animalGroupId) => {
    const stockClassesForAnimalGroup = stockClasses[animalGroupId];
    if (stockClassesForAnimalGroup) {
      return stockClassesForAnimalGroup.reduce(
        (prev, curr) => prev + curr.consumptionKgPerDay * curr.animalCount,
        0
      );
    }
  }
);

export const getGroupWeight = createSelector(
  [selectStockClassByAnimalGroupId, selectItemId],
  (stockClasses, animalGroupId) => {
    const stockClassesForAnimalGroup = stockClasses[animalGroupId];
    if (stockClassesForAnimalGroup) {
      return stockClassesForAnimalGroup.reduce(
        (prev, curr) => prev + curr.averageAnimalWeightKg * curr.animalCount,
        0
      );
    }
  }
);

export const getTotalAnimalCount = createSelector(
  [selectStockClassByAnimalGroupId, selectItemId],
  (stockClasses, animalGroupId) => {
    const stockClassesForAnimalGroup = stockClasses[animalGroupId];
    if (stockClassesForAnimalGroup) {
      return stockClassesForAnimalGroup.reduce(
        (prev, curr) => prev + curr.animalCount,
        0
      );
    }
  }
);

export const fetchstockClassesById = createAsyncThunk(
  "stockClasses/fetchById",
  (stockClassIds: string[]) => stockClassApi.getMany(stockClassIds)
);

export const createStockClass = createAsyncThunk(
  "stockClass/create",
  async (stockClass: StockClass, { dispatch }) => {
    const newStockClass = await stockClassApi.createOne(stockClass);
    return await stockClassApi.getOne(newStockClass.guid);
  }
);

export const updateStockClass = createAsyncThunk(
  "stockClass/update",
  async (stockClass: StockClass, { dispatch }) => {
    const updatedStockClass = await stockClassApi.updateOne(stockClass);
    return await stockClassApi.getOne(updatedStockClass.guid);
  }
);

export const deleteStockClass = createAsyncThunk(
  "stockClass/delete",
  async (stockClassId: string) => {
    await stockClassApi.deleteOne(stockClassId);
    return stockClassId;
  }
);

export const regenerateFeedingAllocation = createAsyncThunk(
  "stockClass/regenerateFeedingAllocation",
  async ({
    stockClassGuid,
    dateStart,
    dateEnd,
  }: {
    stockClassGuid: string;
    dateStart: Date;
    dateEnd: Date;
  }) => {
    return fetchApi(
      `stockClasses/${stockClassGuid}/regenerateFeedingAllocation`,
      {
        method: "POST",
        body: JSON.stringify({
          dateStart,
          dateEnd,
        }),
      }
    ) as Promise<{ feedingAllocations: Array<StockClassFeedAllocation> }>;
  }
);

type StockClassState = EntityState<StockClass> & {
  loading: boolean;
  error: string | null;
  status: "success" | "error" | null;
};

const initialStockClassState: StockClassState =
  stockClassAdapter.getInitialState({
    loading: false,
    error: null,
    status: null,
  });

const stockClassSlice = createSlice({
  name: "stockClass",
  initialState: initialStockClassState,
  reducers: {
    resetStockClasses: () => initialStockClassState,
  },
  extraReducers: {
    [fetchstockClassesById.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchstockClassesById.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassAdapter.upsertMany(state, payload);
    },
    [fetchstockClassesById.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [updateStockClass.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [updateStockClass.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassAdapter.upsertOne(state, payload);
    },
    [updateStockClass.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [createStockClass.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [createStockClass.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassAdapter.upsertOne(state, payload);
    },
    [createStockClass.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [deleteStockClass.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteStockClass.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassAdapter.removeOne(state, payload);
    },
    [deleteStockClass.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
  },
});

export const stockClassReducer = stockClassSlice.reducer;
export const animalGroupReducer = animalGroupSlice.reducer;

export const { resetStockClasses } = stockClassSlice.actions;
export const { resetAnimalGroups } = animalGroupSlice.actions;
