import { Link } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "common/locales";
import { ManualDMInputItem, EventType } from "model";
import StyledCoverAppBar from "./StyledCoverAppBar";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  margin: auto;
  max-width: 400px;
  padding: 50px;
`;

interface Props {
  manualDMInputs: Array<ManualDMInputItem>;
  handleSave: () => void;
  dateTimeMeasurementStart: Date;
  dateTimeMeasurementEnd: Date;
}

const DM_THRESHOLD = 200; //kg DM/ha

function DMAccuracy(dmInputs: Array<ManualDMInputItem>) {
  // for now, we do not count the readings where we missed an event in our accuracy score
  const readingsWithoutEvent = dmInputs.filter(
    (i) => i.event.eventType === EventType.None
  );

  const readingsBelowThreshold = readingsWithoutEvent.filter((i) => {
    if (i.newDryMatterValue) {
      if (Math.abs(i.newDryMatterValue - i.dryMatterValue) < DM_THRESHOLD) {
        return true;
      } else {
        return false;
      }
    } else {
      // we assume that if a farmer does not input data for a field, our data was correct
      return true;
    }
  });

  return {
    readingsBelow: readingsBelowThreshold.length,
    totalCount: readingsWithoutEvent.length,
  };
}

export default function ConfirmCovers({
  manualDMInputs,
  handleSave,
  dateTimeMeasurementStart,
  dateTimeMeasurementEnd,
}: Props) {
  const { t } = useTranslation();

  const fieldsWithInputs = manualDMInputs.filter((i) => i.newDryMatterValue);

  const accuracy = DMAccuracy(manualDMInputs);
  const readingsDate = dateTimeMeasurementStart.toLocaleDateString();
  const startTime = dateTimeMeasurementStart.toLocaleTimeString();
  const endTime = dateTimeMeasurementEnd.toLocaleTimeString();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography variant="h5">
          {t("addCovers.confirmCovers.heading")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <StyledPaper>
          {fieldsWithInputs.length > 0 && (
            <>
              <Typography variant="h1">
                {" "}
                {Math.round(
                  (accuracy.readingsBelow / accuracy.totalCount) * 100
                )}
                %
              </Typography>
              <Typography variant="h2" gutterBottom>
                {" "}
                ({accuracy.readingsBelow} / {accuracy.totalCount})
              </Typography>
              <Typography variant="h6" gutterBottom>
                {t("addCovers.confirmCovers.accuracy", {
                  threshold: DM_THRESHOLD,
                })}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t("addCovers.confirmCovers.datetime", {
                  readingsDate,
                  startTime,
                  endTime,
                })}
              </Typography>
              <Typography variant="body1">
                {t("addCovers.confirmCovers.infoMessage")}
              </Typography>
            </>
          )}
          {fieldsWithInputs.length === 0 && (
            <>
              <Typography variant="h6">
                {t("addCovers.confirmCovers.noData")}
              </Typography>
            </>
          )}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} sm={3}></Grid>
      <StyledCoverAppBar>
        <Button
          component={Link}
          to={fieldsWithInputs.length > 0 ? "enter-reasons" : "enter-covers"}
          color="primary"
        >
          {t("addCovers.back.label")}
        </Button>
        <Button
          color="primary"
          onClick={handleSave}
          disabled={fieldsWithInputs.length === 0}
        >
          {t("addCovers.save.label")}
        </Button>
      </StyledCoverAppBar>
    </Grid>
  );
}
