import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import RecommendIcon from "@mui/icons-material/Recommend";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Field, useAppDispatch, createOneReading, EventType } from "model";
import { mixpanel } from "common/analytics";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";

interface Props {
  field: Field;
}

export default function CoverEntryThumbsUpDialog({ field }: Props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const theme = useTheme();

  return (
    <>
      <Tooltip title={t("confirmCovers.thumbsUp.tooltip")}>
        <IconButton
          onClick={() => {
            setOpen(true);
            mixpanel.track("Cover looks good thumbsUp clicked");
          }}
          edge="end"
          size="large"
        >
          <RecommendIcon sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          mixpanel.track("Cover looks good thumbsUp closed");
        }}
      >
        <DialogTitle>{t("confirmCovers.thumbsUp.title")}</DialogTitle>
        <DialogContent style={{ maxWidth: "300px" }}>
          <FormattedMessage
            id="confirmCovers.thumbsUp.text"
            values={{
              p: (chunks: any) => <p>{chunks}</p>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpen(false);
              mixpanel.track("Cover looks good thumbsUp cancelled");
            }}
          >
            {t("confirmCovers.thumbsUp.cancel")}
          </Button>
          <Button disabled={false} color="primary" onClick={handleSave}>
            {t("confirmCovers.thumbsUp.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  async function handleSave() {
    // we add 5 minutes to the provided measurement time,
    // since we are only asking for the measurement of a single field
    // and we don't want users to have to enter 2 times
    const timeDiff = 5 * 60000;
    const curDate = new Date();
    mixpanel.track("Cover looks good thumbsUp confirmed");
    await dispatch(
      createOneReading({
        guid: "new",
        manualDryMatterInput: {
          dryMatterValue: field.dryMatterNow,
          field: field.guid,
          dateTimeMeasurementStart: curDate.toISOString(),
          dateTimeMeasurementEnd: new Date(
            curDate.getTime() + timeDiff
          ).toISOString(),
          event: {
            eventType: EventType.None,
          },
          kind: "user",
          verificationStatus: "Accepted",
        },
      })
    ).then(unwrapResult);
    mixpanel.track("Single CoverAdd successful (via thumbsUp)");
    notify(t("addCovers.success"));
    setOpen(false);
  }
}
