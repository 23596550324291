import { Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";
import { Tile, selectFieldTilesForNotDeletedBoundaries } from "model";

export default function FieldTileLayers({
  editedFieldId,
}: {
  editedFieldId?: string;
}) {
  const tiles = useSelector(selectFieldTilesForNotDeletedBoundaries).filter(
    (t) => t.field !== editedFieldId
  );
  return (
    <>
      {tiles.map((t: Tile) => (
        <Source
          id={t.guid}
          key={t.guid + "s"}
          type={t.type}
          tiles={t.tiles}
          scheme={t.scheme}
          bounds={t.bounds}
          tileSize={t.tileSize}
          minzoom={t.minzoom}
          maxzoom={t.maxzoom}
        >
          <Layer
            id={t.guid}
            type={"raster"}
            key={t.guid + "l"}
            layout={{
              visibility: t.visibility,
            }}
            paint={{}}
            beforeId="field-fill" // do we have to also add "arable-field-fill"?
          />
        </Source>
      ))}
    </>
  );
}
