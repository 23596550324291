import { FieldPreparationEvent, YieldEvent } from "model";
import { useState } from "react";
import FieldPreparationEventMoreMenu from "./FieldPreparationEventMoreMenu";
import UpdateFieldPreparationEvent from "./UpdateFieldPreparationEvent";
export default function UpdateFieldPreparationEventDialog({
  fieldPreparationEvent,
  menuAnchor,
  closeMenu,
}: {
  fieldPreparationEvent: FieldPreparationEvent;
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!fieldPreparationEvent) {
    return null;
  }
  return (
    <>
      <FieldPreparationEventMoreMenu
        fieldPreparationEvent={fieldPreparationEvent}
        close={closeMenu}
        anchor={menuAnchor}
        setDialogOpen={setDialogOpen}
      />
      <UpdateFieldPreparationEvent
        fieldPreparationEvent={fieldPreparationEvent}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
}
