import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Container,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  FC,
  MutableRefObject,
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
} from "react";
import { MapRef } from "react-map-gl";
import { Link, useRouteMatch, Switch, useHistory } from "react-router-dom";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import {
  closeDrawer,
  createFarm,
  openDrawer,
  selectAllFarms,
  selectCurrentFarm,
  switchFarm,
  useAppDispatch,
  useAppSelector,
} from "model";

import { envelope, lineString } from "@turf/turf";
import { useNotification } from "app/NotificationProvider";
import { unwrapResult } from "@reduxjs/toolkit";
import { mixpanel } from "common/analytics";
import { PrivateRoute } from "app";
import {
  DrawerForm,
  InfoSnackbar,
  MobileBottomDrawer,
  MobileButtonGroup,
  MobileTopHintContainer,
  VerticalCenterContainer,
} from "common/components";
import PostcodeLookupInput from "common/components/PostcodeLookupInput";
import { fetchIPLocation } from "model/geolocation";

interface FarmInputProps {
  mapRef: MutableRefObject<MapRef | null>;
}

export const FarmInput: FC<FarmInputProps> = ({ mapRef }) => {
  const [farmName, setFarmName] = useState<string>("");
  const [farmNameError, setFarmNameError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const userId = useAppSelector((state) => state.user.details?.guid);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { notify } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const userFarms = useAppSelector(selectAllFarms);
  const currentFarm = useAppSelector(selectCurrentFarm);
  useEffect(() => {
    // fetching ip data once on mount
    void dispatch(fetchIPLocation());
  }, []);
  let currentFarmGuid = ""; // should be always set later when we have at least 1 farm
  if (userFarms.length > 0) {
    currentFarmGuid = currentFarm ? currentFarm.guid : userFarms[0].guid;
  }

  const handleSave = async (): Promise<void> => {
    if (farmName === "") {
      setFarmNameError(true);
      return;
    }

    setIsLoading(true);

    const map = mapRef.current?.getMap();
    const farmGeom = envelope(lineString(map.getBounds().toArray()));

    if (farmGeom.geometry && userId) {
      try {
        const newFarm = await dispatch(
          createFarm({
            guid: "new",
            name: farmName,
            owner: userId,
            user: userId,
            geom: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "MultiPolygon",
                    coordinates: [farmGeom.geometry?.coordinates],
                  },
                },
              ],
            },
            fields: [],
            animalGroups: [],
          })
        ).then(unwrapResult);

        notify(t("farmInput.add.success", { farmName }));

        // TODO: figure out why newFarm can be undefined; or this check necessary?
        if (newFarm) {
          void dispatch(switchFarm(newFarm.guid));
          mixpanel.track("FarmAdd successful");
          history.replace("/add-field/enter-details");
        } else {
          history.push("");
        }
      } catch (err) {
        mixpanel.track("FarmAdd failed");
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (farmName !== "") {
      setFarmNameError(false);
    }

    setFarmName(e.target.value);
  };

  useEffect(() => {
    if (isMobile) {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer());
    }
  }, [isMobile, dispatch]);

  if (isMobile) {
    return (
      <>
        <Switch>
          <PrivateRoute path={`${path}/enter-name`}>
            <Dialog open fullScreen>
              <DialogTitle>{t("farm.add.addName.mobile")}</DialogTitle>
              <DialogContent>
                <VerticalCenterContainer>
                  <Container>
                    <DrawerForm>
                      <TextField
                        variant="standard"
                        required
                        value={farmName}
                        error={farmNameError}
                        helperText={t("farm.add.addName.error")}
                        onChange={onInputChange}
                      />
                    </DrawerForm>
                  </Container>
                </VerticalCenterContainer>
              </DialogContent>
              <DialogActions>
                {userFarms.length > 0 && (
                  <Button
                    component={Link}
                    to={`/farm/${currentFarmGuid}`}
                    color="secondary"
                  >
                    {t("edit.button.cancel")}
                  </Button>
                )}
                <Button
                  color="primary"
                  onClick={(e) => {
                    if (farmName === "") {
                      e.preventDefault();
                      setFarmNameError(true);
                    } else {
                      history.push(`${path}/find-location`);
                    }
                  }}
                >
                  {t("edit.button.next")}
                </Button>
              </DialogActions>
            </Dialog>
          </PrivateRoute>
          <PrivateRoute path={`${path}/find-location`}>
            <MobileTopHintContainer>
              <PostcodeLookupInput />
            </MobileTopHintContainer>
            <Container style={{ whiteSpace: "normal" }}>
              <InfoSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={t("farm.add.locationInstructions")}
              />
            </Container>
            <MobileBottomDrawer container>
              <MobileButtonGroup>
                <Button
                  color="secondary"
                  onClick={() => history.push("enter-name")}
                >
                  {t("edit.button.back")}
                </Button>
                <Button
                  color="primary"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {t("edit.button.save")}
                </Button>
              </MobileButtonGroup>
            </MobileBottomDrawer>
          </PrivateRoute>
        </Switch>
      </>
    );
  } else {
    return (
      <>
        <Container style={{ whiteSpace: "normal", paddingTop: "20px" }}>
          <Typography variant="h6" paragraph>
            {t("farm.add.addName.desktop")}
          </Typography>
          <TextField
            variant="standard"
            required
            value={farmName}
            error={farmNameError}
            helperText={t("farm.add.addName.error")}
            onChange={onInputChange}
            fullWidth
            style={{ paddingBottom: "25px" }}
            color="primary"
          />
          <PostcodeLookupInput />
          <Typography variant="body1" paragraph>
            {t("farm.add.saveLocation.desktop")}
            <span role="img" aria-label="point right">
              👉
            </span>
            {t("farm.add.saveFarm.desktop")}
          </Typography>
        </Container>
        <Container>
          <SaveButton color="primary" onClick={handleSave} disabled={isLoading}>
            {t("edit.button.save")}
          </SaveButton>
        </Container>
      </>
    );
  }
  function handleSnackbarClose() {
    setSnackbarOpen(false);
  }
};

const SaveButton = styled(Button)(
  ({ theme }) => `
        position: absolute;
        right: ${theme.spacing(3)}
    `
);
