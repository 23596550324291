import SvgIcon from "@mui/material/SvgIcon";

export default function SwardCompositionIcon() {
  return (
    // https://freesvg.org/ladybird-on-grass
    <SvgIcon viewBox="-4 0.2 140 250" fill="none">
      <path
        fill="#A6C300"
        d="M124 101h-1 1zm1 25s-5-17-8-16v-1c2-5 5-8 6-8h2s3 11 0 25zm3 34s-21 36-22 77-3-5-7-5l-2-20v-1c0 1 13-44 31-51z"
      />
      <path
        fill="#A6C300"
        d="M123 101c-1 0-4 3-6 8-7 14-16 41-19 58l-2 39-3-74c4-15 16-31 18-31 7-2 11-1 12 0z"
      />
      <path
        fill="#A6C300"
        d="M75 131C85 89 97 30 99 7c0 0 1 43-4 82l-2 43a1191 1191 0 0 0 7 110l-10 6-4-11c-7-20-20-65-13-96l2-10z"
      />
      <path
        fill="#A6C300"
        d="M73 141c-7 31 6 76 13 96h-5l-1 1c-2-10-7-28-10-61A336 336 0 0 0 29 37c38 28 46 94 46 94l-2 10zm-9 71 3 17v1c-5-1-8 7-8 7-2-37-30-61-30-61 17 5 33 29 33 29v-1l2 8z"
      />
      <path
        fill="#A6C300"
        d="m80 238 3 7-6-2-3-7a249 249 0 0 1-11-74C57 88 32 41 32 41c7 11 27 50 38 136 3 33 8 51 10 61z"
      />
      <path
        fill="#A6C300"
        d="M64 172c2 37 7 55 10 64l-8 5s2-2 1-12a160 160 0 0 0-5-25c-10-47-27-67-36-76-9 0-19 29-19 29-1-18 7-35 8-36l12-2c2 0 9-1 18 12 10 13 18 33 18 33v-2l1 10z"
      />
      <path
        fill="#839D0B"
        d="m97 212 2-6s1 7 4 9 5 1 5 1l-2 21c0 8-5-5-7-5l-2-20zm-11 25-6 1s-6-28-8-46l-4-28-2-14 2-6 6-6s-6 19-2 44c6 41 14 55 14 55zm-12 0-8 4 1-7c0-12-6-38-6-38s0-8 4-11 1 15 3 29l6 22z"
      />
      <path fill="#839D0B" d="M63 209s-6 9-3 24 3-3 4-4h3l-2-10-2-10z" />
      <path fill="#C9D100" d="m111 101-10 14c-4 7 9-6 13-2l8-12-5-1-6 1z" />
      <path fill="#839D0B" d="m94 157 4 9c2 3-2 27-2 34l-2-43z" />
      <path
        fill="#C9D100"
        d="M127 161s-13 18-18 33-1-10 2-16l11-15c3-3 5-2 5-2zm-98 15s17 14 23 25 2-5-1-9c0 0-7-8-12-11l-10-5zm-11-56s14 5 22 4-7-5-8-5l-14 1zm27-66s13 18 19 35l9 29S56 99 50 82 38 51 29 37c0 0 13 11 16 17z"
      />
      <path
        fill="#839D0B"
        d="m26 129-8-6h-2s-9 22-9 33 8-19 12-23 7-4 7-4zm99-3-5-12-3-5 5-7v-1l2 12 1 13z"
      />
      <path
        fill="#1A171A"
        d="M93 245c-4 3-4 4 5-2l2-1a73 73 0 0 1-2-12 793 793 0 0 1-5-111c0-17 3-36 5-56a660 660 0 0 0 1-46V7v1l-1 4c-5 38-13 72-16 87l-11 49c-2 16 0 29 0 33l7 31 9 29c2 7 4 10 0 0-1-5-7-18-13-47-2-14-6-32-1-54A2166 2166 0 0 0 99 13V7v6a714 714 0 0 1-5 79l-1 49a1345 1345 0 0 0 6 97l1 4a752 752 0 0 0-7 3z"
      />
      <path
        fill="#1A171A"
        d="m82 243-1-3c-3-6-9-38-14-82A475 475 0 0 0 38 51a116 116 0 0 0-9-14l2 2 3 3c15 13 27 41 29 45a222 222 0 0 1 11 40 200 200 0 0 0-22-64L37 44l-4-4c-2-2-5-4-3-1l7 13c14 25 28 82 32 121 3 37 10 67 13 70z"
      />
      <path d="M36 49a324 324 0 0 1 26 100l4 43c1 17 7 45 11 51 6 1 6 4-1-1a589 589 0 0 1-13-81A415 415 0 0 0 36 49z" />
      <path d="M81 237v1l5-1h-5z" />
      <path
        fill="#1A171A"
        d="m93 132 1-2 5-13 7-10 5-6 8-1 4 1h1-2l-5 8-8 18-8 28a612 612 0 0 0-4 29c1-9 2-20 7-40 2-6 5-17 10-28 2-5 4-12 9-15h2l-2-1h-5l-8 1a73 73 0 0 0-17 31z"
      />
      <path d="M125 102c0-1-1-1 0 0v24a168 168 0 0 0-3-9l-5-8 4 5a94 94 0 0 1 3 10c1 1 1 3 1 1 2-10 0-21 0-23zm-62 60c-1-1-9-18-17-29-3-5-8-11-15-13l-10-1-5 1-3 4a84 84 0 0 0-6 32l1-4 6-11c3-8 9-13 10-12l-2 1a64 64 0 0 0-15 25l-1 2v-2l1-10 5-18c1-2 1-8 6-7 4-1 8-2 12-1 8 2 13 9 14 10a226 226 0 0 1 19 33z" />
      <path d="m18 123 10 7c5 5 10 11 13 17 7 11 12 25 16 37a310 310 0 0 1 10 49v6c0 1 0 3-1 2a455 455 0 0 1 5-2l-4 2v-6l-1-14a300 300 0 0 0-23-70l-11-16c-5-6-10-10-11-10l-3-2z" />
      <path d="M61 204c0-1-11-14-20-21a49 49 0 0 0-11-6l-1-1 1 1 2 1a105 105 0 0 1 22 36l5 20v3h-1v-1l1-1 2-3 4-3c2 1 2 0-2 1l-2 3-2 3-1 1v-2a79 79 0 0 0-14-41l-12-15c-1-1-4-2-2-3l4 2 4 2a117 117 0 0 1 23 24zm38 1c0-1 6-17 13-29 3-6 7-11 13-15l3-1-1 1-3 5a158 158 0 0 0-13 39l-4 20-1 10v2a20 20 0 0 0-3-3c-4-2-4-4 1 0l1 1 1 1v-1a1208 1208 0 0 1 2-19 181 181 0 0 1 16-50l2-4 1-2h1s-1-1 0 0h-1l-4 3c-7 4-17 22-24 43-1 4-2 3 0-1z" />
      <path fill="#424242" d="M16 112h-3 1l2 1" />
      <path fill="#242423" d="M19 116v3h-1v-2h1-1v-1c-1 1 0 0 0 0" />
      <path fill="#424242" d="m25 118-1 1v1h1s0 1 0 0v-3 1" />
      <path
        fill="#424242"
        d="M24 120v2h-1 1c-1-1 0 0 0 0l1-2m-1 2v1l-1 1 1-1v1-2m3-2-1-1v-3h-1l2-4v-4 3a35 35 0 0 1-1 4v3c1 0 0 0 0 0l1 1c0 1 0 0 0 0h1"
      />
      <path fill="#424242" d="M27 119v1l2 1 1 2v1-1 1l-1-1-2-2v-1" />
      <path fill="#424242" d="m30 124-1 1h1v1-3" />
      <path fill="#424242" d="M29 125v2h1v-2" />
      <path
        fill="#424242"
        d="M29 127v1h1v-2m5-3v1h1c0-1 0 0 0 0h1v-1m-1 1 1 1 1 1 1 1v-1l1 1v-1l-2-2h-1"
      />
      <path fill="#424242" d="m39 126 1 1v2-1h1-1v-2" />
      <path
        fill="#424242"
        d="M40 128v2h1v-1l-1-1m1 2v1l-1 1h1v-2m-22-21 2-2c2 0 4-2 6 0s-1 8-2 9l-4 1-1-1v-4l-1-3"
      />
      <path
        fill="#424242"
        d="M19 110h-1l-1 1c0 1 0 0 0 0l-1 1v2l1 1v1h2l1-4-1-2"
      />
      <path d="m18 114-1 1h1l1-1h-1m0-4v1h-1v-1h1" />
      <path fill="#424242" d="m17 115-1 2-1 2c0 1 0 0 0 0l1-1 1-2" />
      <path fill="#424242" d="M16 114v2h2m6 0v2h1v-1 1l1-1-1-1" />
      <path
        fill="#F08A41"
        d="M27 107c1 0 5-2 12-1 4 1 7 4 8 5l2 3v5c0 2 0 3-2 4H37c-5-1-10-4-11-5v-3l1-3v-5"
      />
      <path
        fill="#EC701B"
        d="m28 111 1 3c1 1 6 5 10 5l8 1 1 2s-2 2-9 1-12-5-13-5v-3c0-1 0 0 0 0l1-4h1z"
      />
      <path
        fill="#F08A41"
        d="M34 104c3 0 8 1 11 4l2 3s-4-5-12-5l-8 1-1-1s0-2 3-2h5"
      />
      <path fill="#584D3E" d="m26 118 3 3 8 2 4 1 7-2s-3 2-9 1l-10-3-3-2" />
      <path d="m37 104 2 1h1" />
      <path fill="none" stroke="#000" d="m37 104 2 1h1" />
      <path fill="#E0E0E0" d="m18 114-1-1v1m0-3v1h-1 1v-1z" />
      <path d="m41 110-2-1h-1v2l2 2v-1h1v-3m5 9-2 2v1c0 1 0 2 1 1l1-1 1-1-1-2m-12-1-2 1 1 2h2v-1l-1-2m-7-9h1l1 1h1l2-2c1 0 0 0 0 0l-1-1h1l-1-1h-1l-2 1-1 1" />
      <path fill="#F9C5A0" d="M43 109s5 2 6 5v3l-1-1v-2c0-1-2-4-5-5" />
      <path
        fill="#E8E8E8"
        d="m26 107-5 2c-2 1-1 2-1 2 0-1 2-3 4-3l2-1zm-5 4v1h1l-1-1z"
      />
      <path fill="#1E1E1E" d="M20 116v1h3l2-2c1 0-1 2-3 1h-2" />
      <path fill="#E0E0E0" d="M20 113h2v3l-2 1v-1" />
      <path
        fill="#1E1E1E"
        d="m19 110 1 2-1 4h1v-4l-1-2m-2 5v1l2-1c0-1 0 0 0 0v1h-2v-1m-2 4 1-1-1 1c0-1 0 0 0 0m-2-7h3v1l-1-1h-2m3 3v1-1zm0-1 1 1-1-1m9 2 1-1 1-6-1-3 1 1v4l-1 4v3l1 1v1l-1-1v1-2 1h1l-1-1v-2h-1"
      />
      <path
        fill="#1E1E1E"
        d="M25 116h-1v2h1v-1l-1 1 1-2v2h1v-2h-1zm-1 4zm0 0h1-1m0 1v1-1zm-1 1 1 1v-1s0 1 0 0h1-2m1 2v-1 1-1l-1 1m4-4 1 1 1 2h1c1 0 0 0 0 0v1-1 1l-1-1-2-2v-1m3 4-1 1h1v1-1h-1l1-1m-1 2v1h1v-1 1h-1v-1m0 2h1-1m6-5 1 1s0 1 0 0l-1-1h2v1l-1-1h-1m2 2 1 1h1c0 1 0 1 0 0h1l-1 1c0 1 0 0 0 0l-1-1-1-1m3 3v1-1h1l-1 1v-1m0 2h1-1m0 2 1-1v1h-1"
      />
      <path fill="#F9C5A0" d="M35 108v1h1v-1h-1" />
      <path fill="#FEEBD7" d="M35 107c-1 0-2 1-1 2l2-1-1-1" />
      <path d="M16 112h-3 2l1 1-1-1h-2 3m3 4v3h-1v-1l1-1-1-1h-1s1 0 0 0h1v1h1-1v1l1 1v-3m6 2-1 1v1h1v-2 2h-1v-2h1m-1 2v2h-1 1s0 1 0 0c1 0 0 0 0 0v-2l1 1v1c-1 0 0 0 0 0h-2 1s0-1 0 0v-2" />
      <path d="m24 122-1 2h1v-1 1-2 2-1l-1 1 1-1v-1m3-2 2 3 1 1v-1c0 1 0 0 0 0v-1l-3-2v-1 1h-1v-1l-1-1h1v2l1-1 1 1 1 1 1 2v1-1 1l-1-1v-1l-2-2c-1 0 0 0 0 0m3 4-1 1h1v1-2 2-1h-1v-1h1m-1 2v1h1v-1 1h-1v-1c0-1 0-1 0 0m0 1v1s-1 0 0 0h1v-1 1h-1v-1m6-4v1h1c0 1 0 0 0 0h1v-1 1h-2v-1" />
      <path d="m37 125 1 1 1 1v-1h1l-1-1-2-1 1 1 2 1v1l-1-1v1l-2-2c-1-1-1-1 0 0" />
      <path d="m39 127 1 1v1h1v-1h-1v-1s0-1 0 0l1 2h-1v-1l-1-1" />
      <path d="M40 129v1h1a2 2 0 0 0 0-1c-1 0 0-1 0 0v1h-1v-1m1 1v1l-1 1s0-1 0 0h1c0-1 0 0 0 0v-2 2h1-1l-1-1h1v-1" />
      <path
        fill="#191619"
        d="M19 110c0-1 0-1 0 0l1 3a23 23 0 0 1 0 3l1 1 4-1 1-1v-2l1-5-2-2-3 1-1 1-2 1s0 1 0 0l3-2 3-1 2 1v3l-2 5-2 2h-2l-1-1v-4l-1-2"
      />
      <path
        fill="#191619"
        d="m19 110 1 1v3l-1 2h-2v-1l-1-1v-1a2 2 0 0 1 1-1v-1l2-1h-2l-1 4v1h1v1h2l1-1v-2l-1-3"
      />
      <path d="M16 115v2l-1 1v1l1-1 1-2c0-1 0 0 0 0l-1 1-1 2c-1 0 0 0 0 0v-1l1-1v-2c1 0 1 0 0 0m1 1c-1 0-1 0 0 0m-1-2v2-2m8 3 1 1v-1 1h1v-1l-1-1 1 1-1 1v-1a5 5 0 0 0 0 1h-1v-1c0-1 0-1 0 0" />
      <path
        fill="#191619"
        d="M27 108v-1 2l-1 5v3l1 2 6 3 7 1h5l3-1 1-7v-2l-1-1-1-1c-1-2-4-3-6-4l-5-1h-4l-4 1h-1 1l8-1 6 2 4 2 2 2 1 3v5l-2 2-3 1c-5 1-10-1-14-3l-4-3v-2l1-2v-5"
      />
      <path
        fill="#191619"
        d="M34 104h-5l-3 2 1 1a10 10 0 0 1 2-1 21 21 0 0 1 16 3 12 12 0 0 1 2 2v-1l-4-3-3-2-2-1h-3c-1 0-1 0 0 0a18 18 0 0 1 9 4 15 15 0 0 1 3 3v-1h-1l-1-1c-2-1-5-3-8-3h-6a13 13 0 0 0-3 1h-1l-1-1 1-1 3-1h4m14 18-6 2h-3l-3-1a25 25 0 0 1-8-3l-1-1-1-1s0-1 0 0l2 2 6 3c2 0 6 2 9 1l3-1 2-1"
      />
      <path d="M20 113h2v1l1 2h-1v1h-1l-1-1 1 1 1-1h1l-1-2v-1h-2m-2 1v-1h-1v1-1l1 1m-1-3v1h-1 1v-1" />
    </SvgIcon>
  );
}
