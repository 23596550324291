import { useState, useEffect } from "react";
import styled from "styled-components";
import { Paper, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useAppSelector, selectFieldById, selectArableFieldById } from "model";

interface Props {
  fieldId?: string;
  dm: string;
  visible: boolean;
}

interface Position {
  x: number;
  y: number;
}

export const MapCursorTooltip = ({ fieldId, dm, visible }: Props) => {
  const { formatMessage: f } = useIntl();
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const field = useAppSelector((state) =>
    selectFieldById(state, fieldId ?? "")
  );

  const arableField = useAppSelector((state) =>
    selectArableFieldById(state, fieldId ?? "")
  );

  const fieldName = field?.name ?? arableField?.name;

  const offset = { x: 15, y: 15 };

  useEffect(() => {
    const handleMouseChange = ({ clientX, clientY }: MouseEvent) => {
      setPosition({ x: clientX, y: clientY });
    };
    window.addEventListener("mousemove", handleMouseChange);
    return () => window.removeEventListener("mousemove", handleMouseChange);
  });

  return (
    <MapTooltip
      style={{
        display: visible ? "block" : "none",
        position: "fixed",
        top: position.y + offset.y,
        left: position.x + offset.x,
      }}
    >
      <Typography>{fieldName}</Typography>
      {dm != "-1" && (
        <Typography>
          {dm + " " + f({ id: "map.cursorTooltip.unit" })}
        </Typography>
      )}
    </MapTooltip>
  );
};

const MapTooltip = styled(Paper)(
  ({ theme }) => `
  text-align: center;
  padding: ${theme.spacing(1)};
  z-index: 10000;
`
);
