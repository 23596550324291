import { useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import WarningIcon from "@mui/icons-material/Warning";
import Skeleton from "@mui/material/Skeleton";
import { FieldAnimalGroupActionMenu } from "features/fieldDetail";
import { useTranslation } from "common/locales";
import { Link } from "react-router-dom";
import {
  selectAllFields,
  selectAnimalGroupById,
  Field,
  getFieldWithAnimalsForAnimalGroupId,
  useAppDispatch,
  useAppSelector,
  openMoveOutEventDialog,
} from "model";
import FieldIcon from "./FieldIcon";
import MoreVert from "@mui/icons-material/MoreVert";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import AnimalsLocationRoundedIcon from "common/icons/AnimalsLocationIconRounded";
import { moveAnimalsIn } from "features/singleCoverInput/UpdateBiomassAfterMoveInDialog";
interface FieldsListProps {
  fieldIds?: string[];
  emptyText?: string;
}

export default function FieldsList({ fieldIds, emptyText }: FieldsListProps) {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentFieldId, setCurrentFieldId] = useState<string | null>(null);
  let fields = useAppSelector(selectAllFields);
  if (fieldIds) fields = fields.filter(({ guid }) => fieldIds.includes(guid));

  const loading = useAppSelector((state) => state.fields.loading);

  if (fields.length === 0 && loading !== "pending") {
    return <EmptyList text={emptyText} />;
  }

  if (fields.length === 0 && loading === "pending") {
    // TODO: this leads to constant blinking due to RefreshProvider
    return <LoadingList />;
  }

  return (
    <List>
      {currentFieldId && (
        <FieldAnimalGroupActionMenu
          anchor={menuAnchor}
          close={closeMenu}
          fieldId={currentFieldId}
        />
      )}
      {fields.map((field: Field) => (
        <div key={`field-key-${field.guid}`}>
          <FieldListItem key={field.guid} field={field} openMenu={openMenu} />
        </div>
      ))}
    </List>
  );

  function openMenu(fieldId: string, anchor: HTMLButtonElement) {
    setMenuAnchor(anchor);
    setCurrentFieldId(fieldId);
  }

  function closeMenu() {
    setMenuAnchor(null);
    setCurrentFieldId(null);
  }
}

export function EmptyList({ text }: { text?: string }) {
  const { t } = useTranslation();
  return (
    <Box m={3}>
      <Typography color="textSecondary" align="center">
        {text ?? t("fieldlist.empty")}
      </Typography>
    </Box>
  );
}

export function LoadingList() {
  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ArtTrackIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant="text" width={200} />}
          secondary={<Skeleton variant="text" width={100} />}
        />
      </ListItem>
    </List>
  );
}

function FieldListItem({ field, openMenu }: FieldListItemProps) {
  const { t } = useTranslation();
  const animalGroup = useAppSelector((state) =>
    selectAnimalGroupById(state, field?.animalGroup || "")
  );
  const overgrazed = Boolean(
    animalGroup?.postGrazingTarget &&
      field.dryMatterNow &&
      field.dryMatterNow < animalGroup.postGrazingTarget
  );

  const currentFieldWithAnimals = useAppSelector(
    getFieldWithAnimalsForAnimalGroupId(field.animalGroup)
  );

  const hasAnimalsNow =
    field.animalGroup && field.guid === currentFieldWithAnimals?.guid;
  const canMoveIn =
    field.animalGroup && field.guid !== currentFieldWithAnimals?.guid;
  const [isSendingMoveRequest, setSendingMoveRequest] = useState(false);
  const dispatch = useAppDispatch();

  const { notify } = useNotification();
  async function onMoveIn() {
    try {
      if (field) {
        if (currentFieldWithAnimals) {
          dispatch(
            openMoveOutEventDialog({
              open: true,
              newFieldWithAnimals: field,
              currentFieldWithAnimals: currentFieldWithAnimals,
            })
          );
        } else {
          await moveAnimalsIn(
            field,
            currentFieldWithAnimals,
            new Date(),
            dispatch,
            notify,
            t
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  const handleMoveInClick = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setSendingMoveRequest(true);
    await onMoveIn();
    setSendingMoveRequest(false);
  };

  return (
    <ListItemStyled>
      <ListItem button component={Link} to={`/field/${field.guid}`}>
        <ListItemAvatar>
          <FieldIcon {...field} />
        </ListItemAvatar>
        <ListItemText
          primary={field.name}
          secondary={
            field.invalidatedTimestamp
              ? t("fieldlist.noDryMatterNow.text")
              : `${Math.round(field.dryMatterNow)} ${t(
                  "fieldlist.dryMatterNow.unit"
                )}`
          }
        />
        {hasAnimalsNow ? (
          <Tooltip title={t("fieldview.animalsOnThisField.text")}>
            <Box ml={overgrazed ? 1 : 0} mr={overgrazed ? 1.1 : 0}>
              <IconButton
                edge="end"
                aria-haspopup="true"
                disabled={true}
                size="large"
              >
                <AnimalsLocationRoundedIcon htmlColor={animalGroup?.color} />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          ""
        )}
        {canMoveIn ? (
          <Tooltip title={t("fieldview.moveAnimals.text")}>
            <Box ml={overgrazed ? 1 : 0} mr={overgrazed ? 1.1 : 0}>
              <IconButton
                edge="end"
                aria-haspopup="true"
                onClick={handleMoveInClick}
                className="move-in-icon"
                disabled={isSendingMoveRequest}
                size="large"
              >
                <AnimalsLocationRoundedIcon
                  htmlColor={animalGroup?.color}
                  fillOpacity={0.5}
                />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          ""
        )}
        {overgrazed ? (
          <Tooltip title={t("warning.tooltip.title")} placement="top">
            <WarningIcon color="error" />
          </Tooltip>
        ) : (
          ""
        )}
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={(e) => openMenu(field.guid, e.currentTarget)}
            size="large"
          >
            <MoreVert />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </ListItemStyled>
  );
}

type FieldListItemProps = {
  field: Field;
  openMenu: (guid: string, target: any) => void;
};

export const ListItemStyled = styled.div(
  ({ theme }) => `
  & .move-in-icon {
    display: none;
  }

  @media (hover: hover) {
    &:hover .move-in-icon {
      display: block;
    }
  }
`
);
