import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { mixpanel } from "../../common/analytics";
import Pipedrive from "common/utils/pipedrive";
import { LinearProgress } from "@mui/material";
import { restoreToken } from "common/utils/token";

import { useFormik } from "formik";

import { FullScreenDialog } from "common/components";
import {
  fetchToken,
  initializeApp,
  createUser,
  useAppDispatch,
  useAppSelector,
} from "model";
import { unwrapResult } from "@reduxjs/toolkit";
import UserDetails from "./UserDetails";
import FarmDetails from "./FarmDetails";
import Welcome from "./Welcome";
import styled from "styled-components";
import Teaser from "./Teaser";

export interface FormValues {
  mail: string;
  name: string;
  phone: string;
  password: string;
  size: string;
  farmType: string;
  carbonFarmingInterest: string;
}

export default function SignupDialog() {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const creatingUser = useAppSelector((state) => state.user.loading);
  const appInitialising = useAppSelector((state) => state.app.loading);

  const loading = creatingUser === "pending" || appInitialising === "pending";
  const pipedriveApi = new Pipedrive();
  const formik = useFormik<FormValues>({
    initialValues: {
      mail: "",
      name: "",
      phone: "",
      password: "",
      size: "",
      farmType: "",
      carbonFarmingInterest: "",
    },
    onSubmit: () => {
      console.log("submitted");
      void handleSignup();
    },
  });
  // even if we don't have the language supported yet,
  // we still want people to be able to save their prefered language.
  // That way we know which missing translations we need to add.
  const language = navigator.language;

  restoreToken(location); // for now trying to restore token from url only on signup

  const handleSignup = async () => {
    mixpanel.track("Signup submitted");

    try {
      await dispatch(
        createUser({
          guid: "new",
          farms: [],
          name: formik.values.name,
          mail: formik.values.mail,
          phone: formik.values.phone,
          password: formik.values.password,
          lang: language,
        })
      ).then(unwrapResult);
      mixpanel.track("Initial Login attempted");
      await dispatch(
        fetchToken({
          mail: formik.values.mail,
          password: formik.values.password,
        })
      ).then(unwrapResult);
      void dispatch(initializeApp());
    } catch (err) {
      console.log(err);
      mixpanel.track("Initial Login failed");
    }
    if (process.env.NODE_ENV === "production") {
      // only add signups to pipedrive if we are in production
      try {
        const person = await pipedriveApi.addPerson({
          name: formik.values.name,
          email: formik.values.mail,
          phone: formik.values.phone,
          "689dac0343ab7408b80b11091d81cd85b78b0346": formik.values.farmType,
          "34f81b5dea3876fe0040f11ecd5ac00360c26718": formik.values.size,
          "863a8324f0ad1bc3624dcb01762a978ed980b546":
            formik.values.carbonFarmingInterest === "yes" ? "31" : "32",
        });

        await pipedriveApi.addDeal({
          title: `${formik.values.name} Deal`,
          person_id: person.data.id,
          stage_id: "25", // stage_id 25 is "account created"
        });
      } catch (err) {
        console.log(err);
      }
    }

    history.push("/signup/welcome");
  };

  return (
    <FullScreenDialog>
      {loading && <LinearProgress />}
      <Switch>
        <Route path="/signup" exact>
          <Redirect to="/signup/get-started" />
        </Route>
        <Route exact path={`${path}/welcome`}>
          <Welcome />
        </Route>
        <Route exact path={`${path}/get-started`}>
          <Teaser />
        </Route>
        <Route exact path={`${path}/user-details`}>
          <UserDetails
            values={formik.values}
            handleChange={formik.handleChange}
          />
        </Route>
        <Route exact path={`${path}/farm-details`}>
          <FarmDetails
            values={formik.values}
            handleChange={formik.handleChange}
            handleSubmit={formik.handleSubmit}
          />
        </Route>
      </Switch>
    </FullScreenDialog>
  );
}

export const StyledForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 250px;
  padding-bottom: ${theme.spacing(2)};
`
);
