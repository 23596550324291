import {
  Button,
  Grid,
  TextField,
  Typography,
  SelectChangeEvent,
  Divider,
} from "@mui/material";
import { useTranslation } from "common/locales";
import { useIntl } from "react-intl";
import { Dispatch, MouseEventHandler, SetStateAction, useState } from "react";
import { GENERAL_FEED_TYPE, GENERAL_FEED_UNITS } from "model";
import FeedTypeDropdown from "./FeedTypeDropdown";
import EnterDate from "features/general/EnterDate";
import FeedUnitDropdown from "./FeedUnitDropdown";
import ConfirmCancellationDialog from "features/general/ConfirmCancellationDialog";
import { ButtonContainer, ButtonGroup } from "./generalMethods";

interface Props {
  cost: string | undefined;
  setCost: Dispatch<SetStateAction<string | undefined>>;
  setFeedType: Dispatch<SetStateAction<GENERAL_FEED_TYPE | undefined>>;
  setFeedUnit: Dispatch<SetStateAction<GENERAL_FEED_UNITS | undefined>>;
  handleSave: MouseEventHandler<HTMLButtonElement>;
  setNotes: Dispatch<SetStateAction<string | undefined>>;
  note: string | undefined;
  date: Date | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
  feedTypeError: boolean;
  setFeedTypeError: Dispatch<SetStateAction<boolean>>;
  feedUnitError: boolean;
  setFeedUnitError: Dispatch<SetStateAction<boolean>>;
  dateError: boolean;
  feedType?: GENERAL_FEED_TYPE;
  feedUnit?: GENERAL_FEED_UNITS;
  feedAmount: string | undefined;
  setFeedAmount: Dispatch<SetStateAction<string | undefined>>;
  feedAmountError: boolean;
  setFeedAmountError: Dispatch<SetStateAction<boolean>>;
  costError: boolean;
  setCostError: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
}

export default function EnterFeedPurchaseEvent({
  cost,
  setCost,
  setFeedType,
  handleSave,
  setNotes,
  note,
  date,
  setDate,
  feedTypeError,
  setFeedTypeError,
  setFeedUnit,
  feedUnitError,
  setFeedUnitError,
  dateError,
  feedType,
  feedUnit,
  feedAmount,
  setFeedAmount,
  feedAmountError,
  setFeedAmountError,
  costError,
  setCostError,
  handleClose,
}: Props) {
  const { t } = useTranslation();
  const { formatMessage: f } = useIntl();
  const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false);

  const handleGrassTypeChange = (value: GENERAL_FEED_TYPE | undefined) => {
    setFeedType(value);
  };

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      flexDirection={"column"}
      sx={{
        justifyItems: "baseline",
        justifySelf: "baseline",
        alignSelf: "baseline",
      }}
    >
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">
              {t("enterFeedPurchase.heading")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterDate
              disabled={false}
              date={date}
              setDate={setDate}
              dateError={dateError}
              name={"someName"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3} display="flex" alignItems="baseline">
          <Grid item xs={12} sm={4} display="flex" alignItems="baseline">
            <FeedTypeDropdown
              disabled={false}
              onChange={(e: SelectChangeEvent<GENERAL_FEED_TYPE>) => {
                const value = e.target.value;
                handleGrassTypeChange(value as GENERAL_FEED_TYPE);
                if (value) {
                  setFeedTypeError(false);
                } else {
                  setFeedTypeError(true);
                }
              }}
              currentFeedType={feedType}
              feedTypeInputError={feedTypeError}
              label={"feedPurchaseEvent.feedType.text"}
              name={"some name"}
            />
          </Grid>
          <Grid item xs={12} sm={4} display="flex" alignItems="baseline">
            <TextField
              value={cost}
              error={costError}
              type="number"
              variant="standard"
              disabled={false}
              id="feedPurchaseCost"
              onChange={(event) => {
                if (event.target.value === "") {
                  setCostError(false);
                  setCost(undefined);
                } else {
                  setCost(event.target.value);
                  const value = Number(event.target.value);
                  const costError: boolean = Number.isNaN(value) || value < 0;
                  setCostError(costError);
                }
              }}
              label={t("feedPurchaseEvent.cost.label")}
              multiline
              maxRows={12}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3} display="flex" alignItems="baseline">
          <Grid item xs={12} sm={4} display="flex" alignItems="baseline">
            <FeedUnitDropdown
              disabled={false}
              onChange={(e: SelectChangeEvent<GENERAL_FEED_UNITS>) => {
                const value: GENERAL_FEED_UNITS = e.target
                  .value as GENERAL_FEED_UNITS;
                setFeedUnit(value);
                if (value) {
                  setFeedUnitError(false);
                } else {
                  setFeedUnitError(true);
                }
              }}
              currentUnit={feedUnit}
              feedUnitInputError={feedUnitError}
              label={"feedPurchaseEvent.feedUnit.text"}
              name="feedPurchaseEvent.feedUnit"
            />
          </Grid>
          <Grid item xs={12} sm={4} display="flex" alignItems="baseline">
            <TextField
              required
              value={feedAmount}
              type="number"
              variant="standard"
              disabled={false}
              id="feedAmount"
              error={feedAmountError}
              onChange={(event) => {
                if (event.target.value === "") {
                  setFeedAmountError(false);
                  setFeedAmount(undefined);
                } else {
                  setFeedAmount(event.target.value);
                  const value = Number(event.target.value);
                  const feedAmountError: boolean =
                    Number.isNaN(value) || value <= 0;
                  setFeedAmountError(feedAmountError);
                }
              }}
              label={t("feedPurchaseEvent.feedAmount.label")}
              multiline
              maxRows={12}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3} style={{ marginBottom: 40 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              value={note ?? ""}
              variant="standard"
              disabled={false}
              id="feedPurchaseNotes"
              onChange={(event) => {
                setNotes(event.target.value);
              }}
              label={t("reseedEvent.notes.label")}
              multiline
              maxRows={12}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <ButtonContainer>
        <ButtonGroup>
          <Button
            onClick={() => setCancellationDialogOpen(true)}
            color="secondary"
          >
            {f({ id: "addGrassType.cancel.label" })}
          </Button>
          <Button color="primary" onClick={handleSave}>
            {t("addGrassType.save.label")}
          </Button>
        </ButtonGroup>
        <ConfirmCancellationDialog
          dialogOpen={cancellationDialogOpen}
          closeDialog={() => setCancellationDialogOpen(false)}
          onConfirmCancel={() => {
            handleClose();
            // mixpanel.track("Enter re-seed event cancelled");
            // history.goBack();
          }}
          onCancelCancel={() => setCancellationDialogOpen(false)}
        />
      </ButtonContainer>
    </Grid>
  );
}
