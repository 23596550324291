import {
  Container,
  List,
  ListItem,
  Divider,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { mixpanel } from "common/analytics";
import {
  useAppSelector,
  selectYieldSummaryById,
  YieldSummary,
  YieldSummariesWithId,
  YIELD_TYPES,
  MILK_UNITS,
  CROP_UNITS,
} from "model";
import { convertToYieldAmountString } from "./generalMethods";
import YieldEventList from "./YieldEventList";

export default function YieldDetail() {
  const history = useHistory();
  const { t } = useTranslation();
  const yieldSummary: YieldSummariesWithId | undefined = useAppSelector(
    (state) => selectYieldSummaryById(state, 1)
  );
  const locale = useAppSelector((state) => state.app.locale);

  return (
    <>
      <Container>
        <List>
          <ListItem key="add-yield-event">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Add new yield event clicked");
                history.push(`/enter-yield-events`);
              }}
            >
              {t("yieldEventDetailView.button.addYieldEvent.title")}
            </Button>
          </ListItem>
        </List>
      </Container>
      <Divider />
      <FieldEventListContainer>
        <Box
          my={4}
          display="flex"
          alignItems="center"
          gap={4}
          p={0}
          sx={{ border: "2px solid grey", borderRadius: 2 }}
        >
          <Container sx={{ display: "flex", flexDirection: "column" }}>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h5">
                        {t("yield.stock.summary.header")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {yieldSummary && yieldSummary.yieldSummary.length > 0 ? (
                    yieldSummary.yieldSummary.map(
                      (yieldTypeSummary: YieldSummary) => {
                        return yieldTypeSummary.yieldSummaries.map(
                          (singleYieldSummary) => {
                            return (
                              <TableRow key={`${singleYieldSummary.yieldType}`}>
                                <TableCell>
                                  <Typography variant="body1">
                                    {t(
                                      `yieldEvent.yieldType.option.${singleYieldSummary.yieldType}`
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {convertToYieldAmountString(
                                      singleYieldSummary.amount,
                                      locale,
                                      singleYieldSummary.yieldType ===
                                        YIELD_TYPES.MILK
                                        ? MILK_UNITS.LITRE
                                        : CROP_UNITS.KG,
                                      t
                                    )}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        );
                      }
                    )
                  ) : (
                    <TableRow>
                      <TableCell>
                        {" "}
                        <Typography variant="body1">
                          {t("feedStock.summary.noFeedAvailable")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </FieldEventListContainer>
      <FieldEventListContainer>
        <YieldEventList />
      </FieldEventListContainer>
    </>
  );
}

const FieldEventListContainer = styled(Container)`
  flex-grow: 1;
`;
