import { JWT } from "model";
import { default as decode } from "jwt-decode";
import * as H from "history";
import { mixpanel } from "common/analytics";

export function isValid(token: string | null) {
  try {
    if (token) {
      const jwtDecoded = decode<JWT>(token);
      const now = Date.now() / 1000;
      const exp = jwtDecoded.exp;
      if (exp > now) {
        return true;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}

export function importTokenFromLocation(
  location: H.Location<unknown> | Location | undefined
) {
  if (location) {
    const params = new URLSearchParams(location.search);
    if (params && params.has("jwt")) {
      const jwt = params.get("jwt");
      if (jwt !== null && isValid(jwt)) {
        window.localStorage.setItem("jwt", jwt);
        return true;
      }
    }
  }
  return false;
}

export function restoreToken(location?: H.Location<unknown>) {
  if (isValid(window.localStorage.getItem("jwt"))) {
    return true;
  }

  if (importTokenFromLocation(location)) {
    return true;
  }

  if (importTokenFromLocation(window.location)) {
    return true;
  }

  return false;
}

export function tokenValid() {
  return isValid(window.localStorage.getItem("jwt"));
}
