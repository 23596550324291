import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAppSelector, ManualDMInputResponse, Field } from "model";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import {
  SatelliteIcon,
  AnimalsLocationIcon,
  AnimalsLocationRoundedIcon,
} from "common/icons";
import FieldEventConfirmationMenu from "./FieldEventConfirmationMenu";
import { useTranslation } from "common/locales";
import FieldEventMoreMenu from "./FieldEventMoreMenu";
import { useState } from "react";
import { MoreVert, Assessment } from "@mui/icons-material";
import { getFormattedTime } from "../../common/utils/getFormattedTime";
import React from "react";

export default function FieldEventItem({
  dm,
  field,
}: {
  dm: ManualDMInputResponse;
  field: Field;
}) {
  const locale = useAppSelector((state) => state.app.locale);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const postCover = Math.round(
    dm.manualDryMatterInput.event.postEventCover || 0
  );
  const { eventType, dateTimeIn, dateTimeOut, dateTimeCut } =
    dm.manualDryMatterInput.event;
  const { kind, verificationStatus, dateTimeMeasurementStart } =
    dm.manualDryMatterInput;

  if (
    (eventType === "GrazingEvent" || "PartialGrazingEvent") &&
    (dateTimeIn || dateTimeOut)
  ) {
    const dateIn = dateTimeIn && getFormattedTime(dateTimeIn, locale);
    const dateOut = dateTimeOut && getFormattedTime(dateTimeOut, locale);

    let icon = <ManualInputIcon />; // only user events for now
    let title = "fieldevents.grazed.title";
    if (kind === "machine") {
      // TODO: remove machine events
      icon = <AutoDetectedIcon />;
    } else if (!dateTimeOut) {
      // move in event
      icon = <AnimalsLocationIcon htmlColor="#E0E0E0" />;
      title = "fieldevents.moveIn.title";
    } else if (!dateTimeIn) {
      // move out event
      icon = <AnimalsLocationRoundedIcon strokeDasharray="5, 5" />;
      title = "fieldevents.moveOut.title";
    }

    return (
      <ListItem key={`fe-listitem-${dm.guid}`}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={t(title)}
          secondary={
            <>
              {dateIn && (
                <Typography component="span" display="block">
                  {t("fieldevents.grazed.dateIn", { dateIn })}
                </Typography>
              )}
              {dateOut && (
                <Typography component="span" display="block">
                  {t("fieldevents.grazed.dateOut", { dateOut })}
                </Typography>
              )}
              {verificationStatus === "Proposed" && (
                <Typography component="span" display="block">
                  {t("fieldevents.event.postCover", { postCover })}
                </Typography>
              )}
            </>
          }
        />
        {verificationStatus === "Accepted" && dateIn && dateOut && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-haspopup="true"
              onClick={openMenu}
              id={dm.guid}
              size="large"
            >
              <MoreVert />
            </IconButton>
            <FieldEventMoreMenu
              field={field}
              manualInput={dm}
              anchor={menuAnchor}
              close={closeMenu}
              key={`fe-more-${dm.guid}`}
            />
          </ListItemSecondaryAction>
        )}
        {verificationStatus === "Proposed" && (
          <FieldEventConfirmationMenu machineInput={dm} />
        )}
      </ListItem>
    );
  } else if (eventType === "SilageEvent" && dateTimeCut) {
    const dateCut = getFormattedTime(dateTimeCut, locale);
    return (
      <ListItem key={`fe-listitem-${dm.guid}`}>
        <ListItemIcon>
          {kind === "user" && <ManualInputIcon />}
          {kind === "machine" && <AutoDetectedIcon />}
        </ListItemIcon>
        <ListItemText
          primary={t("fieldevents.silage.title")}
          secondary={
            <>
              <Typography component="span" display="block">
                {t("fieldevents.silage.dates", { dateCut })}
              </Typography>
              {verificationStatus === "Proposed" && (
                <Typography component="span" display="block">
                  {t("fieldevents.event.postCover", { postCover })}
                </Typography>
              )}
            </>
          }
        />
        {verificationStatus === "Accepted" && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-haspopup="true"
              onClick={openMenu}
              id={dm.guid}
              size="large"
            >
              <MoreVert />
            </IconButton>
            <FieldEventMoreMenu
              field={field}
              manualInput={dm}
              anchor={menuAnchor}
              close={closeMenu}
              key={`fe-more-${dm.guid}`}
            />
          </ListItemSecondaryAction>
        )}
        {verificationStatus === "Proposed" && (
          <FieldEventConfirmationMenu machineInput={dm} />
        )}
      </ListItem>
    );
  } else if (eventType === "None" && dateTimeMeasurementStart) {
    const date = getFormattedTime(dateTimeMeasurementStart, locale);
    const value = Math.round(dm.manualDryMatterInput.dryMatterValue || 0);
    return (
      <ListItem key={`fe-listitem-${dm.guid}`}>
        <ListItemIcon>
          <Assessment />
        </ListItemIcon>
        <ListItemText
          primary={t("fieldevents.reading.title")}
          secondary={
            <>
              <Typography component="span" display="block">
                {t("fieldevents.reading.date", { date })}
              </Typography>
              <Typography component="span" display="block">
                {t("fieldevents.reading.value", { value })}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  } else {
    return <></>;
  }

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }
}

export function AutoDetectedIcon() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("fieldevent.autodetected.tooltip")}>
      <div>
        <SatelliteIcon />
      </div>
    </Tooltip>
  );
}

function ManualInputIcon() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("fieldevent.manualinput.tooltip")}>
      <KeyboardIcon />
    </Tooltip>
  );
}
