/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { restoreToken } from "common/utils/token";
import { FormattedMessage } from "react-intl";
import { FullScreenDialog } from "common/components";
import { useTranslation } from "common/locales";
import { useCallback, useEffect, useRef, useState } from "react";
import { getRedirectUrlWithToken } from "common/utils";

import { useAppDispatch, initializeApp, initializeFarm, Farm } from "model";
import { unwrapResult } from "@reduxjs/toolkit";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import LoginIcon from "@mui/icons-material/Login";

export enum ExternalProvider { // external provider
  AGRIWEBB = "AGRIWEBB",
}

const externalProviderRetryUrl = {
  [ExternalProvider.AGRIWEBB]:
    "https://portal.agriwebb.com/accounts/marketplace",
};

export const externalProviderName = {
  [ExternalProvider.AGRIWEBB]: "AgriWebb",
};

export default function Oauth2Screen() {
  const location = useLocation();
  const { path } = useRouteMatch();

  restoreToken(location); // for now trying to restore token from url only on signup

  return (
    <FullScreenDialog>
      <Switch>
        <Route path={`${path}/redirect`} exact>
          <Oauth2RedirectScreen />
        </Route>

        <Route path={`${path}/success`} exact>
          <Oauth2SuccessScreen />
        </Route>

        <Route path={`${path}/error`} exact>
          <Oauth2ErrorScreen />
        </Route>
      </Switch>
    </FullScreenDialog>
  );
}

function Oauth2RedirectScreen() {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  useEffect(() => {
    const redirectUrl = getRedirectUrlWithToken();
    if (redirectUrl) {
      setUrl(redirectUrl);
    }
  }, []);
  return (
    <>
      <LinearProgress />
      <DialogTitle id="dialog-title">
        {t("oauth2.redirect.dialog.title")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <FormattedMessage
            id="oauth2.redirect.dialog.content"
            values={{
              a: (chunks: any) => <a href={url}>{chunks}</a>,
              url,
            }}
          />
        </Typography>
      </DialogContent>
    </>
  );
}

const RECENT_IMPORTED_FARMS_CUTOFF = 15 * 60 * 1000; // Not showing farms older than 15min

function Oauth2SuccessScreen() {
  const { t } = useTranslation();
  const fieldsCount = useRef<number>(0);
  const farmsCount = useRef<number>(0);
  const location = useLocation();
  const [oauth2provider, setOauth2Provider] = useState<ExternalProvider | null>(
    null
  );

  const [currentFarms, setCurrentFarms] = useState<Farm[]>([]);
  const [farmGuid, setFarmGuid] = useState<string | undefined>();
  useEffect(() => {
    setOauth2Provider(getOauth2ProviderFromUrl());
  }, []);

  useEffect(() => {
    // showing form only when coming from sign up (not login)
    const script = document.createElement("script");

    script.src = "https://webforms.pipedrive.com/f/loader";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [location]);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleRefresh = useCallback(async () => {
    async function refresh() {
      const farms = await dispatch(initializeApp()).then(unwrapResult);

      let importFinished = true; // NB: a hacky way to figure out when the import has finished: when the fields count doesn't change for 5 seconds
      if (farms && farms.length > 0) {
        const recentFarms = farms.filter((farm) => {
          return (
            farm.externalId &&
            farm.externalSyncedOn &&
            Date.now() -
              (new Date(Date.parse(farm.externalSyncedOn)).getTime() || 0) <=
              RECENT_IMPORTED_FARMS_CUTOFF
          );
        });

        if (recentFarms.length > 0) {
          if (recentFarms.length !== farmsCount.current) {
            importFinished = false;
          }

          const newFieldsCount = recentFarms.reduce(
            (sum, farm) => sum + farm.fields.length,
            0
          );

          if (newFieldsCount === 0) {
            importFinished = false;
          }

          if (newFieldsCount !== fieldsCount.current) {
            importFinished = false;
          }

          console.log({
            importFinished,
            farms: recentFarms?.length || 0,
            newFieldsCount,
          });

          if (importFinished) {
            // TODO: check if backend job was fully processed
            await dispatch(initializeFarm(recentFarms[0]));
            window.history.replaceState(null, "", window.location.pathname); // cleanup all query params from url
            setFarmGuid(recentFarms[0].guid);
            return;
          } else {
            fieldsCount.current = newFieldsCount;
            farmsCount.current = recentFarms.length;
            setCurrentFarms(recentFarms);
            setTimeout(refresh, 5000);
            return;
          }
        }
      }
      setTimeout(refresh, 1000);
    }
    await refresh();
  }, []);

  useEffect(() => {
    void handleRefresh();
  }, []);

  const goToApp = () => {
    history.push(`/farm/${farmGuid}`);
  };

  return (
    <>
      {!farmGuid && <LinearProgress />}
      <StyledDialogTitle id="dialog-title">
        {t("oauth2.success.dialog.title")}
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledTypography2 variant="body1">
          {t("oauth2.success.dialog.contentSignup", {
            providerName:
              externalProviderName[oauth2provider || ExternalProvider.AGRIWEBB],
          })}
        </StyledTypography2>

        <div
          className="pipedriveWebForms"
          data-pd-webforms="https://webforms.pipedrive.com/f/2Z7qhEfSZRmUZyQSG8uAynuTskbSUmXQ2HA3SRiiqbYIzdm4c9N5HYQq3YVzH3A1J"
        ></div>

        <StyledDialogActions>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={goToApp}
            loading={!farmGuid}
            loadingPosition="end"
            endIcon={<LoginIcon />}
          >
            {t("signup.button.newSignup")}
          </LoadingButton>
        </StyledDialogActions>
      </StyledDialogContent>
    </>
  );
}

function Oauth2ErrorScreen() {
  const { t } = useTranslation();
  const [oauth2provider, setOauth2Provider] = useState<ExternalProvider | null>(
    null
  );
  const [errorDescription, setErrorDescription] = useState<string | null>("");
  useEffect(() => {
    setOauth2Provider(getOauth2ProviderFromUrl());
    setErrorDescription(getErrorDescriptionFromUrl());
  }, []);

  return (
    <>
      <DialogTitle id="dialog-title">
        {t("oauth2.error.dialog.title")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {oauth2provider && (
            <FormattedMessage
              id="oauth2.error.dialog.content"
              values={{
                a: (chunks: any) => (
                  <a href={externalProviderRetryUrl[oauth2provider]}>
                    {chunks}
                  </a>
                ),
                providerName: externalProviderName[oauth2provider],
                retryUrl: externalProviderRetryUrl[oauth2provider],
              }}
            />
          )}
        </Typography>
        {errorDescription && (
          <Typography variant="body1">{errorDescription}</Typography>
        )}
      </DialogContent>
    </>
  );
}

function getOauth2ProviderFromUrl() {
  const params = new URLSearchParams(window.location.search);
  if (params.has("externalProviderId")) {
    return params
      .get("externalProviderId")!
      .replace(/\//gi, "") as ExternalProvider;
  }
  return null;
}

function getErrorDescriptionFromUrl() {
  const params = new URLSearchParams(window.location.search);

  if (params.has("description")) {
    return params.get("description");
  }
  return null;
}

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
  padding-left: ${theme.spacing(4)};
  padding-top: ${theme.spacing(2.5)};
  max-width: 450px;
`
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => `
  padding: 12px 32px!important;
  max-width: 450px;
`
);

const StyledDialogActions = styled(DialogActions)(
  ({ theme }) => `
  padding-top: ${theme.spacing(2)};
`
);

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`
);

const StyledTypography2 = styled(StyledTypography)(
  ({ theme }) => `
  margin-bottom: 24px;
`
);
