import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

export const BEGINNING_OF_TIME = "1970-01-01T00:00:01.000Z";

export enum GENERAL_FEED_TYPE {
  HAYLAGE = "HAYLAGE",
  SILAGE = "SILAGE",
  GRAIN = "GRAIN",
  CONCENTRATED_FEED = "CONCENTRATED_FEED",
  HAY = "HAY",
}

export enum GENERAL_FEED_UNITS {
  KG = "KG",
  TONNE = "TONNE",
}

export enum PURCHASE_CURRENCY {
  GBP = "GBP",
}
export type FeedStocks = {
  id: number;
  feedStock: FeedStock[];
};

export type FeedStock = {
  type: GENERAL_FEED_TYPE;
  amount: number;
};

export default class FeedStockAPI {
  baseUrl: string;
  entity: string;
  constructor(entity: string) {
    this.baseUrl = `${
      process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"
    }/v1/`;
    this.entity = entity;
  }

  async fetchFeedStock(params: URLSearchParams): Promise<FeedStocks> {
    const res = await fetch(`${this.baseUrl}${this.entity}?${params}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
    });
    return { id: 1, feedStock: await res.json() } as FeedStocks;
  }
}

const feedStockApi = new FeedStockAPI("feedStock");

export const getFeedStock = createAsyncThunk<
  Promise<FeedStocks>,
  { farmGuid: string; timeAfter: string }
>("feedStock/get", async ({ farmGuid, timeAfter }, { dispatch }) => {
  const feedStocks: FeedStocks = await feedStockApi.fetchFeedStock(
    new URLSearchParams({ farmGuid, timeAfter })
  );
  return feedStocks;
});

export const feedStockAdapter = createEntityAdapter<FeedStocks>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (feedStocks) => 1,
});

export const { selectById: selectFeedStockById } =
  feedStockAdapter.getSelectors((state: RootState) => state.feedStock);

const initialFeedStockState: EntityState<FeedStocks> =
  feedStockAdapter.getInitialState({});

const feedStockSlice = createSlice({
  name: "feedStock",
  initialState: initialFeedStockState,
  reducers: { resetFeedStock: () => initialFeedStockState },
  extraReducers: {
    [getFeedStock.fulfilled.type]: (state, action) => {
      feedStockAdapter.upsertOne(state, action.payload);
    },
  },
});
export const { resetFeedStock } = feedStockSlice.actions;
export const feedStockReducer = feedStockSlice.reducer;
