import { useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Container,
  Divider,
  LinearProgress,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import {
  useAppSelector,
  GeneralFeedEvent,
  GeneralFeed,
  GENERAL_FEED_TYPE,
  GENERAL_FEED_UNITS,
  selectAllAnimalGroups,
  AnimalGroup,
  FeedStocks,
  selectFeedStockById,
} from "model";
import { useTranslation } from "common/locales";
import { useSelector } from "react-redux";
import { FieldArray, Form, Formik, FormikErrors } from "formik";

import { cloneDeep } from "lodash";
import * as Yup from "yup";
import EnterFeedEventFeedDetails from "./EnterFeedEventFeedDetails";
import NameAndGuidMultiSelectDropdown from "features/general/NameAndGuidMultiSelectDropdown";
import EnterDate from "features/general/EnterDate";
import { mixpanel } from "common/analytics/mixpanel";
import NotEnoughFeedDialog, { sumFeedForType } from "./NotEnoughFeedDialog";
import ConfirmCancellationDialog from "features/general/ConfirmCancellationDialog";
import { ButtonContainer, ButtonGroup } from "./generalMethods";

interface Props {
  feedEvent: GeneralFeedEvent | undefined;
  handleSave: (gemeralFeedEvent: GeneralFeedEvent) => void;
  handleClose: () => void;
}

export default function EnterFeedEvent({
  feedEvent,
  handleSave,
  handleClose,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [notEnoughFeedDialogOpen, setNotEnoughFeedDialogOpen] = useState(false);
  const loading = useAppSelector((state) => state.animalGroups.loading);

  const allAnimalGroups: AnimalGroup[] = useSelector(selectAllAnimalGroups);

  const feedStocks: FeedStocks | undefined = useAppSelector((state) =>
    selectFeedStockById(state, 1)
  );

  const checkEnoughFeedAvailable = (
    feeds: GeneralFeed[] | undefined,
    feedStocks: FeedStocks | undefined,
    oldFeeds: GeneralFeed[] | undefined
  ) => {
    for (const feedType of Object.values(GENERAL_FEED_TYPE)) {
      const feedTypeEnum = GENERAL_FEED_TYPE[feedType];
      // sum the new values for this feed Type
      const sumAmountNew = sumFeedForType(feeds, feedTypeEnum);
      // in case this is an update we need to make sure we subtract the original value from the available feed
      const sumAmountOld = sumFeedForType(oldFeeds, feedTypeEnum);
      const availableStock = feedStocks?.feedStock.filter(
        (feedStock) => feedStock.type === feedTypeEnum
      );

      const availableStockAmount = availableStock
        ? availableStock?.length === 0
          ? 0
          : availableStock[0].amount
        : 0;
      if (sumAmountNew - sumAmountOld > availableStockAmount) {
        setNotEnoughFeedDialogOpen(true);
        return false;
      }
    }
    return true;
  };

  const validNumber = (min = 0) =>
    Yup.number()
      .typeError(t("edit.minNumber.error", { min }))
      .moreThan(min, t("edit.minNumber.error", { min }))
      .required(t("edit.required.error"));

  const FeedEventSchema = Yup.object().shape({
    note: Yup.string().optional(),
    animalGroupGuids: Yup.array().of(Yup.string()).required().min(1),
    feedEventDate: Yup.date(),
    feed: Yup.array()
      .of(
        Yup.object().shape({
          amount: validNumber(0).required(),
          feedType: Yup.mixed<GENERAL_FEED_TYPE>()
            .oneOf(Object.values(GENERAL_FEED_TYPE))
            .required(),
          unitType: Yup.mixed<GENERAL_FEED_UNITS>()
            .oneOf(Object.values(GENERAL_FEED_UNITS))
            .required(),
        })
      )
      .test(
        "sum-lte-stock",
        "The feed must be less that or equal available stock",
        (feeds: GeneralFeed[] | undefined) => {
          return checkEnoughFeedAvailable(feeds, feedStocks, feedEvent?.feed);
        }
      )
      .required()
      .min(1),
  });

  const newFeed = {
    amount: "",
  };

  if (loading) {
    return <LinearProgress />;
  }

  const initialFeedEvent = feedEvent ?? {
    note: "",
    animalGroupGuids: [],
    feedEventDate: new Date(),
    feed: [
      {
        amount: "" as unknown as number,
        unitType: "" as GENERAL_FEED_UNITS,
        feedType: "" as GENERAL_FEED_TYPE,
      },
    ],
  };
  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={feedEvent ?? initialFeedEvent}
        validationSchema={FeedEventSchema}
        validateOnChange={false} // disable validation every field change, otherwise would validate all fields
        validateOnBlur={false} // disable validation every field blur, otherwise would validate all fields
        onSubmit={(values) => {
          const enoughFeedAvailable = checkEnoughFeedAvailable(
            values.feed,
            feedStocks,
            feedEvent?.feed
          );
          if (!enoughFeedAvailable) {
            return;
          } else {
            void handleSave({
              ...values,
              feedEventDate: new Date(values.feedEventDate).toISOString(),
            } as GeneralFeedEvent);
          }
        }}
      >
        {({ values, handleChange, errors, validateField, setFieldValue }) => {
          const handleInputChange = async (e: any, fieldName: string) => {
            await (handleChange(e) as any); // async, typings are wrong
            await (validateField(fieldName) as any); // validate only changed field, async, typings are wrong
          };

          return (
            <>
              <Container style={{ textAlign: "left", marginBottom: 40 }}>
                <Typography variant="h6">
                  {t("feedEventInput.title")}
                </Typography>
              </Container>

              <Container style={{ textAlign: "left", marginBottom: 40 }}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <EnterDate
                      disabled={false}
                      date={values.feedEventDate as Date}
                      setDate={async (date: Date | null) => {
                        await setFieldValue("feedEventDate", date, false);
                      }}
                      dateError={!!errors.feedEventDate}
                      name={"feedEventDate"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NameAndGuidMultiSelectDropdown
                      required={true}
                      selectedItems={allAnimalGroups
                        .filter((animalGroup: AnimalGroup) => {
                          return (values.animalGroupGuids as string[]).includes(
                            animalGroup.guid
                          );
                        })
                        .map((animalGroup) => {
                          return {
                            guid: animalGroup.guid,
                            name: animalGroup.name,
                          };
                        })}
                      items={allAnimalGroups.map((animalGroup: AnimalGroup) => {
                        return {
                          name: animalGroup.name,
                          guid: animalGroup.guid,
                        };
                      })}
                      disabled={false}
                      onChange={(e) => handleInputChange(e, "animalGroupGuids")}
                      selectInputError={!!errors.animalGroupGuids}
                      label={t("feedEventInput.AnimalGroups.text")}
                      name={"animalGroupGuids"}
                    />
                  </Grid>
                </Grid>
              </Container>

              <Container style={{ textAlign: "left", marginBottom: 40 }}>
                <Form id="feed-event-input-form">
                  <FieldArray name="feed">
                    {({ remove, push }) => (
                      <div key={"lsls"}>
                        {values.feed.length > 0 &&
                          values.feed.map((food, index) => (
                            <div key={`index - ${index} `}>
                              <EnterFeedEventFeedDetails
                                handleInputChange={handleInputChange}
                                index={index}
                                errors={
                                  errors?.feed?.[index] as
                                    | FormikErrors<GeneralFeed>
                                    | undefined
                                }
                                singleFeed={food}
                                remove={() => remove(index)}
                              />
                            </div>
                          ))}

                        <Button
                          onClick={() => push(cloneDeep(newFeed))}
                          sx={{ background: "#ffffff", color: "#5E5E5E" }}
                        >
                          <Typography>
                            {" "}
                            + {t("feedEventInput.addFeed")}
                          </Typography>
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Form>
              </Container>
              <Container style={{ textAlign: "center", marginBottom: 40 }}>
                <Grid container>
                  <Grid item xs={6} sm={6} display="flex" alignItems="baseline">
                    <Field
                      id="feed-event-input-note"
                      label={t("reseedEvent.notes.label")}
                      autoFocus
                      value={values.note}
                      helperText={errors.note}
                      error={!!errors.note}
                      fullWidth
                      onChange={(e) => handleInputChange(e, "note")}
                      name={"note"}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Container>
              <NotEnoughFeedDialog
                feeds={values.feed}
                oldFeeds={feedEvent?.feed}
                feedStocks={feedStocks}
                open={notEnoughFeedDialogOpen}
                setClose={() => setNotEnoughFeedDialogOpen(false)}
              />
            </>
          );
        }}
      </Formik>
      <Divider />
      <ButtonContainer>
        <ButtonGroup>
          <Button color="secondary" onClick={handleCancel}>
            {t("edit.button.cancel")}
          </Button>
          <Button color="primary" type="submit" form="feed-event-input-form">
            {t("edit.button.save")}
          </Button>
        </ButtonGroup>
        <ConfirmCancellationDialog
          dialogOpen={open}
          closeDialog={() => setOpen(false)}
          onConfirmCancel={() => handleConfirmationClose()}
          onCancelCancel={() => setOpen(false)}
        />
      </ButtonContainer>
    </>
  );

  function handleConfirmationClose() {
    mixpanel.track("FeedEvent creation cancelled");
    handleClose();
  }

  function handleCancel() {
    setOpen(true);
  }
}

const Field = styled(TextField)(
  ({ theme }) => css`
      min - width: 300px;
      padding - bottom: ${theme.spacing(2)};
      `
);
