import { useState } from "react";
import {
  ArableField,
  DiaryEntry,
  FarmerImageUpload,
  Field,
  selectDiaryEntryEventsOnField,
  selectFarmerImageUploadById,
  useAppSelector,
} from "model";
import { Marker } from "react-map-gl";
import { center } from "@turf/turf";
import DiaryEntryDialogWithScroll from "features/fieldEventList/DiaryEntryDialogWithScroll";
import { DiaryEntryImage } from "features/fieldEventList/DiaryEntryImage";
import DiaryEntryDialog from "features/fieldEventList/DiaryEntryDialog";

interface DiaryEntryLocationMarkerProps {
  zoom: number;
  currentField: Field | ArableField;
  setTooltipOverMarker: (value: boolean) => void;
}

export default function DiaryEntryLocationMarkerWithScroll({
  zoom,
  currentField,
  setTooltipOverMarker,
}: DiaryEntryLocationMarkerProps) {
  let size = 50; // non-standard size
  let fontSize: "medium" | "small" = "medium";
  if (zoom > 15) {
    fontSize = "small";
    size = 45;
  }
  const { coordinates } = center(currentField.geom).geometry;
  const [scrollDialogOpen, setScrollDialogOpen] = useState(false);
  const [openDiaryEntryDialogGuid, setOpenDiaryEntryDialogGuid] = useState<
    string | null
  >(null);
  const [border, setBorder] = useState<string>("0px solid #021a40");

  const diaryEntriesForField: DiaryEntry[] = useAppSelector((state) =>
    selectDiaryEntryEventsOnField(state, currentField.guid)
  );

  const farmerImageUpload: FarmerImageUpload | undefined = useAppSelector(
    (state) =>
      selectFarmerImageUploadById(
        state,
        diaryEntriesForField.length > 0
          ? diaryEntriesForField[0].farmerImageUploadId
          : ""
      )
  );

  if (diaryEntriesForField.length === 0) {
    return <div key={`location-div-${currentField.guid}`} />;
  }

  const onImageClick =
    diaryEntriesForField.length === 1
      ? () => {
          setOpenDiaryEntryDialogGuid(diaryEntriesForField[0].guid);
        }
      : () => {
          setScrollDialogOpen(true);
        };

  const onMouseOver = () => {
    setBorder("2px solid #021a40");
    setTooltipOverMarker(true);
  };

  const onMouseOut = () => {
    setBorder("0px solid #021a40");
    setTooltipOverMarker(false);
  };

  let dialog;

  if (diaryEntriesForField.length === 1) {
    dialog = (
      <DiaryEntryDialog
        diaryEntry={diaryEntriesForField[0]}
        openDiaryEntryDialogGuid={openDiaryEntryDialogGuid}
        setOpenDiaryEntryDialogGuid={setOpenDiaryEntryDialogGuid}
      />
    );
  } else {
    dialog = (
      <>
        <DiaryEntryDialogWithScroll
          dialogKey={`DiaryEntryDialogWithScroll-${currentField.guid}`}
          diaryEntries={diaryEntriesForField}
          scrollDialogOpen={scrollDialogOpen}
          setScrollDialogOpen={setScrollDialogOpen}
          setOpenDiaryEntryDialogGuid={setOpenDiaryEntryDialogGuid}
        />
        {diaryEntriesForField.map((diaryEntry) => (
          <div key={`DiaryEntryDialog-outer-div-${diaryEntry.guid}`}>
            <DiaryEntryDialog
              diaryEntry={diaryEntry}
              openDiaryEntryDialogGuid={openDiaryEntryDialogGuid}
              setOpenDiaryEntryDialogGuid={setOpenDiaryEntryDialogGuid}
            />
          </div>
        ))}
      </>
    );
  }

  return (
    <div key={`location-div-${currentField.guid}`}>
      {dialog}
      <Marker
        key={`marker-${currentField.guid}`}
        offsetTop={-size / 2}
        offsetLeft={-size / 2}
        longitude={coordinates[0]}
        latitude={coordinates[1]}
      >
        <DiaryEntryImage
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          border={border}
          key={`image-${currentField.guid}`}
          farmerImageUpload={farmerImageUpload}
          style={{
            borderRadius: "100%",
            objectFit: "cover",
            width: `${size}px`,
            height: `${size}px`,
            cursor: "pointer",
          }}
          onImageClick={onImageClick}
        />
      </Marker>
    </div>
  );
}
