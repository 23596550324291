import { Menu, MenuItem, PopoverPosition } from "@mui/material";
import { useTranslation } from "common/locales";
import { EventEntryDialog } from "features/singleCoverInput";
import {
  deleteOneReading,
  Field,
  ManualDMInputResponse,
  useAppDispatch,
} from "model";
import { mixpanel } from "common/analytics";
import { useNotification } from "app/NotificationProvider";

interface Props {
  field: Field;
  manualInput: ManualDMInputResponse;
  anchor?: null | HTMLElement;
  anchorPosition?: PopoverPosition;
  close: () => void;
}
export default function FieldEventMoreMenu({
  field,
  manualInput,
  anchor,
  anchorPosition,
  close,
}: Props) {
  const { t } = useTranslation();
  const { notify } = useNotification();
  const dispatch = useAppDispatch();
  const isMachineReading = manualInput.manualDryMatterInput.kind === "machine";

  return (
    <Menu
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      anchorReference={
        anchor ? "anchorEl" : anchorPosition ? "anchorPosition" : "none"
      }
      open={anchor ? anchor.id === manualInput.guid : false}
      onClose={close}
      key={`fe-menu-${manualInput.guid}`}
    >
      <MenuItem key={`fe-menuitem-edit-${manualInput.guid}`}>
        <EventEntryDialog
          field={field}
          manualInput={manualInput}
          key={`grazing${manualInput.guid}`}
        />
      </MenuItem>
      <MenuItem
        key={`fe-menuitem-delete-${manualInput.guid}`}
        onClick={() => handleReadingDelete()}
        disabled={isMachineReading}
      >
        {t("fieldevents.menu.delete")}
      </MenuItem>
    </Menu>
  );
  async function handleReadingDelete() {
    await dispatch(deleteOneReading(manualInput.guid));
    notify(t("fieldevents.notify.deleted"));
    mixpanel.track("Single Event deleted");
  }
}
