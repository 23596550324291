import {
  useMediaQuery,
  useTheme,
  Button,
  Container,
  Dialog,
  Theme,
  Typography,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "common/locales";
import {
  useAppDispatch,
  closeAfterFieldCreatedDialog,
  useAppSelector,
  getFieldCount,
  setIsEditing,
} from "model";
import styled from "styled-components";
import { mixpanel } from "common/analytics";

import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
  },
}));

export function AfterFieldCreatedDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const open = useAppSelector((state) => state.app.fieldCreatedDialogOpen);
  const fieldCount = useAppSelector(getFieldCount);
  const history = useHistory();

  const handleClose = () => {
    if (open) {
      dispatch(closeAfterFieldCreatedDialog());
      mixpanel.track("AfterFieldCreatedDialog after 1st field closed");
    }
  };

  const handleClickDialog = () => isMobile && handleClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen={isMobile}
      classes={{ paper: classes.paper }}
      onClick={handleClickDialog}
    >
      <DescriptionTextContainer>
        <>
          {fieldCount == 1 && (
            <HeadlineBox>
              <Headline variant="h6">
                {t("after_first_field_created.field.dialog.title")}
              </Headline>
            </HeadlineBox>
          )}
        </>
        <Typography gutterBottom={true}>
          <>
            {fieldCount == 1 && (
              <FormattedMessage id="after_first_field_created.field.dialog.text" />
            )}
            {fieldCount > 1 && (
              <FormattedMessage id="after_field_created.field.dialog.text" />
            )}
          </>
        </Typography>
      </DescriptionTextContainer>

      <StyledRowBox textAlign="center">
        <CTAButton
          onClick={handleAddFieldClick}
          color="primary"
          variant="contained"
        >
          {t("after_field_created.field.dialog.anotherField")}
        </CTAButton>
        <CTAButton
          onClick={handleAddAnimalGroupClick}
          color="primary"
          variant="contained"
        >
          {t("after_field_created.field.dialog.addAnimalGroup")}
        </CTAButton>
        <CTAButton onClick={handleClose} color="secondary" variant="contained">
          {t("after_field_created.field.dialog.close")}
        </CTAButton>
      </StyledRowBox>
    </Dialog>
  );
  function handleAddFieldClick() {
    handleClose();
    dispatch(setIsEditing());
    mixpanel.track("FieldAdd started");
    history.push(`/add-field/enter-details`);
  }
  function handleAddAnimalGroupClick() {
    handleClose();
    mixpanel.track("AnimalGroupAdd started");
    history.push(`/add-animal-group`);
  }
}

const Headline = styled(Typography)(
  ({ theme }) => `
  margin-left: 0;
  font-weight: 700;
`
);

const CTAButton = styled(Button)`
  text-transform: none;
  font-weight: 700;
  display: inline;
`;

const HeadlineBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
`
);

const StyledRowBox = styled(Box)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(4)};
  display: flex;
  flex-direction: row;
  align-self: center;
  column-gap: ${theme.spacing(2)}
`
);

const DescriptionTextContainer = styled(Container)(
  ({ theme }) => `
  padding: ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(
    2
  )} ${theme.spacing(4)};
  max-width: 450px;
`
);

export default AfterFieldCreatedDialog;
