import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTranslation } from "common/locales";
import { useHistory } from "react-router-dom";
import { mixpanel } from "common/analytics";
import {
  getAllFieldGeoms,
  initializeApp,
  initializeFarm,
  selectCurrentFarm,
  setZoomToGeom,
  useAppDispatch,
  useAppSelector,
} from "model";
import FarmSwitch from "features/farmOverview/FarmSwitch";
import { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { InitialScreenSelectLogo } from "common/icons";
import Typography from "@mui/material/Typography";

const InitialSelectScreen = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentFarm = useAppSelector(selectCurrentFarm);
  const currentFarmId = currentFarm?.guid;
  const fieldGeoms = useAppSelector(getAllFieldGeoms);

  useEffect(() => {
    if (!currentFarm) {
      const initApp = async () => {
        const farms = await dispatch(initializeApp()).then(unwrapResult);
        if (farms && farms.length > 0) {
          const defaultFarm = farms[0];
          await dispatch(initializeFarm(defaultFarm));
        }
      };
      void initApp();
    }
    if (fieldGeoms.features.length > 0) {
      dispatch(setZoomToGeom(fieldGeoms));
    } else {
      if (currentFarm?.geom) {
        dispatch(setZoomToGeom(currentFarm.geom));
      }
    }
    // we need to disable lint here because we cannot have dependency on an object - it would keep zooming otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFarm?.guid, dispatch]);

  return (
    <>
      <Container>
        <List>
          <ListItem
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InitialScreenSelectLogo />
          </ListItem>
          <ListItem>
            <ListItemText
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                justifySelf: "stretch",
                marginBottom: "80px",
              }}
            >
              <FarmSwitch
                switchToFarmView={false}
                fontVariant={"h5"}
                fontColor={"white"}
              />
            </ListItemText>
          </ListItem>

          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            flexDirection={"column"}
            sx={{
              display: "inline-flex",
            }}
          >
            <Grid item xs={12} sm={12} sx={{ display: "inline-flex" }}>
              <Button
                variant="text"
                sx={{
                  bgcolor: "#AFE57F",
                  whiteSpace: "normal",
                  justifySelf: "stretch",
                  flexGrow: 1,
                  color: "#1C4321",
                  boxSizing: "content-box",
                  height: "50px",
                  "&:hover": {
                    backgroundColor: "#C5E97D",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  mixpanel.track("Go to farm view clicked");
                  history.push(`/farm/${currentFarmId || ""}`);
                }}
              >
                <Typography variant="h6">
                  {t("go.to.landmanagement.and.grazing.button")}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: "inline-flex" }}>
              <Button
                variant="text"
                sx={{
                  bgcolor: "#AFE57F",
                  color: "#1C4321",
                  whiteSpace: "normal",
                  justifySelf: "stretch",
                  flexGrow: 1,
                  boxSizing: "content-box",
                  height: "50px",
                  "&:hover": {
                    backgroundColor: "#C5E97D",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  mixpanel.track("Go to inputs and outputs clicked");
                  history.push(`/inputs-and-outputs`);
                }}
              >
                <Typography variant="h6">
                  {t("go.to.inputs.and.outputs")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </List>
      </Container>
    </>
  );
};

export default InitialSelectScreen;
