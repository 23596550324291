import { Grid, TextField, SelectChangeEvent, Button } from "@mui/material";
import { useTranslation } from "common/locales";
import { FormikErrors } from "formik";
import { GENERAL_FEED_TYPE, GeneralFeed } from "model";
import FeedTypeDropdown from "./FeedTypeDropdown";
import FeedUnitDropdown from "./FeedUnitDropdown";
import React from "react";
import { ButtonContainer } from "./generalMethods";

interface Props {
  index: number;
  singleFeed: GeneralFeed;
  handleInputChange: (event: any, name: string) => void;
  errors?: FormikErrors<GeneralFeed>;
  remove: (index: number) => void;
}

export default function EnterFeedEventFeedDetails({
  index,
  singleFeed,
  handleInputChange,
  errors,
  remove,
}: Props) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      flexDirection={"row"}
      sx={{
        justifyItems: "baseline",
        justifySelf: "baseline",
        alignSelf: "baseline",
      }}
      display="flex"
      alignItems="baseline"
    >
      <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
        <FeedTypeDropdown
          disabled={false}
          onChange={(event: SelectChangeEvent<GENERAL_FEED_TYPE>) => {
            handleInputChange(event, `feed[${index}].feedType`);
          }}
          currentFeedType={singleFeed.feedType}
          feedTypeInputError={errors?.feedType ? true : false}
          label={"feedPurchaseEvent.feedType.text"}
          name={`feed[${index}].feedType`}
        />
      </Grid>
      <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
        <FeedUnitDropdown
          disabled={false}
          onChange={(event) => {
            handleInputChange(event, `feed[${index}].unitType`);
          }}
          currentUnit={singleFeed.unitType}
          feedUnitInputError={errors?.unitType ? true : false}
          label={"feedPurchaseEvent.feedUnit.text"}
          name={`feed[${index}].unitType`}
        />
      </Grid>
      <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
        <TextField
          required
          value={singleFeed.amount}
          type="number"
          variant="standard"
          disabled={false}
          id="feedAmount"
          error={errors?.amount ? true : false}
          onChange={(event) => {
            handleInputChange(event, `feed[${index}].amount`);
          }}
          label={t("feedPurchaseEvent.feedAmount.label")}
          maxRows={1}
          name={`feed[${index}].amount`}
        />
      </Grid>
      {index > 0 && (
        <Grid item xs={12} sm={3} display="flex" alignItems="baseline">
          <ButtonContainer>
            <Button color="secondary" onClick={() => remove(index)}>
              {t("feedEventInput.removeFeed")}
            </Button>
          </ButtonContainer>
        </Grid>
      )}
    </Grid>
  );
}
