type Item = {
  guid: string;
};

export default class API<T extends Item> {
  baseUrl: string;
  entity: string;
  constructor(entity: string, version?: string) {
    this.baseUrl = `${
      process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"
    }/${version ?? "v1"}/`;
    this.entity = entity;
  }

  async getIds(params?: URLSearchParams): Promise<Array<string>> {
    const res = await fetch(`${this.baseUrl}${this.entity}?${params}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
    });

    return (await res.json()) as Array<string>;
  }

  async getOne(id: string): Promise<T> {
    const res = await fetch(`${this.baseUrl}${this.entity}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
    });
    return (await res.json()) as T;
  }

  getMany(ids: Array<string>): Promise<Array<T>> {
    const results = ids.map((id) => this.getOne(id));
    return Promise.all(results);
  }

  async updateOne(item: T): Promise<Item> {
    const { guid, ...payload } = item; // removing guid from stock class, not required when updating
    const res = await fetch(`${this.baseUrl}${this.entity}/${item.guid}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    return (await res.json()) as Item;
  }
  async createOne(item: T): Promise<Item> {
    const { guid, ...payload } = item; // removing guid from stock class, not required when updating
    const res = await fetch(`${this.baseUrl}${this.entity}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    return (await res.json()) as Item;
  }
  async deleteOne(id: string) {
    const res = await fetch(`${this.baseUrl}${this.entity}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  }
}

const baseUrl = `${
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"
}/v1/`;

export const fetchApi = async (relativeUrl: string, init: RequestInit) => {
  const res = await fetch(`${baseUrl}${relativeUrl}`, {
    ...init,
    headers: {
      ...init.headers,
      Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
      "Content-Type": "application/json",
    },
  });

  return res.json();
};
