import { Dispatch, SetStateAction, useState } from "react";
import { Container, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Field,
  GENERAL_FEED_TYPE,
  GENERAL_FEED_UNITS,
  GeneralFeedPurchaseEvent,
  PURCHASE_CURRENCY,
  RootState,
  getAllReseedEventsForFarm,
  updateFeedPurchaseEvent,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { useIntl } from "react-intl";
import EnterFeedPurchaseEvent from "./EnterFeedPurchaseEvent";
import { checkConsistency } from "./FeedPurchaseEvents";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function UpdateFeedPurchaseEvent({
  feedPurchaseEvent,
  dialogOpen,
  setDialogOpen,
}: {
  feedPurchaseEvent: GeneralFeedPurchaseEvent;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  const handleClose = () => {
    setDialogOpen(false);
    mixpanel.track("Update feed purchase event cancelled");
  };

  const [feedType, setFeedType] = useState<GENERAL_FEED_TYPE | undefined>(
    feedPurchaseEvent.feedPurchases[0].feedType
  );

  const [feedTypeError, setFeedTypeError] = useState<boolean>(false);

  const [feedUnit, setFeedUnit] = useState<GENERAL_FEED_UNITS | undefined>(
    feedPurchaseEvent.feedPurchases[0].unitType
  );

  const [feedUnitError, setFeedUnitError] = useState<boolean>(false);

  const [notes, setNotes] = useState<string | undefined>(
    feedPurchaseEvent.note
  );

  const [cost, setCost] = useState<string | undefined>(
    feedPurchaseEvent.feedPurchases[0].cost?.toString()
  );
  const [costError, setCostError] = useState<boolean>(false);
  const [feedAmount, setFeedAmount] = useState<string | undefined>(
    feedPurchaseEvent.feedPurchases[0].amount?.toString()
  );
  const [feedAmountError, setFeedAmountError] = useState<boolean>(false);

  const [reseedDate, setReseedDate] = useState<Date | null>(
    new Date(feedPurchaseEvent.feedPurchaseEventDate)
  );

  const [reseedDateError, setReseedDateError] = useState<boolean>(false);

  async function callUpdate() {
    return await dispatch(
      updateFeedPurchaseEvent({
        farmGuid: currentFarmId ?? "",
        guid: feedPurchaseEvent.guid ?? "",
        note: notes ?? "",
        feedPurchaseEventDate: reseedDate?.toISOString() ?? "",
        feedPurchases: [
          {
            costCurrency:
              cost === undefined ? undefined : PURCHASE_CURRENCY.GBP,
            cost: Number(cost),
            unitType: feedUnit ?? GENERAL_FEED_UNITS.TONNE,
            feedType: feedType ?? GENERAL_FEED_TYPE.CONCENTRATED_FEED,
            amount: Number(feedAmount),
          },
        ],
      })
    );
  }

  async function handleSave() {
    const consistent = checkConsistency(
      feedType,
      setFeedTypeError,
      feedUnit,
      setFeedUnitError,
      reseedDate,
      setReseedDateError,
      reseedDateError,
      feedAmount,
      setFeedAmountError,
      cost,
      setCostError
    );
    // check for wrong input
    if (!consistent) {
      return;
    }

    await callUpdate();
    notify(t("updateFeedPurchase.success"));
    mixpanel.track("Update feed purchase event successful");
    handleClose();
  }
  const { formatMessage: f } = useIntl();

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={dialogOpen}
      onClose={handleClose}
    >
      <StyledContainer>
        <EnterFeedPurchaseEvent
          feedType={feedType}
          cost={cost}
          setCost={setCost}
          setFeedType={setFeedType}
          handleSave={handleSave}
          setNotes={setNotes}
          note={notes}
          date={reseedDate}
          setDate={setReseedDate}
          feedTypeError={feedTypeError}
          dateError={reseedDateError}
          setFeedTypeError={setFeedTypeError}
          setFeedUnit={setFeedUnit}
          feedUnitError={feedUnitError}
          setFeedUnitError={setFeedUnitError}
          feedUnit={feedUnit}
          feedAmount={feedAmount}
          setFeedAmount={setFeedAmount}
          feedAmountError={feedAmountError}
          setFeedAmountError={setFeedAmountError}
          costError={costError}
          setCostError={setCostError}
          handleClose={() => {
            handleClose();
          }}
        />
      </StyledContainer>
    </Dialog>
  );
}
