import { Grid } from "@mui/material";
import styled from "styled-components";

export const MobileBottomDrawer = styled(Grid)`
  position: fixed;
  bottom: 0;
  width: 100vw;
  justify-content: center;
  display: flex;
  background-color: white;
`;

export const MobileTopHintContainer = styled(Grid)`
  position: fixed;
  top: 0;
  width: 100vw;
  justify-content: center;
  display: flex;
  background-color: white;
`;

export const VerticalCenterContainer = styled.div`
  position: relative;
  top: 50%;
`;

export const MobileButtonGroup = styled.div(
  ({ theme }) => `
  position: relative;
  right: ${theme.spacing(1)}; // NB: different from desktop
  padding-top: ${theme.spacing(1)}; // NB: different from desktop
  padding-bottom: ${theme.spacing(1)}; // NB: different from desktop
`
);
