import {
  CROP_UNITS,
  GENERAL_FEED_UNITS,
  GRASS_UNITS,
  MEAT_UNITS,
  MILK_UNITS,
} from "model";
import { Container } from "@mui/material";
import styled, { css } from "styled-components";

export const convertToYieldAmountString = (
  amount: number,
  locale: Intl.LocalesArgument,
  unit: MILK_UNITS | GRASS_UNITS | MEAT_UNITS | CROP_UNITS,
  t: (a: string) => string
) => {
  let unitType = unit;

  if (amount > 1000 && unit === CROP_UNITS.KG) {
    amount = amount / 1000;
    unitType = "TONNE" as CROP_UNITS;
  } else if (amount > 1000 && unit === MILK_UNITS.LITRE) {
    amount = amount / 1000;
    unitType = "M3" as MILK_UNITS;
  }
  return (
    amount.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }) +
    " " +
    t(`yieldEvent.unitType.option.${unitType}`)
  );
};

export const ButtonGroup = styled.div(
  ({ theme }) => css`
      position: absolute;
      right: ${theme.spacing(2)};
      padding - top: ${theme.spacing(2)};
      padding - bottom: ${theme.spacing(2)};
      `
);

export const ButtonContainer = styled(Container)`
  position: relative;
  flex: 0 0 auto;
  display: block;
  height: 70px;
  width: 100 %;
  background: white;
`;
