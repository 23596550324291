import { useEffect, useState } from "react";
import { default as decode } from "jwt-decode";
import { JWT, TokenResponse } from "model";

export function useHtmlElementSize(container?: HTMLElement | null) {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (container) {
        // On some browsers (e.g. Chrome 100+ on Pixel 6) the dimensions
        // can be fractional. We never want that behavior to begin with!
        setHeight(Math.round(container.offsetHeight));
        setWidth(Math.round(container.offsetWidth));
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [container]);

  return [height, width];
}

let redirectTimeout: ReturnType<typeof setTimeout> | undefined;

export function useTokenRefresh() {
  useEffect(() => {
    const refreshToken = async () => {
      const token = window.localStorage.getItem("jwt");
      if (token) {
        const jwtDecoded = decode<JWT>(token);
        const now = Date.now() / 1000;
        const exp = jwtDecoded.exp;
        const dt = exp - now;

        // only refresh if more than 10s and less than 30min are left in session
        if (dt < 30 * 60 && dt > 10) {
          const response = await fetch(
            `${
              process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"
            }/v1/tokens/`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          const newToken = ((await response.json()) as TokenResponse).token;
          if (newToken) {
            window.localStorage.setItem("jwt", newToken);
          }
        }

        // TODO: there might be a case when user opened the app with valid jwt token, was redirected to oauth2 api and the jwt token expired. Then user would be redirected back to the login screen
      }

      setTimeout(refreshToken, 60000);
    };
    void refreshToken();
  });
}

export function getRedirectUrlWithToken(): string | null {
  const params = new URLSearchParams(window.location.search);
  if (params.has("redirect")) {
    const redirect = params.get("redirect");
    const token = window.localStorage.getItem("jwt");
    if (redirect) {
      return injectTokenInUrl(redirect, token)?.replace(/\/$/, ""); // inject token if we have to and remove trailing slash
    }
  }
  return null;
}

export function injectTokenInUrl(url: string, token: string | null): string {
  const redirectUrl = new URL(url);
  const allowedHosts = ["localhost", "api.ruumi.io", "api.ruumi.rocks"];
  if (allowedHosts.includes(redirectUrl.hostname) && token) {
    redirectUrl.searchParams.delete("grazing_app_token"); // remove previous token if it's there for whatever reason
    redirectUrl.searchParams.append("grazing_app_token", token);
  }
  return redirectUrl.toString();
}

export function redirect() {
  const redirectUrl = getRedirectUrlWithToken();
  if (redirectUrl && !redirectTimeout) {
    redirectTimeout = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 1500);
  }
}
