import { useHistory } from "react-router-dom";
import { Container, Dialog } from "@mui/material";

import styled from "styled-components";
import EnterFeedEvent from "./EnterFeedEvent";
import {
  GeneralFeedEvent,
  createFeedEvent,
  selectCurrentFarm,
  useAppDispatch,
  useAppSelector,
} from "model";
import { useNotification } from "app/NotificationProvider";
import { mixpanel } from "common/analytics/mixpanel";
import { useTranslation } from "common/locales";

export const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function FeedEvents() {
  const dispatch = useAppDispatch();
  const currentFarm = useAppSelector(selectCurrentFarm);
  const { notify } = useNotification();
  const history = useHistory();
  const handleClose = () => {
    history.goBack();
  };
  const { t } = useTranslation();

  async function handleSave(generalFeedEvent: GeneralFeedEvent) {
    await dispatch(
      createFeedEvent({
        generalFeedEvent,
        farmGuid: currentFarm?.guid ?? "",
      })
    );
    notify(t("addFeedEvent.add.success"));
    mixpanel.track("FeedEvent created");

    handleClose();
  }
  return (
    <Dialog fullScreen disableEscapeKeyDown open onClose={handleClose}>
      <StyledContainer>
        <EnterFeedEvent
          feedEvent={undefined}
          handleSave={handleSave}
          handleClose={() => {
            handleClose();
          }}
        />
      </StyledContainer>
    </Dialog>
  );
}
