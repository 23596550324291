import { GeneralFeedEvent } from "model";
import { useState } from "react";
import FeedEventMoreMenu from "./FeedEventMoreMenu";
import UpdateFeedEvent from "./UpdateFeedEvent";
export default function UpdateFeedEventDialog({
  feedEvent,
  menuAnchor,
  closeMenu,
}: {
  feedEvent: GeneralFeedEvent;
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!feedEvent) {
    return null;
  }
  return (
    <>
      <FeedEventMoreMenu
        feedEvent={feedEvent}
        close={closeMenu}
        anchor={menuAnchor}
        setDialogOpen={setDialogOpen}
      />
      <UpdateFeedEvent
        feedEvent={feedEvent}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
}
