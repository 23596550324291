import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { AlertProps } from "@mui/lab";
import { createContext, useContext, useMemo, useState } from "react";

type ContextProps = {
  notify: (message: string, severity?: AlertProps["severity"]) => void;
};

const NotificationContext = createContext<ContextProps>({
  notify: () => undefined,
});
const { Provider } = NotificationContext;

export default function NotificationProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [notification, setNotification] = useState<Notification | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const value = useMemo(() => ({ notify }), []);

  return (
    <Provider value={value}>
      {children}
      {notification && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={close}
          disableWindowBlurListener
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={notification.severity}
            onClose={close}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </Provider>
  );

  function notify(message: string, severity?: AlertProps["severity"]) {
    setOpen(true);
    setNotification({ message, severity: severity || "success" });
  }

  function close(
    event: Event | React.SyntheticEvent<Element, Event>,
    reason?: string
  ) {
    if (reason === "clickaway") return;
    setOpen(false);
  }
}

export function useNotification() {
  return useContext(NotificationContext);
}

type Notification = {
  message: string;
  severity: AlertProps["severity"];
};
