import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import RecommendIcon from "@mui/icons-material/Recommend";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Field,
  useAppDispatch,
  createOneReading,
  EventType,
  deleteAllTilesForField,
  invalidateFieldData,
} from "model";
import { mixpanel } from "common/analytics";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";

interface Props {
  field: Field;
}

export default function CoverEntryThumbsDownDialog({ field }: Props) {
  const [open, setOpen] = useState(false);
  const [coverValue, setCoverValue] = useState<number>(field.dryMatterNow);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const theme = useTheme();
  const [eventDate, setEventDate] = useState<Date | null>(new Date());
  return (
    <>
      <Tooltip title={t("confirmCovers.thumbsDown.tooltip")}>
        <IconButton
          style={{ transform: "rotate(180deg)" }}
          onClick={() => {
            setOpen(true);
            mixpanel.track("Cover looks good thumbsDown clicked");
          }}
          edge="end"
          size="large"
        >
          <RecommendIcon sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          mixpanel.track("Cover looks good thumbsDown closed");
        }}
      >
        <DialogTitle>{t("confirmCovers.thumbsDown.title")}</DialogTitle>
        <DialogContent style={{ maxWidth: "300px", paddingBottom: "16px" }}>
          <FormattedMessage
            id="confirmCovers.thumbsDown.text"
            values={{
              p: (chunks: any) => <p>{chunks}</p>,
            }}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <>
              <DateTimePicker
                inputFormat="MMMM do HH:mm"
                ampm={false}
                label={t("addCovers.single.measurementDatetime")}
                disableFuture
                value={eventDate}
                onChange={setEventDate}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    fullWidth
                    error={eventDate == null ? true : false}
                  />
                )}
                DialogProps={{
                  /*https://github.com/mui/material-ui/issues/26664*/
                  PaperProps: {
                    sx: { "& *:focus": { outline: "none" } },
                  },
                }}
              />
            </>
          </LocalizationProvider>

          <TextField
            variant="standard"
            label={t("addCovers.single.newCover")}
            fullWidth
            defaultValue={field.dryMatterNow}
            value={coverValue ?? ""}
            type="number"
            onChange={(e) => setCoverValue(Number(e.target.value))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t("addCovers.single.unit")}
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpen(false);
              mixpanel.track("Cover looks good thumbsDown cancelled");
            }}
          >
            {t("confirmCovers.thumbsDown.cancel")}
          </Button>
          <Button
            disabled={coverValue && eventDate ? false : true}
            color="primary"
            onClick={handleSave}
          >
            {t("confirmCovers.thumbsDown.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  async function handleSave() {
    if (!eventDate) {
      return;
    }
    // we add 5 minutes to the provided measurement time,
    // since we are only asking for the measurement of a single field
    // and we don't want users to have to enter 2 times
    const timeDiff = 5 * 60000;
    mixpanel.track("Cover looks good thumbsDown confirmed");
    await dispatch(
      createOneReading({
        guid: "new",
        manualDryMatterInput: {
          dryMatterValue: coverValue,
          field: field.guid,
          dateTimeMeasurementStart: eventDate.toISOString(),
          dateTimeMeasurementEnd: new Date(
            eventDate.getTime() + timeDiff
          ).toISOString(),
          event: {
            eventType: EventType.None,
          },
          kind: "user",
          verificationStatus: "Accepted",
        },
      })
    ).then(unwrapResult);
    notify(t("addCovers.success"));
    mixpanel.track("Single CoverAdd successful (via thumbsDown)");
    await dispatch(deleteAllTilesForField(field.guid));
    dispatch(invalidateFieldData(field.guid));
    setOpen(false);
  }
}
