import { length, area, Feature } from "@turf/turf";

import { ListItem, ListItemIcon, ListItemText, Grid } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CropLandscapeIcon from "@mui/icons-material/CropLandscape";
import { useTranslation } from "common/locales";
import { booleanValid } from "common/utils";

interface FieldStatsProps {
  geom: Feature;
  direction: "column" | "row";
}

export default function FieldStats({ geom, direction }: FieldStatsProps) {
  const { t } = useTranslation();
  const geomIsValid = geom && booleanValid(geom);
  return (
    <Grid container direction={direction}>
      <Grid item>
        <ListItem>
          <ListItemIcon>
            <AspectRatioIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              geomIsValid
                ? `${(Math.round(area(geom)) / 10000).toFixed(2)} ${t(
                    "fieldinput.area.unit"
                  )}`
                : t("fieldinput.na")
            }
            secondary={t("fieldinput.area.text")}
          />
        </ListItem>
      </Grid>
      <Grid item>
        <ListItem>
          <ListItemIcon>
            <CropLandscapeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              geomIsValid
                ? `${Math.round(length(geom, { units: "meters" }))} ${t(
                    "fieldview.perimeter.unit"
                  )}`
                : t("fieldinput.na")
            }
            secondary={t("fieldview.perimeter.text")}
          />
        </ListItem>
      </Grid>
    </Grid>
  );
}
