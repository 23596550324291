import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { App } from "app";

import { mixpanel, logRocket, logRocketConfig } from "./common/analytics";

logRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID ?? "", logRocketConfig);
// catch all unhandled rejections
window.addEventListener("unhandledrejection", function (event) {
  event = event || {};
  console.error(
    "Unhandled rejection (promise: ",
    event.promise,
    ", reason: ",
    event.reason,
    ")."
  );
});

// Mixpanel initialization has to happen before we run any other
// code running mixanel logic; otherwise it fails with errors.
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN ?? "";

const mixpanelOpts = { api_host: "https://api-eu.mixpanel.com" };

mixpanel.init(mixpanelToken, mixpanelOpts);
mixpanel.register({ gitsha: process.env.REACT_APP_GITSHA ?? "" });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
