import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "common/locales";
import { createOneReading, EventType, Field, moveIntoField } from "model";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  invalidateFieldData,
  useAppDispatch,
  deleteAllTilesForField,
  useAppSelector,
  closeMoveOutEventDialog,
  getAllFieldsForAnimalGroupId,
  selectLatestManualReadingDate,
} from "model";
import { mixpanel } from "common/analytics";
import { useNotification } from "app/NotificationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import styled from "styled-components";

const ASYNC_THUNK_REJECTED_REQUEST_STATUS = "rejected";

export default function UpdateBiomassAfterMoveInDialog() {
  const defaultPostCover = 1500;

  const [postCover, setPostCover] = useState<number>(defaultPostCover);

  const handlePostCoverChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const cover = Number(e.target.value);
    setPostCover(cover);
    setBiomassWasChanged(true);
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const moveAnimalsDialogDetails = useAppSelector(
    (state) => state.app.moveAnimalsDialogDetails
  );
  const currentFieldWithAnimals =
    moveAnimalsDialogDetails.currentFieldWithAnimals;
  const newFieldWithAnimals = moveAnimalsDialogDetails.newFieldWithAnimals;
  const currentFieldName = currentFieldWithAnimals?.name ?? "";

  const [animalMoveDate, setAnimalMoveDate] = useState<Date | null>(new Date());

  const fieldsForAnimalGroup: Field[] | undefined = useAppSelector(
    getAllFieldsForAnimalGroupId(currentFieldWithAnimals?.animalGroup ?? "")
  );
  const latestMoveDateForAnimalGroup: Date | undefined = useAppSelector(
    selectLatestManualReadingDate(fieldsForAnimalGroup.map((f) => f.guid))
  );

  const [dateError, setDateError] = useState<boolean>(false);

  const [dateWasChanged, setDateWasChanged] = useState<boolean>(false);
  const [biomassWasChanged, setBiomassWasChanged] = useState<boolean>(false);

  return (
    <>
      <Dialog
        open={moveAnimalsDialogDetails.open}
        onClose={() => {
          dispatch(closeMoveOutEventDialog({ open: false }));
          reset();
        }}
      >
        <DialogContent style={{ maxWidth: "300px", paddingBottom: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">
                {t("moveOut.title", {
                  name: currentFieldName,
                  currentCover: currentFieldWithAnimals?.dryMatterNow,
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="body1">
                {t("moveOut.fromField", {
                  fieldName: currentFieldName,
                })}
              </Typography>
              <Typography variant="body1">
                {t("moveOut.toField", {
                  fieldName: newFieldWithAnimals?.name,
                })}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <StyledDateInfo>
                <Typography variant="body1">
                  {t("moveOut.date.label")}
                </Typography>
                <Tooltip
                  title={t("moveOut.minDateInfo", {
                    minDate: latestMoveDateForAnimalGroup
                      ? (latestMoveDateForAnimalGroup as Date).toLocaleString()
                      : "",
                  })}
                >
                  <InfoIcon style={{ height: 20 }} />
                </Tooltip>
              </StyledDateInfo>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  minDate={latestMoveDateForAnimalGroup}
                  minTime={latestMoveDateForAnimalGroup}
                  maxDateTime={new Date()}
                  inputFormat="MMMM do HH:mm"
                  ampm={false}
                  value={animalMoveDate}
                  onChange={(value: Date | null) => {
                    if (
                      value &&
                      latestMoveDateForAnimalGroup &&
                      (value < (latestMoveDateForAnimalGroup as Date) ||
                        value > new Date())
                    ) {
                      setDateError(true);
                    } else {
                      setDateError(false);
                    }
                    setAnimalMoveDate(value);
                    setDateWasChanged(true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      error={dateError}
                      fullWidth
                    />
                  )}
                  DialogProps={{
                    /*https://github.com/mui/material-ui/issues/26664*/
                    PaperProps: {
                      sx: { "& *:focus": { outline: "none" } },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="body1">
                {t("moveOut.subTitle", {
                  fieldName: currentFieldName,
                  currentCover: currentFieldWithAnimals?.dryMatterNow,
                })}
              </Typography>
              <Typography variant="body1">
                {" "}
                {t("moveOut.subTitle2")}{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="standard"
                fullWidth
                value={postCover}
                onChange={(
                  value: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => {
                  handlePostCoverChange(value);
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t("addCovers.single.unit")}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={dateError}
            color="secondary"
            onClick={async () => {
              await handleSave(
                false,
                currentFieldWithAnimals?.dryMatterNow ?? 0
              );
            }}
          >
            {t("moveOut.leaveIt.label")}
          </Button>
          <Button
            disabled={dateError}
            color="primary"
            onClick={async () => {
              await handleSave(true, postCover);
            }}
          >
            {t("moveOut.changeIt.label")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  function reset() {
    setPostCover(defaultPostCover);
    setAnimalMoveDate(new Date());
    setDateError(false);
    setDateWasChanged(false);
    setBiomassWasChanged(false);
  }

  async function handleSave(invalidateTiles: boolean, finalPostCover: number) {
    mixpanel.track("AnimalMoveIn with cover change started");
    if (biomassWasChanged) {
      mixpanel.track("AnimalMoveIn Farmer changed biomass.");
    }
    if (invalidateTiles) {
      mixpanel.track("AnimalMoveIn Farmer wants biomass to be updated.");
    }
    if (dateWasChanged) {
      mixpanel.track("AnimalMoveIn Date was changed.");
    }
    if (!newFieldWithAnimals) {
      return;
    }
    if (!currentFieldWithAnimals) {
      return;
    }
    if (dateError) {
      return;
    }

    const successfulMoveOut = await moveAnimalsOut(
      finalPostCover,
      invalidateTiles,
      currentFieldWithAnimals.guid,
      animalMoveDate ?? new Date(),
      dispatch,
      notify,
      t
    );

    if (successfulMoveOut) {
      await moveAnimalsIn(
        newFieldWithAnimals,
        currentFieldWithAnimals,
        animalMoveDate ?? new Date(),
        dispatch,
        notify,
        t
      );
    }
    reset();
  }
}

async function moveAnimalsOut(
  finalPostCover: number,
  invalidateTiles: boolean,
  fieldGuid: string,
  dateTimeOut: Date,
  dispatch: any,
  notify: any,
  t: any
) {
  const timeDiff = 5 * 60000;

  const eventData = {
    eventType: EventType.GrazingEvent,
    dateTimeOut: dateTimeOut.toISOString(),
  };

  const createReadingResult = await dispatch(
    createOneReading({
      guid: "new",
      manualDryMatterInput: {
        dryMatterValue: finalPostCover,
        field: fieldGuid,
        dateTimeMeasurementStart: dateTimeOut.toISOString(),
        dateTimeMeasurementEnd: new Date(
          dateTimeOut.getTime() + timeDiff
        ).toISOString(),
        event: eventData,
        kind: "user",
        verificationStatus: "Accepted",
      },
    })
  );
  dispatch(closeMoveOutEventDialog({ open: false }));
  const successfullyMovedOut =
    createReadingResult.meta.requestStatus !==
    ASYNC_THUNK_REJECTED_REQUEST_STATUS;
  if (successfullyMovedOut) {
    notify(t("addCovers.success"));
    mixpanel.track("AnimalMoveOut with cover change success");
    if (
      invalidateTiles &&
      createReadingResult.meta.requestStatus !==
        ASYNC_THUNK_REJECTED_REQUEST_STATUS
    ) {
      await dispatch(deleteAllTilesForField(fieldGuid));
      dispatch(invalidateFieldData(fieldGuid));
    }
  } else {
    notify(t("addCovers.failure"), "error");
    mixpanel.track("AnimalMoveOut with cover change failure");
  }
  return successfullyMovedOut;
}

export async function moveAnimalsIn(
  newFieldWithAnimals: { guid: string; name: string },
  currentFieldWithAnimals: { guid: string } | undefined,
  dateTimeIn: Date,
  dispatch: any,
  notify: any,
  t: any
) {
  try {
    await dispatch(
      moveIntoField({
        currentFieldGuid: currentFieldWithAnimals?.guid,
        newFieldGuid: newFieldWithAnimals.guid,
        dateTimeIn: dateTimeIn,
      })
    );

    mixpanel.track("AnimalMoveIn successful");
    notify(
      t("fieldlistmenu.notification.moveInSuccess", {
        fieldName: newFieldWithAnimals.name,
      }),
      "success"
    );
  } catch (e) {
    console.log(e);
    mixpanel.track("AnimalMoveIn failed");
    notify(
      t("fieldlistmenu.notification.moveInFailed", {
        fieldName: newFieldWithAnimals.name,
      }),
      "error"
    );
  }
}

const StyledDateInfo = styled.div`
  display: flex;
`;
