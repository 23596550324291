import { SvgIcon } from "@mui/material";
import geojson2svg from "geojson2svg";
import { toMercator, Feature, bbox } from "@turf/turf";
import { dryMatterToColor } from "common/utils/grazing-logic";

interface FieldIconProps {
  geom?: Feature;
  dryMatterNow: number;
}

export default function FieldIcon({ geom, dryMatterNow }: FieldIconProps) {
  const bboxEPSG3857 = bbox(toMercator(geom as Feature));
  const converter = geojson2svg({
    viewportSize: { width: 24, height: 24 },
    mapExtent: {
      left: bboxEPSG3857[0],
      bottom: bboxEPSG3857[1],
      right: bboxEPSG3857[2],
      top: bboxEPSG3857[3],
    },
    output: "path",
  });

  return (
    <SvgIcon htmlColor={dryMatterToColor(dryMatterNow, "#b9b9b9")}>
      <path d={converter.convert(toMercator(geom as Feature))} />
    </SvgIcon>
  );
}
