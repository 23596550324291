import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useTranslation } from "common/locales";

interface FieldBoundaryDeleteConfirmationDialogProps {
  open: boolean;
  handleAction: (confirmed: boolean) => void;
}

export default function FieldBoundaryDeleteConfirmationDialog(
  props: FieldBoundaryDeleteConfirmationDialogProps
) {
  const { handleAction, open } = props;
  const { t } = useTranslation();

  const handleCancel = () => {
    handleAction(false);
  };

  const handleConfirm = () => {
    handleAction(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        {t("map.deletefeature.confirmation.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("map.deletefeature.confirmation.description")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          {t("map.deletefeature.confirmation.cancel.label")}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t("map.deletefeature.confirmation.confirm.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
