import HeaderBar from "common/components/HeaderBar";
import { useAppSelector, selectCurrentFarm } from "model";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "common/locales";
import { useMediaQuery, useTheme } from "@mui/material";

export default function DrawerToolbar() {
  const farmMatch = useRouteMatch<{ farmId: string }>("/farm/:farmId");
  const fieldMatch = useRouteMatch<{ fieldId: string }>("/field/:fieldId");
  const fieldEditMatch = useRouteMatch<{ fieldId: string }>(
    "/edit-field/:fieldId"
  );
  const fieldAddMatch = useRouteMatch("/add-field");
  const animalGroupMatch = useRouteMatch<{ animalGroupId: string }>(
    "/animal-group/:animalGroupId"
  );
  const animalGroupAddMatch = useRouteMatch("/add-animal-group");
  const animalGroupEditMatch = useRouteMatch<{ animalGroupId: string }>(
    "/edit-animal-group/:animalGroupId"
  );
  const reseedingDetailMatch = useRouteMatch<{ fieldId: string }>(
    "/grass-types-reseeding-detail"
  );

  const feedOverviewMatch =
    useRouteMatch<{ fieldId: string }>("/feed-overview");

  const yieldOverviewMatch =
    useRouteMatch<{ fieldId: string }>("/yield-overview");

  const fertilizerOverviewMatch = useRouteMatch<{ fieldId: string }>(
    "/fertilizer-overview"
  );

  const fieldPreparationOverviewMatch = useRouteMatch(
    "/field-preparation-overview"
  );

  const initalScreenSelectMatch = useRouteMatch("/initial-screen-select");

  const inputsAndOutputsMatch = useRouteMatch("/inputs-and-outputs");

  const fields = useAppSelector((state) => state.fields.entities);

  const currentFarm = useAppSelector(selectCurrentFarm);
  const currentFarmId = currentFarm?.guid;
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (inputsAndOutputsMatch) {
    return (
      <HeaderBar
        nested
        title={""}
        onBack={() => {
          history.push(`initial-screen-select`);
        }}
      />
    );
  }

  if (initalScreenSelectMatch) {
    return <HeaderBar title={""} burgerColor="#D3D3D3" />;
  }

  if (farmMatch) {
    return <HeaderBar title={t("toolbar.farmview.title")} />;
  }

  if (fieldMatch) {
    const parentAnimalGroupId = fields[fieldMatch.params.fieldId]?.animalGroup;
    return (
      <HeaderBar
        nested
        title={t("toolbar.fieldview.title")}
        onBack={() => {
          if (parentAnimalGroupId) {
            history.push(`/animal-group/${parentAnimalGroupId}`);
          } else {
            backToFarm();
          }
        }}
      />
    );
  }

  if (reseedingDetailMatch) {
    return (
      <HeaderBar
        nested
        title={t("toolbar.grasstype-reseeding.title")}
        onBack={() => {
          history.push(`inputs-and-outputs`);
        }}
      />
    );
  }

  if (feedOverviewMatch) {
    return (
      <HeaderBar
        nested
        title={t("toolbar.feed-overview.title")}
        onBack={() => {
          history.push(`inputs-and-outputs`);
        }}
      />
    );
  }

  if (yieldOverviewMatch) {
    return (
      <HeaderBar
        nested
        title={t("toolbar.yield-overview.title")}
        onBack={() => {
          history.push(`inputs-and-outputs`);
        }}
      />
    );
  }

  if (fertilizerOverviewMatch) {
    return (
      <HeaderBar
        nested
        title={t("toolbar.fertilizer-overview.title")}
        onBack={() => {
          history.push(`inputs-and-outputs`);
        }}
      />
    );
  }

  if (fieldPreparationOverviewMatch) {
    return (
      <HeaderBar
        nested
        title={t("toolbar.field-preparation-overview.title")}
        onBack={() => {
          history.push(`inputs-and-outputs`);
        }}
      />
    );
  }

  if (fieldEditMatch && !isMobile) {
    return <HeaderBar nested needsConfirm title={t("edit.editingField")} />;
  }

  if (fieldAddMatch && !isMobile) {
    return <HeaderBar nested needsConfirm title={t("edit.addingField")} />;
  }

  if (animalGroupMatch) {
    return (
      <HeaderBar
        nested
        title={t("toolbar.animalGroupView.title")}
        onBack={backToFarm}
      />
    );
  }

  if (animalGroupEditMatch) {
    return (
      <HeaderBar nested needsConfirm title={t("edit.editingAnimalGroup")} />
    );
  }

  if (animalGroupAddMatch) {
    return (
      <HeaderBar nested needsConfirm title={t("edit.addingAnimalGroup")} />
    );
  }

  return null;

  function backToFarm() {
    history.push(`/farm/${currentFarmId || ""}`);
  }
}
