import { ReseedEvent, selectFieldsById, useAppSelector } from "model";
import { useState } from "react";
import UpdateReseedEvent from "./UpdateReseedEvent";
import ReseedEventMoreMenu from "features/fieldEventList/ReseedEventMoreMenu";

export default function UpdateReseedEventDialog({
  reseedEvent,
  menuAnchor,
  closeMenu,
}: {
  reseedEvent: ReseedEvent;
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}) {
  const fields = useAppSelector(selectFieldsById(reseedEvent?.fieldGuids));

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!reseedEvent || !fields) {
    return null;
  }
  return (
    <>
      <ReseedEventMoreMenu
        reseedEvent={reseedEvent}
        close={closeMenu}
        anchor={menuAnchor}
        setDialogOpen={setDialogOpen}
      />
      <UpdateReseedEvent
        fields={fields}
        reseedEvent={reseedEvent}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
}
