import { useState, useEffect } from "react";
import { Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { Feature, FeatureCollection, booleanEqual } from "@turf/turf";
import { Field, GRASS_TYPE } from "model/fieldSlice";
import {
  Button,
  Container,
  Divider,
  useMediaQuery,
  useTheme,
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Input,
  InputLabel,
} from "@mui/material";

import { PrivateRoute } from "app";
import {
  TmpFieldDetails,
  setTmpFeatures,
  deleteTmpFeatures,
  setZoomToGeom,
  deleteAllTilesForField,
  createField,
  updateField,
  selectFieldById,
  invalidateFieldData,
  closeDrawer,
  openDrawer,
  useAppDispatch,
  useAppSelector,
  setTentativeFeature,
  getFieldCount,
  setEditorSelectionType,
  setEditorIsInTentativeMode,
  openAfterFieldCreatedDialog,
  selectArableFieldById,
  ArableField,
  FIELD_TYPE,
  createArableField,
  invalidateArableFieldData,
  updateArableField,
  ARABLE_TYPE,
} from "model";
import { booleanValid } from "common/utils";
import {
  ConfirmCancellationDialog,
  DrawerButtonContainer,
  DrawerButtonGroup,
  DrawerForm,
  DrawerFormControl,
  MobileBottomDrawer,
  MobileButtonGroup,
  MobileTopHintContainer,
  VerticalCenterContainer,
} from "common/components";
import { useTranslation } from "common/locales";
import { useNotification } from "app/NotificationProvider";
import { mixpanel } from "../../common/analytics";
import FieldDetailsEditor from "./FieldDetailsEditor";
import FieldStats from "./FieldStats";
import { unwrapResult } from "@reduxjs/toolkit";
import { Alert, AlertColor } from "@mui/material";
import styled from "styled-components";
import { EditorSelectionType } from "features/mapDrawTools/enums";
import DrawingHelpModal from "features/mapDrawTools/DrawingHelpModal";
import ArableFieldDetailsEditor from "./ArableFieldDetailsEditor";
import FieldTypeRadio from "./FieldTypeRadio";
import { useStylesFormControl } from "./AssignedToDropdown";

export default function FieldInput() {
  const { t } = useTranslation();
  const { notify } = useNotification();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { path } = useRouteMatch();
  const [fieldNameError, setFieldNameError] = useState(false);
  const [vegetationTypeInputError, setVegetationTypeInputError] =
    useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const currentFarmId = useAppSelector((state) => state.farms.currentFarmId!);
  const [isLoading, setIsLoading] = useState(false);
  const fieldCount = useAppSelector(getFieldCount); // TODO: doesn't work when directly hitting /draw-boundary route
  const classes = useStylesFormControl();

  const editorIsInTentativeMode = useAppSelector(
    (state) => state.app.editorIsInTentativeMode
  );

  const editorSelectionType = useAppSelector(
    (state) => state.app.editorSelectionType
  );

  const [tmpField, setTmpField] = useState<TmpFieldDetails>({
    guid: "new",
    name: "",
    farm: currentFarmId,
  });

  const fieldGeom = {
    type: "FeatureCollection",
    features: tmpField?.geom ? tmpField?.geom : [],
  } as FeatureCollection;

  useEffect(() => {
    if (fieldGeom.features.length > 0) {
      dispatch(setZoomToGeom(fieldGeom));
    }
  }, [fieldGeom, dispatch]);

  const { fieldId } = useParams<{ fieldId: string }>();
  const fieldToEdit: Field | undefined = useAppSelector((state) =>
    selectFieldById(state, fieldId)
  );

  const arableFieldToEdit: ArableField | undefined = useAppSelector((state) =>
    selectArableFieldById(state, fieldId)
  );

  const [fieldType, setFieldType] = useState<FIELD_TYPE>(
    fieldToEdit
      ? FIELD_TYPE.GRASS
      : arableFieldToEdit
      ? FIELD_TYPE.ARABLE
      : FIELD_TYPE.GRASS
  );

  const [fieldTypeError, setFieldTypeError] = useState(false);
  const tmpFeatures = useAppSelector((state) => state.app.tmpFeatures);
  const tentativeFeature = useAppSelector(
    (state) => state.app.tentativeFeature
  );
  // there should only ever be 1 field boundary, so taking the first element that's a polygon, or undefined if there are no matches ([][0] is undefined)
  const tmpFieldGeom =
    tmpFeatures.filter((f) => f.geometry?.type === "Polygon")[0] ||
    tentativeFeature;
  useEffect(() => {
    const curField: Field | ArableField | undefined =
      fieldToEdit ?? arableFieldToEdit;
    if (curField) {
      setTmpField(curField);
      dispatch(setTmpFeatures([curField.geom])); // not cleaned up properly after save
      dispatch(
        setZoomToGeom({
          type: "FeatureCollection",
          features: [curField.geom],
        })
      );
    } else {
      dispatch(setTmpFeatures([])); // reset when creating a new field
    }
  }, [fieldToEdit, arableFieldToEdit, dispatch]);

  const handleFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpField({ ...tmpField, name: e.target.value });
    setFieldNameError(false);
  };

  const handleFieldTypeChange = (value?: FIELD_TYPE) => {
    if (value) {
      setFieldType(value);
    } else {
      setFieldTypeError(true);
    }
  };

  const handleSave = async () => {
    try {
      const fieldNameSet = !!tmpField.name;
      setFieldNameError(!fieldNameSet);
      if (fieldType === FIELD_TYPE.GRASS) {
        const grassTypeInputError = !tmpField.grassType;
        setVegetationTypeInputError(grassTypeInputError);
        if (grassTypeInputError) {
          return;
        }
      }

      if (fieldType === FIELD_TYPE.ARABLE) {
        const arableTypeInputError = !tmpField.arableType;
        setVegetationTypeInputError(arableTypeInputError);
        if (arableTypeInputError) {
          return;
        }
      }
      if (!fieldNameSet) {
        return;
      }
      if (fieldTypeError) {
        return;
      }

      if (!isGeomValid(tmpFieldGeom)) {
        if (!tentativeFeature || !isGeomValid(tentativeFeature)) {
          return notify(t("fieldinput.boundary.error"), "error");
        } else {
          // defensive logic
          setIsLoading(true);
          dispatch(setTmpFeatures([tentativeFeature] as any));
          dispatch(setTentativeFeature(null)); // TODO: this should belong to the editor
          dispatch(setEditorSelectionType(EditorSelectionType.NONE)); // reset editor
          dispatch(setEditorIsInTentativeMode(false));
          await create(tentativeFeature as any);
        }
      }
      setIsLoading(true);

      // TODO: this should belong to the editor
      dispatch(setEditorSelectionType(EditorSelectionType.NONE)); // reset editor
      dispatch(setEditorIsInTentativeMode(false));

      if (tmpField.guid === "new" && currentFarmId) {
        await create(tmpFieldGeom);
      } else {
        await update();
      }
    } catch (e: any) {
      notify(t("fieldinput.save.error"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmationClose = () => {
    setOpen(false);

    // reset editor
    dispatch(deleteTmpFeatures());
    dispatch(setTentativeFeature(null));
    dispatch(setEditorSelectionType(EditorSelectionType.NONE));
    dispatch(setEditorIsInTentativeMode(false));
  };

  const handleCancel = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isMobile) {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer());
    }
  }, [isMobile, dispatch]);

  const { severity, hintId } = getHint(
    editorIsInTentativeMode,
    editorSelectionType,
    tmpFeatures,
    tentativeFeature
  );

  if (isMobile) {
    return (
      <>
        <Switch>
          <PrivateRoute path={`${path}/enter-details`}>
            <Dialog open fullScreen>
              <DialogTitle>{t("fieldinput.title.mobile")}</DialogTitle>
              <DialogContent>
                <VerticalCenterContainer>
                  <DrawerForm className={classes.formControl}>
                    <InputLabel htmlFor="input-name" variant="standard">
                      {t("edit.fieldname.label")}
                    </InputLabel>
                    <Input
                      required
                      error={fieldNameError}
                      id="input-name"
                      value={tmpField.name}
                      onChange={handleFieldNameChange}
                      autoFocus={true}
                    />
                  </DrawerForm>

                  <Container sx={{ alignContent: "left" }}>
                    <DrawerForm className={classes.formControl}>
                      <FieldTypeRadio
                        disabled={!!(fieldToEdit || arableFieldToEdit)}
                        onChange={handleFieldTypeChange}
                        currentFieldType={fieldType}
                        fieldTypeInputError={fieldTypeError}
                        label={"fieldinput.fieldType.text"}
                      />
                    </DrawerForm>
                  </Container>
                  {fieldType === FIELD_TYPE.GRASS && (
                    <FieldDetailsEditor
                      field={tmpField}
                      handleAssignedToChange={handleAssignedToChange}
                      handleGrassTypeChange={handleGrassTypeChange}
                      fieldNameError={fieldNameError}
                      currentGrassType={fieldToEdit?.grassType ?? undefined}
                      grassTypeInputError={vegetationTypeInputError}
                    />
                  )}
                  {fieldType === FIELD_TYPE.ARABLE && (
                    <ArableFieldDetailsEditor
                      field={tmpField}
                      handleArableTypeChange={handleArableTypeChange}
                      fieldNameError={fieldNameError}
                      currentArableType={
                        arableFieldToEdit?.arableType ?? undefined
                      }
                      arableTypeInputError={vegetationTypeInputError}
                    />
                  )}
                </VerticalCenterContainer>
              </DialogContent>
              <DialogActions>
                <DrawerButtonContainer>
                  <DrawerButtonGroup>
                    <Button color="secondary" onClick={handleCancel}>
                      {t("edit.button.cancel")}
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => {
                        if (!tmpField.name) {
                          setFieldNameError(true);
                        } else {
                          setFieldNameError(false);
                          history.push("draw-boundary");
                        }
                        setVegetationTypeInputError(!tmpField.grassType);
                      }}
                    >
                      {t("edit.button.next")}
                    </Button>
                  </DrawerButtonGroup>
                  <ConfirmCancellationDialog
                    open={open}
                    onClose={handleConfirmationClose}
                  />
                </DrawerButtonContainer>
              </DialogActions>
            </Dialog>
          </PrivateRoute>
          <PrivateRoute path={`${path}/draw-boundary`}>
            <div style={{ whiteSpace: "normal" }}>
              <DrawingHelpModal
                isOpen={snackbarOpen && fieldCount === 0 && !tmpFieldGeom}
                handleClose={handleSnackbarClose}
              />
            </div>
            <MobileTopHintContainer container>
              <Grid item xs={12}>
                <DrawingHint severity={severity}>{t(hintId)}</DrawingHint>
              </Grid>
            </MobileTopHintContainer>
            <MobileBottomDrawer container>
              <Grid item xs={12}>
                <FieldStats geom={tmpFieldGeom} direction="row" />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <MobileButtonGroup>
                  <Button
                    color="secondary"
                    onClick={() => history.push("enter-details")}
                  >
                    {t("edit.button.back")}
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleSave}
                    disabled={isLoading || !isGeomValid(tmpFieldGeom)}
                  >
                    {t("edit.button.save")}
                  </Button>
                </MobileButtonGroup>
              </Grid>
            </MobileBottomDrawer>
          </PrivateRoute>
        </Switch>
      </>
    );
  } else {
    return (
      <>
        <Container>
          <Container>
            <DrawerForm className={classes.formControl}>
              <InputLabel htmlFor="input-name" variant="standard">
                {t("edit.fieldname.label")}
              </InputLabel>
              <Input
                required
                error={fieldNameError}
                id="input-name"
                value={tmpField.name}
                onChange={handleFieldNameChange}
                autoFocus={true}
              />
            </DrawerForm>
          </Container>

          <Container sx={{ alignContent: "left" }}>
            <DrawerForm className={classes.formControl}>
              <FieldTypeRadio
                disabled={!!(fieldToEdit || arableFieldToEdit)}
                onChange={handleFieldTypeChange}
                currentFieldType={fieldType}
                fieldTypeInputError={fieldTypeError}
                label={"fieldinput.fieldType.text"}
              />
            </DrawerForm>
          </Container>

          {fieldType === FIELD_TYPE.GRASS && (
            <FieldDetailsEditor
              field={tmpField}
              handleAssignedToChange={handleAssignedToChange}
              handleGrassTypeChange={handleGrassTypeChange}
              fieldNameError={fieldNameError}
              currentGrassType={fieldToEdit?.grassType ?? undefined}
              grassTypeInputError={vegetationTypeInputError}
            />
          )}
          {fieldType === FIELD_TYPE.ARABLE && (
            <ArableFieldDetailsEditor
              field={tmpField}
              handleArableTypeChange={handleArableTypeChange}
              fieldNameError={fieldNameError}
              currentArableType={arableFieldToEdit?.arableType ?? undefined}
              arableTypeInputError={vegetationTypeInputError}
            />
          )}
        </Container>
        <Container>
          <FieldStats geom={tmpFieldGeom} direction="column" />
        </Container>
        <Divider />
        <DrawerButtonContainer>
          <DrawerButtonGroup>
            <Button
              color="secondary"
              onClick={handleCancel}
              disabled={isLoading}
            >
              {t("edit.button.cancel")}
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              disabled={isLoading || !isGeomValid(tmpFieldGeom)}
            >
              {t("edit.button.save")}
            </Button>
          </DrawerButtonGroup>
          <ConfirmCancellationDialog
            open={open}
            onClose={handleConfirmationClose}
          />
        </DrawerButtonContainer>
        <DrawingHintContainer>
          <DrawingHint severity={severity}>{t(hintId)}</DrawingHint>
        </DrawingHintContainer>
      </>
    );
  }

  function handleAssignedToChange(value?: string) {
    setTmpField({ ...tmpField, animalGroup: value });
  }

  function handleGrassTypeChange(value?: GRASS_TYPE) {
    setTmpField({ ...tmpField, grassType: value });
    setVegetationTypeInputError(!value);
  }

  function handleArableTypeChange(value?: ARABLE_TYPE) {
    setTmpField({ ...tmpField, arableType: value });
    setVegetationTypeInputError(!value);
  }

  async function create(geom: Feature) {
    let newField;
    if (fieldType === FIELD_TYPE.GRASS) {
      newField = await dispatch(
        createField({
          field: {
            ...tmpField,
            dryMatterNow: 0,
            farm: currentFarmId,
            geom,
            area: 0,
            tiles: [],
          },
        })
      ).then(unwrapResult);
      dispatch(invalidateFieldData(newField.guid));
    } else {
      newField = await dispatch(
        createArableField({
          arableField: {
            ...tmpField,
            arableType: tmpField.arableType ?? ARABLE_TYPE.BARLEY,
            farm: currentFarmId,
            geom,
            area: 0,
            tiles: [],
          },
        })
      ).then(unwrapResult);
      dispatch(invalidateArableFieldData(newField.guid));
    }
    notify(t("fieldInput.add.success", { fieldName: tmpField.name }));
    mixpanel.track("FieldAdd successful");
    dispatch(deleteTmpFeatures());
    dispatch(setTentativeFeature(null)); // reset tentative feature
    dispatch(openAfterFieldCreatedDialog());
    if (fieldCount < 1) {
      // show congrats dialog
      mixpanel.track("AfterFieldCreatedDialog after 1st field open");
    } else {
      // show general "want another field?" dialog
      mixpanel.track("AfterFieldCreatedDialog after nst field open");
    }
    history.push(`/field/${newField.guid}`);
  }

  async function update(): Promise<void> {
    const geomChanged = !(
      (fieldToEdit && booleanEqual(tmpFieldGeom, fieldToEdit.geom)) ||
      (arableFieldToEdit && booleanEqual(tmpFieldGeom, arableFieldToEdit.geom))
    );
    if (geomChanged) {
      dispatch(deleteAllTilesForField(tmpField.guid));
    }

    if (fieldType === FIELD_TYPE.GRASS) {
      await dispatch(
        updateField({
          ...tmpField,
          dryMatterNow: (!geomChanged && fieldToEdit?.dryMatterNow) || 0,
          geom: tmpFieldGeom,
          area: (!geomChanged && fieldToEdit?.area) || 0,
        })
      );

      if (geomChanged) {
        dispatch(invalidateFieldData(tmpField.guid));
      }
    } else {
      await dispatch(
        updateArableField({
          guid: tmpField.guid,
          name: tmpField.name,
          farm: tmpField.farm,
          arableType: tmpField.arableType ?? ARABLE_TYPE.BARLEY,
          geom: tmpFieldGeom,
          area: (!geomChanged && fieldToEdit?.area) || 0,
        })
      );
      if (geomChanged) {
        dispatch(invalidateArableFieldData(tmpField.guid));
      }
    }

    notify(t("fieldInput.edit.success", { fieldName: tmpField.name }));
    mixpanel.track("FieldEdit successfull");
    dispatch(deleteTmpFeatures());
    history.push(`/field/${tmpField.guid}`);
  }

  function handleSnackbarClose() {
    setSnackbarOpen(false);
  }
}

function isGeomValid(geom: Feature<any>) {
  return geom && booleanValid(geom);
}

function getHint(
  editorIsInTentativeMode: boolean,
  editorSelectionType: EditorSelectionType,
  tmpFeatures: Array<Feature>,
  tentativeFeature: Feature | null
): { hintId: string; severity: AlertColor } {
  let severity: AlertColor = "info";
  let hintId = "map.clickOnPencilButton.hint"; // edit mode, no feature exists
  // TODO: simplify it
  if (editorIsInTentativeMode) {
    if (!tentativeFeature) {
      // not enough points
      hintId = "map.provideThreePoints.hint";
    } else if (isGeomValid(tentativeFeature)) {
      // valid tentative feature
      hintId = "map.doubleClickToFinish.hint";
    } else {
      // invalid geometry
      hintId = "map.provideValidGeometry.hint";
      severity = "error";
    }
  } else {
    // in edit mode
    if (tmpFeatures.length > 0) {
      // feature exists
      if (editorSelectionType === EditorSelectionType.HANDLES) {
        // selected handles, tell them how to delete
        if (isGeomValid(tmpFeatures[0])) {
          hintId = "map.editingField.hint"; // not using "map.deleteHandles.hint" for now because of the bug in react-draw-map-gl not providing correct handles
        } else {
          // invalid geometry
          hintId = "map.provideValidGeometry.hint";
          severity = "error";
        }
      } else if (editorSelectionType === EditorSelectionType.FEATURE) {
        // feature selected
        if (isGeomValid(tmpFeatures[0])) {
          hintId = "map.editingField.hint"; // valid geometry, can select points and edit, delete
        } else {
          // invalid geometry
          hintId = "map.provideValidGeometry.hint";
          severity = "error";
        }
      } else {
        // no feature selected (pencil button inactive)
        if (isGeomValid(tmpFeatures[0])) {
          hintId = "map.clickOnPencilButtonToEdit.hint"; // valid geometry, can activate pencil and edit
        } else {
          hintId = "map.provideValidGeometry.hint"; // invalid geometry
          severity = "error";
        }
      }
    }
  }

  return {
    severity,
    hintId,
  };
}

const DrawingHintContainer = styled(Container)(
  ({ theme }) => `
  bottom: 0;
`
);

const DrawingHint = styled(Alert)(
  ({ theme }) => `
  background-color: #FFF; // todo move to theme

  & > * {
    white-space: normal;
    align-self: center;
  }
`
);
