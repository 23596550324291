import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "common/locales";
import {
  useAppSelector,
  getRecentProposedEventsForField,
  selectFarmById,
  EventType,
  Field,
} from "model";
import styled from "styled-components";
import FieldEventConfirmationMenu from "./FieldEventConfirmationMenu";
import { AutoDetectedIcon } from "./FieldEventList";
import { EventEntryDialog } from "features/singleCoverInput";

export default function FieldProposedEventListItem({
  field,
}: {
  field: Field;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);
  const farm = useAppSelector((state) => selectFarmById(state, field.farm));
  const events = useAppSelector(getRecentProposedEventsForField)(
    field.guid
  ).slice(0, 1);
  if (!farm || events.length === 0) {
    return null;
  }
  return (
    <li key={`section-${field.guid}`}>
      <Ul>
        <StyledListSubheader>
          <ListItemText
            primary={`${farm?.name} - ${field.name}`}
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </StyledListSubheader>

        {events.map((event) => {
          const postCover = Math.round(
            event.manualDryMatterInput.event.postEventCover || 0
          );
          const eventDetails: string[] = [];
          let eventName = "";
          if (
            (event.manualDryMatterInput.event.eventType ===
              EventType.GrazingEvent ||
              EventType.PartialGrazingEvent) &&
            event.manualDryMatterInput.event.dateTimeIn &&
            event.manualDryMatterInput.event.dateTimeOut
          ) {
            const dateIn = new Date(
              Date.parse(event.manualDryMatterInput.event.dateTimeIn)
            ).toLocaleDateString(locale);
            const dateOut = new Date(
              Date.parse(event.manualDryMatterInput.event.dateTimeOut)
            ).toLocaleDateString(locale);
            eventDetails.push(t("fieldevents.grazed.dateIn", { dateIn }));
            eventDetails.push(t("fieldevents.grazed.dateOut", { dateOut }));
            eventDetails.push(t("fieldevents.event.postCover", { postCover }));
            eventName = t("fieldevents.grazed.title");
          } else if (
            event.manualDryMatterInput.event.eventType ===
              EventType.SilageEvent &&
            event.manualDryMatterInput.event.dateTimeCut
          ) {
            const dateCut = new Date(
              Date.parse(event.manualDryMatterInput.event.dateTimeCut)
            ).toLocaleDateString(locale);
            eventDetails.push(t("fieldevents.silage.dates", { dateCut }));
            eventDetails.push(t("fieldevents.event.postCover", { postCover }));
            eventName = t("fieldevents.silage.title");
          }

          return (
            <StyledListItem
              key={`fe-listitem-${event.guid}`}
              disableGutters={true}
            >
              <ListItemIcon>
                <AutoDetectedIcon />
              </ListItemIcon>
              <StyledListItemText
                primary={eventName}
                primaryTypographyProps={{ variant: "subtitle1" }}
                secondary={
                  <>
                    {eventDetails.map((item, i) => (
                      <Typography
                        component="span"
                        display="block"
                        key={`event-details-${event.guid}-${i}`}
                      >
                        {item}
                      </Typography>
                    ))}
                  </>
                }
              />
              <FieldEventConfirmationMenu machineInput={event}>
                <EventEntryDialog
                  field={field}
                  manualInput={event}
                  icon={<Edit />}
                />
              </FieldEventConfirmationMenu>
            </StyledListItem>
          );
        })}
      </Ul>
    </li>
  );
}

const Ul = styled.ul(
  ({ theme }) => `
  padding: 0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(4)};
`
);

const StyledListSubheader = styled(ListSubheader)(
  ({ theme }) => `
  background-color: #fff;
  padding-left: ${theme.spacing(7)};
  div {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`
);

const StyledListItem = styled(ListItem)`
  padding-top: 0;
  padding-bottom: 0;
` as typeof ListItem;

const StyledListItemText = styled(ListItemText)`
  padding: 0;
`;
