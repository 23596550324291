import DropdownInput from "common/components/DropdownInput";
import { useTranslation } from "common/locales";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useStyles } from "./NameAndGuidMultiSelectDropdown";

interface GenericDropdownProps<T> {
  currentValue?: T;
  disabled: boolean;
  onChange: (e: SelectChangeEvent<T>) => void;
  inputError: boolean;
  label: string;
  name: string;
  possibleValues: { [key: string]: string };
  tPrefix: string;
  required?: boolean;
}

export default function GenericDropdown<T>({
  disabled,
  onChange,
  currentValue,
  inputError,
  label,
  name,
  possibleValues,
  tPrefix,
  required,
}: GenericDropdownProps<T>) {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <FormControl
      disabled={disabled}
      error={inputError}
      fullWidth
      className={classes.formControl}
    >
      <InputLabel
        id="yield-unit-type-select-label"
        variant="standard"
        shrink={true}
        error={inputError}
        required={required ?? true}
      >
        {t(label)}
      </InputLabel>
      <Select
        labelId="yield-unit-type-select-label"
        id="yield-unit-type-select"
        value={currentValue ?? ("" as unknown as T)}
        onChange={onChange}
        input={<DropdownInput />}
        error={inputError}
        name={name}
      >
        {Object.entries(possibleValues).map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {t(`${tPrefix}.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
