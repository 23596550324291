import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { DiaryEntry } from "model";
import DiaryEntryDialogContentForScroll from "./DiaryEntryDialogContentForScroll";

interface Props {
  diaryEntries: DiaryEntry[];
  scrollDialogOpen: boolean;
  setScrollDialogOpen: (newValue: boolean) => void;
  setOpenDiaryEntryDialogGuid: (newValue: string | null) => void;
  dialogKey: string;
}

export default function DiaryEntryDialogWithScroll({
  dialogKey,
  diaryEntries,
  scrollDialogOpen,
  setScrollDialogOpen,
  setOpenDiaryEntryDialogGuid,
}: Props) {
  if (!scrollDialogOpen) {
    return <div key={`diary-entry-dialog-${dialogKey}`}></div>;
  }

  return (
    <div key={`diary-entry-dialog-${dialogKey}`}>
      <Dialog
        disableEscapeKeyDown
        key={dialogKey}
        fullScreen={false}
        open={scrollDialogOpen}
        onClose={() => setScrollDialogOpen(false)}
      >
        <DialogTitle>
          <IconButton
            onClick={() => {
              setScrollDialogOpen(false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ maxWidth: "350px" }}>
          <Box sx={{ overflowY: "scroll" }}>
            <ImageList variant="masonry" cols={2} gap={5}>
              {diaryEntries.map((diaryEntry) => (
                <ImageListItem key={diaryEntry.guid}>
                  <DiaryEntryDialogContentForScroll
                    key={`content-for-scroll-${diaryEntry.guid}`}
                    diaryEntry={diaryEntry}
                    setScrollDialogOpen={setScrollDialogOpen}
                    setOpenDiaryEntryDialogGuid={setOpenDiaryEntryDialogGuid}
                  />
                  <ImageListItemBar
                    position="below"
                    title={
                      <Typography
                        width="150px"
                        component="span"
                        display="block"
                        noWrap
                      >
                        {diaryEntry.diaryEntryText}
                      </Typography>
                    }
                    subtitle={new Date(
                      diaryEntry.entryDate
                    ).toLocaleDateString()}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
