import { useState, useMemo } from "react";
import { differenceInDays } from "date-fns";
import { DIARY_ENTRY_REJECTION_PERIOD_DAYS, DiaryEntry } from "model";
import DiaryEntryMoreMenu from "./DiaryEntryMoreMenu";
import { mixpanel } from "common/analytics/mixpanel";
import DiaryEntryDialog from "./DiaryEntryDialog";

interface Props {
  diaryEntry: DiaryEntry;
  anchor: HTMLElement | null;
  closeMenu: () => void;
}

export default function DiaryEntryDialogAndMoreMenu({
  diaryEntry,
  anchor,
  closeMenu: closeMenu,
}: Props) {
  const [openDiaryEntryDialogGuid, setOpenDiaryEntryDialogGuid] = useState<
    string | null
  >(null);
  const canEdit = useMemo(() => {
    return (
      differenceInDays(
        Date.now(),
        new Date(diaryEntry.creationDate!) ?? Date.now()
      ) <= DIARY_ENTRY_REJECTION_PERIOD_DAYS
    );
  }, [diaryEntry.creationDate]);

  return (
    <>
      <DiaryEntryMoreMenu
        canEdit={canEdit}
        diaryEntry={diaryEntry}
        anchor={anchor}
        openDialog={(openState: boolean) => {
          mixpanel.track("diaryEntry clicked View/Delete");
          setOpenDiaryEntryDialogGuid(diaryEntry.guid);
        }}
        closeMenu={closeMenu}
      ></DiaryEntryMoreMenu>
      <DiaryEntryDialog
        diaryEntry={diaryEntry}
        openDiaryEntryDialogGuid={openDiaryEntryDialogGuid}
        setOpenDiaryEntryDialogGuid={setOpenDiaryEntryDialogGuid}
      />
    </>
  );
}
