import { Container, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import { useTranslation } from "common/locales";
import { throttle } from "lodash";
import { useAppDispatch, useAppSelector } from "model";
import {
  fetchPostcodes,
  instanceOfPostcode,
  Postcode,
  selectPostcode,
} from "model/geolocation";
import { useCallback, useState } from "react";
import styled from "styled-components";

// eslint-disable-next-line @typescript-eslint/ban-types
type ChangeEventType = React.ChangeEvent<{}>;

export default function PostcodeLookupInput() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [postcode, setPostcode] = useState<Postcode | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputError, setInputError] = useState<boolean>(false);
  const isValidPostcode = (postcode: string) =>
    postcode && postcode.trim().length >= 1;

  const tmpPostcodeList = useAppSelector((state) => state.map.tmpPostcodeList);

  const findPostcode = useCallback(
    throttle(async (postcode: string) => {
      const sanitizedPostcode = sanitizePostcode(postcode);
      return dispatch(fetchPostcodes({ code: sanitizedPostcode }));
    }, 200),
    []
  );

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onInputChange = useCallback(
    async (e: ChangeEventType, newInputValue: string) => {
      // user types something
      setInputValue(newInputValue);
      setInputError(false);
      if (isValidPostcode(newInputValue)) {
        return findPostcode(newInputValue);
      }
    },
    []
  );

  const onChange = useCallback(
    (e: ChangeEventType, newValue: Postcode | string | null) => {
      // user selects item
      if (!newValue) {
        return;
      }

      if (instanceOfPostcode(newValue)) {
        setPostcode(newValue);
        return dispatch(selectPostcode(newValue));
      }

      // otherwise trying to find suitable postcode for user's text input
      const sanitizedNewValue = sanitizePostcode(newValue);
      const newPostcode = tmpPostcodeList
        .filter((item) => sanitizedNewValue === item.code)
        .pop();

      if (newPostcode) {
        setPostcode(newPostcode);
        return dispatch(selectPostcode(newPostcode));
      }

      setInputError(true); // selected non-existing postcode
    },
    [tmpPostcodeList]
  );

  // Custom filter options in order to fuzzy match postcodes
  const filterOptions = (
    options: Postcode[],
    state: FilterOptionsState<Postcode>
  ) =>
    options.filter((option) =>
      option.code.startsWith(sanitizePostcode(state.inputValue))
    );

  return (
    <PostcodeLookupContainer maxWidth={false}>
      <Autocomplete
        color="primary"
        options={tmpPostcodeList}
        getOptionLabel={(option) =>
          instanceOfPostcode(option) ? option.formattedCode : option
        }
        freeSolo
        fullWidth
        filterOptions={filterOptions}
        onInputChange={onInputChange}
        onChange={onChange}
        value={postcode}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            color="primary"
            label={t("map.postcodeInput.helperText")}
            variant="outlined"
            placeholder={t("map.postcodeInput.placeholder")}
            error={inputError}
            fullWidth
          />
        )}
      />
    </PostcodeLookupContainer>
  );
}

export const PostcodeLookupContainer = styled(Container)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(1)};
  width: 100%;
  display: block;
  ${theme.breakpoints.up("sm")} {
    padding: 0;
  }
`
);

function sanitizePostcode(postcode: string): string {
  return (postcode || "").toLowerCase().replace(/[^a-z0-9]/gi, "");
}
