import { CircularProgress } from "@mui/material";
import { Source, Layer, MapRef, Marker } from "react-map-gl";
import { useSelector } from "react-redux";
import {
  RootState,
  selectTmpDeletedFeatures,
  selectAllArableFieldsWithNotDeletedBoundaries,
  getArableFieldsWithNoTilesForNotDeletedFieldBoundaries,
  getArableFieldsCenterWithNoTilesForNotDeletedFieldBoundaries,
  selectAllArableFields,
  getAllArableFieldGeoms,
  ArableField,
} from "model";
import { Feature, FeatureCollection } from "geojson";
import { useRouteMatch } from "react-router-dom";
import { MutableRefObject } from "react";
import DiaryEntryLocationMarkerWithScroll from "./DiaryEntryLocationMarkerWithScroll";

interface Props {
  editedFieldId?: string;
  fieldId?: string;
  mapRef: MutableRefObject<MapRef | null>;
  setTooltipOverMarker: (value: boolean) => void;
}

interface MatchProps {
  id: string;
}

export default function ArableFieldGeoJSONLayers({
  editedFieldId,
  fieldId,
  mapRef,
  setTooltipOverMarker,
}: Props) {
  const editMatch = useRouteMatch<MatchProps>("/edit-field/:id");
  const fieldGeoms = useSelector(getAllArableFieldGeoms);
  const currentArableFieldGeom = useSelector(
    (state: RootState) => fieldId && state.arableFields.entities[fieldId]?.geom
  );
  const tmpDeletedFeatures = useSelector(selectTmpDeletedFeatures);
  const arableFieldsWithNoTiles = useSelector(
    getArableFieldsWithNoTilesForNotDeletedFieldBoundaries
  ).filter((fieldGuid) => fieldGuid !== editedFieldId); // TODO: move to selector

  const arableFieldsWithNoTilesCenters = useSelector(
    getArableFieldsCenterWithNoTilesForNotDeletedFieldBoundaries
  ).filter((field) => field.guid !== editedFieldId); // TODO: move to selector;

  const zoom = mapRef.current?.getMap()?.getZoom();
  const allFields = useSelector(selectAllArableFields);

  return (
    <>
      <Source
        id="arable-field-outlines"
        data={fieldGeoms as FeatureCollection}
        type="geojson"
      >
        <Layer
          id="arable-field-fill"
          type="fill"
          //filter out fill of field thats being edited
          filter={["!=", "id", editMatch?.params.id || ""]}
          paint={{ "fill-opacity": 0 }}
        />
        <Layer
          id="arable-field-fill-no-data"
          type="fill"
          //only show this if a field has no tiles
          filter={["in", ["get", "id"], ["literal", arableFieldsWithNoTiles]]}
          paint={{ "fill-color": "#666666" }}
        />
        <Layer
          id="arable-outline"
          type="line"
          paint={{
            "line-color": "#666666",
            "line-width": [
              "interpolate",
              ["exponential", 2],
              ["zoom"],
              10,
              ["*", 5, ["^", 2, -6]],
              24,
              ["*", 5, ["^", 2, 7]],
            ],
            "line-offset": [
              "interpolate",
              ["exponential", 2],
              ["zoom"],
              10,
              ["*", 5 / 2 - 1, ["^", 2, -6]],
              24,
              ["*", 5 / 2 - 1, ["^", 2, 5]],
            ],
          }}
          layout={{
            "line-join": "miter",
          }}
        />
      </Source>
      {currentArableFieldGeom &&
        fieldId &&
        fieldId !== editedFieldId &&
        tmpDeletedFeatures.indexOf(fieldId) === -1 && (
          <Source
            id="arable-field-highlight"
            data={currentArableFieldGeom as Feature}
            type="geojson"
          >
            <Layer
              id="arable-field-highlight"
              type="line"
              paint={{
                "line-color": "#FFFFFF",
                "line-width": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5, ["^", 2, -6]],
                  24,
                  ["*", 5, ["^", 2, 7]],
                ],
                "line-offset": [
                  "interpolate",
                  ["exponential", 2],
                  ["zoom"],
                  10,
                  ["*", 5 / 2 - 1, ["^", 2, -6]],
                  24,
                  ["*", 5 / 2 - 1, ["^", 2, 5]],
                ],
              }}
              layout={{
                "line-join": "miter",
              }}
            />
          </Source>
        )}
      {zoom &&
        zoom >= 13 &&
        !editedFieldId &&
        allFields.map((field: ArableField) => {
          return (
            <DiaryEntryLocationMarkerWithScroll
              setTooltipOverMarker={setTooltipOverMarker}
              key={`arable-diary-locarion-markers-${field.guid}`}
              zoom={zoom}
              currentField={field}
            />
          );
        })}

      {zoom &&
        zoom >= 15 &&
        arableFieldsWithNoTilesCenters.map((field) => {
          const { coordinates } = field.center.geometry;
          return (
            <Marker
              key={`arable-no-tiles-field-${field.guid}`}
              longitude={coordinates[0]}
              latitude={coordinates[1]}
            >
              <CircularProgress size={20} />
            </Marker>
          );
        })}
    </>
  );
}
