import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  unwrapResult,
} from "@reduxjs/toolkit";
import { default as decode } from "jwt-decode";

import { Feature } from "@turf/turf";

import {
  fetchAnimalGroupsById,
  fetchstockClassesById,
  fetchUserById,
  fetchFarmById,
  setCurrentFarmId,
  fetchFieldsById,
  getLatestFieldTiles,
  resetFields,
  resetAnimalGroups,
  resetMap,
  fetchTilesByIds,
  RootState,
  Farm,
  getFieldsWithNoTiles,
  invalidateFieldData,
  User,
  Field,
  getAllReseedEventsForFarm,
  fetchArableFieldsByFarmId,
  getLatestArableFieldTiles,
  getArableFieldsWithNoTiles,
  invalidateArableFieldData,
  ARABLE_TYPE,
  resetArableFields,
  resetFeedStock,
  resetFeedPurchaseEntries,
  getFeedStock,
  getAllFeedPurchaseEventsForFarm,
  BEGINNING_OF_TIME,
  getAllFeedEventsForFarm,
  resetFeedEventEntries,
  getYieldSummary,
  resetYieldSummary,
  getAllYieldEventsForFarm,
  resetYieldEventEntries,
  resetFertilizerEventEntries,
  getAllFertilizerEventsForFarm,
  getAllFieldPreparationEventsForFarm,
  resetFieldPreparationEntries,
} from "model";
import { logRocket, mixpanel } from "../common/analytics";
import { getReadingsForField } from "./manualInputSlice";
import { EditorSelectionType } from "features/mapDrawTools/enums";
import { getRedirectUrlWithToken, redirect } from "common/utils/Hooks";
import {
  getDefaultStockClassDemandTemplates,
  getDefaultStockClasses,
} from "./defaultStockClassesSlice";
import { fetchStockClassBirthingPeriodsById } from "./stockClassBirthingPeriodSlices";
import { fetchStockClassFeedAllocationsById } from "./stockClassFeedAllocationSlice";
import { GRASS_TYPE } from "model/fieldSlice";
import {
  getAllDiaryEntriesForFarm,
  resetDiaryEntries,
} from "./diaryEntrySlice";

type LoginData = {
  mail: string;
  password: string;
};

type PasswordResetEmailData = {
  mail: string;
};

export type TokenResponse = {
  token: string;
};

export type PasswordResetResponse = {
  message: string;
  emailSent: boolean;
};

export type JWT = {
  user: string;
  iat: number;
  exp: number;
};

type AppSliceState = {
  // The login flow consists of us fetching the token and then
  // initializing the app (c.f. fetchToken, initializeApp). We
  // use the loginInProgress state to combine the two states,
  // so that we can block the login form while logging in.
  loginInProgress: boolean;
  loggedIn: boolean;
  locale: string;
  isEditing: boolean;
  drawerOpen: boolean;
  loading: "idle" | "pending";
  authError?: boolean;
  tmpFieldDetails?: TmpFieldDetails;
  growthRate?: number;
  tmpFeatures: Array<Feature>;
  tentativeFeature: Feature | null; // is set only when user draws the field for the first time
  editorIsInTentativeMode: boolean;
  editorSelectionType: EditorSelectionType;
  tmpDeletedFeatures: Array<string>;
  proposedEventsModalOpen: boolean;
  certificationProgramApplicationModalOpen: boolean;
  fieldCreatedDialogOpen: boolean;
  moveAnimalsDialogDetails: MoveAnimalsDialogDetails;
};

export type MoveAnimalsDialogDetails = {
  open: boolean;
  currentFieldWithAnimals?: Field;
  newFieldWithAnimals?: Field;
};

export type TmpFieldDetails = {
  guid: string;
  name: string;
  farm: string;
  geom?: Feature;
  area?: number;
  animalGroup?: string;
  grassType?: GRASS_TYPE;
  arableType?: ARABLE_TYPE;
};

export const initializeApp = createAsyncThunk(
  "app/initialize",
  async (_, { dispatch }) => {
    mixpanel.track("Loading token from localstorage");
    const token = window.localStorage.getItem("jwt");
    if (!token) {
      return;
    }

    const jwtDecoded = decode<JWT>(token);
    mixpanel.track("Validating token");

    let user: User;
    try {
      user = await dispatch(fetchUserById(jwtDecoded.user)).then(unwrapResult);
      if (user && user.guid) {
        mixpanel.track("Token accepted");
      } else {
        mixpanel.track("Token not accepted, no user");
      }
    } catch (e) {
      mixpanel.track("Token not accepted, exception");
      throw e; // rethrowing the error
    }

    // Redirect only after sign in, to make sure the token is valid
    const redirectUrl = getRedirectUrlWithToken();
    if (user && user.guid && redirectUrl) {
      redirect();
      return;
    }

    user.lang && dispatch(setLocale(user.lang));
    const farmIds = user.farms;

    mixpanel.identify(jwtDecoded.user);
    logRocket.identify(jwtDecoded.user);

    // With Mixpanel's identity merge, we need to explicitly store the user guid as
    // a property and then use it in reports; because distinct id can be random uuids
    mixpanel.register({
      user_id: jwtDecoded.user,
      user_type: user.userType,
      gitsha: process.env.REACT_APP_GITSHA,
    });
    mixpanel.track("Initializing app");

    // not waiting for these to finish
    void dispatch(getDefaultStockClasses());
    void dispatch(getDefaultStockClassDemandTemplates());

    if (!farmIds || farmIds.length === 0) return;

    // get all farms and store them in the redux store
    // so we can display the name in the farm switch dropdown
    return await Promise.all(
      farmIds.map((id) => dispatch(fetchFarmById(id)).then(unwrapResult))
    );
  }
);

export const switchFarm = createAsyncThunk(
  "app/switchFarm",
  async (farmId: string, { dispatch }) => {
    const farm = await dispatch(fetchFarmById(farmId)).then(unwrapResult);
    return dispatch(initializeFarm(farm));
  }
);

const getGrowthRate = createAsyncThunk(
  "app/getGrowthRate",
  async (fieldId: string) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/fields/${fieldId}/growthRate`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
        },
      }
    );
    return res.json();
  }
);

export const initializeFarm = createAsyncThunk(
  "app/initializeFarm",
  async (farm: Farm, { dispatch, getState }) => {
    dispatch(setCurrentFarmId(farm.guid));
    dispatch(resetFields());
    dispatch(resetArableFields());
    dispatch(resetAnimalGroups());
    dispatch(resetMap());
    dispatch(resetDiaryEntries());
    dispatch(resetFeedStock());
    dispatch(resetFeedPurchaseEntries());
    dispatch(resetFieldPreparationEntries());
    dispatch(resetFeedEventEntries());
    dispatch(resetYieldSummary());
    dispatch(resetYieldEventEntries());
    dispatch(resetFertilizerEventEntries());

    await dispatch(
      getFeedStock({
        farmGuid: farm.guid,
        timeAfter: BEGINNING_OF_TIME,
      })
    );

    await dispatch(
      getAllFeedPurchaseEventsForFarm({
        farmGuid: farm.guid,
        feedPurchaseEventTimeAfter: BEGINNING_OF_TIME,
      })
    );

    await dispatch(
      getAllFieldPreparationEventsForFarm({
        farmGuid: farm.guid,
        fieldPreparationEventTimeAfter: BEGINNING_OF_TIME,
      })
    );

    await dispatch(
      getAllFeedEventsForFarm({
        farmGuid: farm.guid,
        feedEventTimeAfter: BEGINNING_OF_TIME,
      })
    );

    await dispatch(
      getYieldSummary({
        farmGuid: farm.guid,
        yieldEventTimeAfter: BEGINNING_OF_TIME,
      })
    );

    await dispatch(
      getAllYieldEventsForFarm({
        farmGuid: farm.guid,
        yieldEventTimeAfter: BEGINNING_OF_TIME,
      })
    );
    await dispatch(
      getAllFertilizerEventsForFarm({
        farmGuid: farm.guid,
        fertilizerApplicationTimeAfter: BEGINNING_OF_TIME,
      })
    );

    await dispatch(fetchArableFieldsByFarmId(farm.guid));
    const arableTileIds = getLatestArableFieldTiles(getState() as RootState);
    void dispatch(fetchTilesByIds(arableTileIds as string[]));
    const arableFieldsWithNoTiles = getArableFieldsWithNoTiles(
      getState() as RootState
    );
    arableFieldsWithNoTiles.forEach(
      (fieldId) => void dispatch(invalidateArableFieldData(fieldId))
    );

    if (farm.fields && farm.fields.length !== 0) {
      await dispatch(getAllReseedEventsForFarm({ farmId: farm.guid }));
      await dispatch(fetchFieldsById(farm.fields));

      const tileIds = getLatestFieldTiles(getState() as RootState);
      void dispatch(fetchTilesByIds(tileIds as string[]));
      const fieldsWithNoTiles = getFieldsWithNoTiles(getState() as RootState);
      fieldsWithNoTiles.forEach(
        (fieldId) => void dispatch(invalidateFieldData(fieldId))
      );

      // for now, we take a random (first) field for getting the growth rate,
      // since it is most likely the same for every field anyways
      void dispatch(getGrowthRate(farm.fields[0]));
      void Promise.all(
        farm.fields.map((id) => dispatch(getReadingsForField(id)))
      );
      void dispatch(openProposedEventsModal()); // it will open immediately after the login when we load at least some events
      await dispatch(getAllDiaryEntriesForFarm({ farmId: farm.guid }));
    }

    if (farm.animalGroups) {
      const animalGroups = await dispatch(
        fetchAnimalGroupsById(farm.animalGroups)
      ).then(unwrapResult);
      const stockClasses = await dispatch(
        fetchstockClassesById(animalGroups.flatMap((a) => a.stockClasses))
      ).then(unwrapResult);
      await dispatch(
        fetchStockClassBirthingPeriodsById(
          stockClasses.flatMap((a) => a.birthingPeriods)
        )
      );

      await dispatch(
        fetchStockClassFeedAllocationsById(
          stockClasses.flatMap((a) => a.feedAllocations!)
        )
      );
    }

    void dispatch(loadCertificationProgramApplicationModalClosedState());
  }
);

export const fetchToken = createAsyncThunk<
  Promise<TokenResponse> | undefined,
  LoginData
>("app/fetchToken", async (login) => {
  const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/tokens/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      mail: login.mail,
      password: login.password,
    }),
  });
  return res.json();
});

export const sendPasswordResetEmail = createAsyncThunk<
  Promise<PasswordResetResponse> | undefined,
  PasswordResetEmailData
>("app/sendPasswordResetEmail", async (login) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v1/users/passwordReset`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        mail: login.mail,
      }),
    }
  );
  return res.json();
});

export const loadCertificationProgramApplicationModalClosedState = createAction(
  "certificationProgramApplicationModal/load",
  function () {
    return {
      payload: {
        certificationProgramApplicationModalClosed:
          !!window.localStorage.getItem(
            "certificationProgramApplicationModalClosed"
          ),
      },
    };
  }
);

export const closeCertificationProgramApplicationModal = createAction(
  "certificationProgramApplicationModal/close",
  function () {
    window.localStorage.setItem(
      "certificationProgramApplicationModalClosed",
      "true"
    );
    return {
      payload: {
        certificationProgramApplicationModalClosed: true,
      },
    };
  }
);

const initialAppState: AppSliceState = {
  loginInProgress: false,
  loggedIn: false,
  locale: navigator.language || "en",
  isEditing: false,
  drawerOpen: true,
  loading: "idle",
  authError: undefined,
  tmpFieldDetails: undefined,
  tmpFeatures: [],
  tentativeFeature: null,
  editorIsInTentativeMode: false,
  editorSelectionType: EditorSelectionType.NONE,
  tmpDeletedFeatures: [],
  proposedEventsModalOpen: true,
  certificationProgramApplicationModalOpen: true,
  fieldCreatedDialogOpen: false,
  moveAnimalsDialogDetails: { open: false },
};

export const selectTmpDeletedFeatures = (state: RootState) =>
  state.app.tmpDeletedFeatures;

const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    resetApp: () => initialAppState,
    openDrawer: (state) => {
      state.drawerOpen = true;
    },
    closeDrawer: (state) => {
      state.drawerOpen = false;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setIsEditing: (state) => {
      state.isEditing ? (state.isEditing = false) : (state.isEditing = true);
    },
    setTmpFeatures: (state, action: PayloadAction<Array<Feature>>) => {
      state.tmpFeatures = action.payload;
    },
    setTentativeFeature: (state, action: PayloadAction<Feature | null>) => {
      state.tentativeFeature = action.payload;
    },
    setEditorIsInTentativeMode: (state, action: PayloadAction<boolean>) => {
      state.editorIsInTentativeMode = action.payload;
    },
    setEditorSelectionType: (
      state,
      action: PayloadAction<EditorSelectionType>
    ) => {
      state.editorSelectionType = action.payload;
    },
    deleteTmpFeature: (state, action: PayloadAction<string>) => {
      state.tmpDeletedFeatures.push(action.payload);
    },
    deleteTmpFeatures: (state) => {
      state.tmpFeatures = [];
      state.tmpDeletedFeatures = [];
    },
    openProposedEventsModal: (state) => {
      state.proposedEventsModalOpen = true;
    },
    closeProposedEventsModal: (state) => {
      state.proposedEventsModalOpen = false;
    },
    openAfterFieldCreatedDialog: (state) => {
      state.fieldCreatedDialogOpen = true;
    },
    closeAfterFieldCreatedDialog: (state) => {
      state.fieldCreatedDialogOpen = false;
    },
    openMoveOutEventDialog: (
      state,
      payload: PayloadAction<MoveAnimalsDialogDetails>
    ) => {
      state.moveAnimalsDialogDetails = payload.payload;
    },
    closeMoveOutEventDialog: (
      state,
      payload: PayloadAction<MoveAnimalsDialogDetails>
    ) => {
      state.moveAnimalsDialogDetails = payload.payload;
    },
  },
  extraReducers: {
    [fetchToken.pending.type]: (state, action) => {
      state.loading = "pending";
      state.loginInProgress = true;
    },
    [fetchToken.fulfilled.type]: (state, action) => {
      window.localStorage.setItem("jwt", action.payload.token);
      state.loading = "idle";
      state.loginInProgress = true;
    },
    [fetchToken.rejected.type]: (state, action) => {
      state.authError = action.error ? true : false;
      state.loading = "idle";
      state.loginInProgress = false;
    },
    [getGrowthRate.fulfilled.type]: (state, { payload }) => {
      state.growthRate = payload.growthRate;
    },
    [initializeApp.fulfilled.type]: (state, action) => {
      state.loggedIn = true;
      state.loginInProgress = false;
    },
    [initializeFarm.pending.type]: (state, action) => {
      state.loading = "pending";
      state.loginInProgress = true;
    },
    [initializeFarm.fulfilled.type]: (state, action) => {
      state.loading = "idle";
      state.loginInProgress = false;
    },
    [initializeFarm.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.loginInProgress = false;
    },
    [loadCertificationProgramApplicationModalClosedState.type]: (
      state,
      action
    ) => {
      state.certificationProgramApplicationModalOpen =
        !action.payload.certificationProgramApplicationModalClosed;
    },
    [closeCertificationProgramApplicationModal.type]: (state) => {
      state.certificationProgramApplicationModalOpen = false;
    },
  },
});

export const {
  setTmpFeatures,
  setTentativeFeature,
  deleteTmpFeatures,
  deleteTmpFeature,
  closeDrawer,
  openDrawer,
  setIsEditing,
  setEditorIsInTentativeMode,
  setEditorSelectionType,
  setLocale,
  closeProposedEventsModal,
  openProposedEventsModal,
  resetApp,
  openAfterFieldCreatedDialog,
  closeAfterFieldCreatedDialog,
  openMoveOutEventDialog,
  closeMoveOutEventDialog,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
