import {
  Editor,
  DrawPolygonMode,
  Position,
  EditorProps,
  BaseEvent,
  SelectAction,
} from "react-map-gl-draw";
import {
  ImmutableFeatureCollection,
  Feature,
  Polygon,
} from "@nebula.gl/edit-modes";
import { throttle, uniq } from "lodash";
import styled from "styled-components";
import { THROTTLE_MS } from "./const";

type PointerMoveHandler = (event: BaseEvent) => void;

export class RemovableEditor extends Editor {
  protected throttleMs = THROTTLE_MS; // TODO: hardcoding because can't extend Editor props easily
  protected handlePointerMoveThrottled: PointerMoveHandler | null | undefined =
    null;
  constructor(props: EditorProps) {
    super(props);
    this.handlePointerMoveThrottled = throttle(
      this.handlePointerMoveAux.bind(this),
      this.throttleMs
    );
    this._events.keyup = (event: any) => this.handleKeyUp(event);
    this._events.pointermove = (event: any) =>
      this.handlePointerMoveThrottled && this.handlePointerMoveThrottled(event);
  }

  protected handlePointerMoveAux(event: BaseEvent): void {
    try {
      const unwrappedEvent: any = this._getEvent(event);
      this._onPointerMove(unwrappedEvent);
    } catch (e) {
      console.log("Exception while RemovableEditor handlePointerMoveAux");
      console.error(e);
    }
  }

  private handleKeyUp(event: any) {
    if (this.props.onUpdate) {
      this.props.onUpdate({
        data: this._getFeatureCollection()?.getObject()?.features,
        editType: "onKeyUp", // brand new editType
        editContext: {
          event,
        },
      });
    }
  }

  public componentDidMount() {
    const editor = document?.getElementById("editor");
    if (editor) {
      // make it accept keyboard events
      editor.setAttribute("tabIndex", "0");
      editor.focus();
    }
    super.componentDidMount();
  }

  public canDeleteLastTentativePosition(): boolean {
    if (this._modeHandler instanceof DrawPolygonMode) {
      const clickSequence = this._modeHandler.getClickSequence();
      if (clickSequence && clickSequence.length > 0) {
        // tentative mode
        return true;
      }
    }
    return false;
  }

  public canDeleteSelectedHandles(handles: number[]): boolean {
    const feature = this.getSelectedFeature();
    if (!feature) {
      return false;
    }

    return (
      handles &&
      handles.length > 0 &&
      feature.geometry.coordinates[0].length - handles.length >= 4
    ); // so that polygon still has at least 4 points after removal
  }

  private getSelectedFeature(): any {
    const featureIndex = this._getSelectedFeatureIndex();
    if (typeof featureIndex === "number") {
      // feature selected
      const featureCollection =
        this._getFeatureCollection()?.getObject()?.features;
      return featureCollection[featureIndex];
    }
    return null;
  }

  public getSelectedHandleIndexes(): Array<number> {
    const feature = this.getSelectedFeature();
    if (!feature) {
      return [];
    }

    return this.state.selectedEditHandleIndexes || [];
  }

  public selectHandles(handles: Array<number>) {
    const newState = { selectedEditHandleIndexes: handles };
    this.setState(newState);
  }

  public deleteLastTentativePosition(): number {
    if (this.canDeleteLastTentativePosition()) {
      this._modeHandler._clickSequence.pop();
      this.setState({ lastPointerMoveEvent: undefined as any });
      if (this.props.onUpdate) {
        this.props.onUpdate({
          data: this._getFeatureCollection()?.getObject()?.features,
          editType: "removeTentativePosition", // brand new editType
          editContext: {},
        });
      }
      this.forceUpdate(); // force re-render
    }
    return this._modeHandler._clickSequence.length;
  }

  /**
   * returns remaining selected handle indexes
   * @param selectedHandleIndexes
   * @returns
   */
  public deleteSelectedHandles(selectedHandleIndexes: number[]): number[] {
    if (this.canDeleteSelectedHandles(selectedHandleIndexes)) {
      // Note: here we are expecting polygon only!
      const featureIndex = this._getSelectedFeatureIndex();
      const newFeatures = this.deleteHandles(
        featureIndex,
        selectedHandleIndexes
      ) as unknown as ImmutableFeatureCollection;
      if (this.props.onUpdate) {
        this.props.onUpdate({
          data: newFeatures.getObject().features,
          editType: "removeHandle", // brand new editType
          editContext: {
            featureIndexes: [featureIndex],
          },
        });
      }
      // TODO: we can find the handle closest to the removed one and select it
      this.forceUpdate(); // force re-render
      return [];
    }
    return selectedHandleIndexes;
  }

  public getTentativePolygon(): Feature | null {
    const clickSequence = uniq<Position>(this._modeHandler.getClickSequence());
    if (clickSequence && clickSequence.length > 2) {
      // TODO: verify it doesn't break if user clicks on the same point
      const polygonToAdd: Polygon = {
        type: "Polygon",
        coordinates: [[...clickSequence, clickSequence[0]]],
      };
      const feature: Feature = {
        type: "Feature",
        properties: { guid: "new" },
        geometry: polygonToAdd,
      };
      return feature;
    }
    return null;
  }

  // TODO: is it possible to improve performance with shouldComponentUpdate, e.g. on drag state.lastPointerMoveEvent is the only one that changes

  public render() {
    return <MapEditorContainer>{super.render()}</MapEditorContainer>;
  }
}

// TODO: standardize z-indexes
const MapEditorContainer = styled.div(
  ({ theme }) => `
  z-index: 20000;
  width: 100%;
  height: 100%;
`
);
