import { GeneralFeedPurchaseEvent } from "model";
import { useState } from "react";
import FeedPurchaseEventMoreMenu from "./FeedPurchaseEventMoreMenu";
import UpdateFeedPurchaseEvent from "./UpdateFeedPurchaseEvent";

export default function UpdateFeedPurchaseEventDialog({
  feedPurchaseEvent,
  menuAnchor,
  closeMenu,
}: {
  feedPurchaseEvent: GeneralFeedPurchaseEvent;
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!feedPurchaseEvent) {
    return null;
  }
  return (
    <>
      <FeedPurchaseEventMoreMenu
        feedPurchaseEvent={feedPurchaseEvent}
        close={closeMenu}
        anchor={menuAnchor}
        setDialogOpen={setDialogOpen}
      />
      <UpdateFeedPurchaseEvent
        feedPurchaseEvent={feedPurchaseEvent}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
}
