import {
  TextField,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  Typography,
  FilterOptionsState,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { StockClass, useAppSelector } from "model";
import { useTranslation } from "common/locales";

import styled, { css } from "styled-components";
import { DefaultStockClassDemandTemplate } from "model/defaultStockClassesSlice";
import { useCallback, useState } from "react";

interface StockClassInputProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  index: number;
  stockClass: StockClass;
  error?: string;
  handleInputChange: any;
  validateField: any;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ChangeEventType = React.ChangeEvent<{}>;
type StockClassDemandTemplateAutocompleteOption =
  Required<DefaultStockClassDemandTemplate> & {
    stockClassName: string;
    feedingName: string;
    firstLetter: string;
  };

type UserInputOption = {
  inputValue: string;
  title: string;
};

type AutocompleteOption = StockClassDemandTemplateAutocompleteOption;
type FilteredAutocompleteOption = AutocompleteOption | UserInputOption;
type SelectedAutocompleteOption = FilteredAutocompleteOption | string;

export function instanceOfStockClassDemandTemplateAutocompleteOption(
  object: any
): object is StockClassDemandTemplateAutocompleteOption {
  return typeof object === "object" && "name" in object;
}

export function instanceOfUserInputOption(
  object: any
): object is UserInputOption {
  return typeof object === "object" && "inputValue" in object;
}

export default function StockClassDemandTemplateAutocomplete({
  stockClass,
  index,
  setFieldValue,
  error,
}: StockClassInputProps) {
  const { t } = useTranslation();
  const demandTemplates = useAppSelector(
    (state) => state.defaultStockClasses.demandTemplates
  );

  const autoCompleteOptions: AutocompleteOption[] = demandTemplates.map(
    (option) => {
      const stockClassName = t(
        `defaultStockClass.${option.stockClassStageName}`
      );
      const feedingName = t(`defaultFeeding.${option.name}`);
      const firstLetter = stockClassName[0].toUpperCase();
      return {
        stockClassName,
        feedingName,
        firstLetter,
        ...option,
      };
    }
  );

  const [inputValue, setInputValue] = useState<string>("");

  // user types something, update the input and Formik
  // eslint-disable-next-line @typescript-eslint/ban-types
  const onInputChange = useCallback(
    (e: ChangeEventType, newInputValue: string) => {
      setInputValue(newInputValue);
      setFieldValue(`stockClasses[${index}].name`, newInputValue, false);
    },
    [index]
  );

  // When user selected from the list or typed something and hit enter, we need to propagate new value back to Formik
  const onChange = useCallback(
    (e: ChangeEventType, newValue: SelectedAutocompleteOption | null) => {
      if (!newValue) {
        return;
      }

      // Create new value from the user typed string, when selected "Use xxx" from the list
      if (newValue && instanceOfUserInputOption(newValue)) {
        setInputValue(newValue.inputValue);
        setFieldValue(
          `stockClasses[${index}].name`,
          newValue.inputValue,
          false
        );
        return;
      }

      // Create new value from the selected option
      if (instanceOfStockClassDemandTemplateAutocompleteOption(newValue)) {
        const name = `${newValue.stockClassName}, ${newValue.feedingName}`; // stock class name

        // update text input value in the autocomplete
        setInputValue(name);

        // update default stock class name in the Formik
        setFieldValue(
          `stockClasses[${index}].defaultStockClassName`,
          newValue.defaultStockClass.name,
          false
        );

        // update stock class name in the Formik
        setFieldValue(`stockClasses[${index}].name`, name, false);

        // update averageAnimalWeightKg in the Formik
        setFieldValue(
          `stockClasses[${index}].averageAnimalWeightKg`,
          newValue.defaultStockClass.averageWeightKg.toFixed(2),
          false
        );

        // update consumptionKgPerDay in the Formik
        setFieldValue(
          `stockClasses[${index}].consumptionKgPerDay`,
          (
            newValue.allocatedBodyWeightFraction *
            newValue.defaultStockClass.averageWeightKg
          ).toFixed(2),
          false
        );

        return;
      }

      // Create new value just from the user typed string
      setInputValue(newValue);
      setFieldValue(`stockClasses[${index}].name`, newValue, false);

      //TODO: not validating stock class name, formik validation is having some issues
    },
    [index, stockClass]
  );

  // filtering autocomplete options by user input string
  const filterOptions = (
    options: StockClassDemandTemplateAutocompleteOption[],
    state: FilterOptionsState<StockClassDemandTemplateAutocompleteOption>
  ) => {
    const inputValue = sanitize(state.inputValue);
    return options.filter((option) => {
      const stockClassName = option.stockClassName.toLocaleLowerCase();
      const feedingName = option.feedingName.toLocaleLowerCase();
      return (
        stockClassName.indexOf(inputValue) !== -1 ||
        feedingName.indexOf(inputValue) !== -1 ||
        inputValue.startsWith(stockClassName) ||
        inputValue.startsWith(feedingName)
      );
    });
  };

  return (
    <Autocomplete
      fullWidth
      disablePortal
      freeSolo
      forcePopupIcon={true}
      inputValue={inputValue}
      options={autoCompleteOptions.sort((a, b) => {
        const cmp = -b.stockClassName.localeCompare(a.stockClassName); // first sort by stock class name
        return cmp === 0
          ? a.position - b.position // if the same stock class, sort by id
          : cmp;
      })}
      filterOptions={(options: any[], params) => {
        const filtered: FilteredAutocompleteOption[] = filterOptions(
          options,
          params
        );

        const { inputValue } = params;

        if (inputValue !== "") {
          // add user input as an option to the list
          filtered.unshift({
            inputValue,
            title: t("stockClassDemandTemplateAutocomplete.customValue", {
              inputValue,
            }),
          });
        }

        return filtered;
      }}
      getOptionLabel={(option: any) => {
        // Determines string value for a given option

        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }

        // "Add xxx" value from the list
        if (instanceOfUserInputOption(option)) {
          return option.inputValue;
        }

        // Stock class demand template
        if (instanceOfStockClassDemandTemplateAutocompleteOption(option)) {
          return `${option.stockClassName} ${option.feedingName}`;
        }

        return option as string;
      }}
      onInputChange={onInputChange}
      onChange={onChange}
      value={stockClass.name}
      renderOption={(props, option: any) => {
        if (instanceOfStockClassDemandTemplateAutocompleteOption(option)) {
          return (
            <List {...(props as any)} size="sm">
              <ListItem>
                <ListItemText
                  primary={t(
                    "stockClassDemandTemplateAutocomplete.listItemTitle",
                    {
                      stockClassName: option.stockClassName,
                      allocatedBodyWeightFraction: (
                        option.allocatedBodyWeightFraction * 100
                      ).toFixed(1),
                    }
                  )}
                  secondary={option.feedingName}
                />
              </ListItem>
            </List>
          );
        } else {
          return (
            <List {...(props as any)} size="sm">
              <ListItem>
                <ListItemText
                  primary={<Typography>{option.title || option}</Typography>}
                />
              </ListItem>
            </List>
          );
        }
      }}
      renderInput={(params) => (
        <Field
          {...params}
          label={t("edit.stockClassName.label")}
          id={`stock-class-input-name-${index}`}
          name={`stockClasses[${index}].name`}
          required
          autoFocus
          fullWidth
          margin="normal"
          variant="standard"
          error={!!error}
          helperText={error}
        />
      )}
      popupIcon={<ArrowDropDownIcon />}
      selectOnFocus
      handleHomeEndKeys
      blurOnSelect
      autoHighlight
    />
  );
}

const Field = styled(TextField)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(2)};
  `
);

function sanitize(value: string): string {
  return (value || "").toLocaleLowerCase().trim();
}
