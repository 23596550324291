export const messages = {
  "settings.title": "Profildaten",
  "settings.name": "Name",
  "settings.password": "Passwort",
  "settings.mail": "Email",
  "settings.phone": "Telefon",
  "settings.address": "Rechnungsadresse",
  "settings.button.close": "Schließen",
  "settings.button.save": "Speichern",
  "settings.edit": "Profil Bearbeiten",
  "settings.logout": "Ausloggen",
  "settings.nodata": "nicht eingegeben",
  "passwordChange.success": "Passwort geändert",
  "login.heading": "Weidemanagement App",
  "login.dialog.title": "Anmelden",
  "login.dialog.reset": "Passwort Vergessen",
  "login.reset.popover": "Bitte schreibe eine Email an hello@ruumi.io",
  "login.dialog.signup": "Neu Registrieren",
  "login.mail.label": "Email",
  "login.password.label": "Passwort",
  "login.button.label": "Einloggen",
  "signup.heading": "Weidemanagement App",
  "signup.dialogTitle.userDetails": "Gib deine Details ein",
  "signup.dialogTitle.farmDetails": "Erzähl uns über dein Hof",
  "signup.button.signup": "Registrieren",
  "signup.button.next": "Weiter",
  "signup.button.back": "Zurück",
  "signup.button.getStarted": "Loslegen",
  "signup.mail.label": "Email",
  "signup.name.label": "Name",
  "signup.phone.label": "Tel.",
  "signup.password.label": "Passwort",
  "signup.size.label": "Größe in ha",
  "signup.farmType.label": "Farm Typ",
  "signup.TandC":
    "Indem du dich registrierst, erklärst du dich mit unseren <a>Datenschutz Bestimmungen</a> einverstanden.",
  "signup.farmType.beef": "Rinder",
  "signup.farmType.beef_sheep": "Rinder & Schaafe",
  "signup.farmType.sheep": "Schaaf",
  "signup.farmType.dairy": "Milchkühe",
  "signup.farmType.other": "Sonstiges",
  "signup.cbInterest.label": "Interesse am Carbon Farming",
  "signup.cbInterest.yes": "Ja",
  "signup.cbInterest.no": "Nein",
  "signup.welcome.enterFields.title": "Felder eingeben",
  "signup.welcome.title": "Willkommen",
  "signup.welcome.text":
    "Hier finden Sie einige Tipps für den Anfang. Wenn Sie Fragen haben, können Sie sich jederzeit an uns wenden",
  "signup.welcome.link": "Kontakt zum Support",
  "signup.welcome.enterFields.text":
    "Mit wenigen Klicks können Sie Felder direkt auf der Karte einzeichnen. Wir berechnen die Grasbedeckung innerhalb von Sekunden, damit Sie sie sehen können. Wenn Sie einen großen Betrieb haben, können Sie uns eine Datei mit Ihren Feldgrenzen schicken und wir laden sie für Sie hoch.",
  "signup.welcome.enterAnimalGroups.title": "Tiergruppen eingeben",
  "signup.welcome.enterAnimalGroups.text":
    "Wir erkennen das Grasangebot aus dem Weltraum, aber wir brauchen Ihre Hilfe für den zweiten Teil der Gleichung: die Nachfrage nach Tieren. Legen Sie Tiergruppen mit so vielen Tierklassen an, wie Sie möchten. Wenn Sie Ratschläge wünschen, wie Sie den genauen Bedarf pro Tier ermitteln können, nehmen Sie einfach Kontakt mit uns auf.",
  "signup.welcome.planRotations.title": "Planen Sie Ihre Rotationen",
  "signup.welcome.planRotations.text":
    "Sobald Sie die Felder und Tiergruppen eingerichtet haben, können Sie Ihre Tiere bewegen und Ihre Umtriebe mithilfe des Graskeils planen. Ordnen Sie Felder zu oder nicht, ändern Sie die Rotationslänge und das Weideziel. Die blaue Bedarfslinie zeigt Ihnen, wo Sie Überschüsse oder Defizite haben.",
  "edit.editingField": "Weide Bearbeiten",
  "edit.addingField": "Neue Weide Hinzufügen",
  "edit.editingAnimalGroup": "Tiergruppe Bearbeiten",
  "edit.addingAnimalGroup": "Neue Tiergruppe Hinzufügen",
  "edit.fieldname.label": "Weidename",
  "edit.animalGroupName.label": "Name der Tiergruppe",
  "edit.stockClassName.label":
    "Tier Typ (neue hinzufügen oder Vorlage verwenden)",
  "edit.stockClassName.feedBudgetPlanner.label": "Tiername",
  "edit.stockClassNrOfAnimals.label": "Tieranzahl",
  "edit.stockClassAvgWeight.label": "Durchschnittliches Gewicht",
  "edit.minNumber.error": "Muss mindestens {min} sein",
  "edit.maxNumber.error": "Muss kleiner oder gleich {max} sein",
  "edit.required.error": "Dieses Feld ist erforderlich",
  "edit.number.error": "Dieses Feld muss eine Zahl sein",
  "edit.stockClassFeedType.label": "Fütterung (z.B. 'Trocken - Wartung')",
  "edit.stockClassDemand.label": "Täglicher Futterbedarf",
  "edit.stockClassDefaultStockClass.label": "Art der Tierklasse",
  "edit.weight.label": "Gruppengewicht",
  "edit.demand.label": "Futterbedarf",
  "edit.button.cancel": "Abbrechen",
  "edit.button.save": "Speichern",
  "edit.button.edit": "Bearbeiten",
  "edit.button.back": "Zurück",
  "map.drawfield.drawTools.aria": "Zeichnungswerkzeuge",
  "map.drawfield.tooltip": "Äußere Bezäunung zeichnen oder bearbeiten",
  "map.drawfield.help.tooltip": "Zeichnungshilfe",

  "map.drawfield.help.modal.title": "Feldeditor-Hilfe",
  "map.drawfield.help.modal.welcome":
    "Willkommen im ruumi-Feldeditor! Hier finden Sie einige Tipps, um mit dem Zeichnen von Feldern zu beginnen. Wenn Sie Fragen haben, können Sie sich jederzeit an uns wenden",
  "map.drawfield.help.modal.link": "Kontakt zum Support",
  "map.drawfield.help.modal.createMode.title":
    "Erstellen einer neuen Feldgrenze",
  "map.drawfield.help.modal.createMode.description":
    'Wenn noch keine Feldgrenze vorhanden ist (oder nachdem sie gelöscht wurde), drücken Sie die Schaltfläche <pencilButton></pencilButton>, um in den Modus "Erstellen" zu wechseln. Erstellen Sie dann die neue Feldgrenze, indem Sie auf die Ränder Ihres Feldes in der Karte klicken. Die Feldgrenze wird nach Ihren Klicks gezeichnet.',
  "map.drawfield.help.modal.createMode.zoomControls.description":
    "Verwenden Sie die <navIcon></navIcon>-Schaltflächen zum Vergrößern und Verkleinern für mehr Präzision.",
  "map.drawfield.help.modal.createMode.undo.description":
    'Wenn Sie auf die falsche Stelle geklickt haben, können Sie dies mit der Schaltfläche <undoButton></undoButton> rückgängig machen. Es ist nicht möglich, die Feldbegrenzung im Modus "Erstellen" zu ändern, sondern nur schrittweise rückgängig zu machen.',
  "map.drawfield.help.modal.createMode.boundaryCorrectness.description":
    'Bitte beachten Sie, dass die Feldbegrenzung eine konvexe Form mit mindestens 3 Punkten sein muss, um gespeichert zu werden. Wenn die Begrenzung korrekt ist, werden die Fläche und die Begrenzung sichtbar und die Schaltfläche "SPEICHERN" ist aktiv.',
  "map.drawfield.help.modal.createMode.finish.description":
    'Wenn Sie mit dem Zeichnen fertig sind, klicken Sie entweder auf den <pencilButton></pencilButton> oder drücken Sie "Enter", um den Modus "Erstellen" zu verlassen. Dann können Sie das Feld speichern.',
  "map.drawfield.help.modal.editMode.title":
    "Bearbeitung der bestehenden Feldgrenze",
  "map.drawfield.help.modal.editMode.description":
    'Wenn die Feldgrenze bereits vorhanden ist, wechseln Sie in den Modus "Bearbeiten", indem Sie die Schaltfläche <pencilButton></pencilButton> drücken.',
  "map.drawfield.help.modal.editMode.move.description":
    "Verschieben Sie vorhandene Punkte durch Klicken und Ziehen: <movePointImg></movePointImg>. Fügen Sie neue Punkte hinzu, indem Sie auf die Feldbegrenzung (rote Linien) klicken.",
  "map.drawfield.help.modal.editMode.select.description":
    'Doppelklicken Sie auf die Punkte, um sie auszuwählen. Die ausgewählten Punkte werden rot: <selectPointImg></selectPointImg>. Löschen Sie ausgewählte Punkte mit der Schaltfläche <deleteButton></deleteButton> oder drücken Sie die Taste "Delete". Es können mehrere Punkte auf einmal ausgewählt und gelöscht werden. Doppelklicken Sie erneut auf den ausgewählten Punkt, um die Auswahl aufzuheben.',
  "map.drawfield.help.modal.editMode.finish.description":
    'Wenn Sie mit der Bearbeitung fertig sind, klicken Sie entweder auf die <pencilButton></pencilButton> oder drücken Sie "Enter", um den Modus "Bearbeiten" zu verlassen. Dann können Sie das Feld speichern.',
  "map.drawfield.help.modal.deleteBoundary.title":
    "Löschen der gesamten Feldbegrenzung",
  "map.drawfield.help.modal.deleteBoundary.description":
    'Manchmal kann es erforderlich sein, die Feldbegrenzung von Grund auf neu zu zeichnen. Wenn Sie sich nicht im Modus "Erstellen" oder "Bearbeiten" befinden, verwenden Sie den <deleteButton></deleteButton>, um die gesamte Feldbegrenzung zu löschen. Wechseln Sie dann in den Modus "Erstellen" und zeichnen Sie die neue Begrenzung.',
  "map.drawfield.help.modal.close": "Schließen",
  "map.deletefeature.tooltip":
    "Ausgewähltes Feature löschen (auch mit Tastaturtaste Delete)",
  "map.deleteTentativePosition.tooltip":
    "Vorherigen Punkt löschen (auch mit Tastaturtaste Delete)",
  "map.deleteSelectedHandles.tooltip": "Löschen der ausgewählten Punkte",
  "map.clickOnPencilButton.hint":
    "Drücken Sie die Taste ✏️ und klicken Sie dann auf die Karte, um das Feld zu zeichnen.",
  "map.clickOnPencilButtonToEdit.hint":
    "Drücken Sie die Taste ✏️, um das Feld zu bearbeiten oder zu löschen.",
  "map.provideThreePoints.hint":
    "Bitte fügen Sie mindestens 3 Punkte hinzu, um eine konvexe Feldbegrenzung zu erstellen.",
  "map.provideValidGeometry.hint":
    "Die Feldgeometrie ist ungültig, bitte erstellen Sie eine konvexe Feldbegrenzung mit mindestens 3 Punkten.",
  "map.doubleClickToFinish.hint":
    "Klicken Sie auf den letzten Punkt oder auf die Schaltfläche ✏️, um das Zeichnen zu beenden.",
  "map.editingField.hint":
    "VVerschieben Sie die Punkte oder klicken Sie auf die Begrenzung, um eine neue hinzuzufügen, klicken Sie auf die Punkte, um sie auszuwählen. Drücken Sie zum Beenden erneut die Schaltfläche ✏️.",
  "map.deleteHandles.hint":
    "Klicken Sie erneut auf die ausgewählten Punkte, um die Auswahl aufzuheben.",
  "map.unableDeleteHandles.hint":
    "Es ist nicht möglich, ausgewählte Punkte zu entfernen, da die Feldgrenze nach dem Entfernen mindestens 3 Punkte haben muss, bitte deaktivieren Sie einige",
  "map.deletefeature.confirmation.title": "Feld löschen",
  "map.deletefeature.confirmation.description":
    "Die gesamte Feldgrenze wird entfernt, wollen Sie fortfahren?",
  "map.deletefeature.confirmation.confirm.label": "Löschen",
  "map.deletefeature.confirmation.cancel.label": "Weiter bearbeiten",
  "menu.paddocks.label": "Weiden (Vorschau)",
  "menu.herds.label": "Herden (Vorschau)",
  "menu.history.label": "Protokolle (Vorschau)",
  "warning.tooltip.title": "Überweidet!",
  "fieldinput.area.text": "Größe",
  "fieldinput.area.unit": "ha",
  "fieldinput.na": "N/A",
  "fieldinput.boundary.error": "Ungültige Weidenbegrenzung",
  "fieldinput.save.error": "Speichern nicht möglich",
  "fieldinput.assignedTo.silage": "Silage",
  "fieldinput.assignedTo.text": "Zugeordnet zu",
  "fieldinput.grassType.notsetyet": "N/A",
  "fieldinput.grassType.text": "Weidezusammensetzung",
  "fieldinput.grassType.option.PERMANENT_PASTURE": "Dauerweide",
  "fieldinput.grassType.option.HERBAL_LEY": "Weidekräuter",
  "fieldinput.grassType.option.RYE_GRASS": "Weidelgrass",
  "fieldinput.grassType.option.RYE_GRASS_AND_CLOVER": "Weidelgrass mit Kree",
  "fieldinput.grassType.option.WILDFLOWER_MEADOW": "Wildblumen",
  "fieldinput.grassType.option.LUCERNE_SAINFOIN": "Lucerne und Esparsetten",
  "fieldinput.grassType.option.BRASSICAS": "Kohl",
  "fieldinput.grassType.option.OTHER": "Andere",
  "fieldinput.arableType.option.WHEAT": "Weizen",
  "fieldinput.arableType.option.BARLEY": "Gerste",
  "fieldinput.arableType.option.OILSEED_RAPE": "Ölraps",
  "fieldinput.arableType.option.POTATO": "Kartoffeln",
  "fieldinput.arableType.option.MAIZ": "Mais",
  "fieldinput.arableType.option.CEREALS": "Getreide",
  "fieldinput.arableType.option.VEGETABLES_SALAD": "Gemüse und Salat",
  "fieldinput.arableType.option.OTHERS": "Andere",
  "fieldinput.fieldType.option.ARABLE": "Acker",
  "fieldinput.fieldType.option.GRASS": "Gras",
  "fieldinput.fieldType.text": "Art des Feldes",
  "fieldinput.arableType.text": "Art des Ackers",
  "fieldList.arable.fields": "Äcker",
  "fieldlist.emptyArable": "Keine Äcker",
  " fieldinput.arableType.notsetyet": "Bitte wählen",
  "fieldinput.title.mobile": "Details eingeben",
  "fieldinput.addField.instructions":
    "Klick auf ✏️ oben, dann klick auf der Karte um die Weidebegrenzung zu zeichnen. Doppelklicken Sie auf die Feldbegrenzung, um das Zeichnen zu beenden.",
  "fieldview.area.text": "Größe",
  "fieldview.area.unit": "ha",
  "fieldview.perimeter.text": "Außenumfang",
  "fieldview.perimeter.unit": "m",
  "fieldview.availableDryMatter.text": "Verfügbare Trockenmasse",
  "fieldview.availableDryMatter.unit": "kgTM",
  "fieldview.grazingDaysAhead.text": "Weidetage verfügbar",
  "fieldview.grazingDaysAhead.unit": "{days}d {hours}h",
  "fieldview.growth.text": "Wachstumsrate",
  "fieldview.growth.unit": "kgTM/ha/Tag",
  "fieldview.totalCover.text": "TM Dichte",
  "fieldview.totalCover.unit": "kgTM/ha",
  "fieldview.notfound": "404 - Not Found!",
  "fieldview.overgrazed": "überweidet",
  "fieldview.noDryMatterNow.text": "(am berechnen)",
  "fieldview.fieldUpdate.success": "Weide erfolgreich gespeichert",
  "fieldview.fieldUpdate.error": "Weide konnte nicht gespeichert werden",
  "fieldview.lastUpdated": "Aktualisiert",
  "fieldview.assignedto.text": "Zugeordnet zu",
  "fieldview.moveAnimals.text": "Tiere bewegen",
  "fieldview.animalsOnThisField.text": "Tiere sind auf diesem Feld",
  "popover.availableDM.unit": "kgTM",
  "popover.availableDM.short": "verf.",
  "popover.noDryMatterNow.text": "(am berechnen)",
  "fieldlist.dryMatterNow.unit": "kgTM/ha",
  "fieldlist.noDryMatterNow.text": "(am berechnen)",
  "fieldlist.empty": "Keine Felder zugeordnet",
  "fieldlist.emptySilage": "Keine Felder in der Silage",
  "fieldevents.silage.title": "Gemäht für Silage",
  "fieldevents.silage.dates": "Mähdatum: {dateCut}",
  "fieldevents.grazed.title": "Geweidet",
  "fieldevents.moveIn.title": "Eingezogen",
  "fieldevents.moveOut.title": "Ausgezogen",
  "fieldevents.grazed.dateIn": "Start: {dateIn}",
  "fieldevents.grazed.dateOut": "End: {dateOut}",
  "fieldevents.event.preCover": "Bestand bevor: {preCover} kgTM/ha",
  "fieldevents.event.postCover": "Betand danach: {postCover} kgTM/ha",
  "fieldevents.reading.title": "Betand aktualisiert",
  "fieldevents.reading.value": "Neuer Betands: {value} kgTM/ha",
  "fieldevents.reading.date": "Aktualisiert am: {date}",
  "fieldevents.list.title": "Aktivitäten",
  "fieldevents.menu.edit": "Bearbeiten",
  "fieldevents.menu.accept": "Akzeptieren",
  "fieldevents.menu.reject": "Verwerfen",
  "fieldevents.menu.delete": "Löschen",
  "fieldevents.notify.deleted": "Event gelöscht",
  "fieldevents.notify.accepted": "Automatisch erkanntes Event akzepiert",
  "fieldevents.notify.rejected": "Automatisch erkanntes Event verworfen",
  "fieldevent.autodetected.tooltip": "Automatisch erkanntes Event",
  "fieldevent.manualinput.tooltip": "Event von dir eingeben",
  "farm.growth.label": "Wachstumsrate",
  "farm.growth.unit": "kgTM/ha/day",
  "farm.totalDM.label": "Durchschnittliche TM Dichte",
  "farm.totalDM.unit": "kgTM/ha",
  "farm.addMenu.field": "Feld hinzufügen",
  "farm.addMenu.cover": "Messwert hinzufügen",
  "farm.addMenu.addGrassTypes": "Grassorten angeben",
  "farm.addMenu.animalGroup": "Tiergruppe hinzufügen",
  "farm.add.addName.mobile": "Hofbezeichnung eingeben",
  "farm.add.addName.desktop": "Gib die Bezeichnung des Hofes ein",
  "farm.add.saveLocation.desktop": "Finde den Standort auf der Karte ",
  "farm.add.saveFarm.desktop": ", dann clicke speichern.",
  "farm.add.saveLocation.mobile": "Standort Speichern",
  "farm.add.addName.error": "Bitte Bezeichnung eingeben",
  "farm.add.locationInstructions":
    "Zoome zum Standort deines Hofes oder verwenden Sie die Postleitzahlensuche am oberen Rand, dann clicke speichern.",
  "addCovers.next.label": "Weiter",
  "addCovers.back.label": "Zurück",
  "addCovers.cancel.label": "Abbrechen",
  "addCovers.save.label": "Speichern",
  "addCovers.enterTime.heading": "Zeit Eingeben",
  "addCovers.enterTime.subheading": "Wann hast du gemessen?",
  "addCovers.enterTime.dateLabel": "Datum",
  "addCovers.enterTime.startTimeLabel": "Startzeit",
  "addCovers.enterTime.endTimeLabel": "Endzeit",
  "addCovers.enterTime.invalidFinishTime": "Endzeit muss nach Startzeit sein.",
  "addCovers.enterCovers.heading": "Messewerte Eingeben",
  "addCovers.enterCovers.subheading": "Bitte gib deine Messwerte ein.",
  "addCovers.enterCovers.fieldName": "Weide",
  "addCovers.enterCovers.current": "Momentan",
  "addCovers.enterCovers.new": "Messwert",
  "addCovers.singleEvent.title": "Ereignis Eingeben",
  "addCovers.singleEvent.tooltip": "Ereignis Eingeben",
  "addCovers.single.unit": "kgTM/ha",
  "addCovers.single.measurementDatetime": "Messzeit",
  "addCovers.single.newCover": "Messwert",
  "addCovers.enterReasons.heading": "Ereignis",
  "addCovers.enterReasons.subheading":
    "Wir haben wohl nicht Alles gesehen. Was haben wir verpasst?",
  "addCovers.enterReasons.fieldName": "Weide",
  "addCovers.enterReasons.difference": "Abweichung",
  "addCovers.enterReasons.reason": "Grund",
  "addCovers.enterReasons.menu.grazed": "Beweidet",
  "addCovers.enterReasons.menu.partGrazed": "Am Weiden",
  "addCovers.enterReasons.menu.silage": "Silage",
  "addCovers.enterReasons.menu.noEvent": "Kein Ereignis",
  "addCovers.eventInput.preCover.Label": "TM Davor",
  "addCovers.eventInput.postCover.Label": "TM Danach",
  "addCovers.eventInput.postCover.Error":
    "TM nach Ereignis muss niedriger sein als vorher.",
  "addCovers.eventInput.dateCut.Label": "Mähdatum",
  "addCovers.eventInput.dateIn.Label": "Weidestart",
  "addCovers.eventInput.dateOut.Label": "Weideende",
  "addCovers.eventInput.dateOut.Error": "Weideende muss nach Weidestart sein.",
  "addCovers.confirmCovers.heading": "Bestätigen und Speichern",
  "addCovers.confirmCovers.noData":
    "Keine Daten eingegeben. Gehe zurück um Daten einzugeben.",
  "addCovers.confirmCovers.accuracy":
    "Unserer Messungen waren innerhalb von {threshold} kg TM/ha von den Messewerten die du eingegeben hast.",
  "addCovers.confirmCovers.datetime":
    "Deine Messwerte vom {readingsDate} von {startTime} - {endTime} werden gespeichert.",
  "addCovers.confirmCovers.infoMessage":
    "Die Karte und Grasswedge werden angepasst.",
  "addCovers.success": "Messwerte erfolgreich gespeichert.",
  "addCovers.failure": "Messwerte speichern war nicht erfolgreich.",

  "confirmCovers.thumbsUp.tooltip":
    "Messwert sieht gut aus - klick um zu bestätigen",
  "confirmCovers.thumbsUp.title": "Messwert sieht gut aus!",
  "confirmCovers.thumbsUp.text":
    "<p>Das bedeutet, dass der Messwerte genau genug ist, um eine Entscheidung zum Weidemanagement zu treffen.</p><p>Bitte bestätigen Sie den Messwert, damit wir daraus lernen können.</p>",
  "confirmCovers.thumbsUp.cancel": "Abbrechen",
  "confirmCovers.thumbsUp.save": "Bestätigen",

  "confirmCovers.thumbsDown.tooltip":
    "Messwert ist ungenau - klick um zu zu korrigieren",
  "confirmCovers.thumbsDown.title": "Messwert ist ungenau!",
  "confirmCovers.thumbsDown.text":
    "<p>Das bedeutet, dass der Messwert nicht genau genug ist, um eine Entscheidung über das Weidemanagement zu treffen.</p><p>Bitte geben Sie den Messwert ein, damit wir zukünftige Messwerte besser schätzen können.</p>",
  "confirmCovers.thumbsDown.cancel": "Abbrechen",
  "confirmCovers.thumbsDown.save": "Bestätigen",

  "reseedEvent.heading": "Neu Sähen",
  "addDate.enterTime.dateLabel": "Datum",
  "reseedEvents.selectFields.text": "Feld(er)",
  "reseedEvent.grassType.text": "Zu:",
  "reseedEvent.notes.label": "Notizen",
  "reseedEvents.show.title": "Neu gesäht",
  "reseedEvents.show.titleName": "Neu gesäht {name}",
  "reseedEvent.reseedDate": "Datum: {reseedDate}",
  "reseedEvent.newGrassType": "Grassart: {newGrassType}",
  "editReseedEvent.heading": "Neusaat editieren",
  "editReseedEvent.field.heading": "Feldname",
  "viewReseedEvent.heading": "Nachsaatereignis anzeigen",
  "editReseedEvent.success": "Neusaat erfolgreich editiert",
  "farm.addMenu.addReseedEvent": "Neuansaaten hinzufügen",
  "addGrassType.save.label": "Speichern",
  "addGrassType.cancel.label": "Abbrechen",
  "addGrassType.enterGrassType.heading": "Grasssorten angeben",
  "addGrassType.enterGrassType.subheading":
    "Bitte wählen sie die Grassorten für Felder bei denen das noch nicht geschehen ist.",
  "addGrassType.enterGrassType.tableHeading": "Grasssorten angeben",
  "addGrassType.enterGrassType.fieldName": "Feld",
  "addGrassType.enterGrassType.selection": "Grasssorten",
  "updateGrassTypes.success": "Grasssortenänderung erfolgreich",
  "fieldlistmenu.silage.label": "Verschiebe zu Silage",
  "fieldlistmenu.animalGroup.label": "Verschiebe zu {group}",
  "fieldlistmenu.edit.label": "Weide Bearbeiten",
  "fieldlistmenu.delete.label": "Weide Löschen",
  "fieldlistmenu.moveIn.label": "Tiere auf das Feld bewegen",
  "fieldlistmenu.moveIn.tooltip.silage":
    "Sie können keine Tiere auf ein Silofeld verschieben. Wir behandeln Felder, die keiner Tiergruppe zugeordnet sind, als Silofelder.",
  "fieldlistmenu.moveIn.tooltip.sameField":
    'Um Ihre Tiere in ein anderes Feld zu verschieben, wählen Sie das Feld, in das Sie die Tiere verschieben möchten, und klicken Sie auf "Tiere hierher verschieben"',
  "confirmdeletedialog.cancel.label": "Abbrechen",
  "confirmdeletedialog.confirm.label": "Löschen",
  "confirmationdeletedialog.animalGroup.title": "Tiergruppe löschen",
  "confirmationdeletedialog.animalGroup.description":
    'Bist du dir sicher, dass du "{name}" löschen willst?',
  "confirmDeleteFieldDialog.text":
    "Du willst {fieldName} löschen. Bist du dir sicher?",
  "confirmDeleteFieldDialog.title": "Weide Löschen",
  "confirmcanceldialog.cancel.label": "Weiter bearbeiten",
  "confirmcanceldialog.confirm.label": "Veränderungen verwerfen",
  "confirmcanceldialog.text":
    "Wenn du jetzt abbrichst, verlierst du alle Veränderungen. Bist du dir sicher?",
  "confirmcanceldialog.title": "Veränderungen Verwerfen",
  "toolbar.farmview.title": "Farm",
  "toolbar.fieldview.title": "Weidendetails",
  "toolbar.grasstype-reseeding.title": "Rasentyp und Nachsaat",
  "map.cursorTooltip.unit": "kgTM/ha",
  "hint.welcome.title": "Willkommen ",
  "hint.welcome.text": "Klicke auf + um deine erste Weide anzulegen",
  "hint.animalGroups.title": "Tiergruppe Hinzufügen ",
  "hint.animalGroups.text": "Klicke auf + um deine erste Gruppe anzulegen",
  "animalGroup.menu.edit": "Tiergruppe bearbeiten",
  "animalGroup.menu.delete": "Tiergruppe löschen",
  "animalGroup.groupWeight": "Gesamtgewicht der Tiergruppe",
  "animalGroup.groupDemand": "Bedarf",
  "animalGroup.noFieldsAssigned": "Noch keine Felder hinzugefügt",
  "animalGroup.demandUnit": "{demandPerHaAndDay} kgTM/ha/Tag",
  "animalGroup.animalCount": "Anzahl Tiere in Tiergruppe",
  "animalGroupInput.boundary.error": "Etwas ist fehlgeschlagen",
  "animalGroupInput.addStockClass": "eine weitere Tierklasse hinzufügen",
  "animalGroupInput.demand":
    "{groupDemandInKg} kgTM ({groupDemandInPercent}%) pro Tag",
  "animalGroupInput.stockClasses": "Tierklasses",
  "animalGroupInput.stockClasses.birthingPeriods": "Ablammen/Kalben Details",
  "animalGroupInput.stockClasses.birthingPeriods.dateStart": "Beginn",
  "animalGroupInput.stockClasses.birthingPeriods.dateEnd": "Ende",
  "animalGroupInput.stockClasses.birthingPeriods.expectedNumAnimalsWithoutPregnancy":
    "Anzahl der nicht trächtigen Tiere ",
  "animalGroupInput.stockClasses.birthingPeriods.expectedNumOffspringPerAnimal":
    "Erwartete Anzahl von Kälbern/Lämmern pro Tier",
  "animalGroups.overview.demand": "Bedarf: {demand} kgTM/ha/Tag",
  "animalGroups.overview.availableDryMatter":
    "Verfügbare Trockenmasse: {availableDryMatter} kgTM",
  "animalGroups.overview.averageCover": "TM Dichte: {averageCover} kgTM/ha",
  "toolbar.animalGroupView.title": "Tiergruppe",
  "silage.title": "Silage",
  "silage.description": "TM Dichte: {averageCover} kgTM/ha",
  "grassWedge.open": "Grass Wedge Öffnen",
  "units.kilogramPerHectar": "kgTM/ha",
  "units.days": "Tage",
  "grassWedge.postGrazingTarget": "Nachweide Ziel",
  "grassWedge.preGrazingTarget": "Berechnetes Vorweide Ziel",
  "grassWedge.rotationLength": "Rotationsdauer",
  "grassWedge.averageCover": "Durchschnittliche Trockenmasse KgTM/Ha",
  "grassWedge.demand": "Bedarf KgTM/ha/Tag",
  "grassWedge.growth": "Wachstumsrate kgDT/ha/Tag",
  "grassWedge.grazingDaysAhead": "Weidetage voraus",
  "grassWedge.tooltip.area": "Fläche: {area} ha",
  "grassWedge.tooltip.availableDM":
    "Durchschnittliche Trockenmasse: {dm} kgTM/ha",
  "grassWedge.tooltip.grazindDaysAhead":
    "Weidetage verfügbar: {days}d {hours}h",
  "grassWedge.noFields.prompt":
    "Weisen Sie mindestens zwei Felder für die Nutzung des Grass Wedge zu",
  "grassWedge.singleField.prompt":
    "Weisen Sie ein anderes Feld zu, um die Grass Wedge zu nutzen",
  "fieldlistmenu.notification.movedAnimalGroup":
    "{fieldName} wurde zu {animalGroupName} zugeordnet",
  "fieldlistmenu.notification.movedToSilage":
    "{fieldName} wurde zur Silage zugeordnet",
  "fieldlistmenu.notification.moveFailed":
    "{fieldName} konnte nicht zugeordnet werden",
  "fieldlistmenu.notification.editFailed":
    "{fieldName} konnte nicht bearbeitet werden",
  "fieldlistmenu.notification.moveInFailed":
    "Tiere konnten nicht aufs {fieldName} gebracht werden",
  "fieldlistmenu.notification.moveInSuccess":
    "Es wurden Tiere nach {Feldname} gebracht",
  "grassWedge.assignField": "Feld zuordnen",
  "animalGroup.assignField": "Feld zuweisen",
  "animalGroup.viewFeedAllocation": "Futtermittelzuweisung anzeigen",
  "grassWedge.notification.saved": "Gespeichert",
  "grassWedge.notification.error": "Etwas ist schief gelaufen.",
  "assignFieldDialog.title": "Feld zu dieser Tiergruppe zuordnen",
  "assignFieldDialog.inputLabel": "Feld",
  "assignFieldDialog.nofieldsLabel": "Keine Felder verfügbar",
  "assignFieldDialog.button": "Zuordnen",
  "animalGroupInput.add.success": "{animalGroupName} hinzugefügt",
  "animalGroupInput.edit.success": "{animalGroupName} aktualisiert",
  "fieldInput.add.success": "{fieldName} hinzugefügt",
  "fieldInput.edit.success": "{fieldName} aktualisiert",
  "farmInput.add.success": "{farmName} hinzugefügt",
  "animalGroup.grassWedgeButton.tooltip":
    "Mindestens zwei Felder mit Trockenmasse werden benötigt",
  "fieldProposedEventsModal.headline": "Neue Events",
  "fieldProposedEventsModal.description":
    "Akzeptieren oder Verwerfen Sie die automatisch erkannten Weide- oder Mähereignisse unten, um Ihre Werte zu aktualisieren.",
  "fieldProposedEventsModal.description1":
    "ruumi erkennt jetzt, wann ein Feld zuletzt beweidet oder gemäht wurde. Dies geschieht mit Hilfe von Satellitenradar, das alle 2-3 Tage vorbeifliegt und die Wolkendecke durchdringt. Im Moment werden die Vorschläge für Weideereignisse zu Ihrem Feldverlauf hinzugefügt, wenn Sie sie annehmen oder ablehnen.",
  "fieldProposedEventsModal.description2":
    "Tippen Sie auf die Schaltfläche 'Häkchen', wenn Sie glauben, dass ruumi korrekt ist, oder auf die Schaltfläche 'Kreuz', wenn nicht. Mit der Schaltfläche 'Bleistift' können Sie Eintritts- und Austrittszeiten sowie Abdeckungen vor und nach dem Weidegang hinzufügen. Verwenden Sie die Schaltfläche 'Plus', um ein neues Ereignis zu dem Feld hinzuzufügen.",
  "fieldProposedEventsModal.description3":
    "Standardmäßig setzt ruumi die Felder nach der Beweidung auf 1500 kg TM/ha zurück oder auf den Restwert, den Sie im Grass Wedge der Gruppe angeben.",
  "fieldProposedEventsModal.description4":
    "Sie können auch erkannte Ereignisse finden, wenn Sie auf ein Feld klicken und in der linken unteren Ecke nachsehen.",
  "fieldProposedEventsModal.close": "Schließen",
  "fieldProposedEventsModal.help": "Hilfe",
  "certificationProgramApplicationModal.headline":
    "ruumi Zertifizierungsprogramm",
  "certificationProgramApplicationModal.description":
    "Durch die Nutzung von ruumi erstellen Sie einen umfangreichen Datensatz über Ihre landwirtschaftlichen Aktivitäten. Wir können diese Daten nutzen, um die hochwertigsten Kohlenstoffgutschriften zu erstellen. Klicken Sie unten, um sich für unser Zertifizierungsprogramm für Kohlenstoffgutschriften zu bewerben.",
  "certificationProgramApplicationModal.cta":
    "Anmeldung für ruumi CO2 Zertifikate",
  "carbon.profile.cta.text":
    "Finden Sie heraus, wie Sie die Umstellung auf regenerative Verfahren finanzieren können - ",
  "carbon.profile.cta.link":
    "<Link>buchen Sie eine kostenlose Beratung</Link>.",
  "after_first_field_created.field.dialog.title": "Glückwunsch!",
  "after_first_field_created.field.dialog.text":
    "Sie koennen jetzt weitere Felder zeichnen oder Tiergruppen hinzufügen und diese den Feldern zuordnen. Dann ist es an der Zeit, die Tiere grasen zu lassen!",
  "after_field_created.field.dialog.text": "Feld wurde angelegt!",
  "after_field_created.field.dialog.close": "Schließen",
  "after_field_created.field.dialog.anotherField": "Noch ein Feld zeichnen",
  "after_field_created.field.dialog.addAnimalGroup": "Tiergruppe hinzufügen",
  "map.postcodeInput.placeholder": "AB10 1AB",
  "map.postcodeInput.helperText": "Zur Postleitzahl des Bauernhofs navigieren",
  "oauth2.redirect.dialog.title": "Weiterleitung",
  "oauth2.redirect.dialog.content":
    "Sie werden nun weitergeleitet zu <a>{url}</a>. ",
  "oauth2.success.dialog.title": "Erfolgreich!",
  "oauth2.success.dialog.content":
    "Sie haben Ihr Konto bei {providerName} erfolgreich mit ruumi verbunden. Wir importieren Farmen, Tiere und Felder.",
  "oauth2.success.dialog.contentSignup":
    "Willkommen bei ruumi! Bitte erzählen Sie uns etwas über Ihren Betrieb, während wir Ihre Daten von {providerName} laden.",
  "oauth2.success.dialog.importing":
    "Importiere Farm {farmName}, {animalGroupsCount} Tiergruppen, {fieldsCount} Felder importiert.",
  "oauth2.error.dialog.title": "Fehler",
  "oauth2.error.dialog.content":
    "Konnte keine Verbindung zum Konto {providerName} herstellen, bitte versuchen Sie es <a>hier</a> erneut. ",
  "settings.agriwebb": "AgriWebb verbunden",
  "settings.agriwebb.remove": "Verbindung entfernen",
  "oauth2ClientRemove.success": "Integration von {providerName} entfernt",
  "newSignup.userDetails.alreadyRegistered":
    "Bereits registriert? <a>Hier anmelden</a>.",
  "passwordForgot.dialog.title": "Passwort vergessen",
  "passwordForgot.mail.label": "E-Mail",
  "passwordForgot.button.label": "Passwort zurücksetzen",
  "passwordForgot.success":
    "Großartige Neuigkeiten! Wir haben Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts geschickt. Bitte prüfen Sie Ihren Posteingang.",
  "passwordForgot.success.2":
    "Wenn Sie sie nicht finden können, zögern Sie bitte nicht, uns unter hello@ruumi.io zu kontaktieren, und wir helfen Ihnen gerne weiter.",
  "passwordForgot.failure":
    "Senden der E-Mail an Ihre Adresse nicht möglich. Bitte kontaktieren Sie uns unter hello@ruumi.io.",

  "passwordReset.userDetails.text1": "Legen Sie Ihr neues Passwort fest",
  "passwordReset.userDetails.dialogTitle": "Passwort zurücksetzen",
  "passwordReset.button.label": "Passwort zurücksetzen",
  "passwordChange.failure": "Passwortänderung nicht möglich",

  "defaultStockClass.DAIRY_COW": "Milchkuh",
  "defaultStockClass.DAIRY_COW_2_KGMS": "Milchkuh (2 kgMS)",
  "defaultStockClass.DAIRY_COW_2_KGMS.notes":
    "2kgMS = ~28l @ 7.5% Feststoffe - Spitzenlaktation",
  "defaultStockClass.SUCKLER_COW": "Mutterkuh",
  "defaultStockClass.SUCKLER_COW.notes":
    "inklusive Kalb bis zu einem Alter von 200 Tagen",
  "defaultStockClass.GROWING_CATTLE": "Wachsendes Rind",
  "defaultStockClass.EWE": "Mutterschaf",
  "defaultStockClass.EWE_TWINS": "Mutterschaf (Zwillinge)",
  "defaultStockClass.EWE_TWINS.notes":
    "inklusive Lämmer bis zu einem Alter von 100 Tagen",
  "defaultStockClass.WEANED_LAMB": "Abgesetztes Lamm",

  "defaultFeeding.DRY_MAINTENANCE": "Trocken - Erhaltung",
  "defaultFeeding.THIRTY_DAYS_PRE_CALVING": "30 Tage vor dem Kalben",
  "defaultFeeding.LTE_2_MONTHS_POST_CALVING": "0-2 Monate nach dem Kalben",
  "defaultFeeding.GT_2_MONTHS_POST_CALVING": ">2 Monate nach dem Kalben",
  "defaultFeeding.DRY_MAINTENANCE_BCS_GAIN":
    "Trocken - Erhaltung + BCS Zunahme",
  "defaultFeeding.GTE_3_LTE_4_MONTHS_POST_CALVING":
    "3-4 Monate nach dem Kalben",
  "defaultFeeding.GT_4_MONTHS_POST_CALVING":
    ">4 Monate nach dem Kalben bis zum Absetzen",
  "defaultFeeding.GT_4_MONTHS_POST_CALVING.notes":
    "inklusive Kalb bis zu einem Alter von 200 Tagen",
  "defaultFeeding.MAINTENANCE": "Erhaltung",
  "defaultFeeding.MAINTENANCE_500G_DLWG": "Erhaltung + 0,5 kg DLWG",
  "defaultFeeding.MAINTENANCE_1KG_DLWG": "Erhaltung + 1 kg DLWG",
  "defaultFeeding.MAINTENANCE_GT_1KG_DLWG": "Erhaltung + >1 kg DLWG",
  "defaultFeeding.THIRTY_DAYS_PRE_LAMBING": "30 Tage vor dem Lammern",
  "defaultFeeding.LTE_1_MONTHS_POST_LAMBING": "0-1 Monat nach dem Lammern",
  "defaultFeeding.GT_1_MONTH_POST_LAMBING_TO_WEANING":
    ">1 Monat nach dem Lammern bis zum Absetzen",
  "defaultFeeding.GT_1_MONTH_POST_LAMBING_TO_WEANING.notes":
    "inklusive Lämmer bis zu einem Alter von 100 Tagen",
  "defaultFeeding.MAINTENANCE_50G_DLWG": "Erhaltung + 50 g DLWG",
  "defaultFeeding.MAINTENANCE_150G_DLWG": "Erhaltung + 150 g DLWG",
  "defaultFeeding.MAINTENANCE_GT_150G_DLWG": "Erhaltung + >150 g DLWG",
  "stockClassDemandTemplateAutocomplete.customValue":
    'Verwenden Sie "{inputValue}"',
  "stockClassDemandTemplateAutocomplete.listItemTitle":
    "{stockClassName} — {allocatedBodyWeightFraction}% KGW",
  "common.reloadPage":
    "Die Grazing-App wurde aktualisiert, neue Version wird geladen, bitte warten",
  "moveOut.updateCover": "Bestand auf {name} aktualisieren?",
  "moveOut.fromField": "Von Feld: {fieldName}",
  "moveOut.toField": "Auf Feld: {fieldName}",
  "moveOut.title": "Tiere bewegen sich",
  "moveOut.date.label": "Datum",
  "moveOut.minDateInfo":
    "Diese Tiergruppe wurde zuletzt am {minDate} bewegt. Das gewählte Datum muss danach liegen.",
  "moveOut.subTitle":
    "Bestand auf {fieldName} ändern von {currentCover} kgDM/ha",
  "moveOut.subTitle2": "nach",
  "moveOut.postCover.label": "Neuer Bestand",
  "moveOut.leaveIt.label": "Bestand nicht aktualisieren",
  "moveOut.changeIt.label": "Bestand aktualisieren",
  "feedBudgetPlanner.noFields.prompt":
    "Weisen Sie mindestens einem Tiergruppe ein Feld zu, um den Grassvorrat zu sehen",
  "feedbBudgetPlanner.feedAllocation.allocatedBodyWeightFractionPercent":
    "Zugewiesene BW%: {allocatedBodyWeightFractionPercent}%",
  "feedbBudgetPlanner.feedAllocation.allocatedBodyWeightFractionKg":
    "Zugewiesene kg: {allocatedBodyWeightFractionKg}",
  "feedbBudgetPlanner.feedAllocation.allocationType.OFFSPRING": "Nachwuchs",
  "feedbBudgetPlanner.feedAllocation.allocationType.STOCK_CLASS":
    "Bestandsklasse",
  "feedbBudgetPlanner.feedAllocation.stockClassFeedAllocationLineNameBW":
    "Tägliche Zuteilung für {stockClassName} (BW%)",
  "feedbBudgetPlanner.feedAllocation.stockClassFeedAllocationLineNameKg":
    "Tägliche Zuteilung für {stockClassName} (kg)",
  "feedbBudgetPlanner.feedAllocation.totalDemandPerAnimalGroup.title":
    "Gesamttäglicher Bedarf für {animalGroupName} (kg/ha/Tag)",
  "feedbBudgetPlanner.feedAllocation.totalDemandPerAnimalGroupTooltip":
    "Täglicher Bedarf: {demandKgHaDay} kg/ha/Tag",
  "feedBudgetPlanner.feedAllocation.totalSupply.title":
    "Tägliche Versorgung (kg/ha/Tag)",
  "feedBudgetPlanner.feedAllocation.totalSupply.tooltip":
    "Tägliche Versorgung {supply} (kg/ha/Tag)",
  "feedBudgetPlanner.feedAllocation.totalProjectedSupply.title":
    "Prognostizierte tägliche Versorgung (kg/ha/Tag)",
  "feedBudgetPlanner.feedAllocation.totalDemand.title":
    "Täglicher Bedarf (kg/ha/Tag)",
  "feedBudgetPlanner.feedAllocation.totalDemand.tooltip":
    "Täglicher Bedarf {demand} (kg/ha/Tag)",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.title":
    "Felder zuweisen zu {name}",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.confirmButton.title":
    "Bestätigen",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.cancelButton.title":
    "Abbrechen",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.silageFields": "Silofelder",
  "feedBudgetPlanner.feedAllocation.fieldTransfer.animalGroupFields":
    "Tiergruppenfelder {name}",
  "feedBudgetPlanner.title": "Futterbudget für {name}",
  "feedBudgetPlanner.animalGroupChart.title": "{name} Bedarf & Versorgung",
  "feedBudgetPlanner.stockClassDemandChart.title":
    "{name} daily demand (%BW / kg)",
  "feedBudgetPlanner.stockClassFeedAllocationChart.kg": "kg",
  "feedBudgetPlanner.stockClassFeedAllocationChart.bw": "KGW%",
  "feedBudgetPlanner.helpDialog.title": "Raufutterfütterungsrichtlinien",
  "feedBudgetPlanner.helpDialog.feeding": "Fütterung",
  "feedBudgetPlanner.helpDialog.bw": "%KGW",
  "feedBudgetPlanner.helpDialog.kg": "kgTS/Tag",
  "animalGroupInput.feedAllocation.create.success":
    "{animalGroupName} erstellt, Standard-Futterzuweisung generiert",
  "animalGroupInput.regenerateFeedAllocationDialog.text":
    "Das Standard-Futterbudget wird berechnet, alle manuellen Änderungen an Futterzuweisungen gehen verloren. Möchten Sie fortfahren?",
  "animalGroupInput.regenerateFeedAllocationDialog.title":
    "Änderungen verwerfen",
  "animalGroupInput.regenerateFeedAllocationDialog.confirm.label": "Bestätigen",
  "animalGroupInput.regenerateFeedAllocationDialog.cancel.label":
    "Weiter bearbeiten",
  "feedBudgetPlanner.targetEntryCoverDialog.title":
    "Zieldeckungseintrag für {name}",
  "feedBudgetPlanner.targetEntryCoverDialog.table.description":
    "Für diese Bestandsklasse beträgt die empfohlene Zieldeckung für eine hohe Leistung wie folgt:",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.kgdmha": "kgDM/ha",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.averageWeight":
    "Durchschnittsgewicht (kg)",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.min": "Min",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.optimum": "Optimum",
  "feedBudgetPlanner.targetEntryCoverDialog.table.header.max": "Max",
  "feedBudgetPlanner.targetEntryCoverDialog.input.description":
    "Derzeit eingestellter Zieldeckungseintrag für {name}:",
  "feedBudgetPlanner.referenceArea.birthingPeriod.label":
    "Lammung/Kalbung {start} - {end}",
  "feedBudgetPlanner.referenceLabel.now.label": "Heute",
  "farmView.button.grasstype-reseeding.title": "Rasentyp und Nachsaat",
  "reseedingDetailView.button.addReseedingEvent.title":
    "Neues Nachsaatereignis hinzufügen",
  "reseedingDetailView.button.updateGrassTypes.title":
    "Rasentypen aktualisieren",
  "reseedEvent.field": "{name}",
  "reseedEvent.note": "Hinweis: {note}",
  "reseedEvent.seedingMethod": "Methode: {seedingMethod}",
  "reseedEvent.to": "Zu: {newGrassType}",
  "reseedEvent.list.title": "Vorherige Ereignisse",
  "reseedEvent.menu.editView": "Bearbeiten/Anzeigen",
  "reseedEvent.seedingMethod.option.PLOUGH_AND_POWER_HARROW":
    "Pflug und Kreiselegge",
  "reseedEvent.seedingMethod.option.DIRECT_DRILL": "Drillen",
  "reseedEvent.seedingMethod.option.SHALLOW_CULTIVATION":
    "Flache Bodenbearbeitung",
  "reseedEvent.seedingMethod.option.OVERSEED": "Nachsähen",
  "reseedEvent.seedingMethod.text": "Methode",
  "diaryEntry.diaryEntryText.label": "Tagebuch Eintrag: {diaryEntryText}",
  "diaryEntry.show.titleName": "Tagebuch Eintrag",
  "diaryEntry.entryDate.label": "Datum {diaryEntryDate}",
  "diaryEntry.showImage.title": "Tagebuch Eintrag vom {diaryEntryDate}",
  "diaryEntry.delete.label": "Löschen",
  "diaryEntry.delete.confirm.label": "Ja",
  "diaryEntry.cancel.label": "Abbrechen",
  "diaryEntry.areYouSure.label":
    "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
  "diaryEntry.view.delete.menu": "Ansehen/Löschen",
  "diaryEntry.view.menu": "Ansehen",
  "diaryEntry.view.close.label": "Schliessen",
  "diaryEntry.deleted.notification": "Tagebuch Eintrag erfolgreich gelöscht.",
  "diaryEntry.delete.tooltip": "Tagebuch Eintrag löschen",
  "go.to.landmanagement.and.grazing.button": "Land- und Weidemanagement",
  "go.to.inputs.and.outputs": "Einsatz & Produktion",
  "toolbar.inputs.and.outputs.view": "Einsatz & Produktion",
  "inputs.outputs.reseeding.title": "Neu Sähen",
  "home.button.title": "Home",
};
