import SvgIcon from "@mui/material/SvgIcon";

export default function YieldEventIcon() {
  return (
    <SvgIcon
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="70.000000pt"
      height="70.000000pt"
      viewBox="0 0 70.000000 70.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M215 559 c-60 -34 -116 -68 -122 -77 -16 -19 -18 -254 -3 -282 12
-22 227 -142 255 -142 24 0 207 98 241 129 23 21 24 28 24 140 0 65 -3 128 -6
139 -6 24 -225 154 -259 154 -11 0 -69 -28 -130 -61z m240 -12 c144 -80 135
-66 135 -211 0 -145 13 -126 -150 -215 l-95 -52 -70 37 c-38 21 -94 52 -123
71 l-53 33 3 132 3 132 110 63 c61 35 118 63 127 63 10 0 61 -24 113 -53z"
        />
        <path
          d="M436 457 c2 -7 -16 -32 -40 -56 l-44 -44 -36 22 -35 22 -46 -31 c-25
-17 -45 -35 -45 -40 0 -15 7 -12 51 19 l40 30 32 -20 c18 -10 36 -19 40 -19 4
0 30 20 56 45 27 25 55 44 62 43 8 -2 15 7 17 20 3 19 -1 22 -27 22 -20 0 -29
-4 -25 -13z"
        />
        <path
          d="M447 372 c-35 -35 -37 -42 -37 -100 l0 -62 40 0 40 0 0 100 c0 55 -1
100 -3 100 -2 0 -20 -17 -40 -38z"
        />
        <path
          d="M233 309 c-36 -25 -43 -35 -43 -65 0 -34 0 -34 45 -34 l45 0 0 65 c0
36 -1 65 -2 65 -2 -1 -22 -14 -45 -31z"
        />
        <path
          d="M310 265 l0 -55 40 0 40 0 0 55 c0 52 -1 55 -20 43 -15 -10 -22 -10
-32 0 -22 22 -28 13 -28 -43z"
        />
      </g>
    </SvgIcon>
  );
}
