import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import {
  useAppSelector,
  FEED_EVENT_REJECTION_PERIOD_DAYS,
  GeneralFeedEvent,
  GeneralFeed,
  selectAllAnimalGroups,
  AnimalGroup,
} from "model";
import { useTranslation } from "common/locales";
import { getFormattedTime } from "../../common/utils/getFormattedTime";
import { AddFeedEventIcon } from "common/icons";
import { MoreVert } from "@mui/icons-material";

import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import UpdateFeedEventDialog from "./UpdateFeedEventDialog";
import { convertToFeedStockAmountString } from "./generalMethods";

export default function FeedEventListItem({
  feedEvent,
}: {
  feedEvent: GeneralFeedEvent;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);

  const icon = <AddFeedEventIcon />;

  const { feedEventDate, feed, animalGroupGuids } = feedEvent;
  const formattedTime = getFormattedTime(feedEventDate, locale);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const allAnimalGroups: AnimalGroup[] = useSelector(selectAllAnimalGroups);
  const selectedAnimalGroups = allAnimalGroups.filter((animalGroup) =>
    animalGroupGuids.includes(animalGroup.guid)
  );
  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  const canEdit =
    differenceInDays(
      Date.now(),
      feedEvent.creationDate ? new Date(feedEvent.creationDate) : Date.now()
    ) <= FEED_EVENT_REJECTION_PERIOD_DAYS;

  return (
    <ListItem key={`re-listitem-${feedEvent.guid}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" display="block">
            {t("feedPurchaseEvent.feedPurchaseDate", {
              feedPurchasedDate: formattedTime,
            })}
          </Typography>
        }
        secondary={
          <>
            <Typography component="span" display="block" noWrap>
              {t("feedItem.animalGroups.fedTo")}
              {selectedAnimalGroups
                .map((animalGroup) => animalGroup.name)
                .join(", ")}
            </Typography>
            {feed.map((singleFeed: GeneralFeed) => {
              return (
                <Fragment key={`re-listitem-${singleFeed.feedType}`}>
                  <Typography component="span" display="block">
                    {t(`feedPurchase.feedType.option.${singleFeed.feedType}`)}
                  </Typography>
                  <Typography component="span" display="block">
                    {convertToFeedStockAmountString(
                      singleFeed.amount,
                      locale,
                      singleFeed.unitType,
                      t
                    )}
                  </Typography>
                </Fragment>
              );
            })}
          </>
        }
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={openMenu}
            id={feedEvent.guid}
            size="large"
          >
            <MoreVert />
          </IconButton>
          <UpdateFeedEventDialog
            closeMenu={closeMenu}
            menuAnchor={menuAnchor}
            feedEvent={feedEvent}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
