import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FeatureCollection } from "@turf/turf";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  ListItemIcon,
  Divider,
  IconButton,
  FormControl,
  Button,
  Tooltip,
} from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import StopIcon from "@mui/icons-material/Stop";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import {
  selectAnimalGroupById,
  setZoomToGeom,
  Field,
  selectAllAnimalGroups,
  updateField,
  useAppDispatch,
  useAppSelector,
  getDemand,
  getFieldWithAnimalsForAnimalGroupId,
  openMoveOutEventDialog,
  FIELD_TYPE,
} from "model";
import { FieldEditActionMenu } from "features/fieldDetail";
import { FieldEventList } from "features/fieldEventList";
import { GrassIcon, SwardCompositionIcon } from "common/icons";
import { PinDrop, MoreVert } from "@mui/icons-material";
import { dryMatterToColor } from "common/utils/grazing-logic";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNotification } from "app/NotificationProvider";
import AssignedToDropdown from "features/fieldEdit/AssignedToDropdown";
import AfterFieldCreatedDialog from "features/fieldEdit/AfterFieldCreatedDialog";
import { moveAnimalsIn } from "features/singleCoverInput/UpdateBiomassAfterMoveInDialog";
import CoverEntryThumbsUpDialog from "features/singleCoverInput/CoverEntryThumbsUpDialog";
import CoverEntryThumbsDownDialog from "features/singleCoverInput/CoverEntryThumbsDownDialog";

interface Props {
  field: Field;
}

export default function FieldDetails({ field }: Props) {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const animalGroup = useAppSelector((state) =>
    selectAnimalGroupById(state, field.animalGroup ?? "")
  );

  const demand = useAppSelector((state) =>
    getDemand(state, animalGroup?.guid ?? "")
  );
  const animalGroups = useAppSelector(selectAllAnimalGroups);

  const dispatch = useAppDispatch();
  const { notify } = useNotification();

  const fieldGeom = {
    type: "FeatureCollection",
    features: [field.geom],
  } as FeatureCollection;

  const overgrazed = Boolean(
    animalGroup?.postGrazingTarget &&
      field.dryMatterNow &&
      field.dryMatterNow < animalGroup.postGrazingTarget
  );

  let availableDryMatter;
  if (animalGroup && animalGroup.postGrazingTarget) {
    availableDryMatter = Math.floor(
      ((field.dryMatterNow - animalGroup.postGrazingTarget) * field.area) /
        10000
    );
    if (availableDryMatter < 0) {
      availableDryMatter = 0;
    }
  }

  let grazingDaysAhead;
  if (availableDryMatter && demand) {
    grazingDaysAhead = availableDryMatter / demand;
  }

  const currentFieldWithAnimals = useAppSelector(
    getFieldWithAnimalsForAnimalGroupId(field.animalGroup)
  );

  const canMoveIn =
    field.animalGroup && field.guid !== currentFieldWithAnimals?.guid;

  useEffect(() => {
    dispatch(setZoomToGeom(fieldGeom));
  }, [field.guid, dispatch]);

  async function onMoveIn() {
    try {
      if (field) {
        if (currentFieldWithAnimals) {
          dispatch(
            openMoveOutEventDialog({
              open: true,
              newFieldWithAnimals: field,
              currentFieldWithAnimals: currentFieldWithAnimals,
            })
          );
        } else {
          await moveAnimalsIn(
            field,
            currentFieldWithAnimals,
            new Date(),
            dispatch,
            notify,
            t
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <>
      <ListContainer $overgrazed={overgrazed}>
        <List>
          <ListItem>
            <ListItemText
              primary={field.name}
              secondary={
                overgrazed ? (
                  <Typography color="error" variant="body2">
                    {t("fieldview.overgrazed")}
                  </Typography>
                ) : (
                  ""
                )
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-haspopup="true"
                onClick={openMenu}
                size="large"
              >
                <MoreVert />
              </IconButton>
              <FieldEditActionMenu
                anchor={menuAnchor}
                close={closeMenu}
                fieldId={field.guid || ""}
                fieldType={FIELD_TYPE.GRASS}
              />
            </ListItemSecondaryAction>
          </ListItem>
          {animalGroup && animalGroup.postGrazingTarget && (
            <ListItem>
              <ListItemIcon>
                <GrassIcon />
              </ListItemIcon>

              <ListItemText
                primary={
                  field && !field.invalidatedTimestamp
                    ? `${availableDryMatter || 0} ${t(
                        "fieldview.availableDryMatter.unit"
                      )}`
                    : t("fieldview.noDryMatterNow.text")
                }
                secondary={t("fieldview.availableDryMatter.text")}
              />
            </ListItem>
          )}
          {animalGroup && (
            <ListItem>
              <ListItemIcon>
                <TimelapseIcon />
              </ListItemIcon>

              <ListItemText
                primary={
                  field && !field.invalidatedTimestamp
                    ? t("fieldview.grazingDaysAhead.unit", {
                        days: grazingDaysAhead
                          ? Math.floor(grazingDaysAhead)
                          : 0,
                        hours: grazingDaysAhead
                          ? Math.round((grazingDaysAhead % 1) * 24)
                          : 0,
                      })
                    : t("fieldview.noDryMatterNow.text")
                }
                secondary={t("fieldview.grazingDaysAhead.text")}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemIcon>
              <StopIcon htmlColor={dryMatterToColor(field.dryMatterNow)} />
            </ListItemIcon>
            <ListItemText
              primary={
                field && !field.invalidatedTimestamp
                  ? `${field.dryMatterNow} ${t("fieldview.totalCover.unit")}`
                  : t("fieldview.noDryMatterNow.text")
              }
              secondary={t("fieldview.totalCover.text")}
            />
            <ListItemSecondaryAction>
              <CoverEntryThumbsUpDialog field={field} />
              <CoverEntryThumbsDownDialog field={field} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AspectRatioIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                field
                  ? `${(field.area / 10000).toFixed(2)} ${t(
                      "fieldview.area.unit"
                    )}`
                  : ""
              }
              secondary={t("fieldview.area.text")}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwardCompositionIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                field.grassType
                  ? t(`fieldinput.grassType.option.${field.grassType}`)
                  : t(`fieldinput.grassType.notsetyet`)
              }
              secondary={t("fieldinput.grassType.text")}
            />
          </ListItem>
        </List>
      </ListContainer>
      <Container>
        <ListItem>
          <MoveAnimalsButton
            canMoveIn={!!canMoveIn}
            onMoveIn={onMoveIn}
            fieldHasAnimalGroup={!!field?.animalGroup}
          />
        </ListItem>
      </Container>
      {animalGroups.length > 0 && <Divider />}
      {animalGroups.length > 0 && (
        <Container>
          <ListItem>
            <FormControl>
              <AssignedToDropdown
                assignedTo={field.animalGroup}
                onChange={(guid) => {
                  return moveToAnimalGroup(guid);
                }}
              />
            </FormControl>
          </ListItem>
        </Container>
      )}

      <Divider />
      <FieldEventListContainer>
        <FieldEventList field={field} />
      </FieldEventListContainer>
      <AfterFieldCreatedDialog />
    </>
  );

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }
  async function moveToAnimalGroup(guid?: string) {
    const animalGroupName = animalGroups.find((ag) => ag.guid === guid)?.name;
    try {
      await dispatch(updateField({ ...field, animalGroup: guid })).then(
        unwrapResult
      );
      mixpanel.track("FieldAssign successful");
      notify(
        guid
          ? t("fieldlistmenu.notification.movedAnimalGroup", {
              fieldName: field?.name,
              animalGroupName: animalGroupName,
            })
          : t("fieldlistmenu.notification.movedToSilage", {
              fieldName: field?.name,
            })
      );
    } catch (e) {
      mixpanel.track("FieldAssign failed");
      notify(t("fieldlistmenu.notification.moveFailed"), "error");
    }
  }
}

const ListContainer = styled(Container)<{ $overgrazed: boolean }>(
  ({ theme, $overgrazed }) =>
    $overgrazed &&
    css`
      box-shadow: inset 0 3px 0 0 ${theme.palette.error.main};
    `
);

const FieldEventListContainer = styled(Container)`
  flex-grow: 1;
`;

const StyledButton = styled(Button)(
  ({ theme }) => `
  width: 100%;
  margin-top: ${theme.spacing(1)};
`
) as typeof Button;

const TooltipChild = styled.div(
  ({ theme }) => `
  width: 100%;
`
);

interface MoveAnimalsButtonProps {
  canMoveIn: boolean;
  onMoveIn: () => Promise<void>;
  fieldHasAnimalGroup: boolean;
}

function MoveAnimalsButton({
  canMoveIn,
  onMoveIn,
  fieldHasAnimalGroup,
}: MoveAnimalsButtonProps) {
  const { t } = useTranslation();
  const [isSendingMoveRequest, setSendingMoveRequest] = useState(false);

  const disabled = isSendingMoveRequest || !canMoveIn;

  const handleButtonClick = async () => {
    setSendingMoveRequest(true);
    await onMoveIn();
    setSendingMoveRequest(false);
  };

  const MoveButton = (
    <StyledButton
      startIcon={<PinDrop />}
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={!disabled ? handleButtonClick : undefined}
    >
      {t("fieldview.moveAnimals.text")}
    </StyledButton>
  );

  return canMoveIn ? (
    MoveButton
  ) : (
    <Tooltip
      title={
        fieldHasAnimalGroup
          ? t("fieldlistmenu.moveIn.tooltip.sameField")
          : t("fieldlistmenu.moveIn.tooltip.silage")
      }
    >
      <TooltipChild>{MoveButton}</TooltipChild>
    </Tooltip>
  );
}
