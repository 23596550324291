import { List, ListItem, ListItemText } from "@mui/material";
import {
  useAppSelector,
  FertilizerEvent,
  selectAllFertilizerEvents,
} from "model";
import { useTranslation } from "common/locales";
import FertilizerEventListItem from "./FertilizerEventListItem";
export default function FertilizerEventList() {
  const { t } = useTranslation();

  const fertilizerEvents: Array<FertilizerEvent> = useAppSelector((state) =>
    selectAllFertilizerEvents(state)
  );

  const sortedFertilizerEventsForFarm = fertilizerEvents.sort(
    (a: FertilizerEvent, b: FertilizerEvent) =>
      new Date(b.fertilizerApplicationDate).valueOf() -
      new Date(a.fertilizerApplicationDate).valueOf()
  );

  return (
    <List>
      <ListItem key="title">
        <ListItemText primary={t("feedPurchase.list.title")} />
      </ListItem>
      {sortedFertilizerEventsForFarm.map((event: FertilizerEvent) => {
        return (
          <FertilizerEventListItem
            fertilizerEvent={event}
            key={`re-listitem-${event.guid}`}
          />
        );
      })}
    </List>
  );
}
