import SvgIcon from "@mui/material/SvgIcon";

interface AnimalsLocationIconRoundedProps {
  htmlColor?: string;
  fillOpacity?: number;
  strokeDasharray?: string;
}

export default function AnimalsLocationRoundedIcon({
  htmlColor,
  fillOpacity,
  strokeDasharray,
}: AnimalsLocationIconRoundedProps) {
  return (
    <SvgIcon viewBox={`0 0 32 32`} width="24">
      <circle
        cx="16"
        cy="16"
        r="15.5"
        fill={htmlColor || "#fff"}
        stroke="#000"
        fillOpacity={fillOpacity ?? 1}
        strokeDasharray={strokeDasharray ?? "none"}
      />
      <path
        d="M7 14.714c0 1.436 1.193 2.619 2.641 2.619.81 0 1.491-.338 2.002-.887.213.718.469 1.31.682 1.858.298.76.596 1.436.596 2.027v.507c-.042.929-.042 1.858.64 2.576.553.591 1.49.845 2.853.845 1.406 0 2.3-.254 2.854-.845.682-.676.682-1.647.64-2.576v-.507c0-.591.255-1.267.596-2.027.212-.549.468-1.182.681-1.858.469.549 1.193.887 2.002.887 1.448 0 2.641-1.183 2.641-2.618 0-1.436-1.192-2.619-2.64-2.619h-.512c1.874-1.689 1.917-3.505 1.917-3.59A.516.516 0 0 0 24.082 8a.516.516 0 0 0-.511.507c0 .084-.043 1.731-2.045 3.21a1.765 1.765 0 0 0-.554-.93c-.767-.76-2.172-1.098-4.515-1.098-2.343 0-3.791.338-4.516 1.098-.298.296-.468.591-.553.93-2.002-1.479-2.045-3.126-2.045-3.21A.516.516 0 0 0 8.832 8a.516.516 0 0 0-.511.507c0 .084.042 1.9 1.917 3.59H9.64c-1.448.042-2.64 1.182-2.64 2.617Zm4.217 0c0 .845-.681 1.563-1.576 1.563a1.566 1.566 0 0 1-1.576-1.563c0-.844.682-1.562 1.576-1.562h1.576v1.563Zm13.546 0c0 .845-.681 1.563-1.576 1.563a1.566 1.566 0 0 1-1.576-1.563v-1.562h1.576c.852.042 1.576.718 1.576 1.563Zm-8.349-3.97c2.002 0 3.238.254 3.791.803.256.254.341.591.384 1.014v1.013c0 1.858-.554 3.168-1.023 4.308-.34.844-.681 1.604-.681 2.407v.507c0 .76.042 1.436-.34 1.816-.342.337-1.066.506-2.13.506-1.066 0-1.79-.169-2.13-.506-.384-.38-.341-1.056-.341-1.816v-.507c0-.803-.299-1.563-.682-2.407-.469-1.14-1.022-2.45-1.022-4.308v-.802c0-.085.042-.169 0-.254.042-.422.127-.76.383-1.013.554-.507 1.79-.76 3.791-.76Z"
        fill="#000"
      />
      <path
        d="M15.18 21.133s.596-.295 1.235-.295c.682 0 1.236.295 1.236.295.085.043.17.085.255.085.17 0 .384-.085.469-.253.128-.254.042-.592-.213-.718-.043 0-.81-.465-1.79-.465-.979 0-1.746.422-1.788.465-.256.126-.341.464-.213.718.213.253.553.337.809.168Z"
        fill="#000"
      />
    </SvgIcon>
  );
}
