import { useIntl } from "react-intl";
import * as de from "./de";
import * as en from "./en";

type MessageFormatPrimitiveValue = string | number | boolean | null | undefined;
const localeData: Record<string, { [key: string]: string }> = {
  en: en.messages,
  "en-US": en.messages,
  "en-GB": en.messages,
  de: de.messages,
  "de-DE": de.messages,
};

export function useTranslation() {
  const { formatMessage } = useIntl();
  return {
    t: (id: string, values?: Record<string, MessageFormatPrimitiveValue>) =>
      formatMessage({ id }, values),
  };
}

export default localeData;
