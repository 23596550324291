import styled from "styled-components";
import { FormControl, Container } from "@mui/material";

export const DrawerForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: auto;
  width: fit-content;
  paddingTop: ${theme.spacing(2)};
`
);

export const DrawerFormControl = styled(FormControl)(
  ({ theme }) => `
  min-width: 300,
  padding-bottom: ${theme.spacing(2)},
`
);

export const DrawerButtonContainer = styled(Container)(
  ({ theme }) => `
  flex: 0 0 auto;
  display: block;
  height: 70px;
  width: 100%;
  background: white;
`
);

export const DrawerButtonGroup = styled.div(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(2)};
  padding-top: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(2)};
`
);
