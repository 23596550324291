import SvgIcon from "@mui/material/SvgIcon";

export default function AgriwebbLogoIcon() {
  return (
    <SvgIcon viewBox="0 0 162 97" width="34" height="20" x="0" y="0">
      <path
        fill="#65bb38"
        d="m152.5 84.9-23.8-42.5L104.8 0H57.2L81 42.4H33.4L9.6 84.9H0V97h66.1V84.9h-8.9L81 42.4l23.8 42.5h-8.9V97H162V84.9h-9.5"
      />
      <path fill="#559f2f" d="M33.4 42.4H81L57.2 84.9 33.4 42.4" />
      <path fill="#65bb38" d="M57.2 0 81 42.4h47.7L104.8 0z" />
      <path
        fill="#7bc555"
        d="m152.5 84.9-23.8-42.5H81l23.8 42.5h-8.9V97H162V84.9h-9.5"
      />
      <path
        fill="#65bb38"
        d="M57.2 84.9 33.4 42.4 9.6 84.9H0V97h66.1V84.9h-8.9"
      />
    </SvgIcon>
  );
}
