import { Container, List, ListItem, Divider, Button } from "@mui/material";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import FarmReseedEventList from "./FarmReseedEventList";
import { mixpanel } from "common/analytics";

export default function ReseedingDetail() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <List>
          <ListItem>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Add new re-seeding event clicked");
                history.push(`/enter-reseed-events`);
              }}
            >
              {t("reseedingDetailView.button.addReseedingEvent.title")}
            </Button>
          </ListItem>

          <ListItem>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Update grass types clicked");
                history.push(`/enter-grass-types`);
              }}
            >
              {t("reseedingDetailView.button.updateGrassTypes.title")}
            </Button>
          </ListItem>
        </List>
      </Container>
      <Divider />
      <FieldEventListContainer>
        <FarmReseedEventList />
      </FieldEventListContainer>
    </>
  );
}

const FieldEventListContainer = styled(Container)`
  flex-grow: 1;
`;
