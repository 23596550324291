import { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, Grid, Typography, Paper, TextField } from "@mui/material";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { ManualDMInputItem, RootState } from "model";
import StyledCoverAppBar from "./StyledCoverAppBar";
import { useTranslation } from "common/locales";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  margin: auto;
  max-width: 400px;
  padding: 50px;
`;

interface Props {
  dateTimeMeasurementStart: Date;
  setDateTimeMeasurementStart: Dispatch<SetStateAction<Date>>;
  dateTimeMeasurementEnd: Date;
  setDateTimeMeasurementEnd: Dispatch<SetStateAction<Date>>;
}

export default function EnterTime({
  dateTimeMeasurementStart,
  setDateTimeMeasurementStart,
  dateTimeMeasurementEnd,
  setDateTimeMeasurementEnd,
}: Props) {
  const [finishTimeError, setFinishTimeError] = useState(false);
  const { t } = useTranslation();

  const handleDateChange = (d: Date | null) => {
    if (d) {
      setDateTimeMeasurementStart(d);
      setDateTimeMeasurementEnd(d);
    }
  };
  const handleStartTimeChange = (d: Date | null) => {
    // we want to allow a start time to be changed any time, but set the error if the finish time is before start time
    if (d) {
      setDateTimeMeasurementStart(d);
      if (d < dateTimeMeasurementEnd) {
        setFinishTimeError(false);
      } else {
        setFinishTimeError(true);
      }
    }
  };
  const handleFinishTimeChange = (d: Date | null) => {
    if (d && d > dateTimeMeasurementStart) {
      setFinishTimeError(false);
      setDateTimeMeasurementEnd(d);
    } else {
      setFinishTimeError(true);
    }
  };
  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );
  const { formatMessage: f } = useIntl();

  return (
    <Grid container spacing={3} style={{ minHeight: "50vh" }}>
      <Grid item xs={12} sm={3}>
        <Typography variant="h5">{t("addCovers.enterTime.heading")}</Typography>
        <Typography variant="h6">
          {t("addCovers.enterTime.subheading")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledPaper>
            <DatePicker
              disableFuture
              inputFormat="MMMM do"
              label={t("addCovers.enterTime.dateLabel")}
              value={dateTimeMeasurementStart}
              renderInput={(params) => (
                <TextField variant="standard" {...params} fullWidth />
              )}
              onChange={handleDateChange}
              DialogProps={{
                /*https://github.com/mui/material-ui/issues/26664*/
                PaperProps: {
                  sx: { "& *:focus": { outline: "none" } },
                },
              }}
            />
            <TimePicker
              ampm={false}
              label={t("addCovers.enterTime.startTimeLabel")}
              value={dateTimeMeasurementStart}
              views={["hours", "minutes"]}
              onChange={handleStartTimeChange}
              renderInput={(params) => (
                <TextField variant="standard" {...params} fullWidth />
              )}
              DialogProps={{
                /*https://github.com/mui/material-ui/issues/26664*/
                PaperProps: {
                  sx: { "& *:focus": { outline: "none" } },
                },
              }}
            />
            <TimePicker
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  fullWidth
                  error={finishTimeError}
                  helperText={
                    finishTimeError
                      ? t("addCovers.enterTime.invalidFinishTime")
                      : ""
                  }
                />
              )}
              ampm={false}
              label={t("addCovers.enterTime.endTimeLabel")}
              value={dateTimeMeasurementEnd}
              views={["hours", "minutes"]}
              onChange={handleFinishTimeChange}
              DialogProps={{
                /*https://github.com/mui/material-ui/issues/26664*/
                PaperProps: {
                  sx: { "& *:focus": { outline: "none" } },
                },
              }}
            />
          </StyledPaper>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={3}></Grid>
      <StyledCoverAppBar>
        <Button
          component={Link}
          to={`/farm/${currentFarmId || ""}`}
          color="secondary"
        >
          {f({ id: "addCovers.cancel.label" })}
        </Button>
        <Button
          component={Link}
          to="enter-covers"
          color="primary"
          disabled={finishTimeError}
        >
          {f({ id: "addCovers.next.label" })}
        </Button>
      </StyledCoverAppBar>
    </Grid>
  );
}
