import { YieldEvent } from "model";
import { useState } from "react";
import YieldEventMoreMenu from "./YieldEventMoreMenu";
import UpdateYieldEvent from "./UpdateYieldEvent";
export default function UpdateYieldEventDialog({
  yieldEvent,
  menuAnchor,
  closeMenu,
}: {
  yieldEvent: YieldEvent;
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  if (!yieldEvent) {
    return null;
  }
  return (
    <>
      <YieldEventMoreMenu
        yieldEvent={yieldEvent}
        close={closeMenu}
        anchor={menuAnchor}
        setDialogOpen={setDialogOpen}
      />
      <UpdateYieldEvent
        yieldEvent={yieldEvent}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
}
