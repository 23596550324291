import { useHistory } from "react-router-dom";
import { Container, Dialog } from "@mui/material";

import styled from "styled-components";
import EnterFieldPreparationEvent from "./EnterFieldPreparationEvent";
import { createFieldPreparationEvent, useAppDispatch } from "model";
import { useNotification } from "app/NotificationProvider";
import { mixpanel } from "common/analytics/mixpanel";
import { useTranslation } from "common/locales";
import { FieldPreparationEventFormData } from "./FieldPreparationEventFormData";
import { convertFromFormInput } from "./FieldPreparationEventFormData";

export const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function FieldPreparationEvents() {
  const dispatch = useAppDispatch();

  const { notify } = useNotification();
  const history = useHistory();
  const handleClose = () => {
    history.goBack();
  };
  const { t } = useTranslation();

  async function handleSave(
    fieldPreparationEventFormData: FieldPreparationEventFormData
  ) {
    const fieldPreparationEvent = convertFromFormInput(
      fieldPreparationEventFormData
    );
    await dispatch(createFieldPreparationEvent(fieldPreparationEvent));

    notify(t("addFieldPreparationEvent.add.success"));
    mixpanel.track("FieldPreparationEvent created");

    handleClose();
  }
  return (
    <Dialog fullScreen disableEscapeKeyDown open onClose={handleClose}>
      <StyledContainer>
        <EnterFieldPreparationEvent
          fieldPreparationEvent={undefined}
          handleSave={handleSave}
          handleClose={() => {
            handleClose();
          }}
          fieldSelectDisabled={false}
        />
      </StyledContainer>
    </Dialog>
  );
}
