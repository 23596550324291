import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { FieldIcon } from "features/farmOverview";
import { useTranslation } from "common/locales";
import StyledCoverAppBar from "../bulkCoverInput/StyledCoverAppBar";
import { RootState, getAllFieldGeoms } from "model";
import GrassTypeDropdown from "../fieldEdit/GrassTypeDropdown";
import { GRASS_TYPE } from "model/fieldSlice";
import { useIntl } from "react-intl";
import { FieldWithNewGrassType } from "./GrassTypes";
import { mixpanel } from "common/analytics";

interface Props {
  fieldsWithNewGrassType: Array<FieldWithNewGrassType>;
  setFieldsWithNewGrassType: any;
  handleSave: any;
}

export default function EnterGrassTypes({
  fieldsWithNewGrassType,
  setFieldsWithNewGrassType,
  handleSave,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const fieldGeoms = useSelector(getAllFieldGeoms);

  const handleGrassTypeChange = (
    value: GRASS_TYPE | undefined,
    fieldId: string
  ) => {
    setFieldsWithNewGrassType(
      fieldsWithNewGrassType.map((fieldWithNewGrassType) => {
        if (fieldWithNewGrassType.field.guid === fieldId) {
          return { field: fieldWithNewGrassType.field, newGrassType: value };
        } else {
          return fieldWithNewGrassType;
        }
      })
    );
  };
  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography variant="h5">
          {t("addGrassType.enterGrassType.heading")}
        </Typography>
        <Typography variant="h6">
          {t("addGrassType.enterGrassType.subheading")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TableContainer component={Paper}>
          <Table aria-label={t("addGrassType.enterGrassType.tableHeading")}>
            <TableHead>
              <TableRow>
                <TableCell align="left" component="th">
                  &nbsp;
                </TableCell>
                <TableCell align="left" component="th">
                  {t("addGrassType.enterGrassType.fieldName")}
                </TableCell>
                <TableCell align="left" component="th">
                  {t("addGrassType.enterGrassType.selection")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldsWithNewGrassType.map((fieldWithNewGrassType) => (
                <TableRow key={fieldWithNewGrassType.field.guid}>
                  <TableCell>
                    <FieldIcon
                      geom={fieldGeoms.features.find(
                        (f) =>
                          f.properties?.id === fieldWithNewGrassType.field.guid
                      )}
                      dryMatterNow={fieldWithNewGrassType.field.dryMatterNow}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    {fieldWithNewGrassType.field.name}
                  </TableCell>
                  <TableCell align="right">
                    <GrassTypeDropdown
                      disabled={!!fieldWithNewGrassType.field.grassType}
                      onChange={(value?: GRASS_TYPE) => {
                        handleGrassTypeChange(
                          value,
                          fieldWithNewGrassType.field.guid
                        );
                      }}
                      currentGrassType={fieldWithNewGrassType.newGrassType}
                      grassTypeInputError={false}
                      label={"fieldinput.grassType.text"}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <StyledCoverAppBar>
        <Button
          onClick={() => {
            mixpanel.track("Fields grass type update cancelled");
            history.goBack();
          }}
          color="secondary"
        >
          {f({ id: "addGrassType.cancel.label" })}
        </Button>
        <Button
          color="primary"
          onClick={handleSave}
          disabled={fieldsWithNewGrassType.length === 0}
        >
          {t("addGrassType.save.label")}
        </Button>
      </StyledCoverAppBar>
    </Grid>
  );
}
