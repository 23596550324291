import { useTranslation } from "common/locales";
import {
  FormControl,
  SelectChangeEvent,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { YIELD_TYPES } from "model";

interface YieldTypeRadioButtonsProps {
  currentValue?: YIELD_TYPES;
  disabled: boolean;
  onChange: (e: SelectChangeEvent<YIELD_TYPES>) => void;
  inputError: boolean;
  name: string;
  resetForm: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export default function YieldTypeRadioButtons<T>({
  disabled,
  onChange,
  currentValue,
  inputError,
  name,
  resetForm,
  setFieldValue,
}: YieldTypeRadioButtonsProps) {
  const { t } = useTranslation();

  return (
    <FormControl
      disabled={disabled}
      error={inputError}
      fullWidth
      sx={{ margin: "30px 0px 30px 0px " }}
    >
      <RadioGroup
        row
        id="yield-unit-type-select"
        value={currentValue ?? ("" as unknown as YIELD_TYPES)}
        onChange={(e: SelectChangeEvent<YIELD_TYPES>) => {
          resetForm();
          if ((e.target.value as YIELD_TYPES) === YIELD_TYPES.MEAT) {
            setFieldValue(
              "yieldAmounts[0].detailYieldType",
              YIELD_TYPES.MEAT,
              false
            );
          }
          if ((e.target.value as YIELD_TYPES) === YIELD_TYPES.MILK) {
            setFieldValue(
              "yieldAmounts[0].detailYieldType",
              YIELD_TYPES.MILK,
              false
            );
          }
          onChange(e);
        }}
        name={name}
      >
        {Object.entries(YIELD_TYPES).map(([key, value]) => (
          <FormControlLabel
            sx={{ margin: "0px 40px 0px 0px " }}
            value={value as string}
            key={key}
            control={<Radio />}
            label={t(`yieldEvent.yieldType.${value}`)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
