import { Dispatch, SetStateAction } from "react";
import { Container, Dialog } from "@mui/material";
import {
  FieldPreparationEvent,
  updateFieldPreparationEvent,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import {
  FieldPreparationEventFormData,
  convertFromFormInput,
} from "./FieldPreparationEventFormData";
import EnterFieldPreparationEvent from "./EnterFieldPreparationEvent";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function UpdateFieldPreparationEvent({
  fieldPreparationEvent,
  dialogOpen,
  setDialogOpen,
}: {
  fieldPreparationEvent: FieldPreparationEvent;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const handleClose = () => {
    setDialogOpen(false);
    mixpanel.track("Update fieldPreparation event cancelled");
  };

  async function handleSave(
    fieldPreparationEventFormData: FieldPreparationEventFormData
  ) {
    const convertedFieldPreparationEvent = convertFromFormInput(
      fieldPreparationEventFormData
    );
    await dispatch(
      updateFieldPreparationEvent({
        ...convertedFieldPreparationEvent,
        guid: fieldPreparationEvent.guid,
      })
    );
    notify(t("updateFieldPreparationEvent.add.success"));
    mixpanel.track("FieldPreparation event updated");
    handleClose();
  }
  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={dialogOpen}
      onClose={handleClose}
    >
      <StyledContainer>
        {
          <EnterFieldPreparationEvent
            fieldPreparationEvent={fieldPreparationEvent}
            handleSave={handleSave}
            handleClose={() => {
              handleClose();
            }}
            fieldSelectDisabled={false}
          />
        }
      </StyledContainer>
    </Dialog>
  );
}
