import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import {
  useAppSelector,
  FEED_EVENT_REJECTION_PERIOD_DAYS,
  FertilizerEvent,
  selectAllFields,
  selectAllArableFields,
  Field,
  ArableField,
  FERTILIZER_TOTAL_AMOUNT_UNIT,
  FERTILIZER_TYPES,
} from "model";
import { useTranslation } from "common/locales";
import { getFormattedDate } from "../../common/utils/getFormattedTime";
import { MoreVert } from "@mui/icons-material";

import { useState, Fragment } from "react";
import { useSelector } from "react-redux";

import FertilizerEventIcon from "common/icons/FertilizerEventIcon";
import UpdateFertilizerEventDialog from "./UpdateFertilizerEventDialog";

export const convertToFertilizerAmountString = (
  amount: number,
  locale: Intl.LocalesArgument,
  unit: FERTILIZER_TOTAL_AMOUNT_UNIT,
  t: (a: string) => string
) => {
  let unitType = unit;

  if (amount > 1000 && unit === FERTILIZER_TOTAL_AMOUNT_UNIT.KG) {
    amount = amount / 1000;
    unitType = "TONNE" as FERTILIZER_TOTAL_AMOUNT_UNIT;
  } else if (amount > 1000 && unit === FERTILIZER_TOTAL_AMOUNT_UNIT.GALLONS) {
    amount = amount * 0.00378541;
    unitType = "M3" as FERTILIZER_TOTAL_AMOUNT_UNIT;
  }
  return (
    amount.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }) +
    " " +
    t(`fertilizerEvent.totalAmountUnit.option.${unitType}`)
  );
};

export const getFertilizerTypeString = (
  fertilizerEvent: FertilizerEvent,
  t: (a: string) => string
) => {
  let fertilizerType = "";

  if (
    fertilizerEvent.fertilizerProperties.fertilizerType ===
    FERTILIZER_TYPES.ORGANIC
  ) {
    fertilizerType = t(
      `fertilizerEvent.organicFertilizerType.label.${fertilizerEvent.fertilizerProperties.organicFertilizerType}`
    );
  } else if (
    fertilizerEvent.fertilizerProperties.fertilizerType ===
    FERTILIZER_TYPES.SYNTHETIC
  ) {
    fertilizerType = `Synthetic (${fertilizerEvent.fertilizerProperties.productName})`;
  } else {
    fertilizerType = t(
      `fertilizerEvent.fertilizerType.label.${fertilizerEvent.fertilizerProperties.fertilizerType}`
    );
  }

  return fertilizerType;
};

export default function FertilizerEventListItem({
  fertilizerEvent,
}: {
  fertilizerEvent: FertilizerEvent;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);

  const icon = <FertilizerEventIcon />;

  const formattedTime = getFormattedDate(
    fertilizerEvent.fertilizerApplicationDate,
    locale
  );
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const allGrassFields: Field[] = useSelector(selectAllFields);
  const allArableFields: ArableField[] = useSelector(selectAllArableFields);

  const selectedGrassField = allGrassFields.filter((grassField) =>
    fertilizerEvent.fieldGuids.includes(grassField.guid)
  );

  const selectedArableFields = allArableFields.filter((arableField) =>
    fertilizerEvent.fieldGuids.includes(arableField.guid)
  );

  const fieldNames = [
    selectedArableFields.map((field) => field.name),
    selectedGrassField.map((field) => field.name),
  ]
    .flat()
    .join(", ");
  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }
  const fertilizerAmountString = convertToFertilizerAmountString(
    fertilizerEvent.totalAmount,
    locale,
    fertilizerEvent.totalAmountUnit,
    t
  );

  const fertilizerType = getFertilizerTypeString(fertilizerEvent, t);

  const canEdit =
    differenceInDays(
      Date.now(),
      fertilizerEvent.creationDate
        ? new Date(fertilizerEvent.creationDate)
        : Date.now()
    ) <= FEED_EVENT_REJECTION_PERIOD_DAYS;

  return (
    <ListItem key={`re-listitem-${fertilizerEvent.guid}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" display="block">
            {t("fertilizerEvent.fertilizerEventDate", {
              fertilizerEventDate: formattedTime,
            })}
          </Typography>
        }
        secondary={
          <>
            <Typography component="span" display="block" noWrap>
              {fertilizerType}
            </Typography>
            <Typography component="span" display="block" noWrap>
              {t("fertilizerEvent.field.name")}
              {fieldNames}
            </Typography>

            <Typography component="span" display="block" noWrap>
              {fertilizerAmountString}
            </Typography>

            {fertilizerEvent.note && (
              <Typography
                component="span"
                display="block"
                textOverflow="ellipsis"
                overflow="auto"
              >
                Note: {fertilizerEvent.note}
              </Typography>
            )}
          </>
        }
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={openMenu}
            id={fertilizerEvent.guid}
            size="large"
          >
            <MoreVert />
          </IconButton>
          {
            <UpdateFertilizerEventDialog
              closeMenu={closeMenu}
              menuAnchor={menuAnchor}
              fertilizerEvent={fertilizerEvent}
            />
          }
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
