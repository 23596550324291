import {
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import AppsIcon from "@mui/icons-material/Apps";
import DetailViewLoading from "common/components/DetailViewLoading";
import { FieldsList } from "features/farmOverview";
import {
  useAppDispatch,
  useAppSelector,
  setZoomToGeom,
  getAllFieldsForAnimalGroupId,
  getFieldGeomsForAnimalGroup,
  getAnimalGroupColor,
  getGroupWeight,
  getTotalAnimalCount,
  selectAnimalGroupById,
  getDemand,
  getDemandForFeedAllocations,
} from "model";
import GrassWedgeIcon from "common/icons/GrassWedgeIcon";
import { useTranslation } from "common/locales";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import AnimalGroupMenu from "./AnimalGroupMenu";
import AssignFieldDialog from "./AssignFieldDialog";
import { GrassWedgeDialog } from "features/grassWedge";
import { mixpanel } from "../../common/analytics";

import {
  AnimalGroupWeightIcon,
  GrassIcon,
  AnimalGroupColorIcon,
} from "common/icons";
import { FeedAllocationDialog } from "features/feedAllocation";
import { feedBudgetPlannerEnabledForUserAndAnimalGroup } from "common/featureToggle";

export default function AnimalGroupDetail() {
  const [assignFieldDialogOpen, setAssignFieldDialogOpen] =
    useState<boolean>(false);
  const { animalGroupId } = useParams<{ animalGroupId: string }>();
  const animalGroup = useAppSelector((state) =>
    selectAnimalGroupById(state, animalGroupId)
  );

  const animalCount = useAppSelector((state) =>
    getTotalAnimalCount(state, animalGroupId)
  );

  const groupWeight = useAppSelector((state) =>
    getGroupWeight(state, animalGroupId)
  );
  const animalGroupColor = useAppSelector(getAnimalGroupColor(animalGroupId)); // TODO: remove when fully migrated to the colors from the database

  const user = useAppSelector((state) => state.user.details);
  const feedBudgetPlannerEnabled =
    feedBudgetPlannerEnabledForUserAndAnimalGroup(user, animalGroup);

  const grassWedgeMatch = useRouteMatch(
    "/animal-group/:animalGroupId/grass-wedge"
  );
  const feedAllocationMatch = useRouteMatch(
    "/animal-group/:animalGroupId/feed-allocation"
  );
  const { t } = useTranslation();

  const history = useHistory();

  const fields = useAppSelector(getAllFieldsForAnimalGroupId(animalGroupId));

  const demand = useAppSelector((state) => getDemand(state, animalGroupId));
  const feedAllocationDemand = useAppSelector((state) =>
    getDemandForFeedAllocations(state, animalGroupId)
  );
  const totalAreaForGroup = fields.reduce((acc, f) => acc + f.area, 0);
  let demandPerHaAndDay = demand
    ? Math.round((demand / totalAreaForGroup) * 10000)
    : 0;

  if (feedBudgetPlannerEnabled) {
    demandPerHaAndDay = feedAllocationDemand
      ? Math.round((feedAllocationDemand / totalAreaForGroup) * 10000)
      : 0;
  }

  const showGrassWedgeButton = true; // always allow to open grasswedge

  useZoomToFieldGeoms(animalGroupId);

  if (!animalGroup) return <DetailViewLoading size={2} />;

  const { name } = animalGroup;

  return (
    <>
      <Container>
        <List>
          <ListItem>
            <ListItemIcon>
              <AnimalGroupColorIcon
                color={animalGroup.color ?? animalGroupColor}
              />
            </ListItemIcon>
            <ListItemText primary={name} />
            <ListItemSecondaryAction>
              <AnimalGroupMenu {...animalGroup} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AnimalGroupWeightIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${groupWeight || ""} kg`}
              secondary={t("animalGroup.groupWeight")}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GrassIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                fields.length === 0
                  ? t("animalGroup.noFieldsAssigned")
                  : t("animalGroup.demandUnit", { demandPerHaAndDay })
              }
              secondary={t("animalGroup.groupDemand")}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText
              primary={animalCount}
              secondary={t("animalGroup.animalCount")}
            />
          </ListItem>
        </List>
      </Container>
      <Divider />
      <Container>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <GrassWedgeButton
            animalGroupId={animalGroupId}
            disabled={!showGrassWedgeButton}
          />
          {showGrassWedgeButton && (
            <GrassWedgeDialog
              open={Boolean(grassWedgeMatch)}
              close={() => history.push(`/animal-group/${animalGroupId}`)}
              animalGroup={animalGroup}
            />
          )}
          <StyledButton
            startIcon={<Add />}
            variant="contained"
            color="primary"
            onClick={() => {
              mixpanel.track(
                "feed budgeting assign fields to this animal group button clicked"
              );
              setAssignFieldDialogOpen(true);
            }}
          >
            {t("animalGroup.assignField")}
          </StyledButton>
          <AssignFieldDialog
            open={assignFieldDialogOpen}
            close={() => setAssignFieldDialogOpen(false)}
            animalGroupId={animalGroupId}
          />
          {feedBudgetPlannerEnabled && (
            <FeedAllocationButton animalGroupId={animalGroupId} />
          )}
          {feedBudgetPlannerEnabled && (
            <FeedAllocationDialog
              open={Boolean(feedAllocationMatch)}
              close={() => history.push(`/animal-group/${animalGroupId}`)}
              animalGroup={animalGroup}
            />
          )}
        </Grid>
      </Container>
      <Container>
        <FieldsList fieldIds={fields.map((field) => field.guid)} />
      </Container>
    </>
  );
}

function useZoomToFieldGeoms(animalGroupId: string) {
  const dispatch = useAppDispatch();
  const fieldGeoms = useAppSelector(getFieldGeomsForAnimalGroup(animalGroupId));

  useEffect(() => {
    if (fieldGeoms.features.length > 0) {
      dispatch(setZoomToGeom(fieldGeoms));
    }
  }, [fieldGeoms, dispatch]);
}

function GrassWedgeButton({ animalGroupId, disabled }: GrassWedgeButtonProps) {
  const { t } = useTranslation();

  if (!disabled)
    return (
      <StyledButton
        startIcon={<GrassWedgeIcon />}
        variant="outlined"
        size="large"
        component={Link}
        to={`/animal-group/${animalGroupId}/grass-wedge`}
      >
        {t("grassWedge.open")}
      </StyledButton>
    );

  return (
    <Tooltip title={t("animalGroup.grassWedgeButton.tooltip")} arrow>
      <TooltipChild>
        <StyledButton
          startIcon={<GrassWedgeIcon disabled />}
          variant="outlined"
          disabled
          size="large"
        >
          {t("grassWedge.open")}
        </StyledButton>
      </TooltipChild>
    </Tooltip>
  );
}

type GrassWedgeButtonProps = {
  animalGroupId: string;
  disabled?: boolean;
};

const StyledButton = styled(Button)(
  ({ theme }) => `
  width: 100%;
  margin-top: ${theme.spacing(1)};
`
) as typeof Button;

const TooltipChild = styled.div`
  width: 100%;
`;

function FeedAllocationButton({ animalGroupId }: { animalGroupId: string }) {
  const { t } = useTranslation();
  return (
    <StyledButton
      variant="contained"
      color="primary"
      component={Link}
      onClick={() => {
        mixpanel.track("view feed allocation button clicked");
      }}
      to={`/animal-group/${animalGroupId}/feed-allocation`}
    >
      {t("animalGroup.viewFeedAllocation")}
    </StyledButton>
  );
}
