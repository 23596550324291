import SvgIcon from "@mui/material/SvgIcon";

export default function AddFeedPurchaseIcon() {
  return (
    <SvgIcon
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="66.000000pt"
      height="66.000000pt"
      viewBox="0 0 66.000000 66.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M24 605 c-3 -14 -4 -35 -1 -47 4 -13 0 -26 -10 -33 -20 -15 -10 -45
   17 -52 15 -4 20 -14 20 -37 0 -19 14 -56 35 -91 27 -44 34 -65 30 -89 -6 -35
   -2 -42 42 -74 44 -30 73 -28 116 11 32 30 36 38 31 69 -5 28 0 43 28 84 27 40
   33 59 33 101 0 53 16 86 30 63 4 -6 -1 -18 -11 -25 -17 -13 -17 -14 -2 -15 25
   0 42 36 24 52 -8 8 -12 26 -9 48 2 19 -1 42 -7 50 -9 13 -12 12 -25 -5 -39
   -51 -47 -57 -68 -50 -44 14 -134 17 -156 6 -30 -16 -58 -5 -76 30 -19 36 -33
   37 -41 4z m99 -50 c54 8 120 8 174 0 40 -7 43 -5 62 26 l21 34 0 -38 c0 -39
   -34 -86 -62 -88 -7 0 -3 -4 10 -9 33 -14 29 -63 -10 -126 -34 -57 -38 -58
   -160 -54 -43 2 -105 105 -92 156 4 16 13 24 28 24 19 1 18 2 -6 16 -34 18 -48
   43 -48 86 l0 33 21 -34 c19 -31 22 -33 62 -26z m-75 -47 c7 -7 12 -18 12 -25
   0 -11 -5 -11 -20 -3 -11 6 -20 17 -20 25 0 18 12 19 28 3z m238 -245 c12 -45
   0 -53 -81 -53 l-75 0 0 33 c0 19 2 36 5 38 2 3 36 5 75 5 65 0 71 -2 76 -23z
   m-58 -84 c-12 -7 -24 -7 -35 0 -15 9 -12 10 17 10 29 0 32 -1 18 -10z"
        />
        <path
          d="M130 470 c0 -5 7 -10 15 -10 19 0 19 -39 0 -64 -16 -22 -19 -36 -7
   -36 8 1 42 65 42 80 0 11 -29 40 -41 40 -5 0 -9 -4 -9 -10z"
        />
        <path
          d="M252 468 c-17 -17 -15 -43 8 -80 24 -39 27 -20 4 24 -16 30 -15 32 7
   49 17 13 20 19 9 19 -9 0 -21 -5 -28 -12z"
        />
        <path
          d="M130 440 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
   -4 -4 -10z"
        />
        <path
          d="M270 440 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
   -4 -4 -10z"
        />
        <path
          d="M150 251 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
   -10 -4 -10 -9z"
        />
        <path
          d="M250 244 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0 -10
   -7 -10 -16z"
        />
        <path
          d="M472 408 c-19 -19 -14 -82 8 -111 14 -18 19 -35 15 -54 -5 -27 -4
   -26 10 5 11 23 22 32 39 32 31 0 66 43 66 81 0 24 -4 29 -24 29 -27 0 -76 -35
   -76 -55 0 -8 12 -1 29 16 43 43 71 35 52 -15 -21 -54 -89 -59 -111 -7 -20 50
   6 91 38 59 7 -7 12 -8 12 -2 0 13 -21 34 -35 34 -6 0 -16 -5 -23 -12z"
        />
        <path
          d="M385 303 c-40 -21 -41 -25 -45 -91 -8 -142 -7 -155 20 -169 34 -17
   236 -17 270 0 32 16 35 34 20 137 -13 93 -19 112 -41 118 -11 4 -12 2 -1 -9
   31 -33 6 -58 -76 -74 -48 -10 -129 4 -155 26 l-22 19 24 20 c13 11 34 20 47
   20 12 0 26 5 29 10 10 16 -37 11 -70 -7z m127 -101 c42 3 84 11 93 18 8 7 18
   10 21 7 11 -11 -7 -27 -46 -38 -22 -7 -40 -16 -40 -20 0 -4 20 -2 45 5 45 14
   55 10 33 -12 -9 -9 -8 -12 5 -12 20 0 24 -49 5 -54 -7 -2 -29 -9 -48 -15 -34
   -10 -34 -11 13 -6 36 4 48 2 44 -8 -2 -8 -42 -13 -139 -15 -110 -2 -137 0
   -147 12 -11 13 -6 15 41 11 l53 -5 -42 13 c-23 6 -47 17 -53 23 -14 14 -2 44
   18 44 13 0 12 3 -4 14 -25 18 -12 31 16 16 11 -6 29 -10 38 -8 11 3 3 9 -20
   17 -21 7 -38 19 -38 26 0 12 7 11 37 -2 27 -11 59 -14 115 -11z"
        />
      </g>
    </SvgIcon>
  );
}
