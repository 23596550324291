import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  AnimalGroup,
  getDemand,
  getAllFieldsForAnimalGroupId,
  getAvailableDryMatterForAnimalGroupId,
  updateAnimalGroup,
  useAppDispatch,
  useAppSelector,
} from "model";
import { useNotification } from "app/NotificationProvider";
import HeaderBar from "common/components/HeaderBar";

import { useTranslation } from "common/locales";
import { sumBy } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AssignFieldDialog } from "features/animalGroupDetail";
import { GrassWedge } from "features/grassWedge";
import { mixpanel } from "../../common/analytics";

export default function GrassWedgeDialog({
  open,
  close,
  animalGroup,
}: GrassWedgeProps) {
  useEffect(() => {
    if (open) {
      mixpanel.track("GrassWedge open");
    }
  }, [open]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { guid, name } = animalGroup;
  const [assignFieldDialogOpen, setAssignFieldDialogOpen] = useState(false);
  const growthRate = useAppSelector((state) => state.app.growthRate) || 0;

  const [postGrazingTarget, setPostGrazingTarget] = useState(
    String(animalGroup.postGrazingTarget ?? "1500")
  );
  const [rotationLength, setRotationLength] = useState(
    String(animalGroup.rotationLength ?? "30")
  );

  const fields = useAppSelector(getAllFieldsForAnimalGroupId(guid));
  const demand = useAppSelector((state) => getDemand(state, guid));

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { notify } = useNotification();

  const totalAreaHectar = sumBy(fields, "area") / 10000;

  const totalAvailableDM = Math.round(
    useAppSelector(getAvailableDryMatterForAnimalGroupId(guid))
  );

  let averageCover = 0,
    grazingDaysAhead = 0,
    preGrazingTarget = 0,
    demandPerHaPerDay = 0;

  if (totalAreaHectar > 0) {
    averageCover = Math.round(
      fields.reduce(
        (prev, curr) => prev + (curr.dryMatterNow * curr.area) / 10000,
        0
      ) / totalAreaHectar
    );

    grazingDaysAhead = demand
      ? Math.floor(totalAvailableDM / Number(demand))
      : Infinity;

    demandPerHaPerDay = demand
      ? Math.round(Number(demand) / totalAreaHectar)
      : 0;

    preGrazingTarget = Math.round(
      ((demand || 0) * Number(rotationLength)) / totalAreaHectar +
        Number(postGrazingTarget)
    );
  }

  return (
    <Dialog fullScreen open={open} onBackdropClick={close}>
      <HeaderBar nested title={name} onBack={close} />
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <StyledForm onSubmit={saveAnimalGroup}>
            <Field
              variant="standard"
              onBlur={saveAnimalGroup}
              value={rotationLength}
              onChange={(event) => setRotationLength(event.target.value)}
              type="number"
              label={t("grassWedge.rotationLength")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.days")}
                  </InputAdornment>
                ),
              }}
            />
            <Field
              variant="standard"
              onBlur={saveAnimalGroup}
              value={postGrazingTarget}
              onChange={(event) => setPostGrazingTarget(event.target.value)}
              type="number"
              label={t("grassWedge.postGrazingTarget")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.kilogramPerHectar")}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                step: 100,
              }}
            />
            <Field
              variant="standard"
              value={preGrazingTarget}
              disabled
              type="number"
              label={t("grassWedge.preGrazingTarget")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("units.kilogramPerHectar")}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                step: 100,
              }}
            />
            <AssignButton
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => setAssignFieldDialogOpen(true)}
            >
              {t("grassWedge.assignField")}
            </AssignButton>
            <input type="submit" hidden />
          </StyledForm>
        </Grid>
        <Grid
          container
          justifyContent={isMobile ? "flex-start" : "center"}
          alignItems="center"
          style={{ paddingBottom: theme.spacing(3) }}
        >
          <MetricItem m={2} mx={3}>
            <Typography variant="h6">{averageCover}</Typography>
            <Typography variant="body1">
              {t("grassWedge.averageCover")}
            </Typography>
          </MetricItem>
          <MetricItem m={2} mx={3}>
            <Typography variant="h6">
              {growthRate ? Math.round(growthRate) : ""}
            </Typography>
            <Typography variant="body1">{t("grassWedge.growth")}</Typography>
          </MetricItem>
          <MetricItem m={2} mx={3}>
            <Typography variant="h6">{demandPerHaPerDay}</Typography>
            <Typography variant="body1">{t("grassWedge.demand")}</Typography>
          </MetricItem>
          <MetricItem m={2} mx={3}>
            <Typography variant="h6">{grazingDaysAhead}</Typography>
            <Typography variant="body1">
              {t("grassWedge.grazingDaysAhead")}
            </Typography>
          </MetricItem>
        </Grid>
        {fields.length <= 1 && (
          <Grid
            container
            justifyContent={isMobile ? "flex-start" : "center"}
            alignItems="center"
            style={{ paddingBottom: theme.spacing(3) }}
          >
            <Typography variant="h4">
              {t(
                fields.length === 0
                  ? "grassWedge.noFields.prompt"
                  : "grassWedge.singleField.prompt"
              )}
            </Typography>
          </Grid>
        )}

        <GrassWedge
          fields={fields}
          preGrazingTarget={Number(preGrazingTarget)}
          postGrazingTarget={Number(postGrazingTarget)}
        />
      </DialogContent>
      <AssignFieldDialog
        animalGroupId={animalGroup.guid}
        open={assignFieldDialogOpen}
        close={() => setAssignFieldDialogOpen(false)}
      />
    </Dialog>
  );

  async function saveAnimalGroup(e?: any) {
    e?.preventDefault();
    try {
      await dispatch(
        updateAnimalGroup({
          ...animalGroup,
          rotationLength: Number(rotationLength),
          postGrazingTarget: Number(postGrazingTarget),
        })
      ).then(unwrapResult);
      mixpanel.track("GrassWedge edited");
      notify(t("grassWedge.notification.saved"), "success");
    } catch (e) {
      notify(t("grassWedge.notification.error"), "error");
    }
  }
}

type GrassWedgeProps = {
  open: boolean;
  close: () => void;
  animalGroup: AnimalGroup;
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const Field = styled(TextField)(
  ({ theme }) => `
  min-width: 300;
  margin: ${theme.spacing(2)};
`
);

const AssignButton = styled(Button)(
  ({ theme }) => `
  margin: ${theme.spacing(2)};
`
);

const MetricItem = styled(Box)(
  ({ theme }) => `
  ${theme.breakpoints.down("sm")} {
  display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
  }
`
);
