import { useEffect, useState } from "react";
import styled from "styled-components";
import { FeatureCollection } from "@turf/turf";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Divider,
  IconButton,
} from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { setZoomToGeom, useAppDispatch, ArableField, FIELD_TYPE } from "model";
import { FieldEditActionMenu } from "features/fieldDetail";
import { ArableFieldEventList } from "features/arableFieldEventList";
import { SwardCompositionIcon } from "common/icons";
import { MoreVert } from "@mui/icons-material";
import { useTranslation } from "common/locales";
import AfterFieldCreatedDialog from "features/fieldEdit/AfterFieldCreatedDialog";

interface Props {
  field: ArableField;
}

export default function ArableFieldDetails({ field }: Props) {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const fieldGeom = {
    type: "FeatureCollection",
    features: [field.geom],
  } as FeatureCollection;

  useEffect(() => {
    dispatch(setZoomToGeom(fieldGeom));
  }, [field.guid, dispatch]);

  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary={field.name} secondary={""} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-haspopup="true"
              onClick={openMenu}
              size="large"
            >
              <MoreVert />
            </IconButton>
            <FieldEditActionMenu
              anchor={menuAnchor}
              close={closeMenu}
              fieldId={field.guid || ""}
              fieldType={FIELD_TYPE.ARABLE}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AspectRatioIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              field
                ? `${(field.area / 10000).toFixed(2)} ${t(
                    "fieldview.area.unit"
                  )}`
                : ""
            }
            secondary={t("fieldview.area.text")}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SwardCompositionIcon />
          </ListItemIcon>
          <ListItemText
            primary={t(`fieldinput.arableType.option.${field.arableType}`)}
            secondary={t("fieldinput.arableType.text")}
          />
        </ListItem>
      </List>
      <Divider />
      <FieldEventListContainer>
        <ArableFieldEventList field={field} />
      </FieldEventListContainer>
      <AfterFieldCreatedDialog />
    </>
  );

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }
}

const FieldEventListContainer = styled(Container)`
  flex-grow: 1;
`;
