import { useTranslation } from "common/locales";
import { useConfirm } from "material-ui-confirm";

type ConfirmationOptions = {
  title: string;
  description: string;
};

export default function useConfirmation({
  title,
  description,
}: ConfirmationOptions) {
  const originalConfirm = useConfirm();
  const { t } = useTranslation();
  return async function confirm(): Promise<boolean> {
    try {
      await originalConfirm({
        title,
        description,
        cancellationButtonProps: {
          color: "primary",
        },
        confirmationText: t("confirmdeletedialog.confirm.label"),
        cancellationText: t("confirmdeletedialog.cancel.label"),
        confirmationButtonProps: {
          color: "secondary",
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  };
}
