const standardCowWeight = 700;
const standardCowFoodIntake = 0.02;

export const computeAGD = (DMperHA: number, areaInHA: number) =>
  Math.round(
    (DMperHA * areaInHA) / 10000 / (standardCowWeight * standardCowFoodIntake)
  );

export const minDryMatterSteps = [
  {
    lower: 0,
    upper: 1300,
    color: "#c37a63",
  },
  {
    lower: 1300,
    upper: 1400,
    color: "#d9dbbe",
  },
  {
    lower: 1400,
    upper: 1500,
    color: "#c4e2b6",
  },
  {
    lower: 1500,
    upper: 1600,
    color: "#a7d89b",
  },
  {
    lower: 1600,
    upper: 1700,
    color: "#85c973",
  },
  {
    lower: 1700,
    upper: 1800,
    color: "#6EA95F",
  },
  {
    lower: 1800,
    upper: 1900,
    color: "#4F8B3F",
  },
  {
    lower: 1900,
    upper: 2000,
    color: "#909231",
  },
  {
    lower: 2000,
    upper: 200000,
    color: "#909231",
  },
];

const hex2rgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result![1], 16),
    g: parseInt(result![2], 16),
    b: parseInt(result![3], 16),
  };
};

interface RGB {
  r: number;
  g: number;
  b: number;
}

// we dont necessarily care about perceived color distance. Euclidean Distance should be good enough
const colorDistance = (color1: RGB, color2: RGB) =>
  Math.sqrt(
    Math.pow(color1.r - color2.r, 2) +
      Math.pow(color1.g - color2.g, 2) +
      Math.pow(color1.b - color2.b, 2)
  );

export function colorToDM(color: RGB) {
  let index = 0;
  let min = Infinity;
  dryMatterNowSteps.forEach((s, idx) => {
    const matchScore = colorDistance(color, hex2rgb(s.color));
    if (min > matchScore) {
      min = matchScore;
      index = idx;
    }
  });
  return (dryMatterNowSteps[index].upper + dryMatterNowSteps[index].lower) / 2;
}

export function dryMatterToColor(
  dryMatter?: number,
  fallbackColor = "#ffffff"
) {
  return (
    dryMatterNowSteps.find(
      (d) => dryMatter && d.lower <= dryMatter && d.upper > dryMatter
    )?.color || fallbackColor
  );
}

export const dryMatterNowSteps = [
  {
    lower: 0,
    upper: 700,
    color: "#A8917B",
  },
  {
    lower: 700,
    upper: 1000,
    color: "#CABFA1",
  },
  {
    lower: 1000,
    upper: 1150,
    color: "#D6D6AD",
  },
  {
    lower: 1150,
    upper: 1300,
    color: "#D7E7AA",
  },
  {
    lower: 1300,
    upper: 1450,
    color: "#D1F094",
  },
  {
    lower: 1450,
    upper: 1600,
    color: "#C5E97C",
  },
  {
    lower: 1600,
    upper: 1750,
    color: "#B3E579",
  },
  {
    lower: 1750,
    upper: 1900,
    color: "#A5DE77",
  },
  {
    lower: 1900,
    upper: 2050,
    color: "#99D775",
  },
  {
    lower: 2050,
    upper: 2200,
    color: "#8ED173",
  },
  {
    lower: 2200,
    upper: 2350,
    color: "#82CA71",
  },
  {
    lower: 2350,
    upper: 2500,
    color: "#76C36F",
  },
  {
    lower: 2500,
    upper: 2650,
    color: "#6ABC6D",
  },
  {
    lower: 2650,
    upper: 2800,
    color: "#62B36C",
  },
  {
    lower: 2800,
    upper: 2950,
    color: "#59AB6C",
  },
  {
    lower: 2950,
    upper: 3100,
    color: "#52A36B",
  },
  {
    lower: 3100,
    upper: 3400,
    color: "#4A9A6A",
  },
  {
    lower: 3400,
    upper: 3700,
    color: "#41916A",
  },
  {
    lower: 3700,
    upper: 4000,
    color: "#388969",
  },
  {
    lower: 4000,
    upper: 4500,
    color: "#318168",
  },
  {
    lower: 4500,
    upper: 5000,
    color: "#1E7662",
  },
  {
    lower: 5000,
    upper: 6001,
    color: "#0E6E5C",
  },
];
