import { GENERAL_FEED_UNITS } from "model";
import { Container } from "@mui/material";
import styled, { css } from "styled-components";

export const convertToFeedStockAmountString = (
  amount: number,
  locale: Intl.LocalesArgument,
  unit: GENERAL_FEED_UNITS,
  t: (a: string) => string
) => {
  let unitType = unit;

  if (amount > 1000 && unit === GENERAL_FEED_UNITS.KG) {
    amount = amount / 1000;
    unitType = GENERAL_FEED_UNITS.TONNE;
  }
  return (
    amount.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }) +
    " " +
    t(`feedPurchase.feedUnit.option.${unitType}`)
  );
};

export const ButtonGroup = styled.div(
  ({ theme }) => css`
      position: absolute;
      right: ${theme.spacing(2)};
      padding - top: ${theme.spacing(2)};
      padding - bottom: ${theme.spacing(2)};
      `
);

export const ButtonContainer = styled(Container)`
  position: relative;
  flex: 0 0 auto;
  display: block;
  height: 70px;
  width: 100 %;
  background: white;
`;
