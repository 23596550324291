import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityId,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { RootState } from "./rootReducer";
import {
  BEGINNING_OF_TIME,
  GENERAL_FEED_TYPE,
  GENERAL_FEED_UNITS,
  PURCHASE_CURRENCY,
  getFeedStock,
} from "./feedStockSlice";

export const FEED_PURCHASE_EVENT_REJECTION_PERIOD_DAYS = 30;

export type GeneralFeedPurchaseEvent = {
  farmGuid: string;
  guid: string;
  note: string;
  feedPurchaseEventDate: string;
  feedPurchases: GeneralFeedPurchase[];
  creationDate?: string;
};

export type GeneralFeedPurchase = {
  costCurrency?: PURCHASE_CURRENCY;
  cost?: number;
  unitType: GENERAL_FEED_UNITS;
  feedType: GENERAL_FEED_TYPE;
  amount: number;
};

class FeedPurchaseAPI extends API<GeneralFeedPurchaseEvent> {
  async getAllFeedPurchaseEventsForFarm(
    farmGuid: string,
    feedPurchaseEventTimeAfter?: string
  ): Promise<GeneralFeedPurchaseEvent[]> {
    const feedPurchaseEventTimeAfterParam = feedPurchaseEventTimeAfter
      ? `&feedPurchaseEventTimeAfter=${feedPurchaseEventTimeAfter}`
      : "";
    const res = await fetch(
      `${this.baseUrl}${this.entity}?farmGuid=${farmGuid}${feedPurchaseEventTimeAfterParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as GeneralFeedPurchaseEvent[];
  }
}

const feedPurchaseApi = new FeedPurchaseAPI("feedPurchase");

export const createFeedPurchaseEvent = createAsyncThunk(
  "feedPurchase/create",
  async (generalfeedPurchaseEvent: GeneralFeedPurchaseEvent, { dispatch }) => {
    const result = await feedPurchaseApi.createOne(generalfeedPurchaseEvent);
    await dispatch(
      getFeedStock({
        farmGuid: generalfeedPurchaseEvent.farmGuid,
        timeAfter: BEGINNING_OF_TIME,
      })
    );
    return { ...generalfeedPurchaseEvent, ...result };
  }
);

export const updateFeedPurchaseEvent = createAsyncThunk(
  "feedPurchase/update",
  async (generalfeedPurchaseEvent: GeneralFeedPurchaseEvent, { dispatch }) => {
    const result = await feedPurchaseApi.updateOne(generalfeedPurchaseEvent);
    await dispatch(
      getFeedStock({
        farmGuid: generalfeedPurchaseEvent.farmGuid,
        timeAfter: BEGINNING_OF_TIME,
      })
    );
    return { ...generalfeedPurchaseEvent, ...result };
  }
);

export const feedPurchaseEventAdapter =
  createEntityAdapter<GeneralFeedPurchaseEvent>({
    // we need this because IDs are stored in a field other than `field.id`
    selectId: (feedPurchase) => feedPurchase.guid,
    // Keeps the array sorted by guid
    sortComparer: (a, b) => a.guid.localeCompare(b.guid),
  });

export const { selectAll: selectAllFeedPurchaseEvents } =
  feedPurchaseEventAdapter.getSelectors(
    (state: RootState) => state.feedPurchaseEvents
  );

export const getAllFeedPurchaseEventsForFarm = createAsyncThunk<
  Promise<Array<GeneralFeedPurchaseEvent>>,
  { farmGuid: string; feedPurchaseEventTimeAfter?: string }
>(
  "readings/getFeedPurchaseEventsBulk",
  async ({ farmGuid, feedPurchaseEventTimeAfter }) => {
    return feedPurchaseApi.getAllFeedPurchaseEventsForFarm(
      farmGuid,
      feedPurchaseEventTimeAfter
    );
  }
);

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";
type FeedPurchaseState = EntityState<GeneralFeedPurchaseEvent> & {
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialfeedPurchaseEventState: FeedPurchaseState =
  feedPurchaseEventAdapter.getInitialState({
    loading: "idle",
    error: null,
    status: null,
  });

const feedPurchaseEventSlice = createSlice({
  name: "feedPurchaseEvent",
  initialState: initialfeedPurchaseEventState,
  reducers: {
    resetFeedPurchaseEntries: () => initialfeedPurchaseEventState,
  },
  extraReducers: {
    [createFeedPurchaseEvent.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createFeedPurchaseEvent.fulfilled.type]: (
      state,
      action: PayloadAction<GeneralFeedPurchaseEvent>
    ) => {
      if (state.loading === "pending") {
        feedPurchaseEventAdapter.upsertOne(state, action.payload);
        state.loading = "idle";
        state.status = "success";
      }
    },
    [createFeedPurchaseEvent.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },

    [getAllFeedPurchaseEventsForFarm.fulfilled.type]: (state, action) => {
      feedPurchaseEventAdapter.setAll(
        state,
        action.payload as
          | readonly GeneralFeedPurchaseEvent[]
          | Record<EntityId, GeneralFeedPurchaseEvent>
      );
    },

    [getAllFeedPurchaseEventsForFarm.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [getAllFeedPurchaseEventsForFarm.fulfilled.type]: (
      state,
      action: PayloadAction<Array<GeneralFeedPurchaseEvent>>
    ) => {
      feedPurchaseEventAdapter.upsertMany(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [getAllFeedPurchaseEventsForFarm.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
    [updateFeedPurchaseEvent.pending.type]: (state, action) => {
      state.loading = "pending";
      state.status = null;
    },
    [updateFeedPurchaseEvent.fulfilled.type]: (
      state,
      action: PayloadAction<GeneralFeedPurchaseEvent>
    ) => {
      feedPurchaseEventAdapter.upsertOne(state, action.payload);
      state.loading = "idle";
      state.status = "success";
    },
    [updateFeedPurchaseEvent.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.status = "error";
      state.error = action.error;
    },
  },
});
export const { resetFeedPurchaseEntries } = feedPurchaseEventSlice.actions;
export const feedPurchaseEventReducer = feedPurchaseEventSlice.reducer;
