import { Menu, MenuItem, PopoverPosition, Tooltip } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  selectFieldById,
  getFieldWithAnimalsForAnimalGroupId,
  openMoveOutEventDialog,
  updateField,
} from "model";

import { mixpanel } from "common/analytics";

import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import { moveAnimalsIn } from "features/singleCoverInput/UpdateBiomassAfterMoveInDialog";
interface FieldAnimalGroupActionMenuProps {
  fieldId: string;
  anchor?: null | HTMLElement;
  anchorPosition?: PopoverPosition;
  close: () => void;
}

export default function FieldAnimalGroupActionMenu({
  fieldId,
  anchor,
  anchorPosition,
  close,
}: FieldAnimalGroupActionMenuProps) {
  const currentField = useAppSelector((state) =>
    selectFieldById(state, fieldId)
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { notify } = useNotification();

  const currentFieldWithAnimals = useAppSelector(
    getFieldWithAnimalsForAnimalGroupId(currentField?.animalGroup)
  );

  const canMoveIn =
    currentField?.animalGroup && fieldId !== currentFieldWithAnimals?.guid;

  async function onMoveIn() {
    close();
    try {
      if (currentField) {
        if (currentFieldWithAnimals) {
          dispatch(
            openMoveOutEventDialog({
              open: true,
              newFieldWithAnimals: currentField,
              currentFieldWithAnimals: currentFieldWithAnimals,
            })
          );
        } else {
          await moveAnimalsIn(
            currentField,
            currentFieldWithAnimals,
            new Date(),
            dispatch,
            notify,
            t
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function moveToSilage() {
    if (currentField) {
      close();
      try {
        await dispatch(
          updateField({ ...currentField, animalGroup: undefined })
        );
        mixpanel.track("FieldAssign successful");
        notify(
          t("fieldlistmenu.notification.movedToSilage", {
            fieldName: currentField?.name,
          })
        );
      } catch (e) {
        mixpanel.track("FieldAssign failed");
        notify(t("fieldlistmenu.notification.moveFailed"), "error");
      }
    }
  }

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        anchorPosition={anchorPosition}
        anchorReference={
          anchor ? "anchorEl" : anchorPosition ? "anchorPosition" : "none"
        }
        keepMounted
        open={Boolean(anchor || anchorPosition)}
        onClose={close}
      >
        <MoveAnimalsMenuItem
          canMoveIn={!!canMoveIn}
          fieldHasAnimalGroup={!!currentField?.animalGroup}
          onMoveIn={onMoveIn}
        />
        <UnassignFieldFromAnimalGroupItem
          onUnassignFieldFromAnimalGroup={moveToSilage}
        />
      </Menu>
    </div>
  );
}

const TooltipChild = styled.div`
  display: inline-block;
`;

interface UnassignFieldFromAnimalGroupItemProps {
  onUnassignFieldFromAnimalGroup: () => Promise<void>;
}

function UnassignFieldFromAnimalGroupItem({
  onUnassignFieldFromAnimalGroup,
}: UnassignFieldFromAnimalGroupItemProps) {
  const { t } = useTranslation();

  return (
    <MenuItem onClick={onUnassignFieldFromAnimalGroup}>
      {t("fieldlistmenu.silage.label")}
    </MenuItem>
  );
}

interface MoveAnimalsMenuItemProps {
  canMoveIn: boolean;
  onMoveIn: () => Promise<void>;
  fieldHasAnimalGroup: boolean;
}

function MoveAnimalsMenuItem({
  canMoveIn,
  onMoveIn,
  fieldHasAnimalGroup,
}: MoveAnimalsMenuItemProps) {
  const { t } = useTranslation();
  const disabled = !canMoveIn;

  const MoveMenuItem = (
    <MenuItem disabled={disabled} onClick={!disabled ? onMoveIn : undefined}>
      {t("fieldlistmenu.moveIn.label")}
    </MenuItem>
  );

  return canMoveIn ? (
    MoveMenuItem
  ) : (
    <Tooltip
      title={
        fieldHasAnimalGroup
          ? t("fieldlistmenu.moveIn.tooltip.sameField")
          : t("fieldlistmenu.moveIn.tooltip.silage")
      }
    >
      <TooltipChild>{MoveMenuItem}</TooltipChild>
    </Tooltip>
  );
}
