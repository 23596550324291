import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Field, selectAnimalGroupById, useAppSelector } from "model";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { selectAllFields, updateField, useAppDispatch } from "model";
import { useTranslation } from "common/locales";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { mixpanel } from "common/analytics";

function not(a: readonly Field[], b: readonly Field[]) {
  return a.filter((value) => !b.find((bValue) => bValue.guid === value.guid));
}

function intersection(a: readonly Field[], b: readonly Field[]) {
  return a.filter((value) => b.find((bValue) => bValue.guid === value.guid));
}

interface Props {
  open: boolean;
  close: () => void;
  animalGroupId: string;
}

export default function FieldTransferDialog({
  animalGroupId,
  open,
  close,
}: Props) {
  const allFields = useSelector(selectAllFields);
  const animalGroup = useAppSelector((state) =>
    selectAnimalGroupById(state, animalGroupId)
  );
  const initialAnimalGroupFields = allFields.filter(
    (f) => f.animalGroup === animalGroupId
  );

  const silageFields = allFields.filter((f) => !f.animalGroup);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [checked, setChecked] = useState<readonly Field[]>([]);
  const [left, setLeft] = useState<readonly Field[]>(initialAnimalGroupFields);

  const [right, setRight] = useState<readonly Field[]>(silageFields);

  useEffect(() => {
    setLeft(initialAnimalGroupFields);
    setRight(silageFields);
  }, [allFields, open]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: Field) => () => {
    mixpanel.track("feed budgeting field list interacted");
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    mixpanel.track("feed budgeting field list interacted");
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    mixpanel.track("feed budgeting field list interacted");
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    mixpanel.track("feed budgeting field list interacted");
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    mixpanel.track("feed budgeting field list interacted");
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (name: string, items: readonly Field[]) => (
    <>
      <Typography>{name}</Typography>
      <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
        <List dense component="div" role="list">
          {items.map((value: Field) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem
                key={value.guid}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={!!checked.find((c) => c.guid === value.guid)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} onBackdropClick={onClose}>
      <DialogTitle>
        {t("feedBudgetPlanner.feedAllocation.fieldTransfer.title", {
          name: animalGroup?.name,
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            {customList(
              t(
                "feedBudgetPlanner.feedAllocation.fieldTransfer.animalGroupFields",
                {
                  name: animalGroup?.name,
                }
              ),
              left
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllRight}
                disabled={left.length === 0}
              >
                ≫
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
              >
                &lt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllLeft}
                disabled={right.length === 0}
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            {customList(
              t("feedBudgetPlanner.feedAllocation.fieldTransfer.silageFields"),
              right
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t(
            "feedBudgetPlanner.feedAllocation.fieldTransfer.cancelButton.title"
          )}
        </Button>
        <Button onClick={assign} color="primary">
          {t(
            "feedBudgetPlanner.feedAllocation.fieldTransfer.confirmButton.title"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function onClose() {
    close();
  }

  function assign() {
    mixpanel.track("feed budgeting field list save button clicked");
    left.forEach((f) => {
      void dispatch(updateField({ ...f, animalGroup: animalGroupId }));
    });
    right.forEach((f) => {
      void dispatch(updateField({ ...f, animalGroup: undefined }));
    });
    close();
  }
}
