import { Container } from "@mui/material";
import { DrawerForm } from "common/components";
import AssignedToDropdown from "./AssignedToDropdown";
import GrassTypeDropdown from "./GrassTypeDropdown";
import { TmpFieldDetails } from "model";
import { GRASS_TYPE } from "model/fieldSlice";

interface FieldDetailsEditorProps {
  field: TmpFieldDetails;
  currentGrassType?: GRASS_TYPE;
  handleAssignedToChange: () => void;
  handleGrassTypeChange: (grassType?: GRASS_TYPE) => void;
  fieldNameError?: boolean;
  grassTypeInputError: boolean;
}

export default function FieldDetailsEditor(props: FieldDetailsEditorProps) {
  const {
    field,
    handleAssignedToChange,
    handleGrassTypeChange,
    currentGrassType,
    grassTypeInputError,
  } = props;
  return (
    <Container>
      <DrawerForm>
        <AssignedToDropdown
          assignedTo={field.animalGroup}
          onChange={handleAssignedToChange}
        />

        <GrassTypeDropdown
          disabled={!!currentGrassType}
          onChange={handleGrassTypeChange}
          currentGrassType={field.grassType}
          grassTypeInputError={grassTypeInputError}
          label={"fieldinput.grassType.text"}
        ></GrassTypeDropdown>
      </DrawerForm>
    </Container>
  );
}
