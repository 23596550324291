import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { Container, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import {
  GENERAL_FEED_TYPE,
  GENERAL_FEED_UNITS,
  PURCHASE_CURRENCY,
  RootState,
  createFeedPurchaseEvent,
  useAppDispatch,
} from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import EnterFeedPurchaseEvent from "./EnterFeedPurchaseEvent";

export const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function FeedPurchaseEvents() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  const handleClose = () => {
    history.goBack();
  };

  const [feedType, setFeedType] = useState<GENERAL_FEED_TYPE | undefined>(
    undefined
  );

  const [feedTypeError, setFeedTypeError] = useState<boolean>(false);

  const [feedUnit, setFeedUnit] = useState<GENERAL_FEED_UNITS | undefined>(
    undefined
  );

  const [feedUnitError, setFeedUnitError] = useState<boolean>(false);

  const [notes, setNotes] = useState<string | undefined>(undefined);

  const [cost, setCost] = useState<string | undefined>();
  const [costError, setCostError] = useState<boolean>(false);
  const [feedAmount, setFeedAmount] = useState<string | undefined>();
  const [feedAmountError, setFeedAmountError] = useState<boolean>(false);

  const [date, setDate] = useState<Date | null>(new Date());

  const [dateError, setDateError] = useState<boolean>(false);

  async function createfeedPurchaseEvents() {
    if (!feedType || !date || !currentFarmId || !feedAmount) {
      return;
    }

    return await dispatch(
      createFeedPurchaseEvent({
        farmGuid: currentFarmId,
        guid: " ",
        note: notes ?? "",
        feedPurchaseEventDate: date.toISOString(),
        feedPurchases: [
          {
            costCurrency:
              cost === undefined ? undefined : PURCHASE_CURRENCY.GBP,
            cost: cost ? Number(cost) : undefined,
            unitType: feedUnit ?? GENERAL_FEED_UNITS.TONNE,
            feedType: feedType,
            amount: Number(feedAmount),
          },
        ],
      })
    );
  }

  async function handleSave() {
    const consistent = checkConsistency(
      feedType,
      setFeedTypeError,
      feedUnit,
      setFeedUnitError,
      date,
      setDateError,
      dateError,
      feedAmount,
      setFeedAmountError,
      cost,
      setCostError
    );
    // check for wrong input
    if (!consistent) {
      return;
    }
    await createfeedPurchaseEvents();

    notify(t("createfeedPurchaseEvent.success"));
    mixpanel.track("Create feed purchase events successful");
    handleClose();
  }

  return (
    <Dialog fullScreen disableEscapeKeyDown open onClose={handleClose}>
      <StyledContainer>
        <EnterFeedPurchaseEvent
          feedType={feedType}
          cost={cost}
          setCost={setCost}
          setFeedType={setFeedType}
          handleSave={handleSave}
          setNotes={setNotes}
          note={notes}
          date={date}
          setDate={setDate}
          feedTypeError={feedTypeError}
          dateError={dateError}
          setFeedTypeError={setFeedTypeError}
          setFeedUnit={setFeedUnit}
          feedUnitError={feedUnitError}
          setFeedUnitError={setFeedUnitError}
          feedUnit={feedUnit}
          feedAmount={feedAmount}
          setFeedAmount={setFeedAmount}
          feedAmountError={feedAmountError}
          setFeedAmountError={setFeedAmountError}
          costError={costError}
          setCostError={setCostError}
          handleClose={() => {
            mixpanel.track("Create feed purchase events cancelled");
            handleClose();
          }}
        />
      </StyledContainer>
    </Dialog>
  );
}

export const checkConsistency = (
  feedType: string | undefined,
  setFeedTypeError: Dispatch<SetStateAction<boolean>>,
  feedUnit: string | undefined,
  setFeedUnitError: Dispatch<SetStateAction<boolean>>,
  date: Date | null,
  setDateError: Dispatch<SetStateAction<boolean>>,
  dateError: boolean,
  feedAmount: string | undefined,
  setFeedAmountError: Dispatch<SetStateAction<boolean>>,
  cost: string | undefined,
  setCostError: Dispatch<SetStateAction<boolean>>
) => {
  // check for wrong input
  if (!feedType) {
    setFeedTypeError(true);
  }
  if (!date) {
    setDateError(true);
  }

  if (!feedUnit) {
    setFeedUnitError(true);
  }
  if (!feedAmount) {
    setFeedAmountError(true);
  }

  const feedAmountNumber = Number(feedAmount ?? "");
  const feedAmountError: boolean =
    Number.isNaN(feedAmountNumber) || feedAmountNumber <= 0;
  setFeedAmountError(feedAmountError);

  const costNumber = Number(cost ?? "");
  const costError: boolean = Number.isNaN(costNumber) || feedAmountNumber < 0;
  setCostError(costError);

  if (
    !feedType ||
    !date ||
    !feedUnit ||
    dateError ||
    feedAmountError ||
    costError
  ) {
    return false;
  }
  return true;
};
