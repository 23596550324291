import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";
import { useTranslation } from "common/locales";
import { Link } from "react-router-dom";
import AnimalGroupMenu from "../animalGroupDetail/AnimalGroupMenu";

import Silage from "./Silage";
import {
  useAppSelector,
  AnimalGroup,
  getAnimalGroupColor,
  getDemand,
  selectAllAnimalGroups,
  getAllFieldsForAnimalGroupId,
  getAvailableDryMatterForAnimalGroupId,
  selectFieldIds,
  getDemandForFeedAllocations,
} from "model";
import { sumBy } from "lodash";
import styled from "styled-components";
import { feedBudgetPlannerEnabledForUserAndAnimalGroup } from "common/featureToggle";
import ArableFields from "./ArableFields";

export default function AnimalGroups() {
  const animalGroups = useAppSelector(selectAllAnimalGroups);
  const loading = useAppSelector((state) => state.animalGroups.loading);
  const fieldIds = useAppSelector(selectFieldIds);

  if (animalGroups.length === 0 && loading) return <AnimalGroupsLoading />;

  return (
    <List>
      {animalGroups.map((animalGroup) => (
        <AnimalGroupItem key={animalGroup.guid} {...animalGroup} />
      ))}
      {fieldIds.length > 0 && <Silage />}
      <ArableFields />
    </List>
  );
}

function AnimalGroupItem(animalGroup: AnimalGroup) {
  const { name, guid } = animalGroup;
  const demand = useAppSelector((state) => getDemand(state, guid));
  const availableDryMatter = Math.round(
    useAppSelector(getAvailableDryMatterForAnimalGroupId(guid))
  );
  const animalGroupColor = useAppSelector(getAnimalGroupColor(guid)); // TODO: remove when fully migrated to the colors from the database
  const fields = useAppSelector(getAllFieldsForAnimalGroupId(guid));
  const averageCover =
    fields.length > 0
      ? Math.round(sumBy(fields, "dryMatterNow") / fields.length)
      : 0;

  const totalAreaForGroup = fields.reduce((acc, f) => acc + f.area, 0);
  const user = useAppSelector((state) => state.user.details);

  const feedBudgetPlannerEnabled =
    feedBudgetPlannerEnabledForUserAndAnimalGroup(user, animalGroup);

  const feedAllocationDemand = useAppSelector((state) =>
    getDemandForFeedAllocations(state, guid)
  );

  let demandPerHaAndDay = demand
    ? Math.round((demand / totalAreaForGroup) * 10000)
    : 0;

  if (feedBudgetPlannerEnabled) {
    demandPerHaAndDay = feedAllocationDemand
      ? Math.round((feedAllocationDemand / totalAreaForGroup) * 10000)
      : 0;
  }

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ListItemContainer>
      <ColorBar color={animalGroup.color ?? animalGroupColor} />
      <ListItem component={Link} button to={`/animal-group/${guid}`} key={guid}>
        <ListItemText
          primary={name}
          secondary={[
            t("animalGroups.overview.demand", { demand: demandPerHaAndDay }),
            t("animalGroups.overview.availableDryMatter", {
              availableDryMatter,
            }),
            t("animalGroups.overview.averageCover", { averageCover }),
          ].map((text, i) => (
            <span key={i} className={classes.secondaryText}>
              {text}
            </span>
          ))}
        />
        <ListItemSecondaryAction>
          <AnimalGroupMenu {...animalGroup} />
        </ListItemSecondaryAction>
      </ListItem>
    </ListItemContainer>
  );
}

function AnimalGroupsLoading() {
  return (
    <List>
      {[1, 2].map((n) => (
        <ListItem key={n}>
          <ListItemText
            primary={<Skeleton variant="text" width={200} />}
            secondary={<Skeleton variant="text" width={100} />}
          />
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    secondaryText: {
      margin: 0,
      display: "block",
    },
  })
);

const ListItemContainer = styled.div`
  display: flex;
  li {
    flex-grow: 1;
  }
`;

const ColorBar = styled.div`
  background-color: ${({ color }) => color};
  width: 4px;
  height: 80px;
  align-self: center;
  margin-right: 4px;
`;
