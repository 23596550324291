import { List, ListItem, ListItemText } from "@mui/material";
import { useAppSelector, ArableField } from "model";
import { useTranslation } from "common/locales";
import {
  DiaryEntry,
  selectDiaryEntryEventsOnField,
} from "model/diaryEntrySlice";
import DiaryEntryListItem from "../fieldEventList/DiaryEntryListItem";

export default function ArableFieldEventList({
  field,
}: {
  field: ArableField;
}) {
  const { t } = useTranslation();

  const allEvents: Array<DiaryEntry> = useAppSelector((state) =>
    selectDiaryEntryEventsOnField(state, field.guid)
  );

  const getDate = (event: DiaryEntry): Date => {
    return new Date(event.entryDate);
  };

  // This will now sort on every render and we did not find out how to fix it, see https://github.com/robofarmio/web-app-frontend/pull/775#discussion_r1502445622
  // we might go back to 2021 methods like "onMount" etc to be able to compare if we find this is too slow
  const sortedEventList = allEvents.sort((a: DiaryEntry, b: DiaryEntry) => {
    const dateA: Date = getDate(a);
    const dateB: Date = getDate(b);
    return dateB.valueOf() - dateA.valueOf();
  });

  return (
    <List>
      <ListItem>
        <ListItemText primary={t("fieldevents.list.title")} />
      </ListItem>
      {sortedEventList.map((dm: DiaryEntry) => {
        return <DiaryEntryListItem key={dm.guid} diaryEntry={dm} />;
      })}
    </List>
  );
}
