import SvgIcon from "@mui/material/SvgIcon";

export default function AddFeedEventIcon() {
  return (
    <SvgIcon
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="58.000000pt"
      height="58.000000pt"
      viewBox="0 0 58.000000 58.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,58.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M160 555 c-6 -17 -9 -49 -5 -76 8 -57 -2 -65 -50 -42 -47 22 -71 9
-68 -37 1 -33 19 -53 40 -45 6 2 27 7 46 10 33 7 34 6 39 -32 3 -21 16 -62 28
-91 18 -42 26 -51 39 -45 18 8 98 10 120 3 18 -6 48 51 64 123 13 52 11 51 82
32 29 -8 46 8 47 44 1 46 -15 58 -55 42 -59 -24 -62 -22 -63 44 0 34 -6 70
-12 80 -11 20 -11 20 -17 0 -13 -43 -32 -70 -46 -65 -18 6 -100 6 -119 0 -10
-3 -22 10 -37 40 l-21 45 -12 -30z m100 -190 c0 -32 -45 -49 -56 -20 -10 24
14 47 37 34 10 -5 19 -11 19 -14z m110 10 c17 -20 5 -45 -20 -45 -11 0 -23 7
-26 15 -6 15 11 45 26 45 4 0 13 -7 20 -15z"
        />
        <path
          d="M243 190 c-30 -12 -39 -47 -17 -71 24 -27 104 -27 128 0 44 49 -37
101 -111 71z m17 -40 c0 -21 -15 -27 -25 -10 -7 12 2 30 16 30 5 0 9 -9 9 -20z
m83 6 c6 -15 -1 -26 -15 -26 -4 0 -8 9 -8 20 0 23 15 27 23 6z"
        />
        <path
          d="M403 168 c-38 -18 -28 -42 29 -66 61 -26 75 -23 21 4 l-38 19 45 6
45 6 -52 1 c-57 2 -67 12 -30 30 12 6 18 11 12 11 -5 0 -20 -5 -32 -11z"
        />
        <path
          d="M450 160 l-25 -8 25 0 c14 0 36 4 50 8 l25 8 -25 0 c-14 0 -36 -4
-50 -8z"
        />
        <path
          d="M100 130 c-21 -7 -18 -8 20 -9 32 -1 41 -4 30 -11 -8 -5 -26 -7 -40
-4 -21 4 -22 3 -5 -4 20 -9 20 -9 -2 -16 -11 -4 -27 -13 -33 -21 -6 -8 20 0
59 18 39 18 71 38 71 45 0 14 -59 15 -100 2z"
        />
        <path d="M210 90 c0 -5 12 -10 28 -9 24 0 25 1 7 9 -27 12 -35 12 -35 0z" />
        <path
          d="M165 79 c-4 -6 -13 -8 -20 -5 -16 6 -65 -30 -65 -46 0 -7 11 -1 25
12 25 24 35 16 13 -10 -7 -8 4 1 25 19 20 19 37 36 37 38 0 7 -9 2 -15 -8z"
        />
      </g>
    </SvgIcon>
  );
}
