import {
  Backdrop,
  Container,
  Dialog,
  DialogTitle,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LogoIcon } from "common/icons";
import { useTranslation } from "common/locales";

import styled from "styled-components";

import LoginBackground from "./LoginDialogBackground.svg";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "450px",
    },
  },
}));

export const FullScreenDialog = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledDialog
      open
      fullScreen={isMobile}
      disableEscapeKeyDown
      BackdropComponent={LoginBackdrop}
      classes={{ paper: classes.paper }}
    >
      {children}
    </StyledDialog>
  );
};

const LoginBackdrop = () => {
  const { t } = useTranslation();

  return (
    <StyledBackdrop open={true}>
      <ProductName>
        <Typography variant="h4">
          <LogoIcon /> ruumi
        </Typography>
      </ProductName>
    </StyledBackdrop>
  );
};

const ProductName = styled(Container)`
  text-align: center;
  color: black;
  top: 10%;
  position: absolute;
`;

const StyledDialog = styled(Dialog)(
  ({ theme }) => `
    min-width: ${theme.breakpoints.values.xs}px;
`
);

const StyledBackdrop = styled(Backdrop)`
  background-image: url(${LoginBackground});
  background-size: cover;
`;
