import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { useTranslation } from "common/locales";
import {
  AnimalGroup,
  RootState,
  getAllFieldsForAnimalGroupId,
  useAppSelector,
} from "model";
import { StockClassWithBirthingPeriods } from "model/stockClassBirthingPeriodSlices";
import { useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Dot,
  Label,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import styled from "styled-components";
import { useEffect, useMemo, useRef, useState } from "react";
import { StockClassFeedAllocationExtended } from "./utils";
import { interpolateTimeSeries } from "./interpolation";
import { selectAllEventsForFields } from "../../model/manualInputSlice";
import { getGrowthRatesKgM2DayFromReadings } from "./growthrate";
import {
  addMonths,
  eachMonthOfInterval,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subMonths,
} from "date-fns";
import { uniqBy } from "lodash";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
const EXTRA_X_AXIS_DATE_RANGE_MS = 4 * 24 * 3600 * 1000; // 4 days
const CHART_HEIGHT = 300; // total chart height px, always fixed

interface Props {
  stockClasses: StockClassWithBirthingPeriods[];
  temporaryFeedAllocations: Map<string, StockClassFeedAllocationExtended[]>;
  animalGroup: AnimalGroup;
}

interface DemandDaily {
  demandKgDayHa: number;
  dateStartMs: number;
  dateStart: Date;
}

export default function AnimalGroupDemandSupplyChart({
  stockClasses,
  animalGroup,
  temporaryFeedAllocations,
}: Props) {
  const locale = useSelector((state: RootState) => state.app.locale);

  const fields = useAppSelector(getAllFieldsForAnimalGroupId(animalGroup.guid));
  const readingsByFieldGuid = useAppSelector(
    selectAllEventsForFields(fields.map((f) => f.guid))
  );

  const { t } = useTranslation();

  const dateFormatter = (date: number) => {
    return new Date(date).toLocaleDateString(locale);
  };

  const {
    feedAllocationsDates,
    demandData,
    growthRateKgHaDayAvgPerMonth,
    growthRateKgHaDayAvgPerMonthPast,
    growthRateKgHaDayAvgPerMonthProjected,
  } = useMemo(() => {
    const growthRatesKgM2DayFromReadingsByFieldGuid =
      getGrowthRatesKgM2DayFromReadings(readingsByFieldGuid);

    const growthRatesKgM2DayFromReadings = Array.from(
      growthRatesKgM2DayFromReadingsByFieldGuid.values()
    );

    // average growthrate for all fields
    const growthRateKgHaDayAvg = growthRatesKgM2DayFromReadings
      .reduce((acc, growthRates) => {
        growthRates.forEach((gr, i) => (acc[i] += gr));
        return acc;
      }, new Array(12).fill(0))
      .map((v) => (v * 10000) / growthRatesKgM2DayFromReadingsByFieldGuid.size);

    const totalAreaForGroup = fields.reduce((acc, f) => acc + f.area, 0);

    const demandData: DemandDaily[] = [];
    const feedAllocationsForStockClass = Array.from(
      temporaryFeedAllocations.values()
    );

    const feedAllocationsDates = uniqBy(
      feedAllocationsForStockClass.flatMap((feedAllocations) =>
        feedAllocations.map((fa) => startOfDay(new Date(fa.dateStartMs)))
      ),
      (a) => a.valueOf()
    );

    feedAllocationsDates.sort((a, b) => a.valueOf() - b.valueOf());

    const feedAllocationsStartOfMonths = uniqBy(
      feedAllocationsDates
        .map((d) => startOfMonth(d))
        .concat(
          [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0].map((i) =>
            startOfMonth(addMonths(new Date(), i))
          )
        )
        .concat(
          eachMonthOfInterval({
            start: new Date(),
            end: feedAllocationsDates.at(-1) || new Date(),
          })
        )
        .concat([endOfMonth(feedAllocationsDates.at(-1)!)]),
      (a) => a.valueOf()
    );

    feedAllocationsStartOfMonths.sort((a, b) => a.valueOf() - b.valueOf());

    const growthRateKgHaDayAvgPerMonth = feedAllocationsStartOfMonths.map(
      (f) => ({
        dateStart: f,
        dateStartMs: f.valueOf(),
        growthRateKgHaDayAvg: growthRateKgHaDayAvg[f.getMonth()],
      })
    );

    const now = new Date();
    const growthRateKgHaDayAvgPerMonthProjected =
      growthRateKgHaDayAvgPerMonth.filter((g) => g.dateStart > now);

    const growthRateKgHaDayAvgPerMonthPast = growthRateKgHaDayAvgPerMonth
      .filter((g) => g.dateStart <= now)
      .concat([growthRateKgHaDayAvgPerMonthProjected.at(0)!]);

    if (totalAreaForGroup > 0) {
      // We compute all dates when feed allocations are set, then we interpolate feed allocations at these dates for each stock class and sum

      const sumDemandKgDay: Array<number> = new Array(
        feedAllocationsDates.length
      ).fill(0);

      for (const feedAllocations of feedAllocationsForStockClass) {
        const interpolatedValues = interpolateTimeSeries(
          feedAllocationsDates,
          uniqBy(
            feedAllocations.map((fa) => ({
              date: startOfDay(new Date(fa.dateStartMs)),
              value: fa.allocatedBodyWeightFractionKgTotal,
            })),
            (a) => a.date.valueOf()
          )
        );

        for (let i = 0; i < feedAllocationsDates.length; i++) {
          if (interpolatedValues[i]) {
            sumDemandKgDay[i] += interpolatedValues[i].value;
          }
        }
      }

      for (let i = 0; i < feedAllocationsDates.length; i++) {
        demandData.push({
          demandKgDayHa: Math.round(
            (sumDemandKgDay[i] / totalAreaForGroup) * 10000
          ),
          dateStartMs: feedAllocationsDates[i].valueOf(),
          dateStart: feedAllocationsDates[i],
        });
      }
    }

    const interpolatedGrowthRateValues = interpolateTimeSeries(
      feedAllocationsDates,
      growthRateKgHaDayAvgPerMonth.map((gr) => ({
        date: gr.dateStart,
        value: gr.growthRateKgHaDayAvg,
      }))
    );

    const growthRateKgHaDayAvgPerMonthExtended =
      growthRateKgHaDayAvgPerMonth.concat(
        interpolatedGrowthRateValues.map((f) => ({
          dateStart: f.date,
          dateStartMs: f.date.valueOf(),
          growthRateKgHaDayAvg: f.value,
        }))
      );

    growthRateKgHaDayAvgPerMonthExtended.sort(
      (a, b) => a.dateStartMs - b.dateStartMs
    );

    return {
      feedAllocationsDates,
      demandData,
      growthRateKgHaDayAvgPerMonth,
      growthRateKgHaDayAvgPerMonthPast,
      growthRateKgHaDayAvgPerMonthProjected,
    };
  }, [readingsByFieldGuid, fields, temporaryFeedAllocations]);

  const initialState: {
    left: string | number;
    right: string | number;
    refAreaLeft: "";
    refAreaRight: "";
    top: "dataMax+1";
    bottom: "dataMin-1";
    animation: true;
  } = {
    left: "dataMin",
    right: "dataMax",
    refAreaLeft: "",
    refAreaRight: "",
    top: "dataMax+1",
    bottom: "dataMin-1",
    animation: true,
  };

  const [zoomState, setZoomState] = useState<typeof initialState>(initialState);

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = zoomState;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setZoomState({
        ...zoomState,
        refAreaLeft: "",
        refAreaRight: "",
      });

      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    setZoomState({
      ...zoomState,
      left: refAreaLeft,
      right: refAreaRight,
      refAreaLeft: "",
      refAreaRight: "",
    });
  };

  const zoomOut = () => {
    setZoomState({ ...initialState });
  };

  useEffect(() => {
    setZoomState({
      ...zoomState,
      left: feedAllocationsDates.at(0)
        ? feedAllocationsDates.at(0)!.valueOf() - EXTRA_X_AXIS_DATE_RANGE_MS
        : "dataMin",
      right: feedAllocationsDates.at(-1)
        ? feedAllocationsDates.at(-1)!.valueOf() + EXTRA_X_AXIS_DATE_RANGE_MS
        : "dataMax",
    });
  }, [temporaryFeedAllocations]);

  const containerRef = useRef<HTMLDivElement>(null);
  const { left, right, refAreaLeft, refAreaRight, top, bottom } = zoomState;

  return (
    <div>
      <div style={{ width: "100%", height: "100%", userSelect: "none" }}>
        <IconButton
          edge="end"
          aria-haspopup="true"
          onClick={zoomOut}
          size="large"
          // TODO: zoom disabled for now
          style={{ marginLeft: "50px", display: "none" }}
        >
          <ZoomOutIcon />
        </IconButton>
        <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
          <StyledContainer width="100%" height="100%">
            <AreaChart
              width={730}
              height={CHART_HEIGHT}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              // TODO: zoom disabled for
              // onMouseDown={(e: any) => {
              //   setZoomState({ ...zoomState, refAreaLeft: e.activeLabel });
              // }}
              // onMouseMove={(e: any) => {
              //   zoomState.refAreaLeft &&
              //     setZoomState({ ...zoomState, refAreaRight: e.activeLabel });
              // }}
              // onMouseUp={zoom}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8a5ae2" stopOpacity={0.9} />
                  <stop offset="95%" stopColor="#8a5ae2" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1C4322" stopOpacity={0.9} />
                  <stop offset="95%" stopColor="#1C4322" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="colorXv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#2e7831" stopOpacity={0.9} />
                  <stop offset="95%" stopColor="#2e7831" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis
                dataKey="dateStartMs"
                scale="time"
                tickFormatter={dateFormatter}
                type="number"
                allowDataOverflow
                domain={[left, right]}
              />

              <YAxis domain={[bottom, top]} allowDataOverflow />
              <Tooltip content={(props: any) => <CustomTooltip {...props} />} />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              <ReferenceLine
                x={startOfDay(new Date()).valueOf()}
                stroke="#F7E77F"
                strokeWidth={2}
                label={
                  <Label
                    position="insideTopLeft"
                    value={t("feedBudgetPlanner.referenceLabel.now.label")}
                  />
                }
              />
              <Area
                animationDuration={10}
                type="monotone"
                dataKey={"demandKgDayHa"}
                stroke="#8a5ae2"
                fill="url(#colorUv)"
                strokeWidth={1}
                dot={(props) => <InactiveDot {...props} />}
                name={t("feedBudgetPlanner.feedAllocation.totalDemand.title")}
                {...{ data: demandData }}
                fillOpacity={0.6}
                strokeOpacity={1}
              />
              <Area
                animationDuration={10}
                type="monotone"
                dataKey={"growthRateKgHaDayAvg"}
                stroke="#1C4322"
                fill="url(#colorPv)"
                strokeWidth={1}
                dot={(props) => <InactiveDot {...props} />}
                name={t("feedBudgetPlanner.feedAllocation.totalSupply.title")}
                {...{ data: growthRateKgHaDayAvgPerMonthPast }}
                fillOpacity={0.4}
                strokeOpacity={1}
              />
              <Area
                animationDuration={10}
                type="monotone"
                dataKey={"growthRateKgHaDayAvg"}
                stroke="#2e7831"
                fill="url(#colorXv)"
                strokeWidth={1}
                strokeDasharray="5 5"
                dot={(props) => <InactiveDot {...props} />}
                name={t(
                  "feedBudgetPlanner.feedAllocation.totalProjectedSupply.title"
                )}
                {...{ data: growthRateKgHaDayAvgPerMonthProjected }}
                fillOpacity={0.4}
                strokeOpacity={1}
              />
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  yAxisId="0"
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
            </AreaChart>
          </StyledContainer>
        </div>
      </div>
    </div>
  );
}

export const StyledContainer = styled(ResponsiveContainer)`
  min-height: ${CHART_HEIGHT}px;
`;

interface CustomizedDotProps {
  cx: number;
  cy: number;
  r: number;
  dot: any;
  stroke: string;
  fill: string;
  strokeWidth: string;
  payload: DemandDaily;
  value: number;
}

const InactiveDot = (props: CustomizedDotProps) => {
  const { cx, cy, stroke, payload, value, fill, strokeWidth, r } = props;

  return (
    <Dot
      cx={cx}
      cy={cy}
      r={r}
      stroke={stroke}
      fill={stroke}
      strokeWidth={1}
    ></Dot>
  );
};

type CustomTooltipProps = {
  payload: Array<{
    payload: {
      demandKgDayHa?: number;
      growthRateKgHaDayAvg?: number;
      dateStartMs: number;
      dateStart: Date;
    };
  }>;
  active: boolean;
  label: string;
};

function CustomTooltip({ active, label, payload }: CustomTooltipProps) {
  const { t } = useTranslation();
  const locale = useSelector((state: RootState) => state.app.locale);
  const dateFormatter = (date: number) => {
    return new Date(date).toLocaleDateString(locale);
  };
  if (!payload?.[0]?.payload) return null;
  const feedAllocation = payload[0].payload;

  if (active) {
    return (
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h6">
            {dateFormatter(feedAllocation.dateStartMs)}
          </Typography>
          {!isNaN(payload[0].payload.demandKgDayHa as any) && (
            <Typography>
              {t("feedBudgetPlanner.feedAllocation.totalDemand.tooltip", {
                demand: payload[0].payload.demandKgDayHa!.toFixed(2),
              })}
            </Typography>
          )}
          {!isNaN(payload[0].payload.growthRateKgHaDayAvg as any) && (
            <Typography>
              {t("feedBudgetPlanner.feedAllocation.totalSupply.tooltip", {
                supply: payload[0].payload.growthRateKgHaDayAvg!.toFixed(2),
              })}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
  return null;
}
