import { useTranslation } from "common/locales";
import {
  DialogActions,
  Button,
  TextField,
  Typography,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  FormErrors,
  FormValues,
  StyledForm,
} from "../features/signup/NewSignupDialog";
import styled from "styled-components";
interface Props {
  values: FormValues;
  errors: FormErrors;
  handleChange: (e: React.ChangeEvent<Element>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  passwordUpdateError: boolean;
  passwordResetInProgress: boolean;
}

export default function PasswordResetUserDetails({
  values,
  handleChange,
  handleSubmit,
  errors,
  passwordUpdateError,
  passwordResetInProgress,
}: Props) {
  const { t } = useTranslation();
  const confirmPasswordError =
    !!values.password && !!values.confirmPassword && !!errors.confirmPassword; // only if password and confirm password fields have some text

  return (
    <>
      <StyledDialogTitle id="dialog-title">
        {t("passwordReset.userDetails.dialogTitle")}
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledTypography paragraph>
          {t("passwordReset.userDetails.text1")}
        </StyledTypography>

        <StyledForm id="form-part-1" onSubmit={handleSubmit}>
          <TextField
            variant="standard"
            required
            disabled
            id="mail"
            type="email"
            value={values.mail}
            InputProps={{
              readOnly: true,
            }}
            onChange={handleChange}
            label={t("signup.mail.label")}
          />
          <TextField
            variant="standard"
            required
            id="password"
            value={values.password}
            onChange={handleChange}
            label={t("signup.password.label")}
            type="password"
            autoFocus={true}
          />
          <TextField
            variant="standard"
            required
            id="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            label={t("signup.confirmPassword.label")}
            type="password"
            error={confirmPasswordError}
            helperText={confirmPasswordError && errors.confirmPassword}
          />
          <StyledDialogActions>
            <Button
              color="primary"
              type="submit"
              form="form-part-1"
              variant="contained"
              disabled={
                confirmPasswordError ||
                !values.password ||
                !values.confirmPassword ||
                passwordUpdateError ||
                passwordResetInProgress
              }
            >
              {t("passwordReset.button.label")}
            </Button>
          </StyledDialogActions>
        </StyledForm>
      </StyledDialogContent>
    </>
  );
}

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
  padding-left: ${theme.spacing(4)};
  padding-top: ${theme.spacing(2.5)};
`
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => `
  padding: 12px 32px!important;
`
);

const StyledDialogActions = styled(DialogActions)(
  ({ theme }) => `
  padding-top: ${theme.spacing(2)};
`
);

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`
);
