import { useState, MouseEvent } from "react";
import styled from "styled-components";
import { Fab, MenuItem, Menu } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, setIsEditing, selectFieldIds } from "model";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { mixpanel } from "../../common/analytics";
import { useTranslation } from "common/locales";

export default function AddFab() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fieldIds = useSelector(selectFieldIds);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const { t } = useTranslation();

  return (
    <>
      <Button aria-label="add" size="small" onClick={handleClick}>
        <AddIcon />
      </Button>
      <Menu
        id="add-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fieldIds.length > 0 && (
          <MenuItem onClick={handleAddCoverClick}>
            {f({ id: "farm.addMenu.cover" })}
          </MenuItem>
        )}
        <MenuItem onClick={handleAddFieldClick}>
          {f({ id: "farm.addMenu.field" })}
        </MenuItem>
        <MenuItem onClick={handleAddAnimalGroupClick}>
          {f({ id: "farm.addMenu.animalGroup" })}
        </MenuItem>
        <MenuItem onClick={handleAddFarmClick}>
          {f({ id: "farm.addMenu.farm" })}
        </MenuItem>
      </Menu>
    </>
  );

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAddFieldClick() {
    setAnchorEl(null);
    dispatch(setIsEditing());
    mixpanel.track("FieldAdd started");
    history.push(`/add-field/enter-details`);
  }

  function handleAddFarmClick() {
    setAnchorEl(null);
    mixpanel.track("FarmAdd started");
    history.push(`/add-farm/enter-name`);
  }

  function handleAddAnimalGroupClick() {
    setAnchorEl(null);
    mixpanel.track("AnimalGroupAdd started");
    history.push(`/add-animal-group`);
  }

  function handleAddCoverClick() {
    setAnchorEl(null);
    mixpanel.track("CoverAdd started");
    history.push(`/add-covers/enter-time`);
  }
}

const Button = styled(Fab)`
  color: #7aab6d;
  background: white;
`;
