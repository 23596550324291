import { useHistory, useRouteMatch } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useTranslation } from "common/locales";

interface RegenerateFeedAllocationDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function RegenerateFeedAllocationDialog(
  props: RegenerateFeedAllocationDialogProps
) {
  const { onCancel, onConfirm, open } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        {t("animalGroupInput.regenerateFeedAllocationDialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("animalGroupInput.regenerateFeedAllocationDialog.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          {t("animalGroupInput.regenerateFeedAllocationDialog.cancel.label")}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {t("animalGroupInput.regenerateFeedAllocationDialog.confirm.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
