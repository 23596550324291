import {
  DiaryEntry,
  FarmerImageUpload,
  selectFarmerImageUploadById,
  useAppSelector,
} from "model";
import { DiaryEntryImage } from "./DiaryEntryImage";

interface Props {
  diaryEntry: DiaryEntry;
  setScrollDialogOpen: (newValue: boolean) => void;
  setOpenDiaryEntryDialogGuid: (newValue: string | null) => void;
}

export default function DiaryEntryDialogContentForScroll({
  diaryEntry,
  setScrollDialogOpen,
  setOpenDiaryEntryDialogGuid,
}: Props) {
  const farmerImageUpload: FarmerImageUpload | undefined = useAppSelector(
    (state) =>
      selectFarmerImageUploadById(state, diaryEntry.farmerImageUploadId)
  );

  return (
    <div key={diaryEntry.guid}>
      <DiaryEntryImage
        farmerImageUpload={farmerImageUpload}
        style={{ maxWidth: "150px", height: "auto", cursor: "pointer" }}
        onImageClick={() => {
          setScrollDialogOpen(false);
          setOpenDiaryEntryDialogGuid(diaryEntry.guid);
        }}
      />
    </div>
  );
}
