export enum EditorCanDelete {
  TENTATIVE_POSITION,
  HANDLES,
  FEATURE,
  NONE,
}

export enum EditorSelectionType {
  TENTATIVE_POSITION,
  HANDLES,
  FEATURE,
  NONE,
}
