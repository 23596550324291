import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  unwrapResult,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import {
  changeGrassType,
  fetchFieldsById,
  GRASS_TYPE,
  selectAllFields,
} from "./fieldSlice";
import { RootState } from "./rootReducer";
import { MACHINERY } from "./commonTypes";

export const FIELD_RESEEDED_EVENT_REJECTION_PERIOD_DAYS = 30;

export enum SEEDING_METHOD {
  PLOUGH_AND_POWER_HARROW = "PLOUGH_AND_POWER_HARROW",
  DIRECT_DRILL = "DIRECT_DRILL",
  SHALLOW_CULTIVATION = "SHALLOW_CULTIVATION",
  OVERSEED = "OVERSEED",
}

export enum RESEED_TYPE {
  RESEED = "RESEED",
  OVERSEED = "OVERSEED",
}

export enum RESEED_TOTAL_AMOUNT_UNIT {
  G = "G",
  KG = "KG",
  TONNE = "TONNE",
}

export type ReseedEventBase = {
  note: string;
  reseedDate: string;
  newGrassType: GRASS_TYPE;
  seedingMethod: SEEDING_METHOD;
  machinery?: MACHINERY[];
  fuel?: number;
  reseedType: RESEED_TYPE;
  seedingRate?: number;
  productName?: string;
  totalAmount?: number;
  totalAmountUnit?: RESEED_TOTAL_AMOUNT_UNIT;
  fieldGuids: string[];
};

export type ReseedEvent = ReseedEventBase & {
  guid: string;
  creationDate?: string;
};

class ReseedAPI extends API<ReseedEvent> {
  async getAllReseedEventsForFarm(
    guid: string,
    reseedTimeAfter?: string
  ): Promise<ReseedEvent[]> {
    const reseedTimeAfterParam = reseedTimeAfter
      ? `&reseedTimeAfter=${reseedTimeAfter}`
      : "";
    const res = await fetch(
      `${this.baseUrl}reseeds?farmGuid=${guid}${reseedTimeAfterParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as ReseedEvent[];
  }

  async createReseedsEventForFarm(
    reseedsEvent: ReseedEventBase
  ): Promise<void> {
    await fetch(`${this.baseUrl}reseeds`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reseedsEvent),
    });
    return;
  }
}

const reseedApi = new ReseedAPI("reseeds", "v2");

export const createReseedsEvent = createAsyncThunk(
  "reseeds/create",
  async (reseedEvent: ReseedEvent, { dispatch }) => {
    await reseedApi.createOne(reseedEvent);
    await dispatch(fetchFieldsById(reseedEvent.fieldGuids));
  }
);

export const updateReseedEvent = createAsyncThunk(
  "reseeds/create",
  async (reseedEvent: ReseedEvent, { dispatch }) => {
    await reseedApi.updateOne(reseedEvent);
    await dispatch(fetchFieldsById(reseedEvent.fieldGuids));
  }
);

export const createReseedsEventForFarm = async (
  reseedsEvent: ReseedEventBase
) => {
  await reseedApi.createReseedsEventForFarm(reseedsEvent);
};

export const reseedEventAdapter = createEntityAdapter<ReseedEvent>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (reseedEvent) => reseedEvent.guid,
  // Keeps the array sorted by guid
  sortComparer: (a, b) => a.guid.localeCompare(b.guid),
});

export const {
  selectAll: selectAllReseedEvents,
  selectById: selectReseedEventById,
} = reseedEventAdapter.getSelectors((state: RootState) => state.reseedEvents);

const selectItemId = (state: RootState, itemId: string) => itemId;
export const selectAllReseedEventsForField = createSelector(
  [selectAllReseedEvents, selectItemId],
  (readings, fieldId) =>
    readings.filter((curr) => curr.fieldGuids.includes(fieldId))
);

export const selectFieldsWithReseedEvents = createSelector(
  [selectAllReseedEvents, selectAllFields],
  (reseedEvents, fields) => {
    const fieldGuids = new Set(reseedEvents.map((r) => r.fieldGuids).flat());
    const result = fields.filter((f) => fieldGuids.has(f.guid));
    return result;
  }
);

const initialreseedEventState: EntityState<ReseedEvent> =
  reseedEventAdapter.getInitialState({});

export const getAllReseedEventsForFarm = createAsyncThunk<
  Promise<Array<ReseedEvent>>,
  { farmId: string; reseedTimeAfter?: string }
>("readings/getReseedEventsBulk", async ({ farmId, reseedTimeAfter }) => {
  return reseedApi.getAllReseedEventsForFarm(farmId, reseedTimeAfter);
});

const reseedEventSlice = createSlice({
  name: "reseedEvent",
  initialState: initialreseedEventState,
  reducers: {},
  extraReducers: {
    [getAllReseedEventsForFarm.fulfilled.type]: (state, action) => {
      reseedEventAdapter.setAll(
        state,
        action.payload as readonly ReseedEvent[] | Record<EntityId, ReseedEvent>
      );
    },
  },
});

export const reseedEventReducer = reseedEventSlice.reducer;
