import { AnimalGroup, User } from "model";

export function feedBudgetPlannerEnabledForUser(user?: User) {
  const enabledUserGuids = [
    "1cIv9RttLPdqkTKof9qob4",
    "20Osl0S7LwyqJ1yVbfAfIX",
    "2DbFKjIhrYLGUstspqW9GU",
  ];
  const param = new URL(window.location.href).searchParams.get(
    "feedbudgetplanner"
  ); // temporary to allow demo account to work in the old way until release
  return (
    !!user &&
    (enabledUserGuids.includes(user.guid) || user.userType === "TEST_USER") &&
    !!param
  );
}

export function feedBudgetPlannerEnabledForAnimalGroup(
  animalGroup?: AnimalGroup
) {
  return !animalGroup || !!animalGroup.feedBudgetPlannerEnabled; // enable only for new animal groups or when animal.feedBudgetPlannerEnabled is true for existing ones
}

export function feedBudgetPlannerEnabledForUserAndAnimalGroup(
  user?: User,
  animalGroup?: AnimalGroup
) {
  return (
    feedBudgetPlannerEnabledForUser(user) &&
    feedBudgetPlannerEnabledForAnimalGroup(animalGroup)
  );
}
