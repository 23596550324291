import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  unwrapResult,
} from "@reduxjs/toolkit";
import { fetchApi } from "common/utils/Api";

export enum DEFAULT_STOCK_CLASS_NAME {
  DAIRY_COW = "DAIRY_COW",
  DAIRY_COW_2_KGMS = "DAIRY_COW_2_KGMS",
  SUCKLER_COW = "SUCKLER_COW",
  GROWING_CATTLE = "GROWING_CATTLE",
  EWE = "EWE",
  EWE_TWINS = "EWE_TWINS",
  WEANED_LAMB = "WEANED_LAMB",
}

export enum DEFAULT_FEEDING {
  DRY_MAINTENANCE = "DRY_MAINTENANCE",
  THIRTY_DAYS_PRE_CALVING = "THIRTY_DAYS_PRE_CALVING",
  LTE_2_MONTHS_POST_CALVING = "LTE_2_MONTHS_POST_CALVING",
  GT_2_MONTHS_POST_CALVING = "GT_2_MONTHS_POST_CALVING",
  DRY_MAINTENANCE_BCS_GAIN = "DRY_MAINTENANCE_BCS_GAIN",
  GTE_3_LTE_4_MONTHS_POST_CALVING = "GTE_3_LTE_4_MONTHS_POST_CALVING",
  GT_4_MONTHS_POST_CALVING = "GT_4_MONTHS_POST_CALVING",
  MAINTENANCE = "MAINTENANCE",
  MAINTENANCE_500G_DLWG = "MAINTENANCE_500G_DLWG",
  MAINTENANCE_1KG_DLWG = "MAINTENANCE_1KG_DLWG",
  MAINTENANCE_GT_1KG_DLWG = "MAINTENANCE_GT_1KG_DLWG",
  THIRTY_DAYS_PRE_LAMBING = "THIRTY_DAYS_PRE_LAMBING",
  LTE_1_MONTHS_POST_LAMBING = "LTE_1_MONTHS_POST_LAMBING",
  GT_1_MONTH_POST_LAMBING_TO_WEANING = "GT_1_MONTH_POST_LAMBING_TO_WEANING",
  MAINTENANCE_50G_DLWG = "MAINTENANCE_50G_DLWG",
  MAINTENANCE_150G_DLWG = "MAINTENANCE_150G_DLWG",
  MAINTENANCE_GT_150G_DLWG = "MAINTENANCE_GT_150G_DLWG",
}

export const DEFAULT_FEEDING_FOR_STOCK_CLASS = {
  [DEFAULT_STOCK_CLASS_NAME.DAIRY_COW]: DEFAULT_FEEDING.DRY_MAINTENANCE,
  [DEFAULT_STOCK_CLASS_NAME.SUCKLER_COW]: DEFAULT_FEEDING.DRY_MAINTENANCE,
  [DEFAULT_STOCK_CLASS_NAME.GROWING_CATTLE]: DEFAULT_FEEDING.MAINTENANCE,
  [DEFAULT_STOCK_CLASS_NAME.EWE]: DEFAULT_FEEDING.DRY_MAINTENANCE,
  [DEFAULT_STOCK_CLASS_NAME.WEANED_LAMB]: DEFAULT_FEEDING.MAINTENANCE,
};

export interface DefaultStockClass {
  name: DEFAULT_STOCK_CLASS_NAME;
  averageWeightKg: number;
  targetEntryCoverMinKgM2: number;
  targetEntryCoverOptimumKgM2: number;
  targetEntryCoverMaxKgM2: number;
  birthingAbility: boolean;
  defaultStockClassDemandTemplates?: Array<DefaultStockClassDemandTemplate>;
}

export interface DefaultStockClassDemandTemplate {
  position: number;
  name: DEFAULT_FEEDING;
  allocatedBodyWeightFraction: number;
  feedingAllocationStartDaysRelativeToBirthing: null;
  stockClassStageName: DEFAULT_STOCK_CLASS_NAME;
  defaultStockClass?: DefaultStockClass;
}

export type DefaultStockClasses = Array<Required<DefaultStockClass>>; // defaultStockClassDemandTemplates are required in this object

export type DefaultStockClassDemandTemplates = Array<
  Required<DefaultStockClassDemandTemplate>
>; // defaultStockClass is required in this object

type DefaultStockClassesSliceState = {
  stockClasses: DefaultStockClasses;
  demandTemplates: DefaultStockClassDemandTemplates;
};

export const getDefaultStockClasses = createAsyncThunk<DefaultStockClasses>(
  "defaultStockClasses/getDefaultStockClasses",
  () =>
    fetchApi(`defaultStockClasses`, {
      method: "GET",
    })
);

export const getDefaultStockClassDemandTemplates =
  createAsyncThunk<DefaultStockClassDemandTemplates>(
    "defaultStockClasses/getDefaultStockDemandTemplates",
    () =>
      fetchApi(`defaultStockClasses/demandTemplates`, {
        method: "GET",
      })
  );

const initialState: DefaultStockClassesSliceState = {
  stockClasses: [],
  demandTemplates: [],
};

const defaultStockClassesSlice = createSlice({
  name: "defaultStockClasses",
  initialState,
  reducers: {},
  extraReducers: {
    [getDefaultStockClasses.fulfilled.type]: (state, action) => {
      state.stockClasses = action.payload;
    },
    [getDefaultStockClassDemandTemplates.fulfilled.type]: (state, action) => {
      state.demandTemplates = action.payload;
    },
  },
});

export const defaultStockClassesReducer = defaultStockClassesSlice.reducer;
