import { List, ListItem, ListItemText } from "@mui/material";
import {
  useAppSelector,
  FieldPreparationEvent,
  selectAllFieldPreparationEvents,
} from "model";
import { useTranslation } from "common/locales";
import FieldPreparationEventListItem from "./FieldPreparationEventListItem";
export default function FieldPreparationEventList() {
  const { t } = useTranslation();

  const fieldPreparationEvents: Array<FieldPreparationEvent> = useAppSelector(
    (state) => selectAllFieldPreparationEvents(state)
  );

  const sortedFieldPreparationEventsForFarm = fieldPreparationEvents.sort(
    (a: FieldPreparationEvent, b: FieldPreparationEvent) =>
      new Date(b.fieldPreparationEventDate).valueOf() -
      new Date(a.fieldPreparationEventDate).valueOf()
  );

  return (
    <List>
      <ListItem key="title">
        <ListItemText primary={t("feedPurchase.list.title")} />
      </ListItem>
      {sortedFieldPreparationEventsForFarm.map(
        (event: FieldPreparationEvent) => {
          return (
            <FieldPreparationEventListItem
              fieldPreparationEvent={event}
              key={`re-listitem-${event.guid}`}
            />
          );
        }
      )}
    </List>
  );
}
