import { RouteProps, Route, Redirect, useHistory } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  initializeApp,
  fetchFarmById,
  initializeFarm,
  fetchFieldsById,
  fetchAnimalGroupsById,
  Farm,
} from "model";
import { unwrapResult } from "@reduxjs/toolkit";
import { restoreToken, tokenValid } from "common/utils/token";
import { ReactNode } from "react";

const PrivateRoute: React.FC<RouteProps> = ({
  children,
  location,
  ...rest
}) => {
  const loggedIn = useAppSelector((state) => state.app.loggedIn);
  const dispatch = useAppDispatch();
  const history = useHistory();

  restoreToken(location); // trying to restore the token

  const redirectToInitialViewSelect = () => {
    history.push(`/initial-screen-select`);
  };

  const handleRefresh = async () => {
    let farms: Farm[] | undefined = [];

    try {
      farms = await dispatch(initializeApp()).then(unwrapResult);
    } catch (e) {
      console.log(e);
      history.push(`/login?from=${location?.pathname}`);
      return;
    }

    if (location && location.pathname.includes("oauth2")) {
      // stay on oauth screen
      return;
    }
    if (farms && farms.length > 0) {
      try {
        if (location && location.pathname.includes("farm")) {
          const farmId = location.pathname.split("/")[2];
          const farm = await dispatch(fetchFarmById(farmId)).then(unwrapResult);
          await dispatch(initializeFarm(farm));
        } else if (location && location.pathname.includes("field")) {
          const fieldId = location.pathname.split("/")[2];
          const field = await dispatch(fetchFieldsById([fieldId])).then(
            unwrapResult
          );
          if (field.length === 1) {
            const farm = await dispatch(fetchFarmById(field[0].farm)).then(
              unwrapResult
            );
            await dispatch(initializeFarm(farm));
          } else {
            console.log("found more than 1 field for the same guid");
          }
        } else if (location && location.pathname.includes("animal-group")) {
          const animalGroupId = location.pathname.split("/")[2];
          const animalGroups = await dispatch(
            fetchAnimalGroupsById([animalGroupId])
          ).then(unwrapResult);

          if (animalGroups[0]) {
            const farm = await dispatch(
              fetchFarmById(animalGroups[0].farm)
            ).then(unwrapResult);
            await dispatch(initializeFarm(farm));
          } else {
            // no animalgroups for whatever reason
            await redirectToDefaultFarm(farms);
          }
        } else {
          // if no farm/field is looked for specifically,
          // we direct the user to the first farm created,
          // which presumable is the default farm
          redirectToInitialViewSelect();
        }
      } catch (e) {
        redirectToInitialViewSelect();
      }
    } else {
      history.push("/add-farm/enter-name");
    }

    async function redirectToDefaultFarm(farms: Farm[]) {
      const defaultFarm = farms[0];
      await dispatch(initializeFarm(defaultFarm));
      history.push(`/farm/${defaultFarm.guid}`);
    }
  };

  return (
    <Route
      {...rest}
      render={() => {
        if (loggedIn) {
          return children as ReactNode;
        } else if (tokenValid() && !loggedIn) {
          void handleRefresh(); // Note: if this function throws, the app will hang
          return children as ReactNode;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
