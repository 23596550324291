import { Position } from "@turf/turf";
import { useNotification } from "app/NotificationProvider";
import { AnimalsLocationIcon } from "common/icons";
import {
  Field,
  openDrawer,
  openMoveOutEventDialog,
  setIsDraggingAnimalMarker,
  useAppDispatch,
} from "model";
import { useState } from "react";
import { Marker } from "react-map-gl";
import { useHistory, useRouteMatch } from "react-router-dom";
import { mixpanel } from "common/analytics";
import { useTranslation } from "common/locales";
import { booleanPointInPolygon } from "@turf/turf";
import { moveAnimalsIn } from "features/singleCoverInput/UpdateBiomassAfterMoveInDialog";

interface MatchProps {
  id: string;
}
interface AnimalsLocationMarkerProps {
  htmlColor: string;
  zoom: number;
  coordinates: Position;
  currentField: Field;
  fields: Field[];
}

export default function AnimalsLocationMarker({
  htmlColor,
  zoom,
  coordinates,
  currentField,
  fields,
}: AnimalsLocationMarkerProps) {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const history = useHistory();
  const { t } = useTranslation();
  let width = 24; // non-standard size
  let height = 31; // non-standard size
  let fontSize: "medium" | "small" = "medium";
  if (zoom > 15) {
    fontSize = "small";
    width = 20;
    height = 25.6;
  }

  const initialScreenSelectMatch = useRouteMatch<MatchProps>({
    path: "/initial-screen-select",
    exact: false,
  });

  const inputAndOutputScreenMatch = useRouteMatch<MatchProps>({
    path: "/inputs-and-outputs",
    exact: false,
  });

  const reseedScreenMatch = useRouteMatch<MatchProps>({
    path: "/grass-types-reseeding-detail",
    exact: false,
  });

  return (
    <div
      onClick={() => {
        if (!isDragging && currentField.animalGroup) {
          history.push(`/animal-group/${currentField.animalGroup}`);
          dispatch(openDrawer());
        }
      }}
    >
      <Marker
        offsetTop={-height / 2}
        offsetLeft={-width / 2}
        longitude={coordinates[0]}
        latitude={coordinates[1]}
        draggable={
          !reseedScreenMatch &&
          !inputAndOutputScreenMatch &&
          !initialScreenSelectMatch
        }
        onDragStart={() => {
          setIsDragging(true);
          dispatch(setIsDraggingAnimalMarker(true));
        }}
        onDragEnd={async (e) => {
          setTimeout(() => {
            // setTimeout to prevent onClick event of the outer div when dragging the marker
            setIsDragging(false);
            dispatch(setIsDraggingAnimalMarker(false));
          }, 100);

          await onMoveIn(e.lngLat);
        }}
      >
        <AnimalsLocationIcon
          htmlColor={htmlColor}
          fontSize={fontSize || "small"}
        />
      </Marker>
    </div>
  );

  async function onMoveIn(lngLat: [number, number]) {
    let newField: Field | null = null;
    for (const field of fields) {
      if (booleanPointInPolygon(lngLat, field.geom.geometry as any)) {
        newField = field;
        break;
      }
    }
    if (
      !newField ||
      newField.guid === currentField.guid ||
      newField.animalGroup !== currentField.animalGroup
    ) {
      return;
    }

    try {
      if (newField) {
        if (currentField) {
          dispatch(
            openMoveOutEventDialog({
              open: true,
              newFieldWithAnimals: newField,
              currentFieldWithAnimals: currentField,
            })
          );
        } else {
          await moveAnimalsIn(
            newField,
            currentField,
            new Date(),
            dispatch,
            notify,
            t
          );
        }
      }
      mixpanel.track("AnimalMoveIn with drag successful");
      notify(
        t("fieldlistmenu.notification.moveInSuccess", {
          fieldName: newField.name,
        }),
        "success"
      );
    } catch (e) {
      mixpanel.track("AnimalMoveIn with drag failed");
      notify(
        t("fieldlistmenu.notification.moveInFailed", {
          fieldName: newField.name,
        }),
        "error"
      );
    }
  }
}
