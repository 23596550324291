import { Menu, MenuItem, PopoverPosition, Typography } from "@mui/material";
import {
  deleteField,
  useAppDispatch,
  useAppSelector,
  setIsEditing,
  changeFieldVisibility,
  selectFieldById,
  deleteAllTilesForField,
  FIELD_TYPE,
  selectArableFieldById,
  ArableField,
  Field,
  deleteArableField,
  changeArableFieldVisibility,
} from "model";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { useHistory } from "react-router-dom";
import useConfirmation from "common/utils/useConfirmation";

interface FieldEditActionMenuProps {
  fieldId: string;
  anchor?: null | HTMLElement;
  anchorPosition?: PopoverPosition;
  close: () => void;
  noEdit?: boolean;
  noDelete?: boolean;
  fieldType: FIELD_TYPE;
}

export default function FieldEditActionMenu({
  fieldId,
  anchor,
  anchorPosition,
  close,
  noEdit,
  noDelete,
  fieldType,
}: FieldEditActionMenuProps) {
  const currentField: Field | ArableField | undefined = useAppSelector(
    (state) =>
      fieldType == FIELD_TYPE.GRASS
        ? selectFieldById(state, fieldId)
        : selectArableFieldById(state, fieldId)
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { notify } = useNotification();

  const confirmDeletion = useConfirmation({
    title: t("confirmDeleteFieldDialog.title"),
    description: t("confirmDeleteFieldDialog.text", {
      fieldName: currentField?.name,
    }),
  });

  const currentFarmId = useAppSelector((state) => state.farms.currentFarmId);

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        anchorPosition={anchorPosition}
        anchorReference={
          anchor ? "anchorEl" : anchorPosition ? "anchorPosition" : "none"
        }
        keepMounted
        open={Boolean(anchor || anchorPosition)}
        onClose={close}
      >
        {!noEdit && (
          <MenuItem onClick={edit}>{t("fieldlistmenu.edit.label")}</MenuItem>
        )}
        {!noDelete && (
          <MenuItem onClick={onDelete}>
            <Typography color="secondary">
              {t("fieldlistmenu.delete.label")}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );

  async function onDelete() {
    const confirmed = await confirmDeletion();
    close();
    if (!confirmed || !currentField) return;
    if (fieldType == FIELD_TYPE.GRASS) {
      void dispatch(deleteField(currentField as Field));
    } else {
      void dispatch(deleteArableField(currentField as ArableField));
    }
    dispatch(deleteAllTilesForField(currentField.guid));
    mixpanel.track("Field deleted");
    history.replace(`/farm/${currentFarmId || ""}`);
  }

  function edit() {
    close();
    try {
      dispatch(setIsEditing());
      dispatch(
        fieldType === FIELD_TYPE.GRASS
          ? changeFieldVisibility({ guid: fieldId, visibility: "none" })
          : changeArableFieldVisibility({ guid: fieldId, visibility: "none" })
      );
      mixpanel.track("FieldEdit successful");
      history.push(`/edit-field/${fieldId}/enter-details`);
    } catch (e) {
      mixpanel.track("FieldEdit failed");
      notify(
        t("fieldlistmenu.notification.editFailed", {
          fieldName: currentField?.name,
        }),
        "error"
      );
    }
  }
}
