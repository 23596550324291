import { useHistory, useLocation } from "react-router-dom";
import { mixpanel } from "../common/analytics";
import { LinearProgress } from "@mui/material";
import { restoreToken } from "common/utils/token";

import { useFormik } from "formik";

import { FullScreenDialog } from "common/components";
import {
  fetchToken,
  initializeApp,
  useAppDispatch,
  useAppSelector,
  initializeFarm,
  changePassword,
} from "model";
import { unwrapResult } from "@reduxjs/toolkit";
import PasswordResetUserDetails from "./PasswordResetUserDetails";

import styled from "styled-components";
import { useTranslation } from "common/locales";
import { useEffect, useState } from "react";
import { useNotification } from "./NotificationProvider";

export interface FormValues {
  mail: string;
  password: string;
  confirmPassword: string;
}

export interface FormErrors {
  confirmPassword?: string | undefined;
}

export default function SignupDialog() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const creatingUser = useAppSelector((state) => state.user.loading);
  const appInitialising = useAppSelector((state) => state.app.loading);
  const user = useAppSelector((state) => state.user.details);

  const loading = creatingUser === "pending" || appInitialising === "pending";
  const { notify } = useNotification();

  useEffect(() => {
    if (restoreToken(location)) {
      const workaroundFunc = async () => {
        try {
          await dispatch(initializeApp()).then(unwrapResult);
        } catch (e) {
          console.log(e);
        }
      };
      void workaroundFunc();
    }
  }, [location, dispatch]);

  const { t } = useTranslation();
  const [passwordResetError, setPasswordResetError] = useState<boolean>(false);
  const [passwordResetInProgress, setPasswordResetInProgress] =
    useState<boolean>(false);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      mail: user?.mail || "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: () => {
      void handlePasswordReset();
    },
    validate: (values) => {
      setPasswordResetError(false);
      const errors: FormErrors = {};
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = t("signup.confirmPassword.error");
      }
      return errors;
    },
  });

  restoreToken(location);

  const handlePasswordReset = async () => {
    try {
      if (formik.values.password) {
        setPasswordResetInProgress(true);
        await dispatch(changePassword(formik.values.password)).then(
          unwrapResult
        );
        notify(t("passwordChange.success"));

        mixpanel.track("Password reset successfully");

        await dispatch(
          fetchToken({
            mail: formik.values.mail,
            password: formik.values.password,
          })
        ).then(unwrapResult);
        const farms = await dispatch(initializeApp()).then(unwrapResult);
        if (farms && farms.length > 0) {
          const defaultFarm = farms[0];
          await dispatch(initializeFarm(defaultFarm));
          history.push(`/farm/${defaultFarm.guid}`);
        } else {
          history.push("/add-farm/enter-name");
        }
      }
    } catch (err) {
      console.log(err);
      setPasswordResetError(true);
      notify(t("passwordChange.failure"));
      mixpanel.track("Password reset failed");
    } finally {
      setPasswordResetInProgress(false);
    }
  };

  return (
    <FullScreenDialog>
      {(loading || passwordResetInProgress) && <LinearProgress />}

      <PasswordResetUserDetails
        values={formik.values}
        errors={formik.errors}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
        passwordUpdateError={passwordResetError}
        passwordResetInProgress={passwordResetInProgress}
      />
    </FullScreenDialog>
  );
}

export const StyledForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 250px;
  padding-bottom: ${theme.spacing(2)};
`
);
