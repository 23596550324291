import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

interface InfoSnackbarProps {
  open: boolean;
  handleClose: () => void;
  message: string;
}

const InfoSnackbar = ({ open, handleClose, message }: InfoSnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      style={{ top: "50%" }}
      autoHideDuration={10000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="info" elevation={8}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default InfoSnackbar;
