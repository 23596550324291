import { Container } from "@mui/material";
import styled, { css } from "styled-components";

export const ButtonGroup = styled.div(
  ({ theme }) => css`
      position: absolute;
      right: ${theme.spacing(2)};
      padding - top: ${theme.spacing(2)};
      padding - bottom: ${theme.spacing(2)};
      `
);

export const ButtonContainer = styled(Container)`
  position: relative;
  flex: 0 0 auto;
  display: block;
  height: 70px;
  width: 100 %;
  background: white;
`;
