import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";
import {
  RootState,
  selectAllAnimalGroups,
  selectAllStockClasses,
  selectStockClassByAnimalGroupId,
  StockClass,
  stockClassAdapter,
} from "model";
import { API } from "common/utils";

export enum FEED_ALLOCATION_TYPE {
  STOCK_CLASS = "STOCK_CLASS",
  OFFSPRING = "OFFSPRING",
}

export interface StockClassFeedAllocation {
  guid: string;
  dateStart: string;
  allocatedBodyWeightFraction: number;
  allocationType: FEED_ALLOCATION_TYPE;
  stockClassGuid: string;
}

const stockClassFeedAllocationApi = new API<StockClassFeedAllocation>(
  "stockClassFeedAllocations"
);

const stockClassFeedAllocationAdapter =
  createEntityAdapter<StockClassFeedAllocation>({
    selectId: (stockClassFeedAllocation) => stockClassFeedAllocation.guid,
  });

export const { selectAll: selectAllStockClassFeedAllocations } =
  stockClassFeedAllocationAdapter.getSelectors(
    (state: RootState) => state.stockClassFeedAllocations
  );

type StockClassFeedAllocationsByAnimalGroupId = {
  [id: string]: Array<StockClassFeedAllocation>;
};

const selectStockClassFeedAllocationByStockClassId = createSelector(
  selectAllStockClassFeedAllocations,
  (stockClassFeedAllocations) =>
    stockClassFeedAllocations.reduce(
      (
        acc: StockClassFeedAllocationsByAnimalGroupId,
        stockClassFeedAllocation
      ) => {
        if (!acc[stockClassFeedAllocation.stockClassGuid]) {
          acc[stockClassFeedAllocation.stockClassGuid] = [];
        }
        acc[stockClassFeedAllocation.stockClassGuid].push(
          stockClassFeedAllocation
        );

        return acc;
      },
      {}
    )
);

const selectItemId = (state: RootState, itemId: string) => itemId;

export const selectStockClassFeedAllocationsForStockClass = createSelector(
  [selectStockClassFeedAllocationByStockClassId, selectItemId],
  (stockClassFeedAllocations, stockClassId) =>
    stockClassFeedAllocations[stockClassId]
);

export const selectStockClassFeedAllocationsForAnimalGroup = createSelector(
  [
    selectAllAnimalGroups,
    selectAllStockClasses,
    selectAllStockClassFeedAllocations,
    selectItemId,
  ],
  (animalGroups, stockClasses, stockClassFeedAllocations, animalGroupGuid) => {
    const animalGroup = animalGroups.find((ag) => ag.guid === animalGroupGuid);
    const filteredFeedAllocationGuids = stockClasses
      .filter((sc) => sc.animalGroup === animalGroup?.guid)
      .flatMap((sc) => sc.feedAllocations || []);
    const filteredStockClassFeedAllocations = stockClassFeedAllocations.filter(
      (scbp) => filteredFeedAllocationGuids.includes(scbp.guid)
    );
    return filteredStockClassFeedAllocations;
  }
);

//TODO: current version uses static feed allocation value per stock class for the period until the next feed allocation, ideally should interpolate between two feed allocations using the same algorithm as in the chart
export const getDemandForFeedAllocations = createSelector(
  [
    selectStockClassByAnimalGroupId,
    selectAllStockClassFeedAllocations,
    selectItemId,
  ],
  (stockClasses, feedAllocations, animalGroupId) => {
    const stockClassesForAnimalGroup = stockClasses[animalGroupId];
    let demand = 0;
    const now = new Date().valueOf();
    if (stockClassesForAnimalGroup) {
      for (const sc of stockClassesForAnimalGroup) {
        const feedAllocationsForStockClass = feedAllocations.filter(
          (fa) =>
            sc.feedAllocations?.includes(fa.guid) &&
            new Date(fa.dateStart).valueOf() <= now
        );

        feedAllocationsForStockClass.sort(
          (a, b) =>
            new Date(b.dateStart).valueOf() - new Date(a.dateStart).valueOf()
        ); // sort by date descending

        const currentFeedAllocation = feedAllocationsForStockClass.at(0); // take the latest
        if (currentFeedAllocation) {
          demand +=
            sc.animalCount *
            sc.averageAnimalWeightKg *
            currentFeedAllocation.allocatedBodyWeightFraction;
        }
      }
    }
    return demand;
  }
);

export const fetchStockClassFeedAllocationsById = createAsyncThunk(
  "stockClassFeedAllocations/fetchById",
  (stockClassFeedAllocationIds: string[]) => {
    return stockClassFeedAllocationApi.getMany(stockClassFeedAllocationIds);
  }
);

export const createStockClassFeedAllocation = createAsyncThunk(
  "stockClassFeedAllocation/create",
  async (stockClassFeedAllocation: StockClassFeedAllocation, { dispatch }) => {
    const newStockClassFeedAllocation =
      await stockClassFeedAllocationApi.createOne(stockClassFeedAllocation);
    return await stockClassFeedAllocationApi.getOne(
      newStockClassFeedAllocation.guid
    );
  }
);

export const updateStockClassFeedAllocation = createAsyncThunk(
  "stockClassFeedAllocation/update",
  async (stockClassFeedAllocation: StockClassFeedAllocation, { dispatch }) => {
    const updatedStockClassFeedAllocation =
      await stockClassFeedAllocationApi.updateOne(stockClassFeedAllocation);
    return await stockClassFeedAllocationApi.getOne(
      updatedStockClassFeedAllocation.guid
    );
  }
);

export const deleteStockClassFeedAllocation = createAsyncThunk(
  "stockClassFeedAllocation/delete",
  async (stockClassFeedAllocationId: string) => {
    await stockClassFeedAllocationApi.deleteOne(stockClassFeedAllocationId);
    return stockClassFeedAllocationId;
  }
);

export const deleteStockClassFeedAllocations = createAction<string[]>(
  "stockClassFeedAllocation/deleteMany"
);

type StockClassFeedAllocationState = EntityState<StockClassFeedAllocation> & {
  loading: boolean;
  error: string | null;
  status: "success" | "error" | null;
};

const initialStockClassFeedAllocationState: StockClassFeedAllocationState =
  stockClassFeedAllocationAdapter.getInitialState({
    loading: false,
    error: null,
    status: null,
  });

const stockClassFeedAllocationSlice = createSlice({
  name: "stockClassFeedAllocation",
  initialState: initialStockClassFeedAllocationState,
  reducers: {
    resetStockClassFeedAllocations: () => initialStockClassFeedAllocationState,
  },
  extraReducers: {
    [fetchStockClassFeedAllocationsById.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [fetchStockClassFeedAllocationsById.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.status = "success";
      stockClassFeedAllocationAdapter.upsertMany(
        state,
        payload as
          | readonly StockClassFeedAllocation[]
          | Record<EntityId, StockClassFeedAllocation>
      );
    },
    [fetchStockClassFeedAllocationsById.rejected.type]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.status = "error";
    },
    [updateStockClassFeedAllocation.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [updateStockClassFeedAllocation.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassFeedAllocationAdapter.upsertOne(
        state,
        payload as StockClassFeedAllocation
      );
    },
    [updateStockClassFeedAllocation.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [createStockClassFeedAllocation.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [createStockClassFeedAllocation.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassFeedAllocationAdapter.upsertOne(
        state,
        payload as StockClassFeedAllocation
      );
    },
    [createStockClassFeedAllocation.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [deleteStockClassFeedAllocation.pending.type]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteStockClassFeedAllocation.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      stockClassFeedAllocationAdapter.removeOne(state, payload as EntityId);
    },
    [deleteStockClassFeedAllocation.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.status = "error";
    },
    [deleteStockClassFeedAllocations.type]: (state, { payload }) => {
      stockClassFeedAllocationAdapter.removeMany(
        state,
        payload as readonly EntityId[]
      );
    },
  },
});

export type StockClassWiths = StockClass & {
  Values: Array<StockClassFeedAllocation>;
};

export const stockClassFeedAllocationReducer =
  stockClassFeedAllocationSlice.reducer;
export const { resetStockClassFeedAllocations } =
  stockClassFeedAllocationSlice.actions;
