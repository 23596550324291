import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "common/utils/Api";
import { ExternalProvider } from "features/signup/Oauth2RedirectScreen";
import { RootState } from "model";

export type User = {
  guid: string;
  name?: string;
  mail: string;
  password: string;
  farms: Array<string>;
  phone?: string;
  address?: string;
  lang?: string;
  oauth2clients?: Array<ExternalProvider>;
  userType?: string;
};

type SliceState = {
  loading: "pending" | "idle";
  error?: boolean;
  details?: User;
};

class UserApi extends API<User> {
  async removeOauth2Client(id: string, externalProvider: ExternalProvider) {
    const res = await fetch(
      `${this.baseUrl}${
        this.entity
      }/oauth2/${externalProvider.toLowerCase()}/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  }
}

const userApi = new UserApi("users");

export const fetchUserById = createAsyncThunk<Promise<User>, string>(
  "user/fetchById",
  async (id) => userApi.getOne(id)
);

export const createUser = createAsyncThunk<Promise<{ guid: string }>, User>(
  "user/create",
  async (user) => userApi.createOne(user)
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (password: string, { getState, dispatch, requestId }) => {
    const user = (getState() as RootState).user.details;
    if (user) {
      await userApi.updateOne({ ...user, password });
    }
  }
);

export const removeOauth2Client = createAsyncThunk(
  "user/removeOauth2Client",
  async (
    externalProvider: ExternalProvider,
    { getState, dispatch, requestId }
  ) => {
    const user = (getState() as RootState).user.details;
    if (user) {
      await userApi.removeOauth2Client(user.guid, externalProvider);
    }
  }
);

const initialUserState: SliceState = {
  loading: "idle",
  error: false,
  details: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    resetUser: () => initialUserState,
  },
  extraReducers: {
    [fetchUserById.pending.type]: (state, action) => {
      state.loading = "pending";
    },
    [fetchUserById.fulfilled.type]: (state, action) => {
      state.loading = "idle";
      state.details = action.payload;
    },
    [fetchUserById.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.error = action.error;
    },
    [createUser.pending.type]: (state, action) => {
      state.loading = "pending";
    },
    [createUser.fulfilled.type]: (state, action) => {
      state.loading = "idle";
    },
    [createUser.rejected.type]: (state, action) => {
      state.loading = "idle";
      state.error = action.error;
    },
  },
});

export const userReducer = userSlice.reducer;
export const { resetUser } = userSlice.actions;
