import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ManualDMInputItem, getAllFieldGeoms } from "model";
import { FieldIcon } from "features/farmOverview";
import { useTranslation } from "common/locales";
import StyledCoverAppBar from "./StyledCoverAppBar";

interface Props {
  manualDMInputs: Array<ManualDMInputItem>;
  setManualDMInputs: Dispatch<SetStateAction<Array<ManualDMInputItem>>>;
}

export default function EnterCovers({
  manualDMInputs,
  setManualDMInputs,
}: Props) {
  const { t } = useTranslation();
  const fieldGeoms = useSelector(getAllFieldGeoms);

  const handleDMInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    setManualDMInputs(
      manualDMInputs.map((i) => {
        if (i.field === fieldId) {
          i.newDryMatterValue = e.target.value
            ? Number(e.target.value)
            : undefined;
        }
        return i;
      })
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography variant="h5">
          {t("addCovers.enterCovers.heading")}
        </Typography>
        <Typography variant="h6">
          {t("addCovers.enterCovers.subheading")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TableContainer component={Paper}>
          <Table aria-label={t("addCovers.enterCovers.heading")}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  {t("addCovers.enterCovers.fieldName")}
                </TableCell>
                <TableCell align="center">
                  {t("addCovers.enterCovers.current")}
                </TableCell>
                <TableCell align="center">
                  {t("addCovers.enterCovers.new")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manualDMInputs.map((reading) => (
                <TableRow key={reading.field}>
                  <TableCell>
                    <FieldIcon
                      geom={fieldGeoms.features.find(
                        (f) => f.properties?.id === reading.field
                      )}
                      dryMatterNow={reading.dryMatterValue}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {reading.fieldName}
                  </TableCell>
                  <TableCell align="center">{reading.dryMatterValue}</TableCell>
                  <TableCell align="right">
                    <TextField
                      variant="standard"
                      value={reading.newDryMatterValue ?? ""}
                      type="number"
                      onChange={(e) => handleDMInput(e, reading.field)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={3}></Grid>
      <StyledCoverAppBar>
        <Button component={Link} to="enter-time" color="secondary">
          {t("addCovers.back.label")}
        </Button>
        <Button component={Link} to="enter-reasons" color="primary">
          {t("addCovers.next.label")}
        </Button>
      </StyledCoverAppBar>
    </Grid>
  );
}
