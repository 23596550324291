import { Menu, MenuItem, PopoverPosition } from "@mui/material";
import { mixpanel } from "common/analytics";
import { useTranslation } from "common/locales";
import { FertilizerEvent } from "model";
import { Dispatch, SetStateAction } from "react";

interface Props {
  fertilizerEvent: FertilizerEvent;
  anchor: HTMLElement | null;
  anchorPosition?: PopoverPosition;
  close: () => void;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}
export default function FertilizerEventMoreMenu({
  fertilizerEvent,
  anchor,
  anchorPosition,
  close,
  setDialogOpen,
}: Props) {
  const { t } = useTranslation();
  return (
    <Menu
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      anchorReference={
        anchor ? "anchorEl" : anchorPosition ? "anchorPosition" : "none"
      }
      open={anchor ? anchor.id === fertilizerEvent.guid : false}
      onClose={close}
      key={`fe-menu-${fertilizerEvent.guid}`}
    >
      <MenuItem
        key={`fe-menuitem-edit-${fertilizerEvent.guid}`}
        onClick={() => {
          mixpanel.track("Edit/view fertilizer event clicked");
          setDialogOpen(true);
          close();
        }}
      >
        {t("yieldEvent.menu.editView")}
      </MenuItem>
    </Menu>
  );
}
