import SvgIcon from "@mui/material/SvgIcon";

export default function FieldPreparationEventIcon() {
  return (
    <SvgIcon
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="40.000000pt"
      height="40.000000pt"
      viewBox="0 0 40.000000 40.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M43 346 c27 -21 23 -74 -8 -88 -14 -6 -25 -17 -25 -25 0 -16 0 -16
40 3 65 33 149 -19 159 -98 5 -32 11 -44 26 -47 11 -2 30 -14 42 -27 27 -30
75 -32 103 -4 22 22 24 39 10 84 -5 17 -12 44 -15 60 -4 20 -15 32 -36 40 -29
10 -31 14 -26 54 2 23 1 42 -2 42 -17 0 -31 -27 -31 -57 0 -28 -4 -33 -24 -33
-20 0 -27 8 -39 45 -9 25 -12 45 -6 45 5 0 9 5 9 10 0 6 -39 10 -97 10 -84 -1
-95 -3 -80 -14z m148 -46 c7 -22 10 -43 7 -46 -4 -3 -31 0 -62 8 -54 12 -56
14 -56 45 0 33 0 33 50 33 49 0 49 0 61 -40z m159 -102 c0 -22 -3 -23 -62 -19
-57 3 -77 12 -78 34 0 4 32 7 70 7 66 0 70 -1 70 -22z m-82 -60 c-7 -19 -38
-22 -38 -4 0 10 9 16 21 16 12 0 19 -5 17 -12z m104 -13 c9 -21 8 -28 -7 -45
-23 -25 -47 -25 -70 0 -15 17 -16 24 -7 45 16 35 68 35 84 0z"
        />
        <path
          d="M320 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M29 191 c-37 -37 -38 -79 -3 -120 21 -25 34 -31 64 -31 58 0 95 35
95 90 0 84 -95 121 -156 61z m111 -11 c26 -26 26 -74 0 -100 -42 -42 -113 -11
-113 50 0 61 71 92 113 50z"
        />
        <path
          d="M67 153 c-13 -13 -7 -42 12 -52 27 -15 56 9 47 37 -7 21 -44 31 -59
15z m33 -23 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z"
        />
      </g>
    </SvgIcon>
  );
}
