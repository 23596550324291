import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GlobalStyles } from "@mui/material";
import {
  ThemeProvider as StyledThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { HashRouter, Switch, Route } from "react-router-dom";

import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { ConfirmProvider } from "material-ui-confirm";

import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";

import store from "model/rootReducer";

import localeData from "common/locales";

import NotificationProvider from "./NotificationProvider";
import RefreshProvider from "./RefreshProvider";
import { useTokenRefresh } from "common/utils";

import { PrivateRoute } from "app";
import LoginDialog from "./LoginDialog";
import { MapRef } from "react-map-gl";
import Drawer from "./Drawer";
import { MapView } from "features/mapLayers";
import { NewSignupDialog, SignupDialog } from "features/signup";

import "@mui/material/styles/createPalette";
import Oauth2Screen from "features/signup/Oauth2RedirectScreen";
import PasswordForgotDialog from "./PasswordForgotDialog";
import PasswordResetDialog from "./PasswordResetDialog";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles/createPalette" {
  // adding custom color to the theme
  interface Palette {
    carbon: PaletteColor;
    drawTool: PaletteColor;
  }
  interface PaletteOptions {
    carbon: PaletteColorOptions;
    drawTool: PaletteColorOptions;
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    carbon: true;
    drawTool: true;
  }

  interface ButtonGroupPropsColorOverrides {
    carbon: true;
    drawTool: true;
  }

  interface SvgIconPropsColorOverrides {
    carbon: true;
    drawTool: true;
  }
}

const spacing = (value: number) => `${8 * value}px`;

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  spacing,
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${spacing(1.5)} ${spacing(2.5)}!important`,
        },
      },
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#309381",
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: "#CD0303",
    },
    carbon: {
      main: "#7935bc",
    },
    drawTool: createColor("#e0e0e0"), // old main color
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default function App() {
  let locale = store.getState().app.locale;
  const mapRef = useRef<MapRef>(null);
  const [messages, setMessages] = useState(localeData["en"]);
  useTokenRefresh();

  const updateLocale = () => {
    locale = store.getState().app.locale;
    if (Object.keys(localeData).includes(locale)) {
      setMessages(localeData[locale]);
    } else {
      setMessages(localeData["en"]);
    }
  };
  store.subscribe(updateLocale);

  return (
    <Provider store={store}>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <HashRouter>
          <GlobalStyles styles={{ "*": { "touch-action": "pan-x pan-y" } }} />
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <NotificationProvider>
                  <ConfirmProvider>
                    <RefreshProvider>
                      <Root>
                        <Switch>
                          <Route
                            path="/login"
                            exact
                            children={<LoginDialog />}
                          />
                          <Route
                            path="/signup"
                            children={<NewSignupDialog />}
                          />
                          <PrivateRoute
                            path="/oauth2"
                            children={<Oauth2Screen />}
                          />
                          <Route
                            path="/login/password-forgot"
                            exact
                            children={<PasswordForgotDialog />}
                          />
                          <Route
                            path="/login/password-reset"
                            exact
                            children={<PasswordResetDialog />}
                          />
                          <PrivateRoute
                            path="/"
                            exact
                            children={<LoginDialog />}
                          />
                        </Switch>
                        <Drawer mapRef={mapRef} />
                        <MapView mapRef={mapRef} />
                      </Root>
                    </RefreshProvider>
                  </ConfirmProvider>
                </NotificationProvider>
              </StyledThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </HashRouter>
      </IntlProvider>
    </Provider>
  );
}

const Root = styled.div`
  flex-grow: 1;
`;
