/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-use-before-define */
export type Point = {
  x: number;
  y: number;
};

export type DatePoint = {
  date: Date;
  value: number;
};

/*  
Adapted from: https://gist.github.com/niclasmattsson/7bceb05fba6c71c78d507adae3d29417 . Using similar approach to d3's monotone curve (https://github.com/d3/d3-shape/blob/main/src/curve/monotone.js)

Interpolate using cubic Hermite splines. The breakpoints in arrays xbp and ybp are assumed to be sorted.
Evaluate the function in all points of the array xeval.
Method:
  "Steffen" - monotonic, one pass
Sources:
  Steffen (1990), "A Simple Method for Monotonic Interpolation in One Dimension", http://adsabs.harvard.edu/abs/1990A%26A...239..443S
*/

export function interpolateCubicHermite(
  xeval: Array<number>,
  xbp: Array<number>,
  ybp: Array<number>
): Array<Point> {
  // first we need to determine tangents (m)
  const n = xbp.length;
  if (n < 1) {
    return [];
  }
  const obj = calcTangents(xbp, ybp);
  const m = obj.m; // length n
  const delta = obj.delta; // length n-1

  const c = new Array(n - 1);
  const d = new Array(n - 1);
  for (let k = 0; k < n - 1; k++) {
    const xdiff = xbp[k + 1] - xbp[k];
    c[k] = (3 * delta[k] - 2 * m[k] - m[k + 1]) / xdiff;
    d[k] = (m[k] + m[k + 1] - 2 * delta[k]) / xdiff / xdiff;
  }

  const len = xeval.length;
  const f = new Array<Point>(len);
  let k = 0;

  for (let i = 0; i < len; i++) {
    const x = xeval[i];
    if (x < xbp[0] || x > xbp[n - 1]) {
      // TODO: this might happen if we don't have enough feed allocation points in the database, just setting the value to 0 for now!

      f[i] = {
        x,
        y: 0,
      };
      continue;
    }
    while (k < n - 1 && x > xbp[k + 1]) {
      // TODO: use binary search
      k++;
    }
    const xdiff = x - xbp[k];
    f[i] = {
      x,
      y:
        ybp[k] +
        m[k] * xdiff +
        c[k] * xdiff * xdiff +
        d[k] * xdiff * xdiff * xdiff,
    };
  }

  return f;
}

function calcTangents(x: Array<number>, y: Array<number>) {
  const n = x.length;
  const delta = new Array(n - 1);
  const m = new Array(n);
  for (let k = 0; k < n - 1; k++) {
    const deltak = (y[k + 1] - y[k]) / (x[k + 1] - x[k]);
    delta[k] = deltak;
    if (k == 0) {
      // left endpoint, same for all methods
      m[k] = deltak;
    } else {
      // Steffen
      const p =
        ((x[k + 1] - x[k]) * delta[k - 1] + (x[k] - x[k - 1]) * deltak) /
        (x[k + 1] - x[k - 1]);
      m[k] =
        (Math.sign(delta[k - 1]) + Math.sign(deltak)) *
        Math.min(Math.abs(delta[k - 1]), Math.abs(deltak), 0.5 * Math.abs(p));
    }
  }
  m[n - 1] = delta[n - 2];
  return { m, delta };
}

export function sortPoints(points: Array<Point>) {
  const len = points.length;
  points.sort(function (a, b) {
    return a.x - b.x;
  });
  const x = [],
    y = [];
  let xmin = Infinity,
    xmax = -Infinity;
  for (let i = 0; i < len; i++) {
    x.push(points[i].x);
    y.push(points[i].y);
    xmin = points[i].x < xmin ? points[i].x : xmin;
    xmax = points[i].x > xmax ? points[i].x : xmax;
  }
  return { x, y, xmin, xmax };
}

export function interpolate(x: Array<number>, points: Array<Point>) {
  const sortedPoints = sortPoints(points);
  return interpolateCubicHermite(x, sortedPoints.x, sortedPoints.y);
}

function dateToMs(d: Date): number {
  return d.getTime();
}

function msToDate(x: number): Date {
  return new Date(x);
}

export function interpolateTimeSeries(
  xdates: Array<Date>,
  datePoints: Array<DatePoint>
): Array<DatePoint> {
  xdates.sort((a, b) => a.valueOf() - b.valueOf());
  datePoints.sort((a, b) => a.date.valueOf() - b.date.valueOf());

  const points = datePoints.map((p) => ({
    x: dateToMs(p.date),
    y: p.value,
  }));
  const xeval = xdates.map(dateToMs);
  const result = interpolate(xeval, points);

  return result.map((p) => ({
    date: msToDate(p.x),
    value: p.y,
  }));
}
