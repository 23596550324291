import { Card, CardContent, PopoverPosition, Typography } from "@mui/material";
import {
  useAppSelector,
  Field,
  selectAnimalGroupById,
  getDemand,
  selectFieldById,
} from "model";
import { sortBy } from "lodash";
import React, { useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { dryMatterToColor } from "common/utils/grazing-logic";
import { MoveFieldMenu } from "features/grassWedge";
import { useTranslation } from "common/locales";

export default function FeedAllocation({
  fields,
  preGrazingTarget,
  postGrazingTarget,
}: GrassWedgeProps) {
  const [menuAnchorPosition, setMenuAnchorPosition] =
    useState<PopoverPosition>();
  const [menuFieldId, setMenuFieldId] = useState<string>("");
  const data = prepareData();

  return (
    <>
      <Container>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 100 }}
          >
            <XAxis
              type="category"
              interval={0}
              dataKey="name"
              tickCount={data.length}
              tick={CustomAxisTick}
            />
            <YAxis />
            <Tooltip content={(props: any) => <CustomTooltip {...props} />} />
            <CartesianGrid vertical={false} />
            <Bar
              dataKey="dryMatterNow"
              fill="#8884d8"
              shape={(props: any) => (
                <AreaRectangle {...props} fields={fields} />
              )}
            >
              {data.map((field: Field) => (
                <Cell
                  key={field.guid}
                  fill={dryMatterToColor(field.dryMatterNow)}
                  onClick={(event) => {
                    setMenuFieldId(field.guid);
                    openMenu(event);
                  }}
                  style={{ cursor: "pointer" }}
                />
              ))}
            </Bar>
            <Line
              type="monotone"
              dataKey="demandReference"
              stroke="#2C98F0"
              connectNulls
              strokeWidth="5"
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Container>
      <MoveFieldMenu
        anchorPosition={menuAnchorPosition}
        close={closeMenu}
        fieldId={menuFieldId}
      />
    </>
  );

  function prepareData() {
    const sortedFields: Array<Field & { demandReference?: number }> = sortBy(
      fields,
      ({ dryMatterNow }) => -dryMatterNow
    ).map((field) => ({ ...field }));

    if (sortedFields.length > 1) {
      sortedFields[0].demandReference = preGrazingTarget;
      sortedFields[sortedFields.length - 1].demandReference = postGrazingTarget;
    }

    return sortedFields;
  }

  function openMenu(event: React.MouseEvent<SVGElement, MouseEvent>) {
    setMenuAnchorPosition({ top: event.clientY, left: event.clientX });
  }

  function closeMenu() {
    setMenuAnchorPosition(undefined);
  }
}

type GrassWedgeProps = {
  fields: Field[];
  preGrazingTarget: number;
  postGrazingTarget: number;
};

function AreaRectangle({
  fields,
  width,
  x,
  payload: { area },
  ...props
}: AreaRectangleProps) {
  const minArea = Math.min(...fields.map(({ area }) => area));
  const maxArea = Math.max(...fields.map(({ area }) => area));

  const adjustedWidth =
    width * (0.5 + (area - minArea) / (maxArea - minArea) / 2);

  const adjustedX = x + width / 2 - adjustedWidth / 2;
  return <Rectangle x={adjustedX} width={adjustedWidth} {...props} />;
}

type AreaRectangleProps = {
  fields: Field[];
  width: number;
  x: number;
  payload: Field;
};

type CustomTooltipProps = {
  payload: Array<{ payload: Field }>;
  active: boolean;
  label: string;
};

function CustomTooltip({ active, label, payload }: CustomTooltipProps) {
  const { t } = useTranslation();

  const field = useAppSelector((state) =>
    selectFieldById(state, payload && payload[0] ? payload[0].payload.guid : "")
  );
  let availableDryMatter;

  const animalGroup = useAppSelector((state) =>
    selectAnimalGroupById(state, field?.animalGroup ?? "")
  );

  if (field && animalGroup && animalGroup.postGrazingTarget) {
    availableDryMatter = Math.floor(
      ((field.dryMatterNow - animalGroup.postGrazingTarget) * field.area) /
        10000
    );
    if (availableDryMatter < 0) {
      availableDryMatter = 0;
    }
  }
  const demand = useAppSelector((state) =>
    getDemand(state, field?.animalGroup ?? "")
  );

  if (!payload || !payload[0]) {
    return null;
  }

  let grazingDaysAhead;
  if (availableDryMatter && demand) {
    grazingDaysAhead = availableDryMatter / demand;
  }
  if (active) {
    return (
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h6">{`${label}`}</Typography>
          <Typography>
            {t("grassWedge.tooltip.currentDM", {
              dm: payload[0].payload.dryMatterNow,
            })}
          </Typography>
          <Typography>
            {t("grassWedge.tooltip.area", {
              area: (payload[0].payload.area / 10000).toFixed(2),
            })}
          </Typography>
          <Typography>
            {t("grassWedge.tooltip.grazindDaysAhead", {
              days: grazingDaysAhead ? Math.floor(grazingDaysAhead) : 0,
              hours: grazingDaysAhead
                ? Math.round((grazingDaysAhead % 1) * 24)
                : 0,
            })}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return null;
}

type CustomAxisTickProps = {
  x: number;
  y: number;
  payload: {
    value: string;
    index: number;
  };
};

function CustomAxisTick({ x, y, payload }: CustomAxisTickProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
}

const Container = styled.div`
  width: 90vw;
  height: 60vh;
`;
