import { List, ListItem, ListItemText } from "@mui/material";
import { useAppSelector, YieldEvent, selectAllYieldEvents } from "model";
import { useTranslation } from "common/locales";
import YieldEventListItem from "./YieldEventListItem";
export default function YieldEventList() {
  const { t } = useTranslation();

  const yieldEvents: Array<YieldEvent> = useAppSelector((state) =>
    selectAllYieldEvents(state)
  );

  const sortedYieldEventsForFarm = yieldEvents.sort(
    (a: YieldEvent, b: YieldEvent) =>
      new Date(b.yieldEventDate).valueOf() -
      new Date(a.yieldEventDate).valueOf()
  );

  return (
    <List>
      <ListItem key="title">
        <ListItemText primary={t("feedPurchase.list.title")} />
      </ListItem>
      {sortedYieldEventsForFarm.map((event: YieldEvent) => {
        return (
          <YieldEventListItem
            yieldEvent={event}
            key={`re-listitem-${event.guid}`}
          />
        );
      })}
    </List>
  );
}
