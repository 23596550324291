import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAllFields, Field, useAppDispatch } from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { GRASS_TYPE } from "model/fieldSlice";
import { updateField } from "model";
import EnterGrassTypes from "./EnterGrassTypes";

const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export type FieldWithNewGrassType = {
  field: Field;
  newGrassType?: GRASS_TYPE;
};
export default function GrassTypes() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const handleClose = () => {
    history.goBack();
  };

  const [fieldsWithNewGrassType, setFieldsWithNewGrassType] = useState<
    Array<FieldWithNewGrassType>
  >(
    useSelector(selectAllFields).map((field: Field) => {
      return { field: field, newGrassType: field.grassType };
    })
  );

  async function saveFields() {
    const results: Promise<any>[] = [];
    for (const fieldWithNewGrassType of fieldsWithNewGrassType) {
      results.push(
        dispatch(
          updateField({
            ...fieldWithNewGrassType.field,
            grassType: fieldWithNewGrassType.newGrassType,
          })
        )
      );
    }
    await Promise.all(results);
  }

  async function handleSave() {
    await saveFields();
    notify(t("updateGrassTypes.success"));
    mixpanel.track("Fields grass type update successful");
    handleClose();
  }

  return (
    <Dialog fullScreen disableEscapeKeyDown open onClose={handleClose}>
      <StyledContainer>
        <EnterGrassTypes
          fieldsWithNewGrassType={fieldsWithNewGrassType}
          setFieldsWithNewGrassType={setFieldsWithNewGrassType}
          handleSave={handleSave}
        />
      </StyledContainer>
    </Dialog>
  );
}
