import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "common/locales";
import { mixpanel } from "../common/analytics";
import {
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Typography,
  LinearProgress,
  Divider,
  Popover,
  useTheme,
  Box,
  DialogContent,
} from "@mui/material";

import {
  initializeApp,
  fetchToken,
  useAppDispatch,
  useAppSelector,
  initializeFarm,
  fetchFarmById,
  fetchFieldsById,
  fetchAnimalGroupsById,
  Farm,
} from "model";
import { FullScreenDialog } from "common/components";
import { unwrapResult } from "@reduxjs/toolkit";
import { getNavigatorGeolocation } from "model/geolocation";
import { getRedirectUrlWithToken } from "common/utils";
import { restoreToken } from "common/utils/token";

interface LocationType {
  from: { pathname: string };
}

const SIGNUP_URL = "https://www.ruumi.io/sign-up";
//TODO: we should not show the login dialog if there is a valid token
export default function LoginDialog() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [resetAnchorEl, setResetAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const authError = useAppSelector((state) => state.app.authError);
  const loginInProgress = useAppSelector((state) => state.app.loginInProgress);

  let from = { pathname: "/" };
  if (location.state) {
    from = { pathname: (location.state as LocationType).from.pathname };
  } else {
    const params = new URLSearchParams(location.search);
    if (params.has("from")) {
      from = { pathname: params.get("from") as string };
    }
  }

  const { t } = useTranslation();

  useEffect(() => void dispatch(getNavigatorGeolocation()), [dispatch]); // running once on mount
  restoreToken(location);
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    mixpanel.track("Login attempted");

    try {
      await dispatch(fetchToken({ mail, password })).then(unwrapResult);
      const farms = await dispatch(initializeApp()).then(unwrapResult);
      if (from && from.pathname.includes("oauth2")) {
        // stay on oauth screen
        history.push(from.pathname);
        return;
      }
      if (farms && farms.length > 0) {
        try {
          if (from.pathname.includes("farm")) {
            const farmId = from.pathname.split("/")[2];
            const farm = await dispatch(fetchFarmById(farmId)).then(
              unwrapResult
            );
            await dispatch(initializeFarm(farm));
            history.push(`/farm/${farm.guid}`);
          } else if (from.pathname.includes("field")) {
            const fieldId = from.pathname.split("/")[2];
            const field = await dispatch(fetchFieldsById([fieldId])).then(
              unwrapResult
            );
            if (field.length === 1) {
              const farm = await dispatch(fetchFarmById(field[0].farm)).then(
                unwrapResult
              );
              await dispatch(initializeFarm(farm));
              history.push(`/field/${fieldId}`);
            } else {
              console.log("found more than 1 field for the same guid");
            }
          } else if (from.pathname.includes("animal-group")) {
            const animalGroupId = from.pathname.split("/")[2];
            const animalGroups = await dispatch(
              fetchAnimalGroupsById([animalGroupId])
            ).then(unwrapResult);

            if (animalGroups[0]) {
              const farm = await dispatch(
                fetchFarmById(animalGroups[0].farm)
              ).then(unwrapResult);
              await dispatch(initializeFarm(farm));
              history.push(from.pathname);
            } else {
              // no animalgroups for whatever reason
              await redirectToScreenSelect(farms);
            }
          } else {
            // if no farm/field is looked for specifically,
            // we direct the user to the selection page
            await redirectToScreenSelect(farms);
          }
        } catch (e) {
          await redirectToScreenSelect(farms);
        }
      } else if (getRedirectUrlWithToken()) {
        history.push("/oauth2/redirect");
      } else {
        history.push("/add-farm/enter-name");
      }
      mixpanel.track("Login successful");
      mixpanel.people.increment("logins");
    } catch (err) {
      console.log(err);
      mixpanel.track("Login failed");
    }

    async function redirectToScreenSelect(farms: Farm[]) {
      const defaultFarm = farms[0];
      await dispatch(initializeFarm(defaultFarm));
      history.push(`/initial-screen-select`);
    }
  };

  const handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.push(`/login/password-forgot?mail=${mail}`);
  };

  const handleSignup = (e: React.MouseEvent) => {
    e.preventDefault();

    mixpanel.track("Signup attempted");

    if (from.pathname.includes("/oauth2/")) {
      // allowing to signup before filling the form when coming from oauth2 provider
      history.push(`/signup/user-details?from=${from.pathname}`);
      return;
    }

    const redirectUrl = getRedirectUrlWithToken();

    const signupUrl = new URL(SIGNUP_URL);

    if (redirectUrl) {
      // redirect after signup
      signupUrl.searchParams.append("redirectOnSignup", redirectUrl);
    }

    // TODO: in case the user has entered a mail
    // and password in the login dialog, can we
    // take it over and pre-populate the signup
    // dialog with them?
    window.location.assign(signupUrl.toString());
  };

  const isResetOpen = Boolean(resetAnchorEl);
  const resetId = isResetOpen ? "reset-popover" : undefined;

  return (
    <FullScreenDialog>
      {loginInProgress && <LinearProgress />}
      <DialogTitle id="dialog-title">{t("login.dialog.title")}</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleLogin}>
          <TextField
            variant="standard"
            required
            id="input-mail"
            disabled={loginInProgress}
            type="email"
            error={authError}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            label={t("login.mail.label")}
            autoFocus={true}
            data-private
          />
          <TextField
            variant="standard"
            required
            id="input-password"
            disabled={loginInProgress}
            error={authError}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t("login.password.label")}
            type="password"
          />
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loginInProgress}
            >
              {t("login.button.label")}
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
      <Divider variant="middle" light />
      <Box display="flex" p={1} justifyContent="center">
        <Button
          aria-describedby={resetId}
          onClick={(e) => handleReset(e)}
          size="small"
          disabled={loginInProgress}
        >
          {t("login.dialog.reset")}
        </Button>
        <Popover
          id={resetId}
          open={isResetOpen}
          anchorEl={resetAnchorEl}
          onClose={() => setResetAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{ style: { padding: theme.spacing(3) } }}
        >
          <Typography variant="subtitle1">
            {t("login.reset.popover")}
          </Typography>
        </Popover>
        <Button
          color="primary"
          variant="outlined"
          disabled={loginInProgress}
          onClick={(e) => handleSignup(e)}
          size="small"
        >
          {t("login.dialog.signup")}
        </Button>
      </Box>
    </FullScreenDialog>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: fit-content;
`;
