import { SelectChangeEvent } from "@mui/material";
import DropdownInput from "common/components/DropdownInput";
import { useTranslation } from "common/locales";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ARABLE_TYPE } from "model";
import { useVegetationStyles } from "./GrassTypeDropdown";
import { useStylesFormControl } from "./AssignedToDropdown";

interface ArableTypeDropdownProps {
  currentArableType?: string;
  disabled: boolean;
  onChange: (value?: ARABLE_TYPE) => void;
  arableTypeInputError: boolean;
  label: string;
}

export default function ArableTypeDropdown({
  disabled,
  onChange,
  currentArableType,
  arableTypeInputError,
  label,
}: ArableTypeDropdownProps) {
  const classes = useStylesFormControl();
  const { t } = useTranslation();

  return (
    <FormControl
      className={classes.formControl}
      disabled={disabled}
      error={arableTypeInputError}
      fullWidth
    >
      <InputLabel
        id="assignedGroup-select-label"
        variant="standard"
        shrink={true}
        error={arableTypeInputError}
      >
        {t(label)}
      </InputLabel>
      <Select
        labelId="assignedGroup-select-label"
        id="assignedGroup-select"
        value={currentArableType ?? ""}
        onChange={onChangeSelect}
        input={<DropdownInput />}
        error={arableTypeInputError}
      >
        {Object.entries(ARABLE_TYPE).map(([key, value]) => (
          <MenuItem value={value} key={key}>
            {t(`fieldinput.arableType.option.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  function onChangeSelect(e: SelectChangeEvent<string>) {
    onChange(e.target.value as ARABLE_TYPE | undefined);
  }
}
