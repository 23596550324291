import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { FeatureCollection } from "@turf/turf";
import { RootState } from "model";
import { API } from "common/utils";
import { ExternalProvider } from "features/signup/Oauth2RedirectScreen";

export type Farm = {
  guid: string;
  name: string;
  owner: string;
  user: string;
  geom: FeatureCollection;
  fields: string[];
  animalGroups: string[];
  externalId?: string;
  externalProvider?: ExternalProvider;
  externalSyncedOn?: string;
};

const farmApi = new API<Farm>("farms");

const farmAdapter = createEntityAdapter<Farm>({
  selectId: (farm) => farm.guid,
});

export const fetchFarmById = createAsyncThunk(
  "farm/fetchById",
  (farmId: string) => farmApi.getOne(farmId)
);

export const createFarm = createAsyncThunk(
  "farm/create",
  async (farm: Farm) => {
    const res = await farmApi.createOne(farm);
    const newFarm = await farmApi.getOne(res.guid);
    return newFarm;
  }
);

export const {
  selectAll: selectAllFarms,
  selectById: selectFarmById,
  selectEntities: selectFarmEntities,
  selectIds: selectFarmIds,
} = farmAdapter.getSelectors((state: RootState) => state.farms);

export function selectCurrentFarm(state: RootState) {
  if (!state.farms.currentFarmId) return undefined;
  return state.farms.entities[state.farms.currentFarmId];
}

type Status = "success" | "error" | null;
type LoadingState = "idle" | "pending";

export type FarmState = EntityState<Farm> & {
  currentFarmId?: string;
  loading: LoadingState;
  error: null;
  status: Status;
};

const initialFarmsState: FarmState = farmAdapter.getInitialState({
  currentFarmId: undefined,
  loading: "idle",
  error: null,
  status: null,
});

const farmsSlice = createSlice({
  name: "farms",
  initialState: initialFarmsState,
  reducers: {
    setCurrentFarmId: (state, { payload }) => {
      state.currentFarmId = payload;
    },
    resetFarms: () => initialFarmsState,
  },
  extraReducers: {
    [fetchFarmById.fulfilled.type]: (state, { payload }) => {
      farmAdapter.upsertOne(state, payload);
    },
    [createFarm.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.status = null;
      }
    },
    [createFarm.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "success";
        farmAdapter.upsertOne(state, action.payload);
      }
    },
    [createFarm.rejected.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.status = "error";
        state.error = action.error;
      }
    },
  },
});

const { actions, reducer } = farmsSlice;

export const { setCurrentFarmId, resetFarms } = actions;
export const farmsReducer = reducer;
